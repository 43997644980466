import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Row } from "react-flexbox-grid";

import { Weather } from "../../networking/Weather";
import { formatCelsiusTemperature, TemperatureUnit } from "../../formatting/temperature";

const useStyles = makeStyles((theme) => ({
  cityTemperatureRow: {
    padding: "5px 11px",
    width: "100%",
  },
  city: {
    fontSize: "0.8em",
    fontWeight: 600,
  },
  temperature: {
    color: theme.palette.primary.dark,
    fontSize: "3.5em",
    fontWeight: 700,
  },
  weatherDescription: {
    padding: "5px 11px",
    fontSize: "0.9em",
  },
}));

interface CityTemperatureAttributeProps {
  city: string;
  country: string;
  temperatureCelsius: number;
}

const CityTemperatureAttribute: React.FC<CityTemperatureAttributeProps> = (props) => {
  const classes = useStyles();
  const temperatureUnit: TemperatureUnit =
    props.country === "USA" ? TemperatureUnit.Fahrenheit : TemperatureUnit.Celsius;
  return (
    <div className={classes.cityTemperatureRow}>
      <div className={classes.temperature}>{formatCelsiusTemperature(props.temperatureCelsius, temperatureUnit)}</div>
      <div className={classes.city}>{props.city}</div>
    </div>
  );
};

interface Props extends WithTranslation {
  title: string;
  weather: Weather;
  country: string;
}

const WeatherCard: React.FC<Props> = ({ t, weather, country }: Props) => {
  const { city, temperatureCelsius, description } = weather;
  const classes = useStyles();
  return (
    <>
      <Row>
        <CityTemperatureAttribute city={city} country={country} temperatureCelsius={temperatureCelsius} />
      </Row>
      <Row>
        <div className={classes.weatherDescription}>{t(description)}</div>
      </Row>
    </>
  );
};

export default withTranslation(["hiveTab"])(WeatherCard);

import React, { Component } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import WorkshopDatePicker from "../components/WorkshopDatePicker";
import Spinner from "./Spinner";

import * as Seed from "../seed";

import "./SelectWorkshopDate.css";
import WorkshopReservationForm from "./WorkshopReservationForm";
import { placeWorkshop, workshopTimeSlotsThisYear } from "../networking/workshops";
import { connect } from "react-redux";

const TAKEN_ERROR_CODE = 1404;

const SelectWorkshopDateModes = {
    selectingDate: 0,
    fillingForm: 1,
    success: 2,
    workshopTimeTaken: 3,
    fail: 4,
    contactUs: 5,
};

class SelectWorkshopDate extends Component {
    state = {
        loading: true,
        mode: this.props.bookedWorkshop
            ? SelectWorkshopDateModes.contactUs
            : SelectWorkshopDateModes.selectingDate,
        timeSlots: [],
        workshopType: null,
        workshopId: null,
        selectedTime: null,
        seedWorkshop: null,
    };

    componentDidMount() {
        const { mode, seedWorkshop } = this.state;
        const { workshopType } = this.props;

        if (!seedWorkshop) {
            const seedWorkshop = Seed.visitTypes.find((visitType) =>
                visitType.rdvVisitTypeIds.includes(workshopType)
            );
            this.setState({ seedWorkshop });
        }

        if (mode !== SelectWorkshopDateModes.contactUs) {
            this.fetchTimeSlots();
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        const isPartnerBeekeeper = !this.props.clientBeekeeper?.isStaff;
        const contactEmail = isPartnerBeekeeper
            ? "support@alveole.buzz"
            : this.props.clientBeekeeper?.user?.email
              || this.props?.regions[this.props?.clientRegion]?.emails
              || "info@alveole.buzz";

        if (this.state.loading) {
            const loadingMessageText = window.isEnglish
                ? "One moment, we’re looking for available dates..."
                : "Un instant, nous trouvons les dates disponibles...";

            return (
                <div className="change-visit-dates-modal-content" style={{ minHeight: "600px" }}>
                    <Spinner />
                    <div className="loading-message">{loadingMessageText}</div>
                </div>
            );
        }

        switch (this.state.mode) {
            case SelectWorkshopDateModes.selectingDate: {
                const {
                    seedWorkshop: { titleEn, titleFr },
                } = this.state;

                let headerText = window.isEnglish
                    ? `Select your workshop: `
                    : `Choisissez votre atelier : `;

                const descriptionText = window.isEnglish
                    ? `Select the date and time you would like to have your workshop.`
                    : `Choisissez la date et l’heure à laquelle vous désirez votre atelier.`;

                const noTimeSlotsText = window.isEnglish ? ( // TODO ... localize
                    <>
                        Please{" "}
                        <a href={`mailto:${contactEmail}`}>
                        {isPartnerBeekeeper ? "get in touch with our Customer Service Team" : "get in touch with your beekeeper"}
                        </a>{" "}
                        to book this workshop!
                    </>
                ) : (
                    <>
                        <a href={`mailto:${contactEmail}`}>{isPartnerBeekeeper ? "Contactez notre équipe du service client" : "Contactez votre apiculteur"} </a>{" "}
                        pour réserver cet atelier dès maintenant!
                    </>
                );

                const timeSlotsWithAvailabilities = this.state.timeSlots.find(
                    (timeSlot) => timeSlot.times.length > 0
                );

                if (this.state.timeSlots.length === 0 || !timeSlotsWithAvailabilities) {
                    return (
                        <div className="change-visit-dates-modal-content">{noTimeSlotsText}</div>
                    );
                }

                return (
                    <div className="change-visit-dates-modal-content">
                        <div className="header">{headerText}</div>
                        <div className="header">{window.isEnglish ? titleEn : titleFr}</div>
                        <div className="description">{descriptionText}</div>
                        <WorkshopDatePicker
                            timeSlots={this.state.timeSlots}
                            onReserve={this.onReserve}
                            staffUser={this.props.staffUser}
                            clientRegion={this.props.clientRegion}
                        />
                    </div>
                );
            }
            case SelectWorkshopDateModes.fillingForm: {
                const { workshopType } = this.props;

                const headerText = window.isEnglish
                    ? `Your workshop details`
                    : `Détails de votre atelier`;

                const descriptionText = window.isEnglish
                    ? `Fill in your workshop details.`
                    : `Veuillez remplir les détails de votre atelier.`;

                return (
                    <div className="change-visit-dates-modal-content">
                        <div className="header">{headerText}</div>
                        <div className="description">{descriptionText}</div>
                        <WorkshopReservationForm
                            onSubmit={this.onFormSubmit}
                            workshopTypeId={workshopType}
                            onDatesChangedBackButtonClick={this.onDatesChangedBackButtonClick}
                        />
                    </div>
                );
            }
            case SelectWorkshopDateModes.success: {
                const { isEnglish } = window;
                const { selectedTime } = this.state;
                const newVisitDateFormatted = moment(selectedTime.showTime)
                    .locale(isEnglish ? "en" : "fr")
                    .format(isEnglish ? "dddd, MMMM Do [at] h:mm A" : "dddd D MMMM [à] H:mm");

                const headerText = isEnglish
                    ? "Your workshop has been scheduled"
                    : "Atelier reservé";

                const descriptionText = isEnglish
                    ? `Your workshop has been scheduled for ${newVisitDateFormatted}. See you then!`
                    : `Votre atelier a été reservé le ${newVisitDateFormatted}. Au plaisir de s'y voir!`;

                const closeButtonLabel = isEnglish ? "Close" : "Fermer";

                return (
                    <div className="change-visit-dates-modal-content">
                        <div className="header">{headerText}</div>
                        <div className="description">{descriptionText}</div>
                        <div className="modal-button-wrap">
                            <div
                                className="dates-close-button modal-button"
                                onClick={this.onCloseButtonClick}
                            >
                                {closeButtonLabel}
                            </div>
                        </div>
                    </div>
                );
            }
            case SelectWorkshopDateModes.workshopTimeTaken: {
                const headerText = window.isEnglish
                    ? "Bzzz... This date and time is not available anymore."
                    : "Bzzz... Ces date et heure ne sont plus disponibles.";

                const descriptionText = window.isEnglish
                    ? "The date and time you have selected is not available anymore. Please select another."
                    : "La date et l’heure que vous avez sélectionnées ne sont plus disponible. Veuillez en sélectionner d'autres.";

                const datesChangedBackButtonLabel = window.isEnglish ? "Back" : "Retour";

                return (
                    <div className="change-visit-dates-modal-content">
                        <div className="header">{headerText}</div>
                        <div className="description">{descriptionText}</div>
                        <div className="modal-button-wrap">
                            <div
                                className="dates-back-button modal-button"
                                onClick={this.onDatesChangedBackButtonClick}
                            >
                                {datesChangedBackButtonLabel}
                            </div>
                        </div>
                    </div>
                );
            }
            case SelectWorkshopDateModes.fail: {
                const headerText = window.isEnglish
                    ? "Bzzz... Something went wrong."
                    : "Bzzz... Quelque chose a mal tourné.";

                const descriptionText = window.isEnglish
                    ? "Please try again soon!"
                    : "Veuillez réessayer bientôt";

                const datesChangedBackButtonLabel = window.isEnglish ? "Back" : "Retour";

                return (
                    <div className="change-visit-dates-modal-content">
                        <div className="header">{headerText}</div>
                        <div className="description">{descriptionText}</div>
                        <div className="modal-button-wrap">
                            <div
                                className="dates-back-button modal-button"
                                onClick={this.onDatesChangedBackButtonClick}
                            >
                                {datesChangedBackButtonLabel}
                            </div>
                        </div>
                    </div>
                );
            }
            case SelectWorkshopDateModes.contactUs: {
                const visitType = Seed.visitTypes.find(
                    (visitType) => visitType.id === this.props.addVisitTypeId
                );

                let headerText;
                if (visitType) {
                    const visitTypeTitle = window.isEnglish ? visitType.titleEn : visitType.titleFr;
                    headerText = window.isEnglish
                        ? `Change your ${visitTypeTitle} workshop`
                        : `Modifiez votre atelier ${visitTypeTitle}`;
                } else {
                    headerText = window.isEnglish
                        ? "Change your workshop"
                        : "Modifiez votre atelier";
                }

                const descriptionText = window.isEnglish
                    ? "To change this workshop, please contact us at 1-855-395-0395."
                    : "Pour modifier cet atelier, contactez-nous au 1-855-395-0395.";

                return (
                    <div className="change-visit-dates-modal-content">
                        <div className="header">{headerText}</div>
                        <div className="description">{descriptionText}</div>
                    </div>
                );
            }
            default:
                return null;
        }
    }

    onReserve = (selectedTime) => {
        window.scroll(0, 0);
        this.setState({
            selectedTime,
            mode: SelectWorkshopDateModes.fillingForm,
        });
    };

    onFormSubmit = (formData, isVirtual, organisersEmail) => {
        const {
            selectedTime,
            seedWorkshop: { materiel },
        } = this.state;
        if (!(selectedTime && selectedTime.showTime)) {
            this.setState({
                mode: SelectWorkshopDateModes.selectingDate,
            });
            return;
        }

        window.scroll(0, 0);

        this.setState({
            loading: true,
        });

        const { userId, clientId, onWorkshopPlaced } = this.props;

        placeWorkshop({
            clientId,
            userId,
            selectedTime,
            formData,
            isVirtual,
            organisersEmail,
            materiel,
            sessionToken: this.props.sessionToken,
        })
            .then((response) => {
                const { workshopId } = response;
                this.setState({
                    loading: false,
                    mode: SelectWorkshopDateModes.success,
                    workshopId,
                });
                onWorkshopPlaced();
            })
            .catch((error) => {
                if (error.code === TAKEN_ERROR_CODE) {
                    this.setState({
                        loading: false,
                        mode: SelectWorkshopDateModes.workshopTimeTaken,
                    });
                } else {
                    this.setState({
                        loading: false,
                        mode: SelectWorkshopDateModes.fail,
                    });
                }
            });
    };

    onDatesChangedBackButtonClick = () => {
        window.scroll(0, 0);
        this.setState({
            loading: true,
            mode: SelectWorkshopDateModes.selectingDate,
        });
        this.fetchTimeSlots();
    };

    fetchTimeSlots = () => {
        const { clientId, workshopType, currentlyHasAHive, hiveInstallationDate } = this.props;

        this.setState({ loading: true });
        workshopTimeSlotsThisYear({
            clientId,
            workshopType,
        })
            .then((timeSlots) => {
                if (
                    ["ate_extraction"].some((workshop) => workshop === workshopType)
                    && this.props.clientRegion === 9 // sad hardcode: drop extraction workshops for Washington DC for 2022.
                )
                    return this.setState({
                        timeSlots: [],
                        loading: false,
                    });

                //  this.props.clientRegion !== 2  is a hack to exclude toronto from the currently has a hibe condition.
                if (
                    workshopType === "ate_ouverture"
                    && currentlyHasAHive === false
                    && this.props.clientRegion !== 2
                ) {
                    const timeSlotsAfterInstallation = timeSlots.filter((timeSlot) => {
                        return (
                            hiveInstallationDate
                            && moment(timeSlot.date).isAfter(hiveInstallationDate)
                        );
                    });

                    this.setState({
                        timeSlots: timeSlotsAfterInstallation,
                        loading: false,
                    });
                } else {
                    this.setState({
                        timeSlots,
                        loading: false,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    mode: SelectWorkshopDateModes.fail,
                });
            });
    };

    onCloseButtonClick = () => {
        this.props.closeButtonClicked();
    };
}

SelectWorkshopDate.propTypes = {
    bookedWorkshop: PropTypes.bool,
    workshopType: PropTypes.string,
    clientBeekeeper: PropTypes.object,
    clientRegion: PropTypes.number,
    staffUser: PropTypes.object,
    addVisitTypeId: PropTypes.func,
    userId: PropTypes.string,
    clientId: PropTypes.string,
    onWorkshopPlaced: PropTypes.func,
    sessionToken: PropTypes.string,
    currentlyHasAHive: PropTypes.bool,
    hiveInstallationDate: PropTypes.string,
    closeButtonClicked: PropTypes.func,
    regions: PropTypes.object,
};

const mapStateToProps = ({ region }) => {
    const { regions } = region;
    return regions;
};

export default connect(mapStateToProps)(SelectWorkshopDate);

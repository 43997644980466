import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const hivesWithInspectionSheets = (inspections, condition) => {
    return [...new Set(inspections.filter((i) => condition(i)).map((i) => i.noRuche))];
};

export const fetchInspectionData = async (clientId, sessionToken) => {
    const url = new URL(`${SERVER_URL}inspection/sheets`);
    const params = {
        clientId,
        ...(sessionToken ? {sessionToken} : {}),
        regularOnly: true,
        byHiveNumber: true,
        includeAnswers: true,
    };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });
    const responseObject = await response.json();

    if (response.ok) {
        return responseObject.data;
    } else {
        throw new Error(responseObject.message);
    }
};

import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./EditButton.css";
import editImg from "../img/edit.png";

const EditButton = (props) => {
    return (
        <div className="edit-button" onClick={props.onClick}>
            {props.editing && <div className="done">{props.t("done")}</div>}
            <img src={String(editImg)} alt="edit" />
        </div>
    );
};

EditButton.propTypes = {
    editing: PropTypes.bool,
    onClick: PropTypes.func,
    t: PropTypes.object,
};

export default withTranslation(["hiveTab"])(EditButton);

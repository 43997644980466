import { SERVER_URL } from "./server";

export const shareBuzzToLinkedin = async ({ code, text, mediaUrl, mediaType, redirectUri }) => {
    const url = new URL(`${SERVER_URL}myhive/shareToLinkedin`);
    const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
            code,
            text,
            mediaUrl,
            redirectUri,
            mediaType,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

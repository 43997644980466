import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Card as MuiCard, Grid } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Typography from "@material-ui/core/Typography";

import "./Card.css";
import EditButton from "./EditButton";

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2, 0),
        backgroundSize: "cover",
        backgroundImage: (props) =>
            props["backgroundImage"] ? `url(${props["backgroundImage"]})` : null,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(3),

        "& .MuiTabPanel-root": {
            padding: "0px",
        },
        "& .MuiTabs-root": {
            height: theme.spacing(3),
            minHeight: theme.spacing(3),
        },
        "& .MuiTab-root": {
            height: theme.spacing(3),
            minHeight: theme.spacing(3),
            minWidth: "auto",
            marginRight: theme.spacing(2),
            padding: theme.spacing(0),
            textTransform: "none",
        },
    },
    cardContent: {
        padding: "0px",
        "&:last-child": {
            padding: "0px",
        },
    },
    title: {
        fontWeight: "700",
    },
    link: {
        color: "#4F8DCB",
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    tabPanel: {
        marginTop: theme.spacing(3),
    },
    tabIndicator: {
        background: theme.palette.primary.dark,
    },
    cardSection: {
        borderBottom: "1px solid lightgray",
        padding: theme.spacing(3, 0),
        "&:first-child": {
            paddingTop: theme.spacing(2),
        },
        "&:last-child": {
            borderBottom: "none",
            paddingBottom: "0px",
        },
    },
    sectionTitle: {
        fontSize: "0.85rem",
        fontWeight: "700",
    },
}));

const Card = (props) => {
    const classes = useStyles(props);

    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    let cardChildren = React.Children.toArray(props.children);

    if (props.contentFormat === "tabs") {
        cardChildren = (
            <TabContext value={tabValue.toString()}>
                <TabList
                    TabIndicatorProps={{
                        className: classes.tabIndicator,
                    }}
                    onChange={handleTabChange}
                >
                    {cardChildren.map((child, index) => {
                        return (
                            <Tab
                                key={index}
                                value={index.toString()}
                                label={child.props.title}
                            ></Tab>
                        );
                    })}
                </TabList>

                {cardChildren.map((child, index) => {
                    return (
                        <TabPanel
                            className={classes.tabPanel}
                            key={index}
                            value={index.toString()}
                            label={child.props.title}
                        >
                            {child}
                        </TabPanel>
                    );
                })}
            </TabContext>
        );
    } else if (props.contentFormat === "sections") {
        cardChildren = cardChildren.map((child, index) => {
            return (
                <CardSection key={index} title={child.props.title}>
                    {child}
                </CardSection>
            );
        });
    }

    return (
        <Grid item>
            <MuiCard className={classes.card} elevation={0}>
                <div className={classes.cardContent}>
                    <div className={classes.titleContainer}>
                        <Typography className={classes.title} gutterBottom component="h3">
                            {props.title}
                            {props.edit && (
                                <EditButton editing={props.editing} onClick={props.edit} />
                            )}
                        </Typography>
                        {!!props.link && (
                            <Typography>
                                <a href={props.link} className={classes.link}>
                                    {props.linkText || props.link}
                                </a>
                            </Typography>
                        )}
                    </div>

                    {cardChildren}
                </div>
            </MuiCard>
        </Grid>
    );
};

export const CardSection = (props) => {
    const classes = useStyles(props);

    return (
        <div className={(classes.cardContent, classes.cardSection)}>
            <Typography className={classes.sectionTitle} gutterBottom component="h6">
                {props.title}
                {props.edit && <EditButton editing={props.editing} onClick={props.edit} />}
            </Typography>

            {props.children}
        </div>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    contentFormat: PropTypes.oneOf(["sections", "tabs"]),
    link: PropTypes.string,
    linkText: PropTypes.string,
    children: PropTypes.array,

    // not in use by any parent
    backgroundImage: PropTypes.string,
    edit: PropTypes.any,
    editing: PropTypes.any,
};

CardSection.propTypes = {
    title: PropTypes.string,
    children: PropTypes.array,

    // not in use by any parent
    backgroundImage: PropTypes.string,
    edit: PropTypes.any,
    editing: PropTypes.any,
};

export default Card;

// TODO LOCALIZE
import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import { CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { sendPasswordResetEmail } from "../networking/UserAccounts";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    forgotPassword: {
        color: theme.palette.common.black,
    },
    passwordAlert: {
        borderRadius: 0,
    },
    dialog: {
        width: 400,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonText: {
        color: theme.palette.primary.dark,
    },
}));

const PasswordResetDialogContent = ({ handleCancelClick }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [emailInputValue, setEmailInputValue] = useState("");

    const resetMessage = () => ({
        code: "",
        message: "",
    });
    const [message, setMessage] = useState(resetMessage());

    const [loading, setLoading] = useState(false);

    const handlePasswordError = (status) => {
        switch (status) {
            case "401": {
                return t("appBar.noUser");
            }
            default: {
                return t("appBar.passwordServerError");
            }
        }
    };

    const handleResetPasswordClick = async () => {
        setLoading(true);
        setMessage(resetMessage());

        try {
            await sendPasswordResetEmail({
                username: emailInputValue.toLowerCase(),
                isEnglish: i18n.language.includes("en"),
            });

            setMessage({
                code: "SUCCESS",
                message: t("sent"),
            });

            setLoading(false);
        } catch (error) {
            setMessage({
                code: "ERROR",
                message: handlePasswordError(error.message),
            });
            setLoading(false);
        }
    };

    return (
        <>
            <DialogTitle id="form-dialog-title">
                {t("appBar.resetTitle")}
                <EmojiNatureIcon />
            </DialogTitle>
            <Collapse in={message.code}>
                <Alert severity={message.code.toLowerCase()} className={classes.passwordAlert}>
                    {message.message}
                </Alert>
            </Collapse>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("email")}
                    type="email"
                    value={emailInputValue}
                    onChange={(event) => setEmailInputValue(event.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    // align="center"
                    className={classes.forgotPassword}
                    variant="text"
                    color="default"
                    onClick={loading ? null : handleCancelClick}
                    tabIndex={0}
                    role="button"
                >
                    {t("cancel")}
                </Button>
                <div className={classes.wrapper}>
                    <Button
                        onClick={handleResetPasswordClick}
                        className={classes.buttonText}
                        disabled={loading}
                    >
                        {t("appBar.reset")}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </>
    );
};

PasswordResetDialogContent.propTypes = {
    handleCancelClick: PropTypes.func,
};

export default PasswordResetDialogContent;

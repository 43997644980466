import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import RecentSearchCard from "./RecentSearchCard";
import SmallDotStepper from "./SmallDotStepper";

const useStyles = makeStyles(() => ({
    overflow: {
        overflow: "hidden",
    },
    title: {
        color: "#5a5a5a",
        fontWeight: 600,
        fontSize: "12px",
    },
}));

const RecentSearches = ({ recentSearches = [] }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [index, setIndex] = useState(0);
    const [slideIn, setSlideIn] = useState(true);
    const [slideDirection, setSlideDirection] = useState("down");

    const onArrowClick = (direction) => {
        const increment = direction === "left" ? -1 : 1;
        const newIndex = index + increment;
        const oppDirection = direction === "left" ? "right" : "left";

        setSlideDirection(direction);
        setSlideIn(false);

        setTimeout(() => {
            setIndex(newIndex);
            setSlideDirection(oppDirection);
            setSlideIn(true);
        }, 250);
    };

    if (!recentSearches.length) return null;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {t("searchBar.recent").toUpperCase()}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" className={classes.overflow}>
                <Grid item md={12} xs={12}>
                    {recentSearches.length && (
                        <RecentSearchCard
                            searchObj={recentSearches[index]}
                            slideIn={slideIn}
                            direction={slideDirection}
                        />
                    )}
                </Grid>
                {recentSearches.length > 1 && (
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <SmallDotStepper
                            maxSteps={recentSearches.length}
                            onArrowClick={onArrowClick}
                            index={index}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};
const mapStateToProps = ({ searchClient }) => {
    const { recentSearches } = searchClient;
    return { recentSearches };
};

RecentSearches.propTypes = {
    recentSearches: PropTypes.array,
};

export default connect(mapStateToProps)(RecentSearches);

export enum TemperatureUnit {
  Celsius = "Celsius",
  Fahrenheit = "Fahrenheit",
}

export function formatCelsiusTemperature(temperatureCelsius: number, unit: TemperatureUnit): string {
  switch (unit) {
    case TemperatureUnit.Celsius:
      return `${Math.round(temperatureCelsius)}°C`;
    case TemperatureUnit.Fahrenheit:
      return `${Math.round(temperatureCelsius * 1.8 + 32)}°F`;
  }
}

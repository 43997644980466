import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import PropTypes from "prop-types";

import { getFriendlyHiveName } from "../util";

import ReactGA from "react-ga";

import { subscribe } from "../networking/Client";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "14px",
        display: "block",
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("xs")]: {
            borderRadius: "0px",
        },
    },
    headerWrapper: {
        marginBottom: "1em",
    },
    header: {
        fontWeight: 700,
        color: "white",
        whiteSpace: "pre-line",
    },
    subscribeField: {
        "& .MuiInputBase-root": {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 8,
        },
    },
    subscribeButton: {
        height: "100%",
        textTransform: "none",
        "&:disabled": {
            backgroundColor: theme.palette.primary.main,
        },
    },
    subscribeDescription: {
        width: "80%",
        margin: theme.spacing(1, 0, 3, 0),
    },
    card: {
        display: "block",
        margin: theme.spacing(2),
        borderRadius: "14px",
        backgroundColor: "#f4ecde",
        boxShadow: "none",
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
            marginRight: 0,
            borderRadius: "0px",
        },
    },
    contentContainer: {
        padding: theme.spacing(3),
    },
    linkText: {
        color: "white",
        fontWeight: 700,
    },
    exit: {
        color: theme.palette.primary.main,
        backgroundColor: "white",
        transition: "background .5s, opacity .5s",
        "&:hover": {
            backgroundColor: "white",
            opacity: "0.5",
        },
    },
}));

const TimelineSubscribeCard = ({ currentHive }) => {
    const { t, i18n } = useTranslation(["cards", "common"]);

    const classes = useStyles();
    const [mode, setMode] = useState("READY");
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);

    const subscriptions = JSON.parse(localStorage.getItem("subscriptions") || "[]");
    const ignoredSubscriptions = JSON.parse(localStorage.getItem("ignoredSubscriptions") || "[]");

    const isSubscribed = subscriptions.some((sub) => sub === currentHive.id);
    const isIgnored = ignoredSubscriptions.some((ignored) => ignored === currentHive.id);

    useState(() => {
        if (!isSubscribed) {
            ReactGA.event({
                category: "subscribecard",
                action: "impression",
            });
        }
    }, [currentHive]);

    const handleEmail = (event) => {
        const { value, validity } = event.target;

        setEmailValid(validity.valid);
        setEmail(value);
    };

    const handleSubmit = async () => {
        try {
            await subscribe({
                hiveId: currentHive.id,
                email,
                language: i18n.language,
            });

            ReactGA.event({
                category: "subscribecard",
                action: "subscribe",
                label: "signup",
            });

            const newSubscriptions = Array.from(new Set([...subscriptions, currentHive.id]));

            localStorage.setItem("subscriptions", JSON.stringify(newSubscriptions));
            setMode("SUCCESS");
        } catch (error) {
            setEmailValid(false);
        }
    };
    const handleIgnore = () => {
        ReactGA.event({
            category: "subscribecard",
            action: "ignorePrompt",
            label: "exit",
        });

        const newIgnoredSubscriptions = Array.from(
            new Set([...ignoredSubscriptions, currentHive.id])
        );

        localStorage.setItem("ignoredSubscriptions", JSON.stringify(newIgnoredSubscriptions));
        setMode("IGNORED");
    };

    if (isIgnored || (isSubscribed && mode === "READY")) return null;

    return (
        <>
            <Card className={classes.root} elevation={0}>
                <div className={classes.contentContainer}>
                    <Collapse in={mode === "READY"}>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="flex-start"
                            className={classes.headerWrapper}
                        >
                            <Typography variant="h4" component="h2" className={classes.header}>
                                {t("cards:subscribeToHive.title")}
                            </Typography>
                            <IconButton className={classes.exit} onClick={handleIgnore}>
                                <CloseRoundedIcon />
                            </IconButton>
                        </Grid>
                        <Typography
                            variant="body2"
                            component="p"
                            className={classes.subscribeDescription}
                        >
                            {t("cards:subscribeToHive.consentString", {
                                currentHive: getFriendlyHiveName(currentHive),
                            })}
                        </Typography>
                        <Grid container spacing={2} className={classes.subscribeForm}>
                            <Grid item xs={8}>
                                <TextField
                                    label={t("cards:subscribeToHive.inputLabel")}
                                    value={email}
                                    onChange={handleEmail}
                                    id="email"
                                    type="email"
                                    variant="filled"
                                    fullWidth
                                    className={classes.subscribeField}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!emailValid}
                                    className={classes.subscribeButton}
                                    fullWidth
                                    onClick={handleSubmit}
                                >
                                    {t("common:appBar.signUp")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Collapse>

                    <Collapse in={mode === "SUCCESS"}>
                        <Typography
                            gutterBottom
                            color="primary"
                            variant="h5"
                            component="h2"
                            className={classes.header}
                        >
                            {t("common:thankYou")} <EmojiNatureIcon />
                        </Typography>
                    </Collapse>
                </div>
            </Card>
        </>
    );
};

TimelineSubscribeCard.propTypes = {
    currentHive: PropTypes.object,
};

export default TimelineSubscribeCard;

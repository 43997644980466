import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    closeButton: {
        position: "absolute",
        right: "-48px",
        top: "-12px",
        color: "white",
    },
    modal: {
        outline: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalImageContainer: {
        display: "inline-block",
        objectPosition: "50% 50%",
        outline: "none",
        position: "relative",
    },
}));

const ExpandedImageModal = ({ isOpen, close, children, ...props }) => {
    const classes = useStyles();
    return (
        <Modal className={classes.modal} open={isOpen} onClose={close} {...props}>
            <div className={classes.modalImageContainer}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
                    <CloseIcon />
                </IconButton>
                {children}
            </div>
        </Modal>
    );
};

ExpandedImageModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    children: PropTypes.node,
};

export default ExpandedImageModal;

import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import Spinner from "../components/Spinner";
import UserSection from "../UserSection";
import MyhiveTab from "../tabs/MyhiveTab";
import { withStyles } from "@material-ui/styles";

import { fetchOwnedClients } from "../redux/clients";
import { getClientBeekeeper } from "../networking/Beekeeper";

const styles = (theme) => ({
    myhiveProfileParent: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: 0,
            paddingRight: 0,
        },
        maxWidth: 1150,
    },
});

// TODO extract.
// TODO figure out what data needs to be "top level", and is redux or something a overkill? : { user: ✅, paramsParseClient: [?], }
const MyHiveProfile = ({
    currentUser,
    clientUrl,
    clients = [],
    history,
    classes,
    clientsFetching,
    regions,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    // Select client from clients.
    const client = clients.find((client) => client.MaRucheUrl === clientUrl);

    const [clientBeekeeper, setClientBeekeeper] = useState();
    const [isUserViewingTheirOwnPage, setIsUserViewingTheirOwnPage] = useState(false);

    const setMomentDefaultTimezone = (client = {}) => {
        const region = client.Region;
        const isRegion = region || region === 0;
        const timezone = (isRegion && regions[region]?.time.zone) || "America/Toronto";
        isRegion && moment.tz.setDefault(timezone);
    };

    const fetchClientBeekeeper = async (hiveId) => {
        try {
            const { clientBeekeeper } = await getClientBeekeeper(hiveId);
            setClientBeekeeper(clientBeekeeper);
        } catch (error) {
            enqueueSnackbar(t("error.session"), {
                variant: "warning",
                preventDuplicate: true,
            });
            history.push("/logout");
        }
    };

    useEffect(() => {
        client && fetchClientBeekeeper(client.MaRucheUrl);

        if ((client && client.Email1) === (currentUser && currentUser.username))
            setIsUserViewingTheirOwnPage(true);
        if (clients.some((client) => client.owned && client.MaRucheUrl === clientUrl))
            setIsUserViewingTheirOwnPage(true);

        setMomentDefaultTimezone(client);
    }, [client]);

    const authorized = isUserViewingTheirOwnPage || (currentUser && currentUser.isStaff);

    // User viewing his own account
    const myhiveTabComp = (
        <>
            {/*important: 'key' prop allows us to control component instances and reinitialize when clientUrl changes*/}
            <MyhiveTab
                key={clientUrl}
                clientBeekeeper={clientBeekeeper}
                client={client}
                isUserViewingTheirOwnPage={isUserViewingTheirOwnPage}
            />
        </>
    );

    const userSectionComp = (
        <>
            <UserSection
                parseClient={client}
                clientBeekeeper={clientBeekeeper}
                staffUser={currentUser && currentUser.isStaff}
                onClientChange={(clientId) => this.onClientChange(clientId)}
                user={currentUser}
            />
        </>
    );

    return client && !clientsFetching ? (
        <>
            <div
                className={classes.myhiveProfileParent}
                style={!authorized ? { margin: "0px auto" } : {}}
            >
                <Switch>
                    <Route path="/" exact render={() => myhiveTabComp} />
                    <Route path="/:clientUrl" exact render={() => myhiveTabComp} />
                    <Route path="/:clientUrl/buzz/:buzzId" exact render={() => userSectionComp} />
                    {authorized ? (
                        <Route path="/:clientUrl/*" render={() => userSectionComp} />
                    ) : (
                        <Redirect to="/:clientUrl" />
                    )}
                </Switch>
            </div>
        </>
    ) : (
        <Spinner />
    );
};

const mapStateToProps = ({ client, user, region }) => {
    const { currentUser } = user;
    const { clients, clientsFetching = false } = client;
    const { regions } = region;
    return { clients, currentUser, clientsFetching, regions };
};

MyHiveProfile.propTypes = {
    currentUser: PropTypes.object,
    clients: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.object,
    clientUrl: PropTypes.string,
    clientsFetching: PropTypes.bool,
    classes: PropTypes.object,
    regions: PropTypes.object,
};

export default connect(mapStateToProps, { fetchOwnedClients })(withStyles(styles)(MyHiveProfile));

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import Card from "../Card";
import { Beekeeper } from "../../typings/entities";
import { useSafeAwsUrl } from "../../util";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    fontSize: "0.875rem",
    marginTop: theme.spacing(3),
  },
  sectionContainer: {
    marginTop: theme.spacing(3),
    "&:first-child": {
      marginTop: 0,
    },
  },
  avatarImageContainer: {
    height: "76px",
    width: "76px",
    marginRight: theme.spacing(2),
    backgroundColor: "#eee",
    borderRadius: "50%",
    overflow: "hidden",
  },
  avatarImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  title: {
    fontWeight: 700,
    marginTop: "-6px",
    marginBottom: theme.spacing(1),
  },
  email: {
    fontSize: "inherit",
    marginBottom: theme.spacing(1),
    "& :first-child": {
      color: "#4F8DCB",
    },
  },
  textContainer: {
    flex: 1,
  },
  text: {
    lineHeight: "17px",
  },
  readMoreLink: {
    display: "block",
    color: "#4F8DCB",
    cursor: "pointer",
    marginTop: theme.spacing(1),
  },
}));

export type BeekeeperCardData = {
  name: string;
  bio: string;
  email?: string;
  avatarUrl?: string;
  isStaff?: boolean;
};

interface Props {
  beekeeperData: BeekeeperCardData;
  accountAdvisorData?: BeekeeperCardData;
}

export const BeekeeperCard: React.FC<Props> = ({ beekeeperData, accountAdvisorData }) => {
  const { t } = useTranslation(["cards"]);

  return (
    <Card>
      <div>
        <Section title={t("meetYourBeekeeper.beekeeperTitle")} data={beekeeperData} />
        {accountAdvisorData && <Section title={t("meetYourBeekeeper.accountAdvisorTitle")} data={accountAdvisorData} />}
      </div>
      <></>
    </Card>
  );
};

const Section = ({ title, data }: { title: string; data: BeekeeperCardData }) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionContainer}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.contentContainer}>
        <div className={classes.avatarImageContainer}>
          <img alt="" className={classes.avatarImage} src={data.avatarUrl} />
        </div>
        <div className={classes.textContainer}>
          <Typography variant="subtitle2">{data.name}</Typography>
          {data.email && (
            <Typography className={classes.email}>
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </Typography>
          )}
          <ReadMoreText content={data.bio} charLimit={120} />
        </div>
      </div>
    </div>
  );
};

const ReadMoreText = ({ content, charLimit }: { content: string; charLimit: number }) => {
  const { t } = useTranslation(["cards"]);
  const classes = useStyles();

  const hasReadMore = content.length > charLimit;
  const [isReadMore, setIsReadMore] = useState(false);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const preparedContent =
    isReadMore || !hasReadMore ? content : content.substr(0, charLimit).split(" ").slice(0, -1).join(" ") + " ...";

  return (
    <>
      {preparedContent.split("\n").map((paragraph, index) => {
        return (
          <Typography variant="body2" color="textSecondary" component="p" key={index} className={classes.text}>
            {paragraph || <br />}
          </Typography>
        );
      })}
      {hasReadMore && !isReadMore && (
        <Typography variant="caption" onClick={toggleReadMore} className={classes.readMoreLink}>
          {t("meetYourBeekeeper.readMore")}
        </Typography>
      )}
    </>
  );
};

export function buildBeekeeperData(beekeeper: Beekeeper): BeekeeperCardData {
  return {
    name: `${beekeeper.Nom ?? beekeeper.user.firstName} ${beekeeper.NomFamille ?? beekeeper.user.lastName}`,
    bio: beekeeper.Bio ?? "",
    email: beekeeper.isStaff ? beekeeper.user?.email : undefined,
    avatarUrl: beekeeper.user?.avatar && useSafeAwsUrl(beekeeper.user.avatar.url),
    isStaff: beekeeper.isStaff
  };
}

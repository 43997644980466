import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { changePassword } from "../networking/UserAccounts";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import { Alert } from "@material-ui/lab";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import { useDebouncedEffect } from "../customHooks/useDeboucedEffect";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { logout } from "../redux/user";

const NO_MATCH = "appBar.noMatch";
const NOT_LONG_ENOUGH = "appBar.tooShort";
const INCORRECT_PASSWORD = "appBar.incorrect";
const SAME_PASSWORD = "appBar.mustBeDiff";

const useStyles = makeStyles((theme) => ({
    forgotPassword: {
        // fontSize: '1 rem',
        color: theme.palette.common.black,
    },
    dialog: {
        width: 400,
    },
    passwordAlert: {
        borderRadius: 0,
    },
    passwordAlertLink: {
        color: "inherit",
        cursor: "pointer",
        textDecoration: "underline",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const UpdatePasswordDialog = ({ handleClose, sessionToken, username, userId, history, open }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [oldPasswordInputValue, setOldPasswordInputValue] = useState("");
    const [newPasswordInputValue, setNewPasswordInputValue] = useState("");
    const [newPasswordAgainInputValue, setNewPasswordAgainInputValue] = useState("");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false);
    const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
    const [updatePasswordReady, setUpdatePasswordReady] = useState(false);
    const [newPasswordInputError, setNewPasswordInputError] = useState();
    const [changePasswordError, setChangePasswordError] = useState();

    const getError = (match, longEnough, hasChanged, sameLength, haveLength) => {
        // help! make this less confusing
        if (haveLength && sameLength && !hasChanged) return SAME_PASSWORD;
        if (match) return longEnough ? null : NOT_LONG_ENOUGH;
        if (haveLength && sameLength) return NO_MATCH;
        return longEnough ? NO_MATCH : null;
    };

    useDebouncedEffect(
        () => {
            const match =
                newPasswordInputValue.length
                && newPasswordInputValue === newPasswordAgainInputValue;
            const longEnough = newPasswordAgainInputValue.length >= 5;
            const hasChanged = oldPasswordInputValue !== newPasswordAgainInputValue;
            const sameLength = newPasswordAgainInputValue.length === newPasswordInputValue.length;
            const haveLength = newPasswordAgainInputValue.length && newPasswordInputValue.length;

            setNewPasswordInputError(
                getError(match, longEnough, hasChanged, sameLength, haveLength)
            );
            setUpdatePasswordReady(!!match && !!longEnough);
        },
        [newPasswordInputValue, newPasswordAgainInputValue, oldPasswordInputValue],
        300
    );

    const handleUpdatePasswordClick = async () => {
        setUpdatePasswordLoading(true);
        try {
            await changePassword({
                sessionToken,
                username,
                userId,
                oldPassword: oldPasswordInputValue,
                newPassword: newPasswordAgainInputValue,
            });
            setChangePasswordError(null);
            handleClose();
            enqueueSnackbar(t("appBar.successfullyChangedPassword"), {
                variant: "success",
            });
            history.push("/logout");
        } catch (error) {
            setUpdatePasswordLoading(false);

            if (error.status === 403) {
                setChangePasswordError(INCORRECT_PASSWORD);
            } else {
                setChangePasswordError("Internal server error try again");
            }
        }
    };

    const handleNewPasswordAgainInputChange = (event) => {
        setNewPasswordAgainInputValue(event.target.value);
    };
    const toggleOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };
    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleShowNewPasswordAgain = () => {
        setShowNewPasswordAgain(!showNewPasswordAgain);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                {t("appBar.changePassword")}
                <EmojiNatureIcon />
            </DialogTitle>
            <Collapse in={Boolean(changePasswordError)}>
                <Alert severity="error" className={classes.passwordAlert}>
                    {t(changePasswordError)}
                </Alert>
            </Collapse>
            <DialogContent>
                <TextField
                    disabled={updatePasswordLoading}
                    margin="dense"
                    id="oldPassword"
                    label={t("password")}
                    type={showOldPassword ? "text" : "password"}
                    value={oldPasswordInputValue}
                    onChange={(event) => setOldPasswordInputValue(event.target.value)}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle old password visibility"
                                    onClick={toggleOldPassword}
                                >
                                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    margin="dense"
                    disabled={updatePasswordLoading}
                    id="new-password"
                    label={t("appBar.newPassword")}
                    type={showNewPassword ? "text" : "password"}
                    value={newPasswordInputValue}
                    onChange={(event) => setNewPasswordInputValue(event.target.value)}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowNewPassword}
                                >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    error={newPasswordInputError}
                    disabled={updatePasswordLoading}
                    margin="dense"
                    id="new-password-again"
                    label={t("appBar.newPasswordAgain")}
                    helperText={newPasswordInputError ? t(newPasswordInputError) : null}
                    type={showNewPasswordAgain ? "text" : "password"}
                    value={newPasswordAgainInputValue}
                    onChange={handleNewPasswordAgainInputChange}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowNewPasswordAgain}
                                >
                                    {showNewPasswordAgain ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <div className={classes.wrapper}>
                    <Button onClick={handleClose} color="primary">
                        {t("common:cancel")}
                    </Button>
                    <Button
                        onClick={handleUpdatePasswordClick}
                        color="primary"
                        disabled={
                            newPasswordInputError || !updatePasswordReady || updatePasswordLoading
                        }
                    >
                        {t("appBar.changePassword")}
                    </Button>
                    {updatePasswordLoading && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                </div>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;
    const { sessionToken, username, objectId } = { ...(currentUser || {}) };

    return { sessionToken, username, userId: objectId };
};

UpdatePasswordDialog.propTypes = {
    handleClose: PropTypes.func,
    sessionToken: PropTypes.string,
    username: PropTypes.string,
    userId: PropTypes.string,
    history: PropTypes.object,
    open: PropTypes.bool,
};

export default connect(mapStateToProps, { logout })(withRouter(UpdatePasswordDialog));

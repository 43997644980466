import React, { useState } from "react";
import Card from "../Card";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ICalendarLink from "react-icalendar-link";
import ReactGA from "react-ga";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import GetAppIcon from "@material-ui/icons/GetApp";
import * as Seed from "../../seed";
import ActionableCardItem from "./ActionableCardItem";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    dateLabel: {
        color: theme.palette.primary.dark,
        textTransform: "uppercase",
        fontWeight: "600",
    },
    GAHitBox: {
        width: "132px",
        margin: "-16px",
        padding: "14px 16px",
    },
    iCalLink: {
        color: "white",
        margin: "-16px",
        padding: "14px 16px",
    },
    icon: {
        verticalAlign: "bottom",
        marginRight: "4px",
    },
    visitLabel: {
        fontWeight: 700,
    },
}));

const AppointmentLabel = ({ visitType, time, currentHive }) => {
    const classes = useStyles();
    const { t } = useTranslation(["cards"]);
    const [isHover, setIsHover] = useState(false);

    const visitDescription =
        Seed.visitTypes.find((visitType) => visitType.titleEn.includes(visitType)) || {};

    const iCalEvent = {
        startTime: time.iso,
        title: `${visitType}`,
        description: visitDescription.descriptionEn,
        location: currentHive.Nom,
        url: `https://myhive.alveole.buzz/${currentHive.id}?utm_source=visitreminder&utm_medium=myhive&utm_campaign=springsummer2022`,
    };
    const extraEventParams = "DURATION:PT1H"; // Default duration of 1 hour

    const GAWorkaround = () => {
        ReactGA.event({
            category: "calendarcard",
            action: "downloadical",
            label: visitType,
        });
    };

    const buttonContent = (
        <ICalendarLink
            className={classes.iCalLink}
            filename={`Alveole_Visit_Event.ics`}
            event={iCalEvent}
            rawContent={extraEventParams}
        >
            <div
                className={classes.GAHitBox}
                onClick={GAWorkaround}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                {(isHover && (
                    <>
                        <span>
                            <GetAppIcon className={classes.icon} />
                        </span>
                        {t("upcomingVisits.download")}
                    </>
                )) || (
                    <>
                        <span>
                            <InsertInvitationIcon className={classes.icon} />
                        </span>
                        {t("upcomingVisits.addToCalendar")}
                    </>
                )}
            </div>
        </ICalendarLink>
    );

    const dateString = moment(time.iso)
        .locale(window.isEnglish ? "en" : "fr")
        .format(window.isEnglish ? "MMM D" : "D MMM");
    const timeString = moment(time.iso)
        .locale(window.isEnglish ? "en" : "fr")
        .format(window.isEnglish ? "h:mm A z" : "H[h]mm z");

    return (
        <ActionableCardItem variant="primary" cta={buttonContent} extendWidth actionHover>
            <Typography className={classes.dateLabel} variant="body2" component="p">
                {dateString}
            </Typography>
            <Typography className={classes.visitLabel} variant="body2" component="p">
                {visitType}
            </Typography>
            <Typography variant="body2" component="p">
                {timeString}
            </Typography>
        </ActionableCardItem>
    );
};

AppointmentLabel.propTypes = {
    visitType: PropTypes.string,
    time: PropTypes.string,
    currentHive: PropTypes.object,
};

const useCalendarStyles = makeStyles((theme) => ({
    cardAction: {
        marginBottom: "-24px",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}));

const CalendarCard = ({ visits, currentHive, visitTypes }) => {
    const classes = useCalendarStyles();
    const { t } = useTranslation(["hiveTab"]);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getVisitTitle = (visitTypeId) => {
        const foundVisitType =
            Seed.visitTypes.find((visitType) => visitType.rdvVisitTypeIds.includes(visitTypeId))
            || {};
        const alveoleVisitTypes = visitTypes.find((vt) => vt.visitTypeId === visitTypeId) || {};

        // Returns the visit name based on language, and replaces numbered visit names with default name
        return window.isEnglish
            ? foundVisitType.titleEn
                  || (alveoleVisitTypes.name?.en && isNaN(alveoleVisitTypes.name?.en))
                  || "Beekeeping visit"
            : foundVisitType.titleFr
                  || (alveoleVisitTypes.name?.fr && isNaN(alveoleVisitTypes.name?.fr))
                  || "Visite apicole";
    };

    const dates = visits.map((visit, index) => {
        if (visit.VisitTypeId) {
            const visitType = getVisitTitle(visit.VisitTypeId);
            return (
                <AppointmentLabel
                    key={index}
                    time={visit.showTime || visit.Debut}
                    visitType={visitType}
                    currentHive={currentHive}
                />
            );
        }
        return null;
    });

    return (
        <Card title={t("upcomingVisits")}>
            <div>{expanded ? dates : dates.slice(0, 3)}</div>

            {dates.length > 3 && (
                <CardActions className={classes.cardAction} disableSpacing>
                    <IconButton
                        className={[classes.expand, expanded && classes.expandOpen]}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
            )}
        </Card>
    );
};

const mapStateToProps = ({ visitType }) => {
    const { visitTypes } = visitType;

    return { visitTypes };
};

CalendarCard.propTypes = {
    visits: PropTypes.array,
    currentHive: PropTypes.object,
    visitTypes: PropTypes.array,
};

export default connect(mapStateToProps, {})(CalendarCard);

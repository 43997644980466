import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useSafeAwsUrl } from "../util";
import { useHistory } from "react-router";
import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Image from "@material-ui/icons/Image";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { setJarringLabelPreference, uploadLogo } from "../networking/Jarring";

const useStyles = makeStyles((theme) => ({
    preferencesCard: {
        padding: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        textTransform: "uppercase",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    heading: {
        fontSize: 24,
        marginTop: theme.spacing(2),
    },
    logoUpload: {
        display: "none",
    },
    formRow: {
        marginTop: theme.spacing(1),
        // paddingTop: theme.spacing(2),
        // borderTop: "1px solid #dedede",
        fontSize: 18,
    },
    formType: {
        paddingTop: theme.spacing(1.5),
    },
    label: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        width: "100%",
    },
    changes: {
        marginLeft: "30px",
        width: "90%",
    },
    confirm: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

//do not give approval/changes option for labels with these production statuses
export const STATUSES_TO_IGNORE = ["PRINTED", "TO_PRINT_AUTOMATICALLY"];
export const getIsIgnoredStatus = (status) => STATUSES_TO_IGNORE.includes(status);

const PreferencesLabels = ({ hive }) => {
    const classes = useStyles();
    const cardRef = useRef(null);
    const history = useHistory();
    const { t } = useTranslation(["labels"]);
    const { currentUser } = useSelector((state) => state.user);
    const sessionToken = currentUser && currentUser.sessionToken;

    const [logoBusy, setLogoBusy] = useState(false);
    const [labelBusy, setLabelBusy] = useState(false);

    const [proofChoice, setProofChoice] = useState("APPROVED");
    const [proofChanges, setProofChanges] = useState();

    // reset the custom changes string when switching between selections
    useEffect(() => setProofChanges(""), [proofChoice]);

    const existingLogo = hive.ServicePointer.jarringLogo;
    const label = hive.ServicePointer.jarringLabel;
    const labelIsPdf = label && label.url && label.url.toLowerCase().includes("pdf");

    // the following bundle of conditions checks if the client should be offered labels.
    const lineItems = (hive.ServicePointer.contractLineItems || []).filter(
        (lineItem) => lineItem.product
    );

    // lineItems with products that have "labelsIncluded" set to true get labels included with their plan.
    const isLabelsIncludedByPlan = (lineItem) => !!lineItem.product.labelsIncluded;

    // labels can be purchased outside of the client's plan, too, as an add-on. that add-on is
    // SKU S635.
    // if we haven't determined if labels are included based on the plan or the presence of SKU
    // S635, default to not including them (i.e., mention the dollar amount in the front-end.)
    const isLabelling = (lineItem) => lineItem.product.quickbooksSku === "S635";

    // There is one more sneaky way to get labels included,
    // If they have a line item with the SKU S220 (additional hive) and it's per unit price is exactly $2250 then Jars and labels included.
    // If they have a line item with the SKU S220 (additional hive) and it's price is exactly $1200, only Jars are included, but labels are not included. This is for schools I think.
    // I'm not really sure why this works like this, but basically there are times when the sales people bill an additional hive, and it comes with free jars and labels for the whole contract.
    // Currently, there is no distinction made by product choice, whether the Additional Hive includes free labels and or jars is decided only by the price.
    // Accounting looks at the price of the Additional Hive item, and then manually changes the invoice to match.
    // The way this works is very likely to change, and all of this should get a refactor anyway,
    // but if that didn't happen and you're dusting off this code for another year, you should check with the accountants that this hasn't changed.
    const isLabelsIncludedThroughAdditionalHive = (lineItem) => {
        const ADDITIONAL_HIVE_SKU = "S220";
        const ADDITION_HIVE_WITH_JARRING_LABELS_PRICE = 225000;
        const isAdditionHiveItem = lineItem.product.quickbooksSku === ADDITIONAL_HIVE_SKU;
        const isAdditionHiveWithJarringLabelingPrice =
            ADDITION_HIVE_WITH_JARRING_LABELS_PRICE === lineItem.price;

        return isAdditionHiveItem && isAdditionHiveWithJarringLabelingPrice;
    };

    const automaticApproval =
        hive.ServicePointer.productionStatus
        && hive.ServicePointer.productionStatus.jarring === "TO_PRINT_AUTOMATICALLY";

    const labelsIncluded =
        hive.Region === 14
        || lineItems.some((lineItem) => {
            return (
                isLabelling(lineItem)
                || isLabelsIncludedThroughAdditionalHive(lineItem)
                || isLabelsIncludedByPlan(lineItem)
            );
        });

    const handleNewLogo = (event) => {
        setLogoBusy(true);
        event.preventDefault();

        // TODO error handling. Acts like it succeeded when it fails.
        uploadLogo({ file: event.target.files[0], hiveId: hive.id, sessionToken }).then(() => {
            setLogoBusy(false);
            history.go(0);
        });
    };

    const handleConfirm = () => {
        const preferences = {
            jarringLabelApproval: proofChoice || "",
            jarringLabelDetails: proofChanges || "",
        };

        setLabelBusy(true);
        setJarringLabelPreference({
            preferences,
            hiveId: hive.id,
            userId: currentUser.objectId,
            jarringLabelApprovalTime: new Date().toString(),
            sessionToken,
        })
            .then(() => {
                setLabelBusy(false);
                history.go(0);
            })
            .catch((error) => {
                alert("There was an error! Please try again.", error);
                setLabelBusy(false);
            });
    };

    // Production crew uses "waiting for approval" for clients who have had labels printed before (last year),
    // and "labels ready for approval" for clients who haven't.
    const hasHadLabelsPrintedPreviously =
        hive?.ServicePointer?.productionStatus?.jarring == "WAITING_FOR_APPROVAL";

    return (
        <>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {t("title")}
                <EmojiNatureIcon />
            </Typography>
            <Card className={classes.preferencesCard}>
                <CardContent>
                    <Typography gutterBottom ref={cardRef}>
                        {t("formIntro")}
                    </Typography>
                    {!automaticApproval && (
                        <>
                            <Typography className={classes.heading} component="h2" gutterBottom>
                                Logo
                            </Typography>
                            <Grid container direction="row" spacing={2} className={classes.formRow}>
                                {!existingLogo ? (
                                    <>
                                        <Grid item xs={1}>
                                            <input
                                                onChange={(event) => handleNewLogo(event)}
                                                accept="*"
                                                type="file"
                                                id="logo-upload"
                                                className={classes.logoUpload}
                                            />
                                            <label htmlFor="logo-upload">
                                                <Tooltip title={t("clickHereToUploadYourLogo")}>
                                                    <IconButton
                                                        className={classes.uploadIcon}
                                                        component="span"
                                                        disabled={logoBusy}
                                                    >
                                                        <Image />
                                                        {logoBusy && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={classes.buttonProgress}
                                                            />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            </label>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography className={classes.formType}>
                                                {t("logoQuestion")}
                                            </Typography>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography component="p" gutterBottom>
                                            <Trans i18nKey="needToUpdate">
                                                {"We've received "}
                                                <Link href={useSafeAwsUrl(existingLogo.url)}>
                                                    your logo
                                                </Link>
                                                {"! Need to update it? Please send us an email at "}
                                                <Link href="mailto:design@alveole.buzz">
                                                    design@alveole.buzz
                                                </Link>
                                                .
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}

                    {labelsIncluded ? (
                        <>
                            <Divider className={classes.divider} />
                            <Typography className={classes.heading} component="h2" gutterBottom>
                                {t("label")}
                            </Typography>
                            {label ? (
                                <>
                                    <Typography component="p" gutterBottom>
                                        {t("proofHoneyLabel")}
                                    </Typography>
                                    {hasHadLabelsPrintedPreviously ? (
                                        <Typography component="p">
                                            <strong>{t("willPrintAutomaticallyWarning")}</strong>
                                        </Typography>
                                    ) : null}
                                    <div className={classes.label}>
                                        {labelIsPdf ? (
                                            <Document file={useSafeAwsUrl(label.url)}>
                                                <Page
                                                    pageNumber={1}
                                                    loading={t("pdfLoading")}
                                                    width={
                                                        (cardRef.current
                                                            && cardRef.current.clientWidth)
                                                        || 700
                                                    }
                                                />
                                            </Document>
                                        ) : (
                                            <img
                                                alt=""
                                                src={useSafeAwsUrl(label.url)}
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                    </div>
                                    {!hive.ServicePointer.jarringLabelApproval ? (
                                        getIsIgnoredStatus(
                                            hive.ServicePointer.productionStatus
                                                && hive.ServicePointer.productionStatus.jarring
                                        ) ? (
                                            <Typography component="p" gutterBottom>
                                                {t("alreadyPrinted")}
                                            </Typography>
                                        ) : (
                                            <>
                                                <Typography component="p" gutterBottom>
                                                    <strong>{t("verify")}</strong>
                                                    <ul>
                                                        <li>{t("textAndContent")}</li>
                                                        <li>{t("yourLogo")}</li>
                                                        <li>{t("colours")}</li>
                                                    </ul>
                                                </Typography>
                                                <Typography component="p" gutterBottom>
                                                    {t("noteTrim")}
                                                </Typography>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        value={proofChoice}
                                                        onChange={(event) =>
                                                            setProofChoice(event.target.value)
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            value="APPROVED"
                                                            control={<Radio />}
                                                            label={t("proofApproved")}
                                                        />
                                                        <FormControlLabel
                                                            value="SELF_DESIGN"
                                                            control={<Radio />}
                                                            label={t("proofSelfDesign")}
                                                        />
                                                        <FormControlLabel
                                                            value="HAVE_CHANGES"
                                                            control={<Radio />}
                                                            label={t("proofChanges")}
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                                <Fade
                                                    in={proofChoice === "HAVE_CHANGES"}
                                                    unmountOnExit={true}
                                                >
                                                    <TextField
                                                        variant="outlined"
                                                        className={classes.changes}
                                                        onChange={(event) =>
                                                            setProofChanges(event.target.value)
                                                        }
                                                        label={t("proofDetailsPlaceholder")}
                                                        helperText={t("proofSpecific")}
                                                        disabled={proofChoice !== "HAVE_CHANGES"}
                                                    />
                                                </Fade>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.confirm}
                                                    onClick={handleConfirm}
                                                    disabled={
                                                        proofChoice === "HAVE_CHANGES"
                                                            ? !proofChanges
                                                            : !proofChoice
                                                    }
                                                >
                                                    {t("confirmOrder")}
                                                    {labelBusy && (
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )}
                                                </Button>
                                            </>
                                        )
                                    ) : (
                                        <Typography component="p" gutterBottom>
                                            {t("confirmed")}
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <Typography component="p" gutterBottom>
                                    {t("proofNotReady")}
                                </Typography>
                            )}
                        </>
                    ) : null}
                </CardContent>
            </Card>
        </>
    );
};

PreferencesLabels.propTypes = {
    hive: PropTypes.object,
};

export default PreferencesLabels;

import { BiodiversityReportData, BiodiversitySpeciesData } from "@alveolemtl/alveole-components";
import { ApilabReport, ApilabFloralDiversityData } from "../networking/biodiversityReports";

export function buildFloralBiodiversityReportData(
  apilabReport: ApilabReport,
  language: Language
): BiodiversityReportData {
  return {
    biodiversityScore: Math.round(apilabReport.result.biodiversityScore * 100),
    biodiversityRating: biodiversityScoreToRating(apilabReport.result.biodiversityScore),
    supportedFrameworks: [],
    species: apilabReport.result.floralDiversity.map((floralDiversity) =>
      buildFloralBiodiversitySpeciesData(floralDiversity, language)
    ),
    timestamp: new Date(apilabReport.sampledAt),
  };
}

function buildFloralBiodiversitySpeciesData(
  floralDiversity: ApilabFloralDiversityData,
  language: Language
): BiodiversitySpeciesData {
  return {
    name: language === "en" && floralDiversity.commonName ? floralDiversity.commonName : floralDiversity.taxon,
    type: floralDiversity.plantType ?? "Unknown",
    split: floralDiversity.proportionalAbundance,
    classification: floralDiversity.category ?? "N/A",
    status: floralDiversity.iucn ?? "Unknown",
  };
}

function biodiversityScoreToRating(score: number): "Low" | "Moderate" | "High" {
  if (score < 0.33) {
    return "Low";
  } else if (score < 0.66) {
    return "Moderate";
  }
  return "High";
}

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { patch } from "../networking/Client";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    billingCard: {
        padding: theme.spacing(2),
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    title: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        textTransform: "uppercase",
    },
    field: {
        marginBottom: 12,
    },
    submit: {
        marginTop: theme.spacing(4),
    },
    tabText: {},
}));

const PreferencesBilling = ({ hive, sessionToken, isHiveAdmin }) => {
    const { t } = useTranslation(["preferences", "common"]);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const defaultBillingAddress = hive.Adresse || "";
    const defaultBillingCity = hive.Ville || "";
    const defaultBillingCompany = hive.BillingCompany || "";
    const defaultBillingCountry = hive.Pays || "";
    const defaultBillingName = hive.NomFacturation || "";
    const defaultBillingPhone = hive.TelephoneFacturation || "";
    const defaultBillingEmail = hive.EmailFacturation || "";
    const defaultBillingProvince = hive.Province || "";
    const defaultBillingPostalCode = hive.Codepostal || "";

    const [changes, setChanges] = useState(false);
    const [values, setValues] = useState({
        Adresse: defaultBillingAddress,
        Ville: defaultBillingCity,
        BillingCompany: defaultBillingCompany,
        Pays: defaultBillingCountry,
        NomFacturation: defaultBillingName,
        TelephoneFacturation: defaultBillingPhone,
        EmailFacturation: defaultBillingEmail,
        Province: defaultBillingProvince,
        Codepostal: defaultBillingPostalCode,
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        setChanges(true);
        const { id, value } = event.target;

        setValues({
            ...values,
            [id]: value,
        });
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            await patch({
                hiveId: hive.id,
                sessionToken,
                properties: {
                    ...values,
                    billingInfoSavedAt: Date.now().toString(),
                },
            });

            enqueueSnackbar(t("preferences:billing.saved"), {
                variant: "success",
            });
        } catch (error) {
            setValues({
                Adresse: defaultBillingAddress,
                Ville: defaultBillingCity,
                BillingCompany: defaultBillingCompany,
                Pays: defaultBillingCountry,
                NomFacturation: defaultBillingName,
                TelephoneFacturation: defaultBillingPhone,
                EmailFacturation: defaultBillingEmail,
                Province: defaultBillingProvince,
                Codepostal: defaultBillingPostalCode,
            });
            enqueueSnackbar(error.message || t("preferences:billing.error"), {
                variant: "error",
            });
        }
        setChanges(false);
        setLoading(false);
    };

    const formField = ({
        label = "",
        id = "",
        fullWidth = true,
        className = classes.field,
        onChange = handleChange,
        helperText = "",
    }) => (
        <TextField
            label={label}
            id={id}
            fullWidth={fullWidth}
            value={values[id]}
            className={className}
            onChange={onChange}
            helperText={helperText}
        />
    );

    formField.propTypes = {
        label: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        fullWidth: PropTypes.bool,
        className: PropTypes.string,
        onChange: PropTypes.func,
        helperText: PropTypes.string,
    };

    return isHiveAdmin ? (
        <>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {t("preferences:billing.title")}
                <EmojiNatureIcon />
            </Typography>
            <Card className={classes.billingCard}>
                <CardContent>
                    {formField({
                        label: t("preferences:billing.company"),
                        id: "BillingCompany",
                    })}
                    {formField({
                        label: t("preferences:billing.name"),
                        id: "NomFacturation",
                    })}
                    {formField({
                        label: t("preferences:billing.address"),
                        id: "Adresse",
                        helperText: t("preferences:billing.addressDescription"),
                    })}
                    {formField({
                        label: t("preferences:billing.city"),
                        id: "Ville",
                    })}
                    {formField({
                        label: t("preferences:billing.province"),
                        id: "Province",
                    })}
                    {formField({
                        label: t("preferences:billing.country"),
                        id: "Pays",
                    })}
                    {formField({
                        label: t("preferences:billing.postalCode"),
                        id: "Codepostal",
                    })}
                    {formField({
                        label: t("preferences:billing.phone"),
                        id: "TelephoneFacturation",
                    })}
                    {formField({
                        label: t("preferences:billing.email"),
                        id: "EmailFacturation",
                    })}
                    {formField({
                        label: t("preferences:billing.purchaseOrderNumber"),
                        id: "purchaseOrderNumber",
                    })}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {changes ? t("common:save") : t("common:confirm")}
                        {loading && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                    </Button>
                </CardContent>
            </Card>
        </>
    ) : null;
};

PreferencesBilling.propTypes = {
    hive: PropTypes.object,
    sessionToken: PropTypes.string,
    isHiveAdmin: PropTypes.bool,
};

export default PreferencesBilling;

import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

// import orange from '@material-ui/core/colors/orange';

// #dc000c ... use this red

// set default theme to browser selection
const selectedThemeMode = localStorage.getItem("theme") || null;

// A custom theme for this app
const theme = (themeMode) => {
    const themeModeString = themeMode || selectedThemeMode || "light";
    const darkMode = themeModeString !== "light";

    return createTheme({
        typography: {
            fontFamily: "apercu",
            // fontSize: 14,
            fontWeightLight: 100,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
            h1: {
                fontSize: 26,
                fontWeight: 500,
                [breakpoints.down("sm")]: {
                    fontSize: 22,
                },
            },
            subtitle1: {
                fontWeight: 100,
                fontSize: 16,
                [breakpoints.down("sm")]: {
                    fontSize: 12,
                },
            },
            subtitle3: {
                fontWeight: 400,
                fontSize: "0.875rem",
            },
        },
        palette: {
            type: themeModeString,
            primary: {
                light: "#FFE780",
                main: "#f9b44d", // yellow
                dark: "#df5d37", // red-orange
            },
            secondary: {
                light: "#8BB5B2",
                main: "#58827f", // green
                dark: "#104f55", // darker green
            },
            neutral: {
                lightgrey: "#f7f7f7",
                grey: "#d0d7de",
                beige: "#f0ece5",
                charcoal: "#2b2a2b",
            },
            brand: {
                purple: "#af9dcc",
                lightbrown: "#ce8e5c",
                darkred: "#831a11",
                blue: "#3d6ab2",
            },
            background: {
                default: darkMode ? "#202020" : "#F7F7F3",
                white: "#fff",
            },
        },
        shape: {
            borderRadius: 8,
        },
        overrides: {
            MuiCard: {
                root: {
                    // border: "1px solid black",
                },
            },
            MuiCardContent: {
                root: {
                    "&:last-child": {
                        // paddingBottom: 16,
                    },
                },
            },
        },
        props: {
            MuiCard: {
                // elevation: 1,
                // variant: "outlined",
            },
        },
        paths: {
            alveole:
                "polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%)",
        },
    });
};

export default theme;

// official alvéole colours
//
// oranges: #b87f39 #b89e6e #f4aa5e #ebcfb0
// blue to green: #0d497a #4f8dcb #52867b #00a775
// purple to orange: #942e59 #f04923 #f4c7bc #f6916b

// new very light orange background from the web: #f4ecde

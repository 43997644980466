import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import PasswordResetDialogContent from "./PasswordResetDialogContent";
import LoginDialogContent from "./MyhiveTab/LoginDialogContent";

const LoginDialog = ({ open, handleClose }) => {
    const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);

    const handleCancelClick = () => setForgotPasswordClicked(false);
    const handleForgotPasswordClick = () => setForgotPasswordClicked(true);

    const handleCloseDialog = () => {
        setForgotPasswordClicked(false);
        handleClose();
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="form-dialog-title"
        >
            {forgotPasswordClicked ? (
                <PasswordResetDialogContent
                    handleCancelClick={handleCancelClick}
                    handleClose={handleClose}
                />
            ) : (
                <LoginDialogContent handleForgotPasswordClick={handleForgotPasswordClick} />
            )}
        </Dialog>
    );
};

LoginDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default LoginDialog;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import * as Seed from "../seed";

import WorkshopCard from "../components/WorkshopCard";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

import { useSnackbar } from "notistack";
import WorkshopDialog from "../components/WorkshopDialog";
import Modal from "../components/Modal";
import SelectWorkshopDate from "../components/SelectWorkshopDate";
import { fetchYearVisits } from "../networking/Appointment";
import { fetchWorkshopAllotments } from "../networking/workshops";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
        marginBottom: 100,
    },
    title: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        textTransform: "uppercase",
    },
    search: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: "30px 0px",
        marginRight: "auto",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    chipRoot: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
        "&:focus": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
    },
    scheduler: {
        height: 300,
    },
}));

const WorkshopsTab = ({
    currentUser,
    clients,
    clientUrl,
    clientGreaterRegion,
    clientBeekeeper,
    visitTypes = [],
    regions,
}) => {
    const { t } = useTranslation(["workshops"]);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm")); //bool

    const [numCols, setNumCols] = useState(0);
    const [workshopModalOpen, setWorkshopModalOpen] = useState(false);
    const [chipsSelected, setChipsSelected] = useState([]);
    const [selectedWorkshop, setSelectedWorkshop] = useState({});
    const [schedulerOpen, setSchedulerOpen] = useState(false);
    const [schedulerState, setSchedulerState] = useState({});
    const [hiveInstallationDate, setHiveInstallationDate] = useState("");

    const selectedTagsFilter = (workshop) => {
        if (!chipsSelected.length) {
            return true;
        }
        //if chips/tags are selected, check that workshop tags include selected tags
        return workshop.tags && chipsSelected.every((tag) => workshop.tags.includes(tag));
    };

    const relevantWorkshops = visitTypes
        .filter((visitType) => visitType.visitTypeId.startsWith("ate"))
        .filter(selectedTagsFilter)
        .filter((workshop) => workshop?.availableIn?.includes(clientGreaterRegion));
    const workshopTags = [
        ...new Set(relevantWorkshops.reduce((acc, { tags }) => [...acc, ...tags], [])),
    ];

    const client = clients?.find((client) => client.MaRucheUrl === clientUrl);

    useEffect(() => {
        const num = mobileScreen ? 1 : 3;
        setNumCols(num);
    }, [mobileScreen]);

    useEffect(() => {
        //fetch existing workshop / rendezvous info and set in state
        Promise.all([
            fetchCurrentYearRdvs(),
            fetchWorkshopAllotments(client?.objectId, currentUser.sessionToken),
        ]).then(([currentYearRdvs, workshopAllotments]) => {
            const isParis = client.Region === 14;

            setSchedulerState({
                ...schedulerState,
                isLoading: false,
                visitTypes: isParis ? Seed.visitTypesParis : Seed.visitTypes,
                currentYearRdvs,
                workshopAllotments,
                currentYearWorkshops: filterForWorkshops(currentYearRdvs),
            });
        });
    }, []);

    useEffect(() => {
        //update hive installation date once visitTypes are available
        if (schedulerState.visitTypes) {
            const installationRdvDebut =
                getRdvsForVisitTypeId("installation")[0] //if installation was not made this current year, value here is undefined
                && getRdvsForVisitTypeId("installation")[0].Debut;
            const hiveInstallationDate = installationRdvDebut && moment(installationRdvDebut);
            setHiveInstallationDate(hiveInstallationDate);
        }
    }, [schedulerState]);

    // notify the user if they have any workshops to book
    useEffect(() => {
        const workshopsToSchedule = (schedulerState.workshopAllotments || []).length;

        if (workshopsToSchedule) {
            enqueueSnackbar(
                workshopsToSchedule !== 1
                    ? t("workshopsToSchedule_other", { count: workshopsToSchedule })
                    : t("workshopsToSchedule_one"),
                {
                    variant: "success",
                    preventDuplicate: true,
                }
            );
        }
    }, [schedulerState]);

    const fetchCurrentYearRdvs = () => fetchYearVisits(client?.objectId, currentUser.sessionToken);

    const filterForWorkshops = (rdvs) => {
        return rdvs.filter((rdv) => {
            const visitTypeId = rdv.VisitTypeId || "";
            return visitTypeId.startsWith("ate_");
        });
    };

    const getRdvsForVisitTypeId = (visitId) => {
        const visitType =
            schedulerState.visitTypes
            && schedulerState.visitTypes.find((visitType) => visitType.id === visitId);

        if (!schedulerState.currentYearRdvs || !visitType) return [];

        // Find RDVs with a matching visit type
        return schedulerState.currentYearRdvs.filter((rdv) => {
            const rdvVisitTypeId = rdv.VisitTypeId;
            return (
                "rdvVisitTypeIds" in visitType && visitType.rdvVisitTypeIds.includes(rdvVisitTypeId)
            );
        });
    };

    const toggleTagStatus = (tagName) => {
        if (chipsSelected.includes(tagName)) {
            const arr = chipsSelected.filter((tag) => tag !== tagName);
            setChipsSelected(arr);
        } else {
            setChipsSelected([...chipsSelected, tagName]);

            ReactGA.event({
                category: "workshop_tags",
                action: "click",
                label: tagName,
            });
        }
    };

    const handleTagClick = (tagName) => {
        toggleTagStatus(tagName);
    };

    const handleWorkshopSelect = (workshop) => {
        setSelectedWorkshop(workshop);
        setSchedulerState({ ...schedulerState, visitTypeId: workshop.visitTypeId });

        ReactGA.event({
            category: "workshop_card",
            action: "open",
            label: workshop.visitTypeId,
        });
    };

    const handleScheduleNowClick = (workshop) => {
        handleWorkshopSelect(workshop);
        setWorkshopModalOpen(false);
        setSchedulerOpen(true);

        ReactGA.event({
            category: "workshop_card",
            action: "schedule",
            label: workshop.visitTypeId,
        });
    };

    const handleSchedulerModalClose = () => {
        setSchedulerState({
            ...schedulerState,
            addVisitTypeId: null,
            editDatesRdv: null,
            visitTypeId: null,
        });
        setSchedulerOpen(false);
    };

    const handleWorkshopPlaced = () =>
        Promise.all([
            fetchCurrentYearRdvs(),
            fetchWorkshopAllotments(client?.objectId, currentUser.sessionToken),
        ]).then(([currentYearRdvs, workshopAllotments]) => {
            setSchedulerState({
                ...schedulerState,
                currentYearRdvs,
                workshopAllotments,
            });
        });

    return (
        <div className={classes.root}>
            {schedulerOpen && (
                <Modal
                    zIndex={950}
                    closable={true}
                    onModalClosed={handleSchedulerModalClose}
                    content={
                        <SelectWorkshopDate
                            hiveInstallationDate={hiveInstallationDate}
                            userId={currentUser.objectId}
                            currentlyHasAHive={!!getRdvsForVisitTypeId("ouverture")[0]}
                            bookedWorkshop={schedulerState.bookedWorkshop}
                            loading={true}
                            availabilities={[]}
                            selectedTime={null}
                            clickedDate={null}
                            clientId={client?.objectId}
                            clientRegion={client.Region}
                            clientBeekeeper={clientBeekeeper}
                            workshopType={schedulerState.visitTypeId}
                            closeButtonClicked={handleSchedulerModalClose}
                            onWorkshopPlaced={handleWorkshopPlaced}
                            regions={regions}
                        />
                    }
                />
            )}
            <WorkshopDialog
                modalOpen={workshopModalOpen}
                setModalOpen={setWorkshopModalOpen}
                workshop={selectedWorkshop}
                handleScheduleNowClick={handleScheduleNowClick}
            />
            <div className={classes.chipRoot}>
                {workshopTags.map((tag, key) => (
                    <Chip
                        key={key}
                        label={t(`tags.${tag}`)}
                        onClick={() => handleTagClick(tag)}
                        className={classes.chip}
                        variant={chipsSelected.includes(tag) ? "default" : "outlined"}
                        clickable
                    />
                ))}
            </div>
            <Typography className={classes.title} color="textSecondary" gutterBottom></Typography>
            <GridList
                cellHeight={"auto"}
                className={classes.workshopList}
                spacing={36}
                cols={numCols}
            >
                {relevantWorkshops.map((workshop, index) => (
                    <GridListTile key={index}>
                        <WorkshopCard
                            workshop={workshop}
                            setModalOpen={setWorkshopModalOpen}
                            handleWorkshopSelect={handleWorkshopSelect}
                            handleScheduleNowClick={handleScheduleNowClick}
                        />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
};

const mapStateToProps = ({ client, user, visitType }) => {
    const { currentUser } = user;
    const { clients, clientUrl } = client;
    const { visitTypes } = visitType;

    return { currentUser, clients, clientUrl, visitTypes };
};

WorkshopsTab.propTypes = {
    currentUser: PropTypes.object,
    clients: PropTypes.arrayOf(PropTypes.object),
    clientUrl: PropTypes.string,
    clientGreaterRegion: PropTypes.string,
    clientBeekeeper: PropTypes.object,
    visitTypes: PropTypes.array,
    regions: PropTypes.object,
};

export default connect(mapStateToProps, {})(WorkshopsTab);

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchIpLocation } from "./redux/ipLocation";
import { PostHogProvider, usePostHog } from "posthog-js/react";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";

import useAnalytics, { POSTHOG_HOST, POSTHOG_KEY } from "./analytics";
import AppBar from "./components/AppBar";
import Home from "./tabs/Home";
import Hidden from "@material-ui/core/Hidden";
import "./App.css";
import { useSafeAwsUrl } from "./util";

import { fetchClient, fetchOwnedClients, setClientUrl } from "./redux/clients";
import { fetchAnalytics } from "./redux/analytics";
import { fetchSearchClients } from "./redux/searchClients";
import { isAuthenticated } from "./networking/UserAccounts";

import moment from "moment-timezone";

import MyHiveProfile from "./components/MyhiveProfile";
import ProfileDialog from "./components/ProfileDialog";
import { fetchRegions } from "./redux/regions";
import { fetchVisitTypes } from "./redux/visitTypes";
import {
    NavigationSidebar,
    GlobalSidebarItem,
    HiveSidebarItem,
} from "@alveolemtl/alveole-components";
import UpdatePasswordDialog from "./components/UpdatePasswordDialog";
import { languages, convertI18nLanguages } from "./seed";
import { getFriendlyHiveName } from "./util";

const App = ({
    match: {
        params: { clientUrl, buzzId },
    },
    history,
    clients,
    fetchClient,
    fetchOwnedClients,
    currentUser,
    fetchSearchClients,
    fetchIpLocation,
    fetchAnalytics,
    setClientUrl,
    fetchRegions,
    fetchVisitTypes,
}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t, i18n } = useTranslation();
    const [themeMode, _setThemeMode] = useState(localStorage.getItem("theme") || "light");
    const [showClientSearch, setShowClientSearch] = useState(false);
    const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = useState(false);
    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const ownedHives = clients
        .filter((client) => client.owned)
        .sort((client1, client2) => {
            const client1Name = getFriendlyHiveName(client1);
            const client2Name = getFriendlyHiveName(client2);
            if (client1Name < client2Name) return -1;
            if (client1Name > client2Name) return 1;
            return 0;
        });
    const navigationSidebar = useMemo(() => {
        if (!currentUser) {
            return null;
        }
        return (
            <div style={{ zIndex: 1000 }}>
                <NavigationSidebar
                    hives={ownedHives.map((hive) => ({ id: hive.id, name: hive.Nom }))}
                    onHiveSidebarItemClick={(hiveSidebarItem, id) => {
                        switch (hiveSidebarItem) {
                            case HiveSidebarItem.Gallery:
                                history.push(`/${id}/gallery`);
                                break;
                            case HiveSidebarItem.Preferences:
                                history.push(`/${id}/preferences`);
                                break;
                            case HiveSidebarItem.Workshops:
                                history.push(`/${id}/workshops`);
                                break;
                            case HiveSidebarItem.Downloads:
                                history.push(`/${id}/downloads`);
                                break;

                            case HiveSidebarItem.Root:
                                history.push(`/${id}`);
                        }
                    }}
                    languages={languages.map((language) => ({
                        label: language.name,
                        languageCode: language.id,
                    }))}
                    selectedHiveId={clientUrl}
                    selectedSidebarItem={(() => {
                        if (window.location.href.includes("workshops")) {
                            return HiveSidebarItem.Workshops;
                        }
                        if (window.location.href.includes("gallery")) {
                            return HiveSidebarItem.Gallery;
                        }
                        if (window.location.href.includes("preferences")) {
                            return HiveSidebarItem.Preferences;
                        }
                        if (window.location.href.includes("downloads")) {
                            return HiveSidebarItem.Downloads;
                        }
                        if (window.location.pathname === "/") {
                            return GlobalSidebarItem.FindAHive;
                        }
                        return HiveSidebarItem.Root;
                    })()}
                    selectedLanguageCode={convertI18nLanguages(i18n.language)}
                    user={{
                        name: `${currentUser.firstName || ""} ${currentUser.lastName || ""}`,
                        title: currentUser.tagline || "",
                        avatarUrl: useSafeAwsUrl(currentUser.avatar?.url),
                    }}
                    onGlobalSidebarItemClick={(item) => {
                        switch (item) {
                            case GlobalSidebarItem.ChangePassword:
                                setShowUpdatePasswordDialog(true);
                                break;
                            case GlobalSidebarItem.SignOut:
                                history.push("/logout");
                                break;
                            case GlobalSidebarItem.FindAHive:
                                history.push("/");
                                break;
                            case GlobalSidebarItem.Profile:
                                setShowProfileDialog(true);
                        }
                    }}
                    externalLinks={[
                        {
                            label: t("appBar.feedback"),
                            url: "https://u89fw2m3i66.typeform.com/to/Jvl1ZDZN",
                        },
                        { label: t("appBar.website"), url: "https://www.alveole.buzz" },
                        { label: t("appBar.blog"), url: "https://www.alveole.buzz/blog/" },
                    ]}
                    onLanguageChange={(code) => handleLanguageChange(code)}
                />
            </div>
        );
    }, [ownedHives, window.location.href, languages, clientUrl, i18n.language, currentUser]);

    const handleLanguageChange = (language) => {
        window.isEnglish = language === "en";
        moment.locale(language);
        i18n.changeLanguage(language).then(() => i18n.reloadResources());
    };

    useAnalytics();
    const posthog = usePostHog();

    useEffect(() => {
        fetchRegions();
        fetchVisitTypes();
        fetchSearchClients();
        fetchIpLocation();
        fetchAnalytics();
    }, []);

    // check the currentUser's sessionToken for validity
    useEffect(() => {
        if (currentUser && currentUser.sessionToken) {
            isAuthenticated({ sessionToken: currentUser.sessionToken }).then((token) => {
                if (!token) {
                    enqueueSnackbar(t("error.session"), {
                        variant: "warning",
                        preventDuplicate: true,
                    });

                    history.push("/logout");
                }
            });
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {
            posthog?.identify(currentUser.email, {
                email: currentUser.email,
                role: currentUser.isStaff ? "staff" : currentUser.isPartner ? "partner" : "user",
            });
        }
    }, []);

    useEffect(() => {
        const isMaRuche = window.location.href.includes("maruche");
        const languageToSet = isMaRuche ? "fr" : i18n.language;

        const englishyRegex = /en([^c]|$)/g;

        window.isEnglish = !!languageToSet.match(englishyRegex);

        i18n.changeLanguage(languageToSet);
        moment.locale(languageToSet);

        if (currentUser) {
            // console.log("we do have a user", currentUser);
            // try {
            fetchOwnedClients({
                userId: currentUser.id,
                sessionToken: currentUser.sessionToken,
            });
            // } catch(error) {
            //   console.log("found it");
            // }

            const cookieConsent = localStorage.getItem("cookieConsent") === "CONSENTED";

            const handleConsent = (key) => {
                localStorage.setItem("cookieConsent", "CONSENTED");
                closeSnackbar(key);
            };

            const consentButton = (key) => (
                <>
                    <Button color="inherit" size="small" onClick={() => handleConsent(key)}>
                        {t("consent.acceptButton")}
                    </Button>
                </>
            );

            if (!cookieConsent) {
                enqueueSnackbar(t("consent.text"), {
                    // TODO localize
                    variant: "info",
                    persist: true,
                    preventDuplicate: true,
                    action: consentButton,
                });
            }
        }
    }, [currentUser]);

    // const tryClient = (hiveId) =>

    useEffect(() => {
        //set current client url in state
        setClientUrl(clientUrl);
        // if we haven't already fetched the client, do so
        if (clientUrl && !clients.some((client) => client.MaRucheUrl === clientUrl)) {
            fetchClient({
                hiveId: clientUrl,
                sessionToken: currentUser?.sessionToken,
            }).catch((error) => {
                // if the backend says this URL has been permanently moved, let's forward the app there, with a warning.
                if (Object.keys(error).includes("forwardTo")) {
                    history.push("/" + error.forwardTo);

                    return enqueueSnackbar(
                        `That location permanently moved to ${error.forwardTo}.`,
                        {
                            // TODO localize
                            variant: "warning",
                        }
                    );
                }

                // force a logout on session errors
                history.push("/logout");
            });
        }
    }, [clientUrl, currentUser]);
    // Trying to visit a client URL.

    if (clientUrl) {
        return (
            <PostHogProvider apiKey={POSTHOG_KEY} options={{ api_host: POSTHOG_HOST }}>
                <ThemeProvider theme={theme(themeMode)}>
                    <CssBaseline />
                    <UpdatePasswordDialog
                        open={showUpdatePasswordDialog}
                        handleClose={() => setShowUpdatePasswordDialog(false)}
                        history={history}
                    />
                    {showProfileDialog ? (
                        <ProfileDialog
                            open={showProfileDialog}
                            handleClose={() => setShowProfileDialog(false)}
                        />
                    ) : null}

                    {currentUser ? (
                        <div style={{ display: "flex" }}>
                            {navigationSidebar}
                            <div style={{ margin: "0 auto", width: "100%" }}>
                                <MyHiveProfile
                                    clientUrl={clientUrl}
                                    clients={clients}
                                    buzzId={buzzId}
                                    history={history}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <AppBar showClientSearch={showClientSearch} />
                            <Hidden smDown>
                                <div style={{ paddingTop: 50 }} />
                            </Hidden>

                            <MyHiveProfile
                                clientUrl={clientUrl}
                                clients={clients}
                                buzzId={buzzId}
                                history={history}
                            />
                        </>
                    )}
                </ThemeProvider>
            </PostHogProvider>
        );
    }

    return (
        <PostHogProvider apiKey={POSTHOG_KEY} options={{ api_host: POSTHOG_HOST }}>
            <ThemeProvider theme={theme(themeMode)}>
                <UpdatePasswordDialog
                    open={showUpdatePasswordDialog}
                    handleClose={() => setShowUpdatePasswordDialog(false)}
                    history={history}
                />
                {showProfileDialog ? (
                    <ProfileDialog
                        open={showProfileDialog}
                        handleClose={() => setShowProfileDialog(false)}
                    />
                ) : null}

                {currentUser ? (
                    <div style={{ display: "flex", zIndex: 1000 }}>
                        {navigationSidebar}
                        <div style={{ width: "100%", backgroundColor: "white" }}>
                            <Home setShowClientSearch={setShowClientSearch} />
                        </div>
                    </div>
                ) : (
                    <div style={{ backgroundColor: "white" }}>
                        <AppBar showClientSearch={showClientSearch} />
                        <Home setShowClientSearch={setShowClientSearch} />
                    </div>
                )}
            </ThemeProvider>
        </PostHogProvider>
    );
};

const mapStateToProps = ({ client, user }) => {
    const { currentUser } = user;
    const { clients } = client;
    return { clients, currentUser };
};

App.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    clients: PropTypes.array,
    fetchClient: PropTypes.func,
    fetchOwnedClients: PropTypes.func,
    currentUser: PropTypes.object,
    fetchSearchClients: PropTypes.func,
    fetchIpLocation: PropTypes.func,
    fetchAnalytics: PropTypes.func,
    setClientUrl: PropTypes.func,
    fetchRegions: PropTypes.func,
    fetchVisitTypes: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, {
        fetchClient,
        fetchOwnedClients,
        fetchSearchClients,
        fetchIpLocation,
        fetchAnalytics,
        setClientUrl,
        fetchRegions,
        fetchVisitTypes,
    })
)(App);

import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

import ReactGA from "react-ga";

import { languages } from "../seed";

import { makeStyles } from "@material-ui/core/styles";
import { AppBar as MuiAppBar, Tooltip, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ProfileIcon from "../img/icon-user.svg";
import GlobeIcon from "../img/icon-globe.svg";

import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";

import Menu from "@material-ui/core/Menu";
import LoginDialog from "./LoginDialog";
import OwnedHivesDialog from "./OwnedHivesDialog";
import ProfileDialog from "./ProfileDialog";
import SearchIcon from "@mui/icons-material/Search";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckIcon from "@mui/icons-material/Check";
import LoginIcon from "@mui/icons-material/Login";

import { useSafeAwsUrl, getQueryStringParams, getFriendlyHiveName } from "../util";

import CtaBar from "./CtaBar";
import NpsBar from "./NpsBar";

import UpdatePasswordDialog from "./UpdatePasswordDialog";
import PasswordReset from "./PasswordReset";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: "fixed",
        left: 0,
        top: 48,
        width: "100%",
        zIndex: 99,
        backgroundColor: "#ffa23f", //dark yellow ..?
    },
    ctaBar: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: "1px solid #ede6dc",
    },
    ctaLogo: {
        height: theme.spacing(6),
    },
    light: {
        color: "#fafafa",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    utilityButton: {
        [theme.breakpoints.down("xs")]: {
            padding: "3px",
        },
    },
    title: {
        flexGrow: 1,
        [theme.breakpoints.down("sm")]: {
            fontSize: "3vw",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
    alveole: {
        letterSpacing: "2px",
        fontKerning: "none",
    },
    subMenu: {
        marginLeft: theme.spacing(4),
    },
    avatar: {
        backgroundColor: theme.palette.primary,
        height: theme.spacing(3),
        width: theme.spacing(3),
        // backgroundColor: 'rgb(41 129 186)'
    },
    avatarImg: {
        width: "100%",
        height: "100%",
    },
    avatarIcon: {
        fontSize: 50,
    },
    ctaClose: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    ctaCloseIcon: {
        fontSize: "16px",
    },
    presenceMenu: {
        minWidth: 280,
    },
    buttonGrid: {
        flexWrap: "nowrap",
        width: "auto",
    },
}));

const AppBar = ({
    light = false,
    elevation = 1,
    match,
    history,
    clients = [],
    currentUser,
    showClientSearch,
}) => {
    const { t, i18n } = useTranslation();

    const isDeep = history.location.pathname.split("/").length > 2;
    const clientUrl = match && match.params && match.params.clientUrl;

    const classes = useStyles();
    const [title, setTitle] = useState("alvéole"); // TODO localize
    const [anchorEl, setAnchorEl] = useState(null);
    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [showPasswordResetDialog, setShowPasswordResetDialog] = useState(false);
    const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = useState(false);
    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const [ownedHivesDialogOpen, setOwnedHivesDialogOpen] = useState(false);
    const [anchorVeggieBurger, setAnchorVeggieBurger] = useState(null);
    // const [anchorNotifications, setAnchorNotifications] = useState(null);
    const [anchorLanguageMenu, setAnchorLanguageMenu] = useState(null);

    // const [notificationsTotal, setNotificationsTotal] = useState(0);

    const open = Boolean(anchorEl);
    const languageMenuOpen = Boolean(anchorLanguageMenu);

    const location = useLocation();
    useEffect(() => {
        //parse query string & check if login=true is part of url query string
        const paramsObj = getQueryStringParams(location.search);

        //if login=true, show login modal
        if (paramsObj.login === "true" && !currentUser) {
            setShowLoginDialog(true);
        }
        if ((paramsObj.reset || paramsObj.create) && !currentUser) {
            setShowPasswordResetDialog(true);
        }
        if (paramsObj.myHives === "show" && currentUser && clients.length > 0) {
            setOwnedHivesDialogOpen(true);
        }
    }, [currentUser, clients]);

    useEffect(() => {
        if (clientUrl && clients.length > 0) {
            const selectedHive = clients.find((client) => client.MaRucheUrl === clientUrl);
            if (selectedHive) setTitle(getFriendlyHiveName(selectedHive));
        }
    }, [clients, clientUrl]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    /*const handleNotifications = (event) => {
        if (notificationsTotal > 0) {
            ReactGA.event({
                category: "notifications",
                action: "impression",
            });

            setAnchorNotifications(event.currentTarget);
        }
    };*/
    const handleVeggieBurger = (event) => {
        setAnchorVeggieBurger(event.currentTarget);
        ReactGA.event({
            category: "appbar",
            action: "open",
            label: "veggieburger",
        });
    };
    const handleLanguageMenu = (event) => {
        setAnchorLanguageMenu(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorVeggieBurger(null);
        setAnchorLanguageMenu(null);
        // setAnchorNotifications(null);
        setOwnedHivesDialogOpen(false);
    };

    const handleLanguageChange = (language) => {
        window.isEnglish = language === "en";
        moment.locale(language);
        i18n.changeLanguage(language).then(() => i18n.reloadResources());
        handleClose();
    };

    const handleLoginClick = () => {
        handleClose();
        setShowLoginDialog(true);
    };

    /*const handleUpdatePasswordClick = () => {
        handleClose();
        setShowUpdatePasswordDialog(true);
    };*/

    /*const handleProfileClick = () => {
        handleClose();
        setShowProfileDialog(true);
    };*/

    const handleProfileModalClose = () => {
        handleClose();
        setShowProfileDialog(false);
    };

    const handlePasswordResetModalClose = () => {
        setShowPasswordResetDialog(false);
        handleClose();
    };

    const handleLoginModalClose = () => {
        setShowLoginDialog(false);
        handleClose();
    };
    const handleUpdatePasswordDialogClose = () => {
        setShowUpdatePasswordDialog(false);
        handleClose();
    };
    const BackButton = (
        <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={() => history.push(`/${clientUrl}`)}
        >
            <ArrowBackIcon />
        </IconButton>
    );

    const VeggieBurger = (
        <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            aria-controls="veggieburger"
            onClick={handleVeggieBurger}
        >
            <MoreIcon style={{ color: "#2D3648" }} />
        </IconButton>
    );

    const handleMenuClick = (url, external = false) => {
        handleClose();
        return external ? window.open(url) : history.push(url);
    };

    const ownedHives = clients
        .filter((client) => client.owned)
        .sort((client1, client2) => {
            const client1Name = getFriendlyHiveName(client1);
            const client2Name = getFriendlyHiveName(client2);
            if (client1Name < client2Name) return -1;
            if (client1Name > client2Name) return 1;
            return 0;
        });

    const hiveMenuItems = ownedHives.map((client) => {
        const { MaRucheUrl } = client;

        return (
            <MenuItem key={MaRucheUrl} onClick={() => handleMenuClick(`/${MaRucheUrl}`)}>
                {getFriendlyHiveName(client)}
            </MenuItem>
        );
    });

    const hiveMenuSection = (
        <div>
            <ListSubheader>{t("appBar.myHives")}</ListSubheader>
            {hiveMenuItems.map((Item) => Item)}
        </div>
    );

    const miscMenuSection = (
        <div>
            <ListSubheader style={{ lineHeight: 0, padding: 16 }}>alvéole</ListSubheader>
            <MenuItem onClick={() => handleMenuClick("/")} style={{ fontWeight: 500 }}>
                <SearchIcon fontSize={"small"} style={{ marginRight: 6, color: "#2D3648" }} />
                {t("appBar.home")}
            </MenuItem>
            <MenuItem
                component={"a"}
                href={"https://u89fw2m3i66.typeform.com/to/Jvl1ZDZN"}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: 500 }}
            >
                <OpenInNewIcon fontSize={"small"} style={{ marginRight: 6, color: "#2D3648" }} />
                {t("appBar.feedback")}
            </MenuItem>
            <MenuItem
                key="website"
                onClick={() => handleMenuClick("https://www.alveole.buzz", true)}
                style={{ fontWeight: 500 }}
            >
                <OpenInNewIcon fontSize={"small"} style={{ marginRight: 6, color: "#2D3648" }} />
                {t("appBar.website")}
            </MenuItem>
            <MenuItem
                key="blog"
                onClick={() => handleMenuClick("https://www.alveole.buzz/blog/", true)}
                style={{ fontWeight: 500 }}
            >
                <OpenInNewIcon fontSize={"small"} style={{ marginRight: 6, color: "#2D3648" }} />
                {t("appBar.blog")}
            </MenuItem>
        </div>
    );

    // TODO localize aria-labels, tooltips
    return (
        <div className={`${classes.root} ${light ? classes.light : null}`}>
            <LoginDialog
                open={!currentUser && showLoginDialog}
                handleClose={handleLoginModalClose}
                history={history}
            />
            <UpdatePasswordDialog
                open={showUpdatePasswordDialog}
                handleClose={handleUpdatePasswordDialogClose}
                history={history}
            />
            <PasswordReset
                open={showPasswordResetDialog}
                handleClose={handlePasswordResetModalClose}
                history={history}
            />
            {ownedHivesDialogOpen && (
                <OwnedHivesDialog
                    open={ownedHivesDialogOpen}
                    handleClose={() => setOwnedHivesDialogOpen(false)}
                    ownedHives={ownedHives}
                    onClick={handleMenuClick}
                />
            )}
            {showProfileDialog && (
                <ProfileDialog open={showProfileDialog} handleClose={handleProfileModalClose} />
            )}
            <MuiAppBar color="inherit" elevation={elevation}>
                <Toolbar variant="dense">
                    {isDeep ? BackButton : VeggieBurger}
                    <Menu
                        id="veggieburger"
                        anchorEl={anchorVeggieBurger}
                        open={Boolean(anchorVeggieBurger)}
                        keepMounted
                        onClose={handleClose}
                    >
                        {hiveMenuItems.length > 0 ? hiveMenuSection : null}
                        {miscMenuSection}
                    </Menu>
                    <Typography
                        variant="h6"
                        className={`${classes.title} ${
                            title === "alvéole" ? classes.alveole : null
                        }`}
                    >
                        {title}
                    </Typography>
                    <Grid container spacing={0} className={classes.buttonGrid}>
                        <Grid item>
                            <Tooltip title={t("appBar.language")}>
                                <IconButton
                                    aria-label="Change language"
                                    aria-controls="language-menu"
                                    aria-haspopup="true"
                                    color="inherit"
                                    className={classes.utilityButton}
                                    onClick={handleLanguageMenu}
                                >
                                    <img src={GlobeIcon} alt="" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {/* <Grid item>
                            <Tooltip title={t("appBar.notifications")}>
                                <IconButton
                                    aria-label="Notifications"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={handleNotifications}
                                    className={classes.utilityButton}
                                >
                                    <Badge
                                        badgeContent={notificationsTotal || 0}
                                        color="error"
                                        overlap="rectangular"
                                    >
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Grid> */}
                        <Grid item>
                            <Tooltip title={t("appBar.profile")}>
                                <IconButton
                                    aria-label="profile of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    className={classes.utilityButton}
                                >
                                    {currentUser && currentUser.avatar ? (
                                        <Avatar aria-label="author" className={classes.avatar}>
                                            <img
                                                alt=""
                                                className={classes.avatarImg}
                                                src={useSafeAwsUrl(currentUser.avatar.url)}
                                            />
                                        </Avatar>
                                    ) : (
                                        <img src={ProfileIcon} alt="" />
                                    )}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    {/* <NotificationsPanel
                        anchorEl={anchorNotifications}
                        open={Boolean(anchorNotifications)}
                        onClose={handleClose}
                        setNotificationsTotal={setNotificationsTotal}
                        disableScrollLock={false}
                    /> */}
                    <Menu
                        id="language-menu"
                        anchorEl={anchorLanguageMenu}
                        open={languageMenuOpen}
                        onClose={handleClose}
                        disableScrollLock={true}
                        style={{ marginTop: "24px" }}
                    >
                        {languages.map((language) => (
                            <MenuItem
                                selected={language.id === i18n.language}
                                onClick={() => handleLanguageChange(language.id)}
                                value={language.id}
                                key={language.id}
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                {language.name}
                                {language.id === i18n.language ? (
                                    <CheckIcon
                                        fontSize={"small"}
                                        style={{ marginLeft: 6, color: "#2D3648" }}
                                    />
                                ) : null}
                            </MenuItem>
                        ))}
                    </Menu>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{ classes: { root: classes.presenceMenu } }}
                        disableScrollLock={true}
                        style={{ marginTop: "24px" }}
                    >
                        <MenuItem onClick={handleLoginClick} style={{ fontWeight: 500 }}>
                            <LoginIcon
                                fontSize={"small"}
                                style={{ marginRight: 6, color: "#2D3648" }}
                            />
                            {t("appBar.signIn")}
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </MuiAppBar>
            {currentUser && <NpsBar />}
            {!currentUser && <CtaBar showClientSearch={showClientSearch} />}
        </div>
    );
};

const mapStateToProps = ({ client, user }) => {
    const { clients } = client;
    const { currentUser } = user;
    return { clients, currentUser };
};

AppBar.propTypes = {
    light: PropTypes.bool,
    elevation: PropTypes.number,
    match: PropTypes.object,
    history: PropTypes.object,
    clients: PropTypes.array,
    currentUser: PropTypes.object,
    showClientSearch: PropTypes.bool,
};

export default connect(mapStateToProps, {})(withRouter(AppBar));

import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
        backgroundColor: "transparent",
    },
    dot: {},
    dotActive: {
        backgroundColor: theme.palette.primary.dark,
    },
    icons: {},
    chevron: {
        color: theme.palette.primary.dark,
    },
}));

const SmallDotStepper = ({ maxSteps, onArrowClick, index }) => {
    const classes = useStyles();

    return (
        <MobileStepper
            variant="dots"
            steps={maxSteps}
            activeStep={index}
            position="static"
            classes={{
                root: classes.root,
                dot: classes.dot,
                dotActive: classes.dotActive,
            }}
            backButton={
                <IconButton
                    size="small"
                    onClick={() => onArrowClick("left")}
                    disabled={index === 0}
                >
                    <ChevronLeftIcon className={classes.chevron} />
                </IconButton>
            }
            nextButton={
                <IconButton
                    size="small"
                    onClick={() => onArrowClick("right")}
                    disabled={index === maxSteps - 1}
                >
                    <ChevronRightIcon className={classes.chevron} />
                </IconButton>
            }
        />
    );
};

SmallDotStepper.propTypes = {
    index: PropTypes.number,
    maxSteps: PropTypes.number,
    onArrowClick: PropTypes.func,
};

export default SmallDotStepper;

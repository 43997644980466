import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const fetchMediaForMain = async ({
    count = 0,
    isMaRuche = false,
    sessionToken,
    geoLocation = {},
}) => {
    const url = new URL(`${SERVER_URL}myhive/media/main/`);

    const params = {
        ...(sessionToken ? {sessionToken} : {}),
        count,
        isMaRuche,
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
        zipCode: geoLocation.zip_code,
    };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

export const fetchMedia = async ({ hives, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/media/`);

    const params = { sessionToken, hives: JSON.stringify(hives) };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

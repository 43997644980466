import React, { useEffect } from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import Hero from "../components/HomeHero";
import NearbyBuzzes from "../components/HomeNearbyBuzzes";
import WhereWeBuzz from "../components/HomeWhereWeBuzz";
import JoinTheMovement from "../components/HomeJoinTheMovement";
import Footer from "../components/Footer";

const Home = ({ setShowClientSearch }) => {
    useEffect(() => {
        ReactGA.event({
            category: "landing",
            action: "impression",
        });
    }, []);

    return (
        <>
            <Hero setShowClientSearch={setShowClientSearch} />
            <NearbyBuzzes />
            <WhereWeBuzz />
            <JoinTheMovement />
            <Footer />
        </>
    );
};

Home.propTypes = {
    setShowClientSearch: PropTypes.func,
};

export default Home;

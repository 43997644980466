import { connect } from "react-redux";
import { logout } from "./redux/user";
import PropTypes from "prop-types";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

function Logout({ logout, history }) {
    const posthog = usePostHog();

    useEffect(() => {
        logout();
        // reset PostHog user and send logout event
        posthog?.reset();
        posthog?.capture("user_logged_out");
        history.push("/?login=true");
    }, [logout, posthog, history]);

    return null;
}

Logout.propTypes = {
    logout: PropTypes.func,
    history: PropTypes.object,
};

export default connect(null, { logout })(Logout);

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useSafeAwsUrl } from "../util";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
    card: {
        width: "inherit",
        height: "inherit",
        backgroundColor: theme.palette.neutral.beige,
    },
    buttonbase: {
        width: "100%",
        height: "75px",
    },
    selected: {
        border: "1px solid",
        borderColor: theme.palette.primary.main,
    },
    cityList: {
        display: "flex",
        "flex-wrap": "wrap",
    },
    image: {
        width: "50px",
        height: "50px",
        borderRadius: theme.spacing(2),
        backgroundSize: "cover",
        "clip-path": theme.paths.alveole,
        [theme.breakpoints.down("xs")]: {
            width: "40px",
            height: "40px",
        },
    },
    content: {
        margin: "auto",
        textAlign: "left",
    },
    ville: {
        fontSize: "15px",
        fontWeight: "700",
        marginBottom: theme.spacing(0.25),
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
        },
    },
    numHives: {
        color: theme.palette.primary.dark,
        fontSize: "10px",
        fontWeight: 500,
        textTransform: "uppercase",
        [theme.breakpoints.down("xs")]: {
            fontSize: "10px",
        },
    },
    tabs: {},
}));

const CityCardList = ({ cities, cityIdHighlighted, setCityIdHighlighted, setStepperIndex }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language.includes("en");

    const handleClick = (e, city) => {
        e.preventDefault();
        ReactGA.event({
            category: "main",
            action: "click",
            label: "whereWeBuzzCity",
        });

        setStepperIndex(0);
        setCityIdHighlighted(city.regionId);
    };

    return (
        <Grid container spacing={2}>
            {cities.map((city, key) => {
                if (city.hives > 0) {
                    return (
                        <Grid key={key} item xs={6} md={3}>
                            <ButtonBase
                                className={classes.buttonbase}
                                onClick={(e) => handleClick(e, city)}
                            >
                                <Card
                                    elevation={0}
                                    className={`${classes.card} ${
                                        cityIdHighlighted === city.regionId ? classes.selected : ""
                                    }`}
                                >
                                    <CardContent>
                                        <Grid container justify="center">
                                            <Grid item xs={5} md={4}>
                                                <div
                                                    className={classes.image}
                                                    style={{
                                                        backgroundImage: `url(${useSafeAwsUrl(
                                                            "https://files.alveole.buzz/1eb72aa04e2248e5637b345a9f023ba0_IMG_20210810_112416.jpg.jpeg"
                                                        )})`,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={7} md={8} className={classes.content}>
                                                <Typography component="p" className={classes.ville}>
                                                    {isEnglish ? city.city : city.cityFR}
                                                </Typography>
                                                <Typography
                                                    component="p"
                                                    className={classes.numHives}
                                                >
                                                    {city.hives} {t("hives")}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </ButtonBase>
                        </Grid>
                    );
                }
                return null;
            })}
        </Grid>
    );
};

CityCardList.propTypes = {
    cities: PropTypes.array,
    cityIdHighlighted: PropTypes.number,
    setCityIdHighlighted: PropTypes.func,
    setStepperIndex: PropTypes.func,
};

export default CityCardList;

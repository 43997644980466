import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";

import CopyToClipboard from "./CopyToClipboard";

export default function ConfirmResetDialog({
    open,
    handleConfirmReset,
    handleCancelReset,
    resetLink,
    linkOpen,
    resetLoading,
    username,
}) {
    const { t } = useTranslation(["preferences", "common"]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancelReset}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("preferences:resetWarning.title")} <EmojiNatureIcon />{" "}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("preferences:resetWarning.description1")}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        {t("preferences:resetWarning.description2")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CopyToClipboard
                        label={(t("preferences:resetWarning.linkLabel") + username).toUpperCase()}
                        value={resetLink}
                        open={linkOpen}
                        handleConfirm={handleConfirmReset}
                        loading={resetLoading}
                    />
                </DialogActions>
                <DialogActions>
                    <Button onClick={handleCancelReset} color="primary">
                        {t("preferences:resetWarning.cancel")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmResetDialog.propTypes = {
    open: PropTypes.bool,
    handleConfirmReset: PropTypes.func,
    handleCancelReset: PropTypes.func,
    resetLink: PropTypes.string,
    linkOpen: PropTypes.func,
    resetLoading: PropTypes.bool,
    username: PropTypes.string,
};

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";

import { getRelationships } from "../networking/Client";

import ColleagueRow from "./PreferencesManagementColleagueRow";

const useStyles = makeStyles((theme) => ({
    preferencesCard: {
        padding: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        textTransform: "uppercase",
    },
}));

const PreferencesManagement = ({ hive }) => {
    const classes = useStyles();
    const { t } = useTranslation(["preferences", "common"]);
    const { currentUser } = useSelector((state) => state.user);
    const sessionToken = currentUser && currentUser.sessionToken;
    const hiveId = hive && hive.MaRucheUrl;

    const [userClientRelations, setUserClientRelations] = useState([]); //array of relationship objects

    const fetchRelationships = async () => {
        const relationships = await getRelationships({ hiveId, sessionToken });

        setUserClientRelations(relationships);
    };

    // fetch hive editors and set them to state
    // filter off the current user, who is the admin and does not appear in this list
    // TODO handle that filtered response in the back-end
    useEffect(() => {
        fetchRelationships();
    }, []);

    return (
        <>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {t("preferences:management.title")}
                <EmojiNatureIcon />
            </Typography>
            <Card className={classes.preferencesCard}>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                        {t("preferences:management.description")}
                    </Typography>
                    {userClientRelations
                        && userClientRelations
                            .filter((relationship) => !!relationship.user)
                            .sort((relationship1) =>
                                relationship1.user.username === hive.Email1 ? -1 : 1
                            )
                            .map((relationship) => (
                                <ColleagueRow
                                    mainContact={relationship.user.username === hive.Email1}
                                    relationship={relationship}
                                    classes={classes}
                                    sessionToken={sessionToken}
                                    hiveId={hive.MaRucheUrl}
                                    fetchRelationships={fetchRelationships}
                                    key={relationship.user.objectId}
                                    visitRemindersSub={relationship.emailOptIn === "subscribed"}
                                    buzzNotificationsSub={
                                        hive.subscriptions
                                        && hive.subscriptions.some(
                                            (sub) => sub.email === relationship.user.username
                                        )
                                    }
                                />
                            ))}
                    <ColleagueRow
                        editable={true}
                        classes={classes}
                        sessionToken={sessionToken}
                        hiveId={hive.MaRucheUrl}
                        fetchRelationships={fetchRelationships}
                    />
                </CardContent>
            </Card>
        </>
    );
};

PreferencesManagement.propTypes = {
    hive: PropTypes.object,
};

export default PreferencesManagement;

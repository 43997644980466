import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";

import CityCardList from "./CityCardList";
import WhereWeBuzzCard from "./WhereWeBuzzCard";
import StepperDots from "./StepperDots";

const useStyles = makeStyles(() => ({
    view: {
        overflow: "hidden",
    },
}));

const WhereWeBuzzTabView = (props) => {
    const {
        setStepperIndex,
        stepperIndex,
        cities,
        cityIdHighlighted,
        setCityIdHighlighted,
        mostActiveHives,
    } = props;

    const classes = useStyles();
    const [slideIn, setSlideIn] = useState(true);
    const [slideDirection, setSlideDirection] = useState("left");

    const onArrowClick = (direction) => {
        const increment = direction === "left" ? -1 : 1;
        const newIndex = stepperIndex + increment;

        const oppDirection = direction === "left" ? "right" : "left";
        setSlideDirection(direction);
        setSlideIn(false);

        setTimeout(() => {
            setStepperIndex(newIndex);
            setSlideDirection(oppDirection);
            setSlideIn(true);
        }, 250);
    };

    return (
        <div className={classes.view}>
            <Grid item xs={12}>
                {!!mostActiveHives.length && (
                    <WhereWeBuzzCard
                        hive={mostActiveHives[stepperIndex]}
                        slideIn={slideIn}
                        direction={slideDirection}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                {mostActiveHives.length > 1 ? (
                    <StepperDots
                        maxSteps={mostActiveHives.length}
                        onArrowClick={onArrowClick}
                        index={stepperIndex}
                    />
                ) : null}
            </Grid>
            <Grid item xs={12}>
                <CityCardList
                    cities={cities}
                    cityIdHighlighted={cityIdHighlighted}
                    setCityIdHighlighted={setCityIdHighlighted}
                    setStepperIndex={setStepperIndex}
                />
            </Grid>
        </div>
    );
};

WhereWeBuzzTabView.propTypes = {
    setStepperIndex: PropTypes.func,
    stepperIndex: PropTypes.number,
    cities: PropTypes.array,
    cityIdHighlighted: PropTypes.number,
    setCityIdHighlighted: PropTypes.func,
    mostActiveHives: PropTypes.arrayOf(PropTypes.object),
};

export default WhereWeBuzzTabView;

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmRemoveDialog({
    open,
    handleConfirmRemoveBuzz,
    handleCancelRemoveBuzz,
}) {
    const { t } = useTranslation(["common", "buzz"]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCancelRemoveBuzz}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t("buzz:confirmRemoveBuzz.title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("buzz:confirmRemoveBuzz.description")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelRemoveBuzz} color="primary">
                        {t("common:cancel")}
                    </Button>
                    <Button onClick={handleConfirmRemoveBuzz} color="primary" autoFocus>
                        {t("common:remove")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmRemoveDialog.propTypes = {
    open: PropTypes.bool,
    handleConfirmRemoveBuzz: PropTypes.func,
    handleCancelRemoveBuzz: PropTypes.func,
};

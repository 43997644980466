import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSafeAwsUrl } from "../util";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Slide from "@material-ui/core/Slide";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
    hiveCard: {
        backgroundColor: theme.palette.neutral.beige,
        margin: "10px 0",
        // height: "400px",
    },
    content: {
        color: "#5a5a5a",
        lineHeight: "1.5",
        margin: "auto",
    },
    buzzImage: {
        width: "100%",
        height: "100%",
        borderRadius: theme.spacing(2),
        backgroundSize: "cover",
        [theme.breakpoints.down("sm")]: {
            height: "150px",
        },
    },
    location: {
        color: theme.palette.primary.dark,
        fontWeight: "500",
        margin: "10px",
    },
    hiveLink: {
        margin: "10px",
        fontSize: "15px",
        fontWeight: "700",
        verticalAlign: "middle",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {},
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
        },
    },
    title: {
        fontSize: "20px",
        fontWeight: "700",
        margin: "10px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "15px",
        },
    },
    description: {
        margin: "10px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px",
        },
    },
    linkIcon: {
        position: "relative",
        top: "3px",
    },
}));

const WhereWeBuzzCard = ({ history, direction, slideIn, hive }) => {
    const { t } = useTranslation(["common", "cities"]);
    const classes = useStyles();

    const imgUrl = hive.MaRucheCoverPicture
        ? hive.MaRucheCoverPicture.url
        : "https://files.alveole.buzz/1eb72aa04e2248e5637b345a9f023ba0_IMG_20210810_112416.jpg.jpeg";

    const hiveClick = (event, url) => {
        event.preventDefault();

        ReactGA.event({
            category: "main_whereWeBuzz",
            action: "click",
            label: "Hive.MaRucheUrl",
        });

        url && history.push(`/${url}`);
    };

    return (
        <Slide direction={direction} in={slideIn}>
            <div>
                <Card className={classes.hiveCard} elevation={0}>
                    <CardContent>
                        <Grid container spacing={2} className={classes.buzzGrid}>
                            <Grid item md={6} xs={12}>
                                <div
                                    className={classes.buzzImage}
                                    style={{ backgroundImage: `url(${useSafeAwsUrl(imgUrl)})` }}
                                />
                            </Grid>
                            <Grid item md={6} xs={12} className={classes.content}>
                                <Typography variant="body2" className={classes.location}>
                                    {hive.Ville + ", " + hive.Pays}
                                </Typography>
                                <Typography component="p" className={classes.title}>
                                    {hive.Nom}
                                </Typography>
                                <Typography component="p" className={classes.description}>
                                    {t(`cities:${hive.Region.toString()}.description`)}
                                </Typography>
                                <Typography component="p" className={classes.hiveLink}>
                                    <Link
                                        onClick={(event) => hiveClick(event, hive.MaRucheUrl)}
                                        target="_blank"
                                        rel="noopener"
                                        color="secondary"
                                    >
                                        {t("common:visitHive")}
                                    </Link>
                                    <Link
                                        onClick={(event) => hiveClick(event, hive.MaRucheUrl)}
                                        className={classes.linkIcon}
                                        target="_blank"
                                        rel="noopener"
                                        color="secondary"
                                    >
                                        <ArrowForwardIcon />
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Slide>
    );
};

WhereWeBuzzCard.propTypes = {
    history: PropTypes.object,
    direction: PropTypes.string,
    slideIn: PropTypes.bool,
    hive: PropTypes.object,
};

export default withRouter(WhereWeBuzzCard);

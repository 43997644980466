import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import {
  BeeHomeWidget,
  BeeHomeWidgetData,
  BiodiversityType,
  BiodiversityWidget,
  BiodiversityWidgetStyle,
  BiodiversityReportData,
  ProjectExplainerWidget,
  YearQuarter,
} from "@alveolemtl/alveole-components";

import Card from "../Card";
import { BeekeeperCard, buildBeekeeperData } from "./BeekeeperCard";
import InspectionSheetCard from "./InspectionSheetCard";
import CalendarCard from "./CalendarCard";
import MapCard from "./Map/MapCard";
import WeatherCard from "./WeatherCard";
import TimelineSubscribeCard from "../TimelineSubscribeCard";
import CtaCard from "./CtaCard";

import { getIsSchoolOrBusinessType } from "../../util";
import { Weather } from "../../networking/Weather";
import { Beekeeper, Client, Inspection, User, Visit } from "../../typings/entities";
import { ThemeProvider } from "@mui/material";
import { MUItheme } from "../../theme/theme";

const floralSpeciesColorMap: Record<string, string> = {
  Herb: "#6200EE",
  Tree: "#26A69A",
  Shrub: "#FFD43A",
  Multiple: "#CBD2E0",
};

export function getShowInspectionSheetCard(
  isSchoolOrBusiness: boolean,
  isParis: boolean,
  relevantInspection: Inspection
): boolean {
  try {
    return !!(isSchoolOrBusiness && !isParis && Object.keys(relevantInspection.answers).length);
  } catch (error) {
    return false;
  }
}

function getLatestRelevantInspection(inspections: Inspection[]): Inspection | undefined {
  try {
    const validInspectionAnswers = Object.fromEntries(
      Object.entries(inspections[0].answers)
        .filter(([_, { hiveDead }]) => hiveDead !== "true" || hiveDead !== true)
        .filter(([_, { numBoxes }]) => Boolean(numBoxes))
        .filter(([id]) => id.includes("hive"))
    );
    return { ...inspections[0], answers: validInspectionAnswers };
  } catch (error) {
    return undefined;
  }
}

interface Props {
  client: Client;
  clientBeekeeper: Beekeeper;
  currentUser?: User;
  visits?: Visit[];
  inspections?: Inspection[];
  beeHomeWidgetData?: BeeHomeWidgetData[];
  floralBiodiversityReports?: BiodiversityReportData[];
  weather?: Weather;
}

export const HiveStats: React.FC<Props> = ({
  client,
  clientBeekeeper,
  currentUser,
  inspections,
  visits,
  beeHomeWidgetData,
  floralBiodiversityReports,
  weather,
}: Props) => {
  const language: Language = window.isEnglish ? "en" : "fr";
  const { t } = useTranslation(["cards", "hiveTab"]);
  const country: string = client.Pays;
  const typeClient: number = client.ServicePointer.typeClient || 6;
  const isSchoolOrBusiness: boolean = getIsSchoolOrBusinessType(typeClient);
  const isParis: boolean = client.Region === 14;
  const showVisitCards: boolean = isSchoolOrBusiness && !isParis && !!visits && visits.length > 0;
  const relevantInspection: Inspection | undefined = inspections && getLatestRelevantInspection(inspections);
  const showInspectionSheetCard: boolean =
    !!relevantInspection && getShowInspectionSheetCard(isSchoolOrBusiness, isParis, relevantInspection);
  const clientCoordinates = client.ServicePointer.locationPointer;
  const hiveCoordinates = client.ServicePointer.hiveGeoPoint;
  const coordinates = {
    lat: clientCoordinates?.lat ?? hiveCoordinates?.latitude,
    lng: clientCoordinates?.lng ?? hiveCoordinates?.longitude,
  };
  const showMarketingCards = !currentUser;
  const showBeekeeperCard = clientBeekeeper && clientBeekeeper.user;
  const showBeeHomeCard = !!(beeHomeWidgetData && beeHomeWidgetData.length);
  const showBiodiversityCard = !!(floralBiodiversityReports && floralBiodiversityReports.length);
  const currentQuarter: YearQuarter = YearQuarter.fromDate(new Date());

  const recentProjectExplainerSeen = localStorage.getItem("projectExplainerSeen");
  let recentProjectExplainerSeenParsed = [] as string[];
  if (recentProjectExplainerSeen) {
    recentProjectExplainerSeenParsed = JSON.parse(recentProjectExplainerSeen);
  }

  function projectExplainerNotSeen() {
    let notSeen = true;

    recentProjectExplainerSeenParsed.forEach((element) => {
      if (client.MaRucheUrl == element) {
        notSeen = false;
      }
    });

    return notSeen;
  }

  function handleCloseProjectExplainer() {
    localStorage.setItem(
      "projectExplainerSeen",
      JSON.stringify([...recentProjectExplainerSeenParsed, client.MaRucheUrl])
    );
  }

  return (
    <div>
      <ThemeProvider theme={MUItheme}>
        {showMarketingCards && <TimelineSubscribeCard currentHive={client} />}
        {showMarketingCards && (
          <ProjectExplainerWidget
            isOpen={projectExplainerNotSeen()}
            language={language}
            packageName={client.ServicePointer.package}
            clientName={client.MaRucheNom || client.Nom}
            handleCloseProjectExplainer={handleCloseProjectExplainer}
          />
        )}
        {showMarketingCards && <CtaCard />}
        {showBeekeeperCard && (
          <BeekeeperCard
            beekeeperData={buildBeekeeperData(clientBeekeeper)}
            accountAdvisorData={undefined /* TODO add account advisor data once it's available */}
          />
        )}
        {showVisitCards && <CalendarCard visits={visits} currentHive={client} />}
        <Card title={t("cards:moreAboutYourProject.title")} contentFormat={"tabs"}>
          {showInspectionSheetCard && (
            <InspectionSheetCard title={t("hiveTab:inspectionSheet")} inspection={relevantInspection} />
          )}
          {weather && <WeatherCard title={t("hiveTab:weather")} weather={weather} country={country} />}
          {showInspectionSheetCard && <MapCard title={t("hiveTab:beesFly")} coordinates={coordinates} />}
        </Card>
        {showBeeHomeCard && (
          <ErrorBoundary fallback={<div>Could not display BeeHome data.</div>}>
            <BeeHomeWidget beeHomes={beeHomeWidgetData} language={language} />
          </ErrorBoundary>
        )}
        {showBiodiversityCard && (
          <div style={{ marginTop: "17px" }}>
            <ErrorBoundary fallback={<div>Could not display biodiversity data.</div>}>
              <BiodiversityWidget
                biodiversityType={BiodiversityType.Floral}
                style={BiodiversityWidgetStyle.NarrowFrameless}
                endQuarter={currentQuarter}
                biodiversityReports={floralBiodiversityReports}
                colorBySpeciesTypeMap={floralSpeciesColorMap}
                showSupportedFrameworks={false}
                language={language}
              />
            </ErrorBoundary>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
};

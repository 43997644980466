import { SERVER_URL } from "./server";

export type BeeHomeData = {
  id: 1 | 2;
  location: { lat: number; lng: number };
  inspectionData: BeeHomeInspectionData[];
};

export type BeeHomeInspectionData = {
  id: string;
  timestamp: string; // ISO 8601 date string
  answers: { [questionId: string]: unknown };
  details: BeeHomeDetails[];
  beesFlightRangesMeters: number[];
};

export type BeeHomeDetails = {
  questionId: string;
  cappedTubes: {
    labels: { en: string; fr: string };
    number: number;
  };
  bees: {
    labels: { en: string; fr: string };
    number: number;
    perTube: number;
    flightRangeMeters: number;
  };
};

export async function fetchBeeHomeData(clientId: string): Promise<BeeHomeData[]> {
  const response = await fetch(`${SERVER_URL}clients/${clientId}/beeHomes`);
  return response.json();
}

import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import WorkshopsTab from "./tabs/WorkshopsTab";
import GalleryTab from "./tabs/GalleryTab";
import PreferencesTab from "./tabs/PreferencesTab";

import Buzz from "./tabs/Buzz";
import { connect } from "react-redux";
import { DownloadsTab } from "./tabs/DownloadsTab";

class UserSection extends Component {
    render() {
        const { parseClient, clientBeekeeper, regions } = this.props;

        const galleryTabComp = <GalleryTab currentHiveId={parseClient.id} user={this.props.user} />;
        const greaterRegion = regions[parseClient.Region]?.greaterRegion?.en || undefined;

        const workshopsTabComp = (
            <WorkshopsTab
                clientGreaterRegion={greaterRegion}
                clientBeekeeper={clientBeekeeper}
                regions={regions}
            />
        );

        const preferencesTabComp = <PreferencesTab hive={parseClient} />;
        const downloadsTabComp = <DownloadsTab client={parseClient} />;

        return (
            <div style={{ padding: "0 12px" }}>
                <Switch>
                    <Route path="/:clientUrl/ateliers" exact render={() => workshopsTabComp} />
                    <Route path="/:clientUrl/workshops" exact render={() => workshopsTabComp} />
                    <Route path="/:clientUrl/preferences" exact render={() => preferencesTabComp} />
                    <Route path="/:clientUrl/gallery" exact render={() => galleryTabComp} />
                    <Route path="/:clientUrl/downloads" exact render={() => downloadsTabComp} />
                    <Route
                        path="/:clientUrl/buzz/:buzzId"
                        exact
                        render={(props) => <Buzz {...props} client={parseClient} />}
                    />
                    <Redirect to="/:clientUrl" />
                </Switch>
            </div>
        );
    }
}

UserSection.propTypes = {
    parseClient: PropTypes.object,
    clientBeekeeper: PropTypes.object,
    user: PropTypes.object,
    regions: PropTypes.object,
};

const mapStateToProps = ({ region }) => {
    const { regions } = region;
    return { regions };
};

export default connect(mapStateToProps)(UserSection);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
    card: {
        display: "block",
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
            marginRight: 0,
            borderRadius: "0px",
        },
    },
    skeleton: {
        marginTop: -32,
        borderRadius: theme.spacing(2),
        height: 200,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
}));

const TimelineSkeleton = () => {
    const classes = useStyles();

    return (
        <>
            <Skeleton
                className={[classes.card, classes.skeleton]}
                style={{ animationDelay: "0.2s" }}
            />
            <Skeleton
                className={[classes.card, classes.skeleton]}
                style={{ animationDelay: "0.4s" }}
            />
            <Skeleton
                className={[classes.card, classes.skeleton]}
                style={{ animationDelay: "0.6s" }}
            />
        </>
    );
};

export default TimelineSkeleton;

import * as Sentry from "@sentry/react";
import { environment, Environment } from "./environments";

/** @see https://docs.sentry.io/platforms/javascript/configuration/ */
export function initSentry() {
  Sentry.init({
    enabled: environment !== Environment.Local,
    environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false, blockAllMedia: false }),
    ],
    dsn: "https://5f02794cc9f79b2cb682522e0128fb73@o4507414517055488.ingest.us.sentry.io/4507414520463360",
    tracePropagationTargets: ["localhost", /^https:\/\/.*\.alveole\.buzz/],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

import { connect } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

const Admin = ({ currentUser }) => {
    const isStaff = (currentUser && currentUser.isStaff) || false;
    const history = useHistory();

    if (!isStaff) history.push("/404");
    if (isStaff) window.location.href = "https://analytique.alveole.buzz/services/";

    return null;
};

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;
    return { currentUser };
};

Admin.propTypes = {
    currentUser: PropTypes.object,
};

export default connect(mapStateToProps, {})(Admin);

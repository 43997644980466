import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EmojiNatureIcon from "@material-ui/icons/EmojiNature";

import { unsubscribe } from "../networking/Client";
import { getFriendlyHiveName } from "../util";
import Spinner from "./Spinner";

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: "950px",
        height: "350px",
        margin: theme.spacing(2), //"50px 15px",
        padding: "30px 15px",
        textAlign: "center",
        color: theme.palette.primary.main,
        boxShadow: `1px 1px .4em ${theme.palette.primary.main}, -1px -1px .4em ${theme.palette.primary.main}`,
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(10),
        },
    },
    header: {
        fontFamily: "apercumedium, sans-serif",
        fontWeight: "400",
        width: "100%",
    },
    message: {},
    button: {
        //copied from LoginModalContent.css button styling
        display: "inline-block",
        padding: "0 20px",
        fontSize: "14px",
        color: theme.palette.primary.main,
        border: `3px solid ${theme.palette.primary.main}`,
        height: "40px",
        lineHeight: "36px",
        textAlign: "center",
        boxSizing: "border-box",
        margin: "25px auto 0 auto",
        cursor: "pointer",
        transition: "color 0.1s, background-color 0.1s",
    },
}));

const Unsubscribe = ({ history }) => {
    const [response, setResponse] = useState("");
    const [mode, setMode] = useState("LOADING");

    const classes = useStyles();
    const { t } = useTranslation(["buzz", "common"]);

    const { hiveId, email } = useParams();

    useEffect(() => {
        unsubscribe({
            decrypt: true,
            hiveId,
            email,
        })
            .then((data) => {
                setResponse(data);
                setMode("SUCCESS");
            })
            .catch(() => {
                setMode("FAILED");
            });
    }, []);

    const handleClick = () => {
        history.push("/");
    };

    return (
        <>
            {mode === "LOADING" && <Spinner />}
            {mode === "SUCCESS" && (
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h5">
                            <EmojiNatureIcon />
                            {t("buzz:unsubscribe.header")}
                            {getFriendlyHiveName(response[0])}
                            <EmojiNatureIcon />
                        </Typography>
                        <Typography variant="subtitle1">{t("buzz:unsubscribe.message")}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button className={classes.button} onClick={handleClick}>
                            {t("buzz:unsubscribe.button")}
                        </Button>
                    </CardActions>
                </Card>
            )}
            {mode === "FAILED" && (
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h5">There was an error with this request</Typography>
                        <Typography>Failed to unsubscribe</Typography>
                    </CardContent>
                    <CardActions>
                        <Button className={classes.button} onClick={handleClick}>
                            {t("buzz:unsubscribe.button")}
                        </Button>
                    </CardActions>
                </Card>
            )}
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;
    return { currentUser };
};

Unsubscribe.propTypes = {
    history: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(Unsubscribe));

// TODO localize
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import { useHistory } from "react-router";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { login } from "../../redux/user";

import { useLocation } from "react-router-dom";
import { getQueryStringParams } from "../../util";
import { usePostHog } from "posthog-js/react";

const useStyles = makeStyles((theme) => ({
    forgotPassword: {
        // fontSize: '1 rem',
        color: theme.palette.common.black,
    },
    dialog: {
        width: 400,
    },
    passwordAlert: {
        borderRadius: 0,
    },
    passwordAlertLink: {
        color: "inherit",
        cursor: "pointer",
        textDecoration: "underline",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    buttonText: {
        color: theme.palette.primary.main,
    },
}));

const LoginDialogContent = ({ handleForgotPasswordClick, login, loginError, loginLoading }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [passwordInputValue, setPasswordInputValue] = useState("");
    const [emailInputValue, setEmailInputValue] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const posthog = usePostHog();

    const paramsObj = getQueryStringParams(location.search);

    const handleLoginClick = async () => {
        const email = emailInputValue.toLocaleLowerCase();
        const redirectUrl = await login({
            username: email,
            password: passwordInputValue,
        });
        if (!loginError) {
            posthog?.capture("user_logged_in");
        }
        if (redirectUrl) {
            history.push(redirectUrl);
        }
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        if (loginError) setPasswordError(true);
        if (!loginError) setPasswordError(false);
    }, [loginError]);

    useEffect(() => {
        if (paramsObj.e) {
            setEmailInputValue(decodeURIComponent(paramsObj.e));
        }
    }, []);

    // TODO localize
    return (
        <>
            <DialogTitle>
                {t("appBar.signIn")}
                <EmojiNatureIcon />
            </DialogTitle>
            <Collapse in={passwordError}>
                <Alert severity="error" className={classes.passwordAlert}>
                    {t("appBar.incorrect")}
                </Alert>
            </Collapse>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t("email")}
                    type="email"
                    value={emailInputValue}
                    onChange={(event) => setEmailInputValue(event.target.value)}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="name"
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    value={passwordInputValue}
                    onChange={(event) => setPasswordInputValue(event.target.value)}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleTogglePassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    // align="center"
                    className={classes.forgotPassword}
                    variant="text"
                    onClick={loginLoading ? null : handleForgotPasswordClick}
                    tabIndex={0}
                    role="button"
                >
                    {t("appBar.forgot")}
                </Button>
                <div className={classes.wrapper}>
                    <Button
                        onClick={handleLoginClick}
                        className={classes.buttonText}
                        disabled={loginLoading}
                    >
                        {t("appBar.signIn")}
                    </Button>
                    {loginLoading && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                </div>
            </DialogActions>
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { currentUser, loginError, loginLoading } = user;
    return { currentUser, loginError, loginLoading };
};

LoginDialogContent.propTypes = {
    login: PropTypes.func,
    loginError: PropTypes.bool,
    loginLoading: PropTypes.bool,
    handleForgotPasswordClick: PropTypes.func,
};

export default connect(mapStateToProps, { login })(LoginDialogContent);

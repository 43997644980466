import { configureStore } from "@reduxjs/toolkit";
import clientReducer from "./clients";
import searchClientReducer from "./searchClients";
import ipLocationReducer from "./ipLocation";
import userReducer from "./user";
import analyticsReducer from "./analytics";
import regionsReducer from "./regions";
import visitTypesReducer from "./visitTypes";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";

const loadLocalUser = () => {
  try {
    const localUser = localStorage.getItem("user");
    if (localUser) return JSON.parse(localUser);

    return null;
  } catch (error) {
    localStorage.removeItem("user");

    return null;
  }
};

const loadRecentSearches = () => {
  const recentSearches = localStorage.getItem("recentSearches");
  return JSON.parse(String(recentSearches)) || [];
};

const preloadedState = {
  client: {
    clients: [],
    clientUrl: "",
  },
  user: {
    currentUser: loadLocalUser(),
  },
  searchClient: {
    searchClients: [],
    recentSearches: loadRecentSearches(),
  },
  ipLocation: {
    ipLocationFetching: true,
  },
};

export const reducer = {
  client: clientReducer,
  user: userReducer,
  searchClient: searchClientReducer,
  ipLocation: ipLocationReducer,
  analytics: analyticsReducer,
  region: regionsReducer,
  visitType: visitTypesReducer,
};

const store: ToolkitStore = configureStore({
  // @ts-expect-error TS2322
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  // @ts-expect-error TS2322
  preloadedState,
});

export default store;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";
import { environment, Environment } from "./networking/environments";

export const POSTHOG_KEY =
    (environment === Environment.Production && "phc_zbjWrg8ofghfuDTcT6yyIsxQXKDRbnDNBtW9HkXYO0e")
    || (environment === Environment.Development
        && "phc_l5nnEPEhibofTlPdMztVGbI5PQWLNKOObvPdxr0xGwT");
export const POSTHOG_HOST = "https://us.i.posthog.com";

const TRACKING_ID = "UA-72120764-3";
const debug = [Environment.Local, Environment.Development].includes(environment);

ReactGA.initialize(debug ? "UA-00000000-0" : TRACKING_ID, {
    titleCase: false,
    debug,
    // testMode: inDevelopment,
});

ReactGA.set({ anonymizeIp: true });

const useAnalytics = () => {
    const location = useLocation();
    const { currentUser } = useSelector((state) => state.user);

    const isStaff = (currentUser && currentUser.isStaff) || false;

    useEffect(() => {
        if (!isStaff) ReactGA.pageview(location.pathname + location.search);

        // this potentially grabs the google tracker code... could use it as our unique fingerprint
        // for allowing anonymous <3'ing, etc.
        // console.log(ReactGA.ga(tracker => console.log(tracker.get("clientId"))));
    }, [location]);
};

export default useAnalytics;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import ProfileCover from "../components/MyhiveTab/ProfileCover";
import { fetchBuzz } from "../networking/Buzz";

import TimelineCard from "../components/TimelineCard";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    buzz: {
        marginTop: theme.spacing(2),
    },
}));

const Buzz = ({ client = null, match }) => {
    const classes = useStyles();
    const [buzz, setBuzz] = useState();

    const { buzzId } = match.params;

    const _fetchBuzz = async () => {
        const fetchedBuzz = await fetchBuzz({
            buzzId,
            hive: client.id,
        });

        setBuzz(fetchedBuzz);
    };

    useEffect(() => {
        _fetchBuzz();
    }, [buzzId]);

    return buzz ? (
        <Grid container alignItems="flex-start" spacing={2}>
            <Helmet>
                <meta property="og:title" content="This is a buzz... welcome to the buzz" />
            </Helmet>
            <Grid item xs={12}>
                <ProfileCover client={client} showStats={false} />
                <div className={classes.buzz}>
                    <TimelineCard buzz={buzz} />
                </div>
            </Grid>
        </Grid>
    ) : null;
};

Buzz.propTypes = {
    client: PropTypes.object,
    match: PropTypes.any,
};

export default Buzz;

import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const fetchYearVisits = async (clientId, sessionToken) => {
    const url = new URL(`${SERVER_URL}myhive/visits/${clientId}`);
    const currentYear = new Date().getFullYear();

    const start = new Date(currentYear, 0, 1);
    const end = new Date(currentYear, 11, 31);

    const params = { from: start, until: end };
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
            sessionToken,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

export const fetchUpcomingVisits = async (clientId, sessionToken) => {
    const url = new URL(`${SERVER_URL}myhive/visits/${clientId}`);

    const today = new Date();
    const theFuture = new Date();
    theFuture.setDate(theFuture.getDate() + 150);
    const params = { from: today, until: theFuture };
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
            sessionToken,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

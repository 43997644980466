import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        [theme.breakpoints.down("1024")]: {
            marginLeft: "-50px",
            marginRight: "-50px",
        },
    },
    field: {
        marginBottom: 12,
    },
    mediumInput: {
        width: "50%",
        [theme.breakpoints.down("1024")]: {
            width: "100%",
        },
    },
}));

const WorkshopReservationForm = ({
    onSubmit,
    workshopTypeId,
    onDatesChangedBackButtonClick,
    visitTypes,
}) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation(["common", "workshops"]);
    const isEnglish = i18n.language.includes("en");
    const workshopType = visitTypes.find((vt) => vt.visitTypeId === workshopTypeId) || {};
    const canBeVirtual = !!workshopType.virtual;
    const canBeInPerson = !!workshopType.inPerson;

    const [errors, setErrors] = useState({});
    const [formValues, setFormValues] = useState({
        nameOfOrganiser: "",
        mobilePhone: "",
        email: "",
        ageGroup: "adults",
        language: isEnglish ? "english" : "french",
        //if it's possible to be in person, default to that (to show in-person questions on load)
        inPersonOrVirtual: canBeInPerson ? "inPerson" : "virtual",
        address: "",
        room: "",
        parking: "",
        numberOfParticipants: "",
        commentsAndInstructions: "",
    });
    const isVirtual = formValues.inPersonOrVirtual === "virtual";

    const maxParticipants =
        workshopType.participantsLimit?.[isVirtual ? "virtual" : "inPerson"] || null;

    const requiredInPersonFields = [
        "nameOfOrganiser",
        "mobilePhone",
        "email",
        "ageGroup",
        "language",
        "inPersonOrVirtual",
        "address",
        "parking",
        "numberOfParticipants",
    ];
    const requiredVirtualFields = [
        "nameOfOrganiser",
        "mobilePhone",
        "email",
        "ageGroup",
        "language",
        "inPersonOrVirtual",
        "numberOfParticipants",
    ];

    const handleInputChange = (event) => {
        const { value, name } = event.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const validate = () => {
        const newErrors = {};

        for (const field in formValues) {
            if (field === "mobilePhone") {
                const isValid = validatePattern({
                    key: field,
                    //something that atleast resembles a phone number, surrounded by anything -- for extensions etc.
                    pattern:
                        ".*(?:\\(?(?:\\+|00)\\d{1,2}\\)?)?[\\s\\-\\.]*\\(?\\d{3}\\)?[\\s\\-\\.]*\\d{3}[\\s\\-\\.]*\\d{4}.*",
                });
                if (!isValid) newErrors[field] = t("workshops:reservationForm.inputErrors.phone");
            }

            if (field === "email") {
                const isValid = validatePattern({
                    key: field,
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/,
                });
                if (!isValid) newErrors[field] = t("workshops:reservationForm.inputErrors.email");
            }

            if (field === "numberOfParticipants") {
                const isValid = validateNumInput({
                    key: field,
                    max: maxParticipants || 3000,
                    min: 1,
                });
                if (!isValid)
                    newErrors[field] = t("workshops:reservationForm.participantsLimit", {
                        max: maxParticipants,
                    });
            }
            //if a required field is left empty
            const required = isVirtual ? requiredVirtualFields : requiredInPersonFields;
            if (required.includes(field) && !formValues[field]) {
                newErrors[field] = t("workshops:reservationForm.inputErrors.empty");
            }
        }
        setErrors(newErrors);

        return !Object.keys(newErrors).length;
    };

    const validatePattern = ({ key, pattern }) => {
        const isValid = !!formValues[key].match(pattern);
        return isValid;
    };

    const validateNumInput = ({ key, max, min }) => {
        const isValid = Number(formValues[key]) <= max && Number(formValues[key]) >= min;
        return isValid;
    };

    const verifyValueTranslation = (key, value) => {
        //map radio/select input values to their locale translations
        if (key === "inPersonOrVirtual" && value === "inPerson")
            value = t("workshops:tags.IN_PERSON");
        if (key === "inPersonOrVirtual" && value === "virtual") value = t("workshops:tags.ONLINE");
        if (key === "language" && value === "english") value = t("common:english");
        if (key === "language" && value === "french") value = t("common:french");
        if (key === "language" && value === "german") value = t("common:german");
        if (key === "language" && value === "dutch") value = t("common:dutch");
        if (key === "ageGroup" && value === "adults") value = t("workshops:reservationForm.adults");

        return value;
    };

    const handleSubmit = () => {
        window.scroll(0, 0);
        const valid = validate();
        if (!valid) return;

        const formattedFormValues = Object.entries(formValues)
            //filter address/room/parking fields if workshop is virtual before formatting ToDo object
            .filter(
                (field) =>
                    !(
                        isVirtual
                        && (field[0] === "address" || field[0] === "room" || field[0] === "parking")
                    )
            )
            .map(
                ([key, value]) =>
                    `\u2022 ${t(`workshops:reservationForm.${key}`)}: ${
                        verifyValueTranslation(key, value) || "-"
                    }`
            );

        onSubmit(formattedFormValues.join("\n"), isVirtual, formValues.email);
    };

    const textField = ({
        name,
        required = false,
        fullWidth = false,
        placeholder = "",
        helperText = "",
        type = "string",
        inputProps = {},
        multiline = false,
        additionalClassName = "",
    }) => (
        <TextField
            variant="outlined"
            className={`${classes.field} ${additionalClassName}`}
            type={type}
            inputProps={inputProps}
            label={t(`workshops:reservationForm.${name}`)}
            id={`${name}-input`}
            name={name}
            placeholder={placeholder || ""}
            required={required}
            fullWidth={fullWidth}
            value={formValues[name]}
            error={!!errors[name]}
            helperText={helperText}
            onChange={handleInputChange}
            multiline={multiline}
        />
    );

    textField.propTypes = {
        name: PropTypes.string,
        required: PropTypes.bool,
        fullWidth: PropTypes.bool,
        placeholder: PropTypes.string,
        helperText: PropTypes.string,
        type: PropTypes.string,
        inputProps: PropTypes.object,
        multiline: PropTypes.bool,
        additionalClassName: PropTypes.string,
    };

    return (
        <Card className={classes.wrapper}>
            <CardContent>
                {canBeVirtual && canBeInPerson && (
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend">
                            {t("workshops:reservationForm.inPersonOrVirtual")}
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby={t("workshops:reservationForm.inPersonOrVirtual")}
                            name="inPersonOrVirtual"
                            value={formValues.inPersonOrVirtual}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel
                                key="inPerson"
                                value="inPerson"
                                control={<Radio />}
                                label={t("workshops:tags.IN_PERSON")}
                            />
                            <FormControlLabel
                                key="virtual"
                                value="virtual"
                                control={<Radio />}
                                label={t("workshops:tags.ONLINE")}
                            />
                        </RadioGroup>
                    </FormControl>
                )}
                {textField({
                    name: "nameOfOrganiser",
                    fullWidth: true,
                    required: true,
                    helperText: errors.nameOfOrganiser,
                })}
                <Grid container>
                    <Grid item lg={4} xs={12}>
                        {textField({
                            name: "mobilePhone",
                            type: "tel",
                            required: true,
                            placeholder: "1-555-123-4567",
                            helperText: errors.mobilePhone,
                        })}
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        {textField({
                            name: "email",
                            type: "email",
                            required: true,
                            placeholder: "example@gmail.com",
                            helperText: errors.email,
                        })}
                    </Grid>
                    <Grid item lg={2} xs={12}>
                        <FormControl>
                            <InputLabel id="ageGroup-label">
                                {t("workshops:reservationForm.ageGroup")}
                            </InputLabel>
                            <Select
                                name="ageGroup"
                                labelId="ageGroup-label"
                                required
                                value={formValues.ageGroup}
                                onChange={handleInputChange}
                                className={classes.field}
                            >
                                <MenuItem key="2-5" value="2-5">
                                    2-5
                                </MenuItem>
                                <MenuItem key="6-10" value="6-10">
                                    6-10
                                </MenuItem>
                                <MenuItem key="10-15" value="10-15">
                                    10-15
                                </MenuItem>
                                <MenuItem key="15-18" value="15-18">
                                    15-18
                                </MenuItem>
                                <MenuItem key="adults" value="adults">
                                    {t("workshops:reservationForm.adults")}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={2} xs={12}>
                        <FormControl>
                            <InputLabel id="language-label">
                                {t("common:appBar.language")}
                            </InputLabel>
                            <Select
                                name="language"
                                labelId="language-label"
                                required
                                value={formValues.language}
                                onChange={handleInputChange}
                                className={classes.field}
                            >
                                <MenuItem key="english" value="english">
                                    {t("common:english")}
                                </MenuItem>
                                <MenuItem key="french" value="french">
                                    {t("common:french")}
                                </MenuItem>
                                <MenuItem key="german" value="german">
                                    {t("common:german")}
                                </MenuItem>
                                <MenuItem key="dutch" value="dutch">
                                    {t("common:dutch")}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={!isVirtual}>
                    {textField({
                        name: "address",
                        fullWidth: true,
                        required: !isVirtual,
                        helperText: errors.language,
                    })}
                    {textField({
                        name: "room",
                        fullWidth: true,
                    })}
                    {textField({
                        name: "parking",
                        fullWidth: true,
                        required: !isVirtual,
                        helperText: errors.parking,
                    })}
                </Collapse>
                {textField({
                    name: "numberOfParticipants",
                    required: true,
                    helperText: maxParticipants
                        ? t("workshops:reservationForm.participantsLimit", { max: maxParticipants })
                        : "",
                    inputProps: { inputProps: { min: 0, max: maxParticipants } },
                    type: "number",
                    additionalClassName: classes.mediumInput,
                })}
                {textField({
                    name: "commentsAndInstructions",
                    fullWidth: true,
                    multiline: true,
                })}
            </CardContent>
            <CardActions>
                <Button variant="contained" color="primary" onClick={onDatesChangedBackButtonClick}>
                    {t("workshops:reservationForm.back")}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {t("workshops:schedule")}
                </Button>
            </CardActions>
        </Card>
    );
};

WorkshopReservationForm.propTypes = {
    onSubmit: PropTypes.func,
    workshopType: PropTypes.string,
    onDatesChangedBackButtonClick: PropTypes.func,
    visitTypes: PropTypes.array,
    workshopTypeId: PropTypes.string,
};

const mapStateToProps = ({ visitType }) => {
    const { visitTypes } = visitType;
    return { visitTypes };
};

export default connect(mapStateToProps)(WorkshopReservationForm);

import React from "react";
import GoogleMap from "google-map-react"; // not to be confused with react-google-maps
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import "./MapCard.css";

import grayMapStyle from "./GrayMapStyle.json";

function createMapOptions() {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: "none",
        scrollwheel: false,
        styles: grayMapStyle,
    };
}

const ConcentricCircles = () => {
    return <div className="concentric-circles" />;
};

const MapCard = (props) => {
    const coordinates = {
        lat: props.coordinates && props.coordinates.lat,
        lng: props.coordinates && props.coordinates.lng,
    };

    return coordinates.lat && coordinates.lng ? (
        <>
            <div className="wrapper">
                <GoogleMap
                    bootstrapURLKeys={{ key: "AIzaSyB7-4mctoHh27b2cEYiFcuuMvPVXsUYCbE" }}
                    center={coordinates}
                    zoom={13}
                    options={createMapOptions}
                >
                    <ConcentricCircles lat={coordinates.lat} lng={coordinates.lng} text={"Bees"} />
                </GoogleMap>
            </div>
        </>
    ) : null;
};

MapCard.propTypes = {
    coordinates: PropTypes.shape({
        lat: PropTypes.any,
        lng: PropTypes.any,
    }),
    title: PropTypes.string,
};

export default withTranslation(["hiveTab"])(MapCard);

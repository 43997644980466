import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useOnScreen } from "../util";

import Hidden from "@material-ui/core/Hidden";
import ClientSearch from "../ClientSearch";
import RecentSearches from "./RecentSearches";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HexGrid from "./HexGrid";
import heroGrid from "../img/heroGrid.png";

const useStyles = makeStyles((theme) => ({
    heroParent: {
        backgroundImage: `url("${heroGrid}")`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        paddingTop: 200,
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.down("sm")]: {
            height: "auto",
            backgroundImage: "none",
            backgroundColor: theme.palette.neutral.beige,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    heroContent: {
        maxWidth: "1150px",
        margin: "0 auto",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    heroTitle: {
        fontWeight: 700,
        fontSize: "50px",
    },
    heroSubtitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        color: "#5a5a5a",
        lineHeight: "1.5",
        fontSize: "14px",
    },
    clientSearchParent: {
        marginBottom: theme.spacing(2),
    },
}));

const Hero = ({ recentSearches, setShowClientSearch }) => {
    const classes = useStyles();
    const ref = useRef();
    const scrollbarVisible = useOnScreen(ref, "-100px");
    const { t } = useTranslation(["landingPage"]);

    // triggers when the scrollbar disappears or reappears on the viewport
    useEffect(() => {
        setShowClientSearch(!scrollbarVisible);
    }, [scrollbarVisible]);

    return (
        <div className={classes.heroParent}>
            <Grid
                container
                spacing={2}
                className={classes.heroContent}
                style={{ position: "relative" }}
            >
                <Grid item md={6} xs={12}>
                    <Typography variant="h2" color="primary" className={classes.heroTitle}>
                        {t("landingPage:hero.title")}
                    </Typography>
                    <Typography component="p" className={classes.heroSubtitle}>
                        {t("landingPage:hero.description")}
                    </Typography>
                    <div ref={ref} className={classes.clientSearchParent}>
                        <ClientSearch analyticsCategory="landing_clientsearch" />
                    </div>
                    {!!recentSearches.length && <RecentSearches />}
                </Grid>
                <Hidden smDown>
                    <HexGrid />
                </Hidden>
            </Grid>
            <Hidden smDown>
                <div style={{ paddingBottom: 170 }} />
            </Hidden>
        </div>
    );
};

const mapStateToProps = ({ searchClient }) => {
    const { recentSearches } = searchClient;
    return { recentSearches };
};

Hero.propTypes = {
    analytics: PropTypes.object,
    recentSearches: PropTypes.array,
    setShowClientSearch: PropTypes.func,
};

export default connect(mapStateToProps)(Hero);

import { listSearchClients } from "../networking/Client";

const FETCHING = "alveole-maruche/searchClients/FETCHING";
const FETCHED = "alveole-maruche/searchClients/FETCHED";
const ERROR = "alveole-maruche/searchClients/ERROR";
const SEARCH = "alveole-maruche/searchClients/SEARCH";

export default function searchClientReducer(
    state = {
        searchClients: [],
        recentSearches: [],
    },
    action = {}
) {
    switch (action.type) {
        case FETCHING: {
            return {
                ...state,
                searchClientsFetching: true,
            };
        }
        case FETCHED: {
            return {
                ...state,
                searchClientsFetching: false,
                searchClients: action.payload,
            };
        }
        case ERROR: {
            return {
                ...state,
                searchClientsFetching: false,
                error: action.payload,
            };
        }
        case SEARCH: {
            //add timestamp to search object
            const timestamp = Date.now();
            const newSearch = { ...action.payload, timestamp };
            const oldSearches = state.recentSearches; //array of 0-3 values
            //if new search is a duplicate, remove old search from array
            const cutArray = oldSearches.filter(
                (search) => search.MaRucheUrl !== newSearch.MaRucheUrl
            );
            //add new search object to array of 3 most recent searches
            const updatedSearches = [newSearch, ...cutArray.slice(0, 2)];
            //set array in local storage
            localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));

            return {
                ...state,
                recentSearches: updatedSearches,
            };
        }
        default:
            return state;
    }
}

export const setSearches = (searchClient) => {
    return {
        type: SEARCH,
        payload: searchClient,
    };
};

const searchClientsFetching = () => {
    return {
        type: FETCHING,
    };
};
const searchClientsFetched = (searchClients) => {
    return {
        type: FETCHED,
        payload: searchClients,
    };
};

const searchClientsError = (errorMessage) => {
    return {
        type: ERROR,
        payload: errorMessage,
    };
};

export const fetchSearchClients = () => {
    return (dispatch) => {
        dispatch(searchClientsFetching());
        return listSearchClients()
            .then((clients) => {
                dispatch(searchClientsFetched(clients));
            })
            .catch((error) => dispatch(searchClientsError(error)));
    };
};

import fetch from "node-fetch";
import { SERVER_URL } from "./networking/server";

//Body: { username, password: }

export const login = async (body) => {
    const call = new URL(SERVER_URL + "myhive/login");

    const response = await fetch(call, {
        method: "post",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw responseObject;
    }
};

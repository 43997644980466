import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { fetchMedia } from "../networking/Media";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { useSafeAwsUrl } from "../util";
import ExpandedImageModal from "../components/ExpandedImageModal";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        // backgroundColor: theme.palette.background.paper,
        paddingTop: "30px",
    },
    gridList: {
        // TODO - whats the plan for the width here?
        width: "100%",
        height: "100%",
    },
    image: {
        cursor: "pointer",
        height: "100%",
        objectFit: "cover",
        "&:hover": {
            "& ~ $titleBar": {
                visibility: "unset",
            },
        },
    },
    title: {
        color: "#fafafa",
    },
    titleBar: {
        "&:hover": {
            visibility: "unset",
        },
        [theme.breakpoints.down("md")]: {
            visibility: "unset",
        },
        background:
            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, "
            + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
        visibility: "hidden",
    },
    expandedImage: {
        outline: "none",
        maxHeight: "95vh",
        maxWidth: "80vw",
    },
    search: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        margin: "30px 0px",
        marginRight: "auto",
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const GalleryTab = ({ user, currentHiveId, width }) => {
    const classes = useStyles();
    const [media, setMedia] = useState();
    const [expandedImage, setExpandedImage] = useState();
    const [searchInputValue, setSearchInputValue] = useState();
    const [searchedMedia, setSearchedMedia] = useState();

    const handleCloseExpandedImage = () => {
        setExpandedImage();
    };

    const handleSearchChange = (event) => {
        setSearchInputValue(event.target.value);
    };

    const getTileCols = () => {
        if (isWidthUp("md", width)) {
            return 2;
        }

        if (isWidthUp("sm", width)) {
            return 3;
        }

        return 6;
    };

    const filterMediaBySearch = (media, searchInputValue) => {
        return media.filter((medium) => {
            return (
                medium.buzz
                && medium.buzz.text.toLowerCase().includes(searchInputValue.toLowerCase())
            );
        });
    };

    useEffect(() => {
        (async () => {
            try {
                const currentHiveMedia = await fetchMedia({
                    hives: [currentHiveId],
                    sessionToken: user.sessionToken,
                });

                setMedia(currentHiveMedia.filter((medium) => medium.mimeType.includes("image")));
            } catch (error) {
                // TODO: clear tokens and logout on session errors
            }
        })();
    }, [currentHiveId]);

    useEffect(() => {
        if (searchInputValue && searchInputValue.length && media) {
            const filtered = filterMediaBySearch(media, searchInputValue);
            setSearchedMedia(filtered);
        } else {
            setSearchedMedia(media);
        }
    }, [searchInputValue, media]);

    return (
        <div className={classes.root}>
            <Paper component="form" className={classes.search}>
                <InputBase
                    className={classes.input}
                    placeholder="Search MyHive gallery"
                    inputProps={{ "aria-label": "search myhive gallery" }}
                    onChange={(event) => handleSearchChange(event)}
                    value={searchInputValue}
                />
                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>

            <GridList cellHeight={300} className={classes.gridList} cols={6}>
                {searchedMedia
                    && searchedMedia.map((medium) => (
                        <GridListTile
                            className={classes.tile}
                            key={medium.file.url}
                            cols={getTileCols()}
                        >
                            <img
                                onClick={() => setExpandedImage(medium)}
                                src={useSafeAwsUrl(medium.file.url)}
                                alt={medium.title}
                                className={classes.image}
                            />
                            {
                                <GridListTileBar
                                    title={(medium.buzz && medium.buzz.text) || "No title"}
                                    titlePosition="top"
                                    subtitle={<span>Uploaded by: {medium.author.username}</span>}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    actionIcon={
                                        <IconButton aria-label={`star ${medium.title}`}>
                                            <a
                                                href={useSafeAwsUrl(medium.file.url)}
                                                download
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <GetAppIcon className={classes.title} />
                                            </a>
                                        </IconButton>
                                    }
                                />
                            }
                        </GridListTile>
                    ))}
            </GridList>
            <ExpandedImageModal isOpen={!!expandedImage} close={handleCloseExpandedImage}>
                <img
                    src={expandedImage && useSafeAwsUrl(expandedImage.file.url)}
                    alt={expandedImage && expandedImage.title}
                    className={classes.expandedImage}
                />
            </ExpandedImageModal>
        </div>
    );
};

GalleryTab.propTypes = {
    user: PropTypes.object,
    currentHiveId: PropTypes.string,
    width: PropTypes.string,
};

export default withWidth()(GalleryTab);

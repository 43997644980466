import React, { Component } from "react";
import PropTypes from "prop-types";

import $ from "jquery";

import "./Modal.css";

class Modal extends Component {
    render() {
        const closeButton = this.props.closable ? (
            <div className="close" onClick={this.closeClicked} />
        ) : null;
        const zIndex = this.props.zIndex || 1;
        const top = this.props.top;

        return (
            <div>
                <div
                    className="modal-bg"
                    onClick={this.backgroundClicked}
                    style={{ zIndex: zIndex }}
                />
                <div
                    className={
                        "modal"
                        + (this.props.fixed ? " fixed" : "")
                        + (this.props.margin ? " margin" : "")
                    }
                    style={{ zIndex: zIndex + 1, top: top }}
                >
                    {closeButton}
                    {this.props.content}
                </div>
            </div>
        );
    }

    componentDidMount() {
        $("html, body").animate({ scrollTop: 0 }, 500);
        if (this.props.noscroll) {
            $("body").addClass("noscroll");
        }
    }

    componentWillUnmount() {
        if (this.props.noscroll) {
            $("body").removeClass("noscroll");
        }
    }

    backgroundClicked = (e) => {
        if (e.target.className === "modal-bg" && this.props.closable) {
            this.props.onModalClosed();
        }
    };

    closeClicked = () => {
        this.props.onModalClosed();
    };
}

Modal.propTypes = {
    closable: PropTypes.bool,
    zIndex: PropTypes.number,
    top: PropTypes.number,
    fixed: PropTypes.bool,
    margin: PropTypes.number,
    content: PropTypes.object,
    noscroll: PropTypes.bool,
    onModalClosed: PropTypes.func,
};

export default Modal;

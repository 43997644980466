import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useSafeAwsUrl } from "../util";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import { CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { patchAvatar } from "../networking/ImageUpload";
import CropDialog from "./CropDialog";
import { connect } from "react-redux";
import { updateUser } from "../redux/user";

const useStyles = makeStyles((theme) => ({
    // root: {
    // }
    field: {
        marginTop: 8,
        marginBottom: 8,
    },
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        cursor: "pointer",
    },
    avatarImg: {
        width: "100%",
        height: "100%",
    },
    avatarIcon: {
        fontSize: 130,
        cursor: "pointer",
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    fileInput: {},
}));

const ProfileDialog = ({
    open = false,
    handleClose,
    currentUser,
    userBeekeeper,
    sessionToken,
    updateUser,
    userLoading,
}) => {
    const { t } = useTranslation(["profile", "common"]);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const uploadAvatarEl = useRef(null);

    const [userValues, setUserValues] = useState({
        firstName: currentUser.firstName || "",
        lastName: currentUser.lastName || "",
        tagline: currentUser.tagline || "",
    });
    const [beekeeperValues, setBeekeeperValues] = useState(
        userBeekeeper && userBeekeeper.Bio ? { Bio: userBeekeeper.Bio } : {}
    );
    const [avatarToCrop, setAvatarToCrop] = useState();
    const [newAvatar, setNewAvatar] = useState();
    const [avatarUrl, setAvatarUrl] = useState(
        currentUser && currentUser.avatar && currentUser.avatar.url
    );

    const [cropDialogOpen, setCropDialogOpen] = useState(false);

    const [changes, setChanges] = useState(false);

    const handleUserChange = (event) => {
        setChanges(true);
        const { id, value } = event.target;

        setUserValues({
            ...userValues,
            [id]: value,
        });
    };
    const handleBeekeeperChange = (event) => {
        setChanges(true);
        const { id, value } = event.target;

        setBeekeeperValues({
            ...beekeeperValues,
            [id]: value,
        });
    };

    const handleUploadAvatar = (event) => {
        const file = event.target.files[0];
        event.target.value = ""; // https://github.com/ngokevin/react-file-reader-input/issues/11

        setAvatarToCrop(file);
        setCropDialogOpen(true);
    };

    useEffect(() => {
        if (avatarToCrop) setCropDialogOpen(true);
    }, [avatarToCrop]);

    useEffect(() => {
        if (newAvatar) {
            setAvatarUrl(URL.createObjectURL(newAvatar));
            setChanges(true);

            patchAvatar({
                userId: currentUser.objectId,
                file: newAvatar,
                sessionToken,
            });
        }
    }, [newAvatar]);

    const handleSave = async () => {
        try {
            await updateUser({
                userId: currentUser.objectId,
                beekeeperId: userBeekeeper && userBeekeeper.objectId,
                userValues,
                beekeeperValues,
                sessionToken,
            });
            handleClose();
        } catch (error) {
            // an error occurred, don't close, don't do anything.
        }
    };

    return (
        <>
            <Dialog
                className={classes.root}
                fullWidth
                fullScreen={fullScreen}
                maxWidth={"sm"}
                open={open}
                aria-labelledby="form-dialog-title"
                onClose={handleClose}
            >
                <DialogTitle id="form-dialog-title">
                    {t("common:appBar.profile")}
                    <EmojiNatureIcon />
                </DialogTitle>
                <DialogContent
                // dividers
                >
                    <DialogContentText>{t("profile:intro")}</DialogContentText>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={5} md={3}>
                                    <Tooltip
                                        title="Upload a photo"
                                        arrow
                                        onClick={() => uploadAvatarEl.current.click()}
                                    >
                                        {avatarUrl ? (
                                            <Avatar aria-label="author" className={classes.avatar}>
                                                <img
                                                    alt=""
                                                    className={classes.avatarImg}
                                                    src={useSafeAwsUrl(avatarUrl)}
                                                />
                                            </Avatar>
                                        ) : (
                                            <AccountCircle className={classes.avatarIcon} />
                                        )}
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={7} md={9}>
                                    <TextField
                                        label={t("profile:name")}
                                        value={userValues.firstName}
                                        onChange={handleUserChange}
                                        id="firstName"
                                        fullWidth
                                        variant="outlined"
                                        className={classes.field}
                                    />
                                    <TextField
                                        label={t("profile:lastName")}
                                        value={userValues.lastName}
                                        onChange={handleUserChange}
                                        id="lastName"
                                        fullWidth
                                        variant="outlined"
                                        className={classes.field}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DialogContentText>{t("profile:taglineDescription")}</DialogContentText>
                            <TextField
                                label={t("profile:tagline")}
                                value={userValues.tagline}
                                onChange={handleUserChange}
                                id="tagline"
                                fullWidth
                                variant="outlined"
                                className={classes.field}
                            />
                        </Grid>
                        {userBeekeeper ? (
                            <Grid item xs={12}>
                                <DialogContentText>
                                    {`As a beekeeper, a longer bio will be presented to your clients
                                    in a "Meet Your Beekeeper" card on their Hive, along with your
                                    photo.`}
                                </DialogContentText>
                                <TextField
                                    label={t("profile:beekeeperBio")}
                                    value={beekeeperValues.Bio}
                                    onChange={handleBeekeeperChange}
                                    id="Bio"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    variant="outlined"
                                    className={classes.field}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t("cancel")}
                    </Button>
                    <div className={classes.wrapper}>
                        <Button
                            onClick={handleSave}
                            color="primary"
                            disabled={userLoading || !changes}
                        >
                            {t("save")}
                        </Button>
                        {userLoading && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                    </div>
                </DialogActions>
                <input
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleUploadAvatar}
                    type="file"
                    ref={uploadAvatarEl}
                />
            </Dialog>
            <CropDialog
                open={cropDialogOpen}
                handleClose={() => setCropDialogOpen(false)}
                handleSet={setNewAvatar}
                file={avatarToCrop}
                aspect={1}
                circularCrop={true}
            />
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { currentUser, userLoading, userErrorMessage } = user;
    const userBeekeeper = currentUser && currentUser.beekeeper;
    const sessionToken = currentUser && currentUser.sessionToken;
    return { currentUser, userBeekeeper, sessionToken, userLoading, userErrorMessage };
};

ProfileDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    currentUser: PropTypes.object,
    userBeekeeper: PropTypes.object,
    sessionToken: PropTypes.string,
    updateUser: PropTypes.func,
    userLoading: PropTypes.bool,
};

export default connect(mapStateToProps, { updateUser })(ProfileDialog);

import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const send = async ({ text, data, messageType, sessionToken }) => {
    if (process.env.NODE_ENV !== "production") return null;
    const url = new URL(`${SERVER_URL}myhive/slack/postMessage`);
    const params = { text, data, messageType, sessionToken };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "post",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

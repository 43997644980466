import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Tabs, Tab } from "@material-ui/core";
import { Box } from "@mui/material";
import { Client } from "../typings/entities";
import { DownloadCardWidget } from "@alveolemtl/alveole-components";

interface Props {
  client: Client;
}

enum KitRegions {
  Dutch = "dutch",
  German = "german",
  UK = "uk",
  CanEN = "canen",
  CanFR = "canfr",
  USA = "us",
  France = "france",
}
enum Packages {
  MTB = "mtb",
  TYI = "tyi",
  MTWB = "mtwb",
  WLP = "wlp",
  default = "def",
}

const KITS: { [key in KitRegions]: { [key in Packages]: string } } = {
  [KitRegions.Dutch]: {
    [Packages.default]: "https://drive.google.com/drive/folders/1YYm5PlDgn3r0YYmxBYRD8yhh-uAh1JE4?usp=drive_link",
    [Packages.WLP]: "https://drive.google.com/drive/folders/1SVubqvuoeLOOXtoHk0ryHea7OccgGE3E?usp=drive_link",
    [Packages.MTB]: "https://drive.google.com/drive/folders/11rVFtuk4kxYWgkXpdkZxdL_qNesVqeGp?usp=drive_link",
    [Packages.MTWB]: "https://drive.google.com/drive/folders/1SVubqvuoeLOOXtoHk0ryHea7OccgGE3E?usp=drive_link",
    [Packages.TYI]: "https://drive.google.com/drive/folders/1JrAQx6BA8MrsDQFyrcIfVP79Mce7mqKw?usp=drive_link",
  },
  [KitRegions.German]: {
    [Packages.default]: "https://drive.google.com/drive/folders/1UQ_jt4onim85i8DugMvVz763WsKUcWTH?usp=drive_link",
    [Packages.WLP]: "https://drive.google.com/drive/folders/1i1qaavBViA0_M_RKgW2J0vTWDSdr1l4W?usp=drive_link",
    [Packages.MTB]: "https://drive.google.com/drive/folders/1UQ_jt4onim85i8DugMvVz763WsKUcWTH?usp=drive_link",
    [Packages.MTWB]: "https://drive.google.com/drive/folders/1i1qaavBViA0_M_RKgW2J0vTWDSdr1l4W?usp=drive_link",
    [Packages.TYI]: "https://drive.google.com/drive/folders/1zPif8kM1FH9lQfsNB-cHsNriiY5dXDLu?usp=drive_link",
  },
  [KitRegions.UK]: {
    [Packages.default]: "https://drive.google.com/drive/folders/1omfUHN5Dz9d_WgxdwHKB_jBIcXv6R9uk?usp=drive_link",
    [Packages.WLP]: "https://drive.google.com/drive/folders/1TB3NnFuW5x9MUjxDcG106zSKv0Oq1quf?usp=drive_link",
    [Packages.MTB]: "https://drive.google.com/drive/folders/19E_uK5sGA98J7-HyWJrB__nxSDjXGCf7?usp=drive_link",
    [Packages.MTWB]: "https://drive.google.com/drive/folders/1K8LupDmbIKaUK6ezjTAcOMCTB7n57hle?usp=drive_link",
    [Packages.TYI]: "https://drive.google.com/drive/folders/1EsCFkf5fMx8LUoMGC9PWpeBbbry-fcUK?usp=drive_link",
  },
  [KitRegions.CanEN]: {
    [Packages.default]: "https://drive.google.com/drive/folders/18ZlE9XV2uL0CI16OANv-nzd7Kb314EpK?usp=drive_link",
    [Packages.WLP]: "https://drive.google.com/drive/folders/1prk9oTeg12HiNWc5jpwOHgGaU3OhChIE?usp=drive_link",
    [Packages.MTB]: "https://drive.google.com/drive/folders/1hyhTn97ywWvr52fpw5MpBVTjbdjO1hGj?usp=drive_link",
    [Packages.MTWB]: "https://drive.google.com/drive/folders/1K8LupDmbIKaUK6ezjTAcOMCTB7n57hle?usp=drive_link",
    [Packages.TYI]: "https://drive.google.com/drive/folders/1YPX-ANFcpgdSu8katZ9zk7cdd7y-3Cfz?usp=drive_link",
  },
  [KitRegions.CanFR]: {
    [Packages.default]: "https://drive.google.com/drive/folders/1Hhk0CQ_3x3sYxd0LntmtfnvT1Q_ZwOIt?usp=drive_link",
    [Packages.WLP]: "https://drive.google.com/drive/folders/1-Z0YQMNvMTYbvvaB3KZiV2lEj1Rd1RV1?usp=drive_link",
    [Packages.MTB]: "https://drive.google.com/drive/folders/1znWNtalMgMD8CPziCrXO2dABOAqih7bo?usp=drive_link",
    [Packages.MTWB]: "https://drive.google.com/drive/folders/1-Z0YQMNvMTYbvvaB3KZiV2lEj1Rd1RV1?usp=drive_link",
    [Packages.TYI]: "https://drive.google.com/drive/folders/1gCgSYtchfX9vAHng1To552Gtrasml3E9?usp=drive_link",
  },
  [KitRegions.USA]: {
    [Packages.default]: "https://drive.google.com/drive/folders/1wIsVXTdC_Jge9Ali7UR5A7OU7810UJQV?usp=drive_link",
    [Packages.WLP]: "https://drive.google.com/drive/folders/1cff4CFBOoMnzq-dSx35g2Ob_mQi-Drh0?usp=drive_link",
    [Packages.MTB]: "https://drive.google.com/drive/folders/13OE57hsIrvm4ta1VRgc-jU5o30z1JP4x?usp=drive_link",
    [Packages.MTWB]: "https://drive.google.com/drive/folders/1zq-cDe4jk1hAfbqi6nYj7j3lcD4QG92e?usp=drive_link",
    [Packages.TYI]: "https://drive.google.com/drive/folders/1BrYe6oneCF46yyvjFq4u-kPaM0368oTo?usp=drive_link",
  },
  [KitRegions.France]: {
    [Packages.default]: "https://drive.google.com/drive/folders/1TJ7UQASZug9Z5869znckd3cXTkE3--Dn?usp=drive_link",
    [Packages.WLP]: "https://drive.google.com/drive/folders/1nQGCKd8I6zeQTZQ7wj0984etG4mNG8QO?usp=drive_link",
    [Packages.MTB]: "https://drive.google.com/drive/folders/1qXOj3GVQ7Am_7A__UsKROKp23K5dM7X7?usp=drive_link",
    [Packages.MTWB]: "https://drive.google.com/drive/folders/1-Z0YQMNvMTYbvvaB3KZiV2lEj1Rd1RV1?usp=drive_link",
    [Packages.TYI]: "https://drive.google.com/drive/folders/1q8ItERHOlkop4NkMbk4DSB63D7XSXLYy?usp=drive_link",
  },
};

function getPackageShort(packageLong: string): Packages {
  switch (packageLong) {
    case "Track your Impact":
      return Packages.TYI;

    case "Meet the Bees":
      return Packages.MTB;

    case "Meet the Wild Bees":
      return Packages.MTWB;

    case "Welcome Local Pollinators":
      return Packages.WLP;

    default:
      return Packages.default;
  }
}

function getKitRegion(country: string, language: Language): KitRegions {
  country = country.toLowerCase();

  switch (country) {
    case "canada":
      return language === "en" ? KitRegions.CanEN : KitRegions.CanFR;

    case "netherlands":
    case "the netherlands":
      return KitRegions.Dutch;

    case "france":
    case "luxembourg":
    case "belgium":
    case "belgique":
      return language === "en" ? KitRegions.USA : KitRegions.France;

    case "germany":
      return KitRegions.German;

    case "uk":
    case "england":
      return language === "en" ? KitRegions.UK : KitRegions.CanFR;

    default:
      return language === "en" ? KitRegions.USA : KitRegions.CanFR;
  }
}

export const DownloadsTab: React.FC<Props> = ({ client }) => {
  const { t } = useTranslation(["common"]);
  const language: Language = window.isEnglish ? "en" : "fr";
  const packageType = getPackageShort(client.ServicePointer.package);
  const region = getKitRegion(client.ServicePointer.pays || client.Pays, language);

  const KIT_URL: string = KITS[region][packageType];

  function handleClickDownload() {
    window.open(KIT_URL, "_blank");
  }

  return (
    <Box>
      <Typography
        style={{
          fontWeight: 700,
          fontSize: 40,
          lineHeight: "120%",
          letterSpacing: "0.4px",
          paddingTop: 24,
          paddingBottom: 24,
        }}
        variant="h3"
      >
        {t("main.downloads")}
      </Typography>

      <Tabs
        style={{ paddingTop: 24 }}
        TabIndicatorProps={{
          style: {
            background: "#2B2A2B",
            textTransform: "none",
          },
        }}
        value={0}
      >
        <Tab
          style={{
            fontWeight: 700,
            fontSize: 18,
            textTransform: "none",
            textAlign: "left",
            paddingLeft: 0,
          }}
          label={t("commsKit")}
        />
      </Tabs>
      <Box marginTop={3}>
        <DownloadCardWidget
          packageName={client.ServicePointer.package}
          language={language}
          handleClickDownload={handleClickDownload}
        />
      </Box>
    </Box>
  );
};

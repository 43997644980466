import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImage } from "../util";

const CropDialog = ({
    open = true,
    file,
    handleClose,
    handleSet,
    aspect,
    circularCrop = false,
}) => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ aspect });
    const [imageNode, setImageNode] = useState();
    const [blobUrl, setBlobUrl] = useState();

    useEffect(() => {
        //setting blobUrl outside of useEffect generates new url in rerender - may error with ReactCrop
        if (file) setBlobUrl(URL.createObjectURL(file));
    }, [file]);

    const onCropChange = (crop) => {
        setCrop({
            ...crop,
            aspect,
        });
    };

    const handleUse = async () => {
        const croppedImage = await getCroppedImage(imageNode, crop, file.name);
        handleSet(croppedImage);
        handleClose();
        setCrop({ aspect });
    };

    const handleCancel = () => {
        setCrop({ aspect });
        handleClose();
    };

    return (
        <Dialog open={Boolean(open && file)} maxWidth="md">
            <DialogTitle id="form-dialog-title">{t("crop")}</DialogTitle>
            <DialogContent>
                <ReactCrop
                    src={blobUrl}
                    crop={crop}
                    // ruleOfThirds
                    circularCrop={circularCrop}
                    onImageLoaded={(image) => setImageNode(image)}
                    onChange={onCropChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    {t("cancel")}
                </Button>
                <Button disabled={crop.width === 0} onClick={handleUse} color="primary">
                    {t("save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CropDialog.propTypes = {
    open: PropTypes.bool,
    file: PropTypes.object,
    handleClose: PropTypes.func,
    handleSet: PropTypes.func,
    aspect: PropTypes.number,
    circularCrop: PropTypes.bool,
};

export default CropDialog;

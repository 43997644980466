import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import * as Seed from "../seed";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    media: {
        height: 140,
    },
    header: {
        height: 40,
        color: "#5a5a5a",
    },
    description: {
        maxWidth: 345,
        height: 180,
        display: "-webkit-box",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 9,
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "inherit",
            height: "inherit",
        },
    },
    actionButton: {
        fontSize: 12,
    },
}));

const WorkshopCard = ({ workshop, setModalOpen, handleWorkshopSelect, handleScheduleNowClick }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation(["common", "workshops"]);
    const isEnglish = i18n.language.includes("en");

    const [cardImage, setCardImage] = useState();
    useEffect(() => {
        (async () => {
            const cardImage = (await import("../img/" + workshop.visitTypeId + "1.jpg")).default;
            setCardImage(cardImage);
        })();
    }, [workshop.visitTypeId]);

    const workshopDetails = Seed.visitTypes.find((visitType) =>
        // todo - why "ate_extraction"?
        visitType.rdvVisitTypeIds.includes(workshop.visitTypeId || "ate_extraction")
    );
    const { descriptionEn, descriptionFr, titleEn, titleFr } = workshopDetails;

    const handleClick = () => {
        // 2 different workshop ids exist in seed vs. enums
        handleWorkshopSelect({ ...workshopDetails, ...workshop });
        setModalOpen(true);
    };

    return (
        <Card>
            <CardActionArea onClick={handleClick}>
                <CardMedia className={classes.media} image={cardImage} />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="h2"
                        color="textSecondary"
                        component="h2"
                        class={classes.header}
                    >
                        {isEnglish ? titleEn : titleFr}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        className={classes.description}
                    >
                        {isEnglish ? descriptionEn : descriptionFr}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button
                    color="secondary"
                    fullWidth={true}
                    className={classes.actionButton}
                    onClick={handleClick}
                >
                    {t("common:ctaBar.ctaButton")}
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    fullWidth={true}
                    className={classes.actionButton}
                    onClick={() => handleScheduleNowClick(workshop)}
                >
                    {t("workshops:schedule")}
                </Button>
            </CardActions>
        </Card>
    );
};

WorkshopCard.propTypes = {
    workshop: PropTypes.object,
    setModalOpen: PropTypes.func,
    handleWorkshopSelect: PropTypes.func,
    handleScheduleNowClick: PropTypes.func,
};

export default WorkshopCard;

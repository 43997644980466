import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const useStyles = makeStyles((theme) => ({
    root: {
        overflowX: "hidden",
        padding: 0,
        "&:first-child": {
            paddingTop: 0,
        },
    },
    content: {
        padding: "5%",
    },
    title: {
        fontWeight: "700",
    },
    carousel: {
        position: "relative",
    },
    imgContainer: {
        maxHeight: "500px",
        height: "500px",
        width: "auto",
        maxWidth: "1000px",
        marginRight: 5,
    },
    img: {
        height: "inherit",
        objectFit: "cover",
        objectPosition: "50% 50%",
    },
    invitations: {
        color: theme.palette.primary.dark,
    },
    button: {
        textTransform: "none",
        width: "100%",
        marginTop: "20px",
    },
    exit: {
        position: "absolute",
        top: 10,
        right: 20,
        color: "white",
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        },
    },
    chips: {
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
        margin: "0 5px 5px",
    },
}));

const WorkshopDialog = ({ modalOpen, setModalOpen, workshop, handleScheduleNowClick }) => {
    const { t, i18n } = useTranslation(["common", "workshops"]);
    const classes = useStyles();
    const theme = useTheme();
    const [images, setImages] = useState([]);
    const [invitations, setInvitations] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isEnglish = i18n.language.includes("en");
    const { titleEn, titleFr, descriptionEn, descriptionFr, visitTypeId, tags } = workshop;
    const [infoTable, setInfoTable] = useState({
        //   available: '',
        //   experience: '',
        //   duration: '',
        //   numParticipants: ''
    });

    const getAvailability = () => {
        if (tags.includes("ALL_YEAR")) return t("workshops:tags.ALL_YEAR");
        if (tags.includes("SEASONAL")) return t("workshops:tags.SEASONAL");
        return "";
    };

    const getExperienceLevel = () => {
        if (tags.includes("INTRODUCTORY") && tags.includes("DIG_DEEPER"))
            return t("workshops:experienceLevel.all");
        if (tags.includes("INTRODUCTORY")) return t("workshops:tags.INTRODUCTORY");
        if (tags.includes("DIG_DEEPER")) return t("workshops:tags.DIG_DEEPER");
        return "";
    };

    const getDuration = () => {
        return isEnglish ? workshop.durationEn : workshop.durationFr;
    };

    const getNumOfParticipants = () => {
        if (tags.includes("LARGE_GROUP") && tags.includes("SMALL_GROUP"))
            return t("workshops:numOfParticipants.all");
        if (tags.includes("LARGE_GROUP")) return t("workshops:tags.LARGE_GROUP");
        if (tags.includes("SMALL_GROUP")) return t("workshops:tags.SMALL_GROUP");
        return "";
    };

    const getInvitations = ({
        inPersonInvitationLinkEn,
        inPersonInvitationLinkFr,
        virtualInvitationLinkEn,
        virtualInvitationLinkFr,
    }) => {
        if (isEnglish) {
            return [inPersonInvitationLinkEn || null, virtualInvitationLinkEn || null].filter(
                (invitation) => invitation
            );
        } else {
            return [inPersonInvitationLinkFr || null, virtualInvitationLinkFr || null].filter(
                (invitation) => invitation
            );
        }
    };

    useEffect(() => {
        (async () => {
            //set images once workshop has been passed down
            if (visitTypeId) {
                const workshopImage1 = (await import("../img/" + visitTypeId + "1.jpg")).default;
                const workshopImage2 = (await import("../img/" + visitTypeId + "2.jpg")).default;
                const workshopImage3 = (await import("../img/" + visitTypeId + "3.jpg")).default;
                const images = [workshopImage1, workshopImage2, workshopImage3];
                setImages(images);
            }
            //invitation links for workshop modal
            const invitations = getInvitations(workshop);
            setInvitations(invitations);

            if (tags) {
                const info = {
                    available: getAvailability(),
                    // virtual: getVirtual(),
                    experience: getExperienceLevel(),
                    duration: getDuration(),
                    numParticipants: getNumOfParticipants(),
                };

                setInfoTable(info);
            }
        })();
    }, [workshop, isEnglish]);

    const onClose = () => setModalOpen(false);

    return (
        <div>
            <Dialog open={modalOpen} onClose={onClose} fullScreen={fullScreen} maxWidth="lg">
                <DialogContent className={classes.root}>
                    <CardMedia>
                        <Carousel
                            autoPlay={true}
                            interval={5000}
                            transitionTime={800}
                            infiniteLoop={true}
                            stopOnHover={true}
                            dymanicHeight={true}
                            autofocus={true}
                            centerMode
                            className={classes.carousel}
                            showThumbs={false}
                            showStatus={false}
                        >
                            {!!images.length
                                && images.map((image, key) => (
                                    <div key={key} className={classes.imgContainer}>
                                        <img alt="" className={classes.img} src={image} />
                                    </div>
                                ))}
                        </Carousel>
                        <IconButton className={classes.exit} onClick={onClose}>
                            <CloseRoundedIcon fontSize="large" />
                        </IconButton>
                    </CardMedia>
                    <div className={classes.content}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <DialogContentText variant="h4">
                                    {isEnglish ? titleEn : titleFr}
                                </DialogContentText>
                                <DialogContentText>
                                    {isEnglish ? descriptionEn : descriptionFr}
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item xs={12} md={4}>
                                {invitations.length > 0 ? (
                                    <>
                                        <DialogContentText className={classes.title}>
                                            {t("workshops:invitations")}
                                        </DialogContentText>
                                        {invitations.map((invitation, key) => (
                                            <DialogContentText key={key}>
                                                <Link
                                                    href={invitation.url}
                                                    target="_blank"
                                                    className={classes.invitations}
                                                >
                                                    &#8226; {invitation.linkText}
                                                </Link>
                                            </DialogContentText>
                                        ))}
                                    </>
                                ) : null}
                                {/* <DialogContentText id="workshop-links-media-kit">
                    <Link href={"#"}>
                    &#8226; {"Alvéole Mini Media Kit"}
                    </Link>
                </DialogContentText> */}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} xs={12} md={7}>
                            {Object.entries(infoTable).map(([title, value], key) => (
                                <Grid key={key} item md={4}>
                                    <DialogContentText className={classes.title}>
                                        {t(`workshops:${title}`)}
                                    </DialogContentText>
                                    <DialogContentText>{value}</DialogContentText>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Button
                                    className={classes.button}
                                    onClick={() => handleScheduleNowClick(workshop)}
                                    variant="contained"
                                    color="secondary"
                                >
                                    {t("workshops:schedule").toLowerCase()}
                                </Button>
                            </Grid>
                            <Grid item md={1}></Grid>
                            <Grid item md={4}>
                                {tags
                                    && tags.map((tag, key) => (
                                        <Chip
                                            key={key}
                                            label={t(`workshops:tags.${tag}`).toLowerCase()}
                                            className={classes.chips}
                                            variant="outlined"
                                        />
                                    ))}
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

WorkshopDialog.propTypes = {
    modalOpen: PropTypes.bool,
    setModalOpen: PropTypes.func,
    workshop: PropTypes.object,
    handleScheduleNowClick: PropTypes.func,
};

export default WorkshopDialog;

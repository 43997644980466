import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const get = async () => {
    const url = new URL(`${SERVER_URL}myhive/analytics`);

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();
    if (responseObject.status !== "OK") throw new Error("uhoh");

    return responseObject.data;
};

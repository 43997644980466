import React, { Component } from "react";

import Backdrop from "@material-ui/core/Backdrop";

import "./Spinner.css";

class Spinner extends Component {
    render() {
        return (
            <Backdrop
                open={true}
                invisible={true}
                style={{ zIndex: 999, height: "100vh", position: "static" }}
            >
                <section className="container">
                    <div>
                        <div>
                            <span className="one h6"></span>
                            <span className="two h3"></span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <span className="one h1"></span>
                            <span className="two h4"></span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <span className="one h5"></span>
                            <span className="two h2"></span>
                        </div>
                    </div>
                </section>
            </Backdrop>
        );
    }
}
//<CircularProgress color="primary" />

// {/* return (<div classNameName="loader">Loading...</div>) */}

export default Spinner;

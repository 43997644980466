import { get } from "../networking/analytics";

const FETCHING = "alveole-maruche/analytics/FETCHING";
const FETCHED = "alveole-maruche/analytics/FETCHED";
const ERROR = "alveole-maruche/analytics/ERROR";

export default function analyticsReducer(
    state = {
        bees: 0,
        cities: 0,
        hives: 0,
        people: 0,
        whereWeBuzz: {},
    },
    action = {}
) {
    switch (action.type) {
        case FETCHING: {
            return {
                ...state,
                analyticsFetching: true,
            };
        }
        case FETCHED: {
            return {
                ...state,
                analyticsFetching: false,
                ...action.payload,
            };
        }
        case ERROR: {
            return {
                ...state,
                analyticsFetching: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export const analyticsFetching = () => {
    return {
        type: FETCHING,
    };
};
export const analyticsFetched = (analytics) => {
    return {
        type: FETCHED,
        payload: analytics,
    };
};

export const analyticsError = (errorMessage) => {
    return {
        type: ERROR,
        payload: errorMessage,
    };
};

export const fetchAnalytics = () => {
    return (dispatch) => {
        dispatch(analyticsFetching());
        return get()
            .then((analytics) => {
                dispatch(analyticsFetched(analytics));
            })
            .catch((error) => dispatch(analyticsError(error)));
    };
};

/* eslint-disable react/no-string-refs */
import React from "react";
import "./InspectionSheetCard.css";
import PropTypes from "prop-types";

import broodFrameImage from "../../img/frame_brood.png";
import honeyFrameImage from "../../img/frame_honey.png";
import emptyFrameImage from "../../img/frame_empty.png";
import legendEnImage from "../../img/legende_en.png";
import legendFrImage from "../../img/legende_fr.png";
import hive1boxImage from "../../img/ruche1boite.png";
import hive2boxesImage from "../../img/ruche2boites.png";
import hive3boxesImage from "../../img/ruche3boites.png";
import hive4boxesImage from "../../img/ruche4boites.png";
import hive5boxesImage from "../../img/ruche5boites.png";

class DynamicHive extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.CANVAS_WIDTH = 500;
        this.CANVAS_HEIGHT = 400;
        this.state = {
            //brood frames are placed in this order, [[a,b],] a = box index, b = frame index
            broodSequence: [
                [0, 4],
                [0, 5],
                [0, 3],
                [0, 6],
                [0, 2],
                [0, 7],
                [0, 1],
                [0, 8],
                [1, 5],
                [1, 4],
                [1, 6],
                [1, 3],
                [1, 7],
                [1, 2],
                [1, 8],
                [1, 1],
                [2, 5],
                [2, 4],
                [2, 6],
                [2, 3],
                [2, 7],
                [2, 2],
                [2, 8],
                [2, 1],
                [3, 5],
                [3, 4],
                [3, 6],
                [3, 3],
                [3, 7],
                [3, 2],
                [3, 8],
                [3, 1],
                [4, 5],
                [4, 4],
                [4, 6],
                [4, 3],
                [4, 7],
                [4, 2],
                [4, 8],
                [4, 1],
            ],
            //honey frames are placed in this order, [[a,b],] a = box index, b = frame index
            honeySequence: [
                [0, 0],
                [0, 9],
                [1, 0],
                [1, 9],
                [1, 2],
                [1, 7],
                [1, 4],
                [1, 5],
                [2, 8],
                [2, 1],
                [2, 6],
                [2, 3],
                [3, 0],
                [3, 9],
                [3, 2],
                [3, 7],
                [3, 4],
                [3, 5],
                [4, 8],
                [4, 1],
                [4, 6],
                [4, 3],
                [1, 1],
                [1, 8],
                [1, 3],
                [1, 6],
                [2, 9],
                [2, 0],
                [2, 7],
                [2, 2],
                [2, 5],
                [2, 4],
                [3, 1],
                [3, 8],
                [3, 3],
                [3, 6],
                [4, 9],
                [4, 0],
                [4, 7],
                [4, 2],
                [4, 5],
                [4, 4],
            ],
            frames2DArray: [],
            emptyFrames2DArray: [
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            ],
            FrameType: Object.freeze({
                None: 0,
                Empty: 1,
                Brood: 2,
                Honey: 3,
            }),
        };
    }

    drawFrame = (
        frameType,
        boiteIndex,
        frameIndex,
        numBoxes,
        context,
        frameBroodImage,
        frameHoneyImage,
        frameEmptyImage
    ) => {
        let { FrameType } = this.state;
        if (frameType === FrameType.None || boiteIndex >= numBoxes || typeof numBoxes !== "number")
            return;
        var framePositions = this.getFramePositions(numBoxes, boiteIndex);
        var frameOffsetX = framePositions[0];
        var frameOffsetY = framePositions[1];
        var frameSpacing = framePositions[2];
        var frameScalingFactor = framePositions[3];

        var frameImage;
        if (frameType === FrameType.Brood) {
            frameImage = frameBroodImage;
        } else if (frameType === FrameType.Honey) {
            frameImage = frameHoneyImage;
        } else if (frameType === FrameType.Empty) {
            frameImage = frameEmptyImage;
        }

        var imageX = frameOffsetX + frameIndex * frameSpacing;
        var imageY = frameOffsetY;
        var imageWidth = frameImage.width * frameScalingFactor;
        var imageHeight = frameImage.height * frameScalingFactor;
        context.imageSmoothingQuality = "high";
        context.drawImage(
            frameImage,
            imageX,
            imageY,
            Math.floor(imageWidth),
            Math.floor(imageHeight)
        );
    };

    mapBroodSequenceToFramesState = () => {
        return new Promise((res) => {
            // copy the 2d array...
            let copyOfFrames2DArray = this.state.emptyFrames2DArray.map((arr) => arr.slice());
            //put brood frames in the copy
            for (let i = 0; i < this.props.eggFramesCount; i++) {
                const [box, frame] = this.state.broodSequence[i];
                copyOfFrames2DArray[box][frame] = this.state.FrameType.Brood;
            }
            this.setState(
                {
                    frames2DArray: copyOfFrames2DArray,
                },
                res()
            );
        });
    };

    mapHoneySequenceToFramesState = () => {
        let { frames2DArray } = this.state;
        const { honeySequence, FrameType } = this.state;
        const { honeyFramesCount } = this.props;
        const maxBoxIndex = this.props.numBoxes - 1;

        return new Promise((res) => {
            let copyOfFrames2DArray = frames2DArray.map((arr) => arr.slice());
            let placedFramesCount = 0;
            let currentPosition = 0;

            while (placedFramesCount < honeyFramesCount) {
                let [box, frame] = honeySequence[currentPosition].slice();
                //if we reach the box max and all the honey frames haven't been placed,
                //loop over copyOfFrames2DArray and put honey anywhere that isn't brood frame
                if (box > maxBoxIndex) {
                    for (let boxIndex = 0; boxIndex <= maxBoxIndex; boxIndex++) {
                        // eslint-disable-next-line
                        copyOfFrames2DArray[boxIndex].forEach((frame, frameIndex) => {
                            if (frame === FrameType.None && placedFramesCount < honeyFramesCount) {
                                copyOfFrames2DArray[boxIndex][frameIndex] = FrameType.Honey;
                                placedFramesCount++;
                            }
                        });
                    }
                    break;
                }

                if (copyOfFrames2DArray[box][frame] !== FrameType.Brood) {
                    copyOfFrames2DArray[box][frame] = FrameType.Honey;
                    placedFramesCount++;
                    currentPosition++;
                } else {
                    currentPosition++;
                }
            }
            this.setState(
                {
                    frames2DArray: copyOfFrames2DArray,
                },
                res()
            );
        });
    };
    // [left, top, spacing, scaling]
    getFramePositions = (numberOfBoxes, boxIndex) => {
        if (this.props.numBoxes === 1) {
            if (boxIndex === 0) {
                return [87, 88, 24, 0.42];
            }
        } else if (this.props.numBoxes === 2) {
            if (boxIndex === 1) {
                return [114, 65, 19, 0.31];
            } else if (boxIndex === 0) {
                return [115, 176, 19, 0.33];
            }
        } else if (this.props.numBoxes === 3) {
            if (boxIndex === 2) {
                return [149, 55, 15, 0.23];
            } else if (boxIndex === 1) {
                return [147, 145, 15, 0.23];
            } else if (boxIndex === 0) {
                return [145, 229, 15, 0.25];
            }
        } else if (this.props.numBoxes === 4) {
            if (boxIndex === 3) {
                return [159, 60, 11.8, 0.17];
            } else if (boxIndex === 2) {
                return [161, 124, 11.8, 0.17];
            } else if (boxIndex === 1) {
                return [162, 184, 11.5, 0.19];
            } else if (boxIndex === 0) {
                return [162, 256, 10.6, 0.2];
            }
        } else if (this.props.numBoxes === 5) {
            if (boxIndex === 4) {
                return [185, 56, 8.7, 0.14];
            } else if (boxIndex === 3) {
                return [185, 111, 9, 0.14];
            } else if (boxIndex === 2) {
                return [186, 160, 9, 0.16];
            } else if (boxIndex === 1) {
                return [185, 218, 9, 0.16];
            } else if (boxIndex === 0) {
                return [185, 274, 8, 0.17];
            }
        }
    };

    drawHive = () => {
        const { FrameType } = this.state;
        var canvas = this.refs.canvas;
        var context = canvas.getContext("2d");
        // if () { // we're not using this right now, but i think we will eventually.
        //     context.imageSmoothingQuality = "high";
        //     return this.loadImage(require("../../img/2-boxes.jpg")).then((image) => {
        //         canvas.width = image.width;
        //         canvas.height = image.height;
        //         context.drawImage(image, 0, 0);
        //     });
        // };
        const hiveForegroundImagePromise = this.loadImage(this.selectHiveForegroundImage());
        const broodFrameImagePromise = this.loadImage(broodFrameImage);
        const honeyFrameImagePromise = this.loadImage(honeyFrameImage);
        const emptyFrameImagePromise = this.loadImage(emptyFrameImage);

        const legendImagePromise = window.isEnglish
            ? this.loadImage(legendEnImage)
            : this.loadImage(legendFrImage);

        this.mapBroodSequenceToFramesState()
            .then(() => {
                return this.mapHoneySequenceToFramesState();
            })
            .then(() => {
                // load all the necessary images
                Promise.all([
                    hiveForegroundImagePromise,
                    broodFrameImagePromise,
                    honeyFrameImagePromise,
                    emptyFrameImagePromise,
                    legendImagePromise,
                ]).then(
                    ([
                        hiveForegroundImage,
                        broodFrameImage,
                        honeyFrameImage,
                        emptyFrameImage,
                        legendImage,
                    ]) => {
                        //clear the canvas
                        context.clearRect(0, 0, canvas.width, canvas.height);
                        canvas.width = this.CANVAS_WIDTH; //hiveForegroundImage.width;
                        canvas.height = this.CANVAS_HEIGHT; //hiveForegroundImage.height;
                        for (var i = 0; i < 5; i++) {
                            // boxes
                            for (var j = 0; j < 10; j++) {
                                // frames
                                var frameType = this.state.frames2DArray[i][j];
                                if (frameType === FrameType.None) {
                                    frameType = FrameType.Empty;
                                }
                                this.drawFrame(
                                    frameType,
                                    i,
                                    j,
                                    this.props.numBoxes,
                                    context,
                                    broodFrameImage,
                                    honeyFrameImage,
                                    emptyFrameImage
                                );
                            }
                        }
                        // Draw the box
                        context.drawImage(hiveForegroundImage, 0, 0);
                        // Draw legend
                        context.drawImage(
                            legendImage,
                            410,
                            320,
                            legendImage.width / 2,
                            legendImage.height / 2
                        );
                    }
                );
            });
    };
    /*
    // TODO single image to the canvas ** extract this ** and think about image handling
    imageFor = (inspectionType) => {
        if (!inspectionType) return require("../../img/ruche_nourrissage.png");
        if (inspectionType.includes("reg_nourissage"))
            return require("../../img/ruche_nourrissage.png");
        if (inspectionType === "reg_hivernage") return require("../../img/ruche_hivernage.jpg");
        return require("../../img/2-boxes.jpg");
    };
    */
    // TODO draw a single image to the canvas ** extract this **
    loadImage = (imageSrc) => {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.src = imageSrc;
            image.onload = () => {
                resolve(image);
            };
            image.onerror = () => {
                reject("error loading image");
            };
        });
    };
    selectHiveForegroundImage = () => {
        switch (this.props.numBoxes) {
            case 0:
                return hive1boxImage;
            case 1:
                return hive1boxImage;
            case 2:
                return hive2boxesImage;
            case 3:
                return hive3boxesImage;
            case 4:
                return hive4boxesImage;
            case 5:
                return hive5boxesImage;
            default:
                return hive1boxImage;
        }
    };

    componentDidMount() {
        this.drawHive();
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.drawHive();
        }
    }

    render() {
        return (
            <canvas
                width={this.CANVAS_WIDTH}
                height={this.CANVAS_HEIGHT}
                className="square-hive-drawing"
                ref="canvas"
            />
        );
    }
}

DynamicHive.propTypes = {
    inspectionType: PropTypes.string,
    eggFramesCount: PropTypes.number,
    numBoxes: PropTypes.number,
    honeyFramesCount: PropTypes.number,
};

export default DynamicHive;

import { Fragment } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { pdfjs } from "react-pdf";
import "moment/locale/fr";
import "@alveolemtl/alveole-components/dist/style.css";

import App from "./App";
import Logout from "./Logout";
import Unsubscribe from "./components/Unsubscribe";
import Admin from "./components/Admin";
import Head from "./components/Head";
import store from "./redux/store";
import { initSentry } from "./networking/errorReporting";
import "./template.css";
import "./index.css";
import "./fonts/apercu.css";
import "./i18n";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

initSentry();
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const root = createRoot(document.getElementById("root")!);

root.render(
  <Fragment>
    <ReduxProvider store={store}>
      <Head />
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/linkedin" component={LinkedInCallback} />
            <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
            <Route path="/admin" component={Admin} />
            <Route path="/unsubscribe/:hiveId/:email" component={Unsubscribe} />
            <Route path="/deconnexion" exact component={Logout} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/" exact component={withRouter(App)} />
            <Route path="/:clientUrl" component={withRouter(App)} />
            <Route path="/:clientUrl/:buzzId" component={withRouter(App)} />
            <Route component={withRouter(App)} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ReduxProvider>
  </Fragment>
);

import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TimelineMedia from "./TimelineMedia";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import FavoriteIcon from "@material-ui/icons/Favorite";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@material-ui/icons/Facebook";
import ShareIcon from "@material-ui/icons/Share";
import Badge from "@material-ui/core/Badge";
import TextField from "@material-ui/core/TextField";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloudQueueIcon from "@material-ui/icons/CloudQueue"; //cloudy
import WbSunnyIcon from "@material-ui/icons/WbSunny"; //sunny + hot
import BeachAccessIcon from "@material-ui/icons/BeachAccess"; //rainy
import AcUnitIcon from "@material-ui/icons/AcUnit"; //cold
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import { green } from "@material-ui/core/colors";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { Link, CircularProgress } from "@material-ui/core";
import { removeBuzz, buzzPatch, toggleFavouriteBuzz } from "../networking/Buzz";
import { twitter, facebook } from "../networking/social";
import { useSafeAwsUrl, makeId } from "../util";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog";
import { useSnackbar } from "notistack";
import Linkify from "linkify-react";
import { formatCelsiusTemperature, TemperatureUnit } from "../formatting/temperature";

const weatherIcons = {
    hot: <WbSunnyIcon />,
    cold: <AcUnitIcon />,
    rainy: <BeachAccessIcon />,
    sunny: <WbSunnyIcon />,
    cloudy: <CloudQueueIcon />,
};

const useStyles = makeStyles((theme) => ({
    media: {},
    header: {
        padding: theme.spacing(0, 0, 2, 0),
    },
    avatar: {
        backgroundColor: "rgb(41 129 186)",
    },
    avatarImg: {
        width: "100%",
        height: "100%",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    card: {
        display: "block",
        borderRadius: theme.spacing(2),
        boxShadow: "none",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            minWidth: "100%",
            marginLeft: 0,
            marginRight: 0,
            borderRadius: "0px",
        },
    },
    cardContent: {
        padding: theme.spacing(2, 0),
    },
    cardActions: {
        padding: 0,
        margin: "-8px",
    },
    text: {
        textAlign: "left",
        fontSize: "18px",
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
    },
    datestamp: {
        textAlign: "left",
    },
    datestampLink: {
        cursor: "pointer",
    },
    favourite: {
        transitionProperty: "color",
        transitionDuration: "0.2s",
        "&:hover": {
            color: "#cc0000",
        },
    },
    favourited: {
        color: "#cc0000",
    },
    share: {
        transitionProperty: "color",
        transitionDuration: "0.2s",
        "&:hover": {
            color: "#3388ff",
        },
    },
    editInput: {
        "& .MuiFilledInput-root": {
            background: "rgba(235, 207, 176, 0.3)",
        },
    },
    editButton: {
        margin: 5,
    },
    editActionButtons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    weather: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(0.5),
    },
    weatherIcon: {
        marginLeft: "5px",
    },
}));

const TimelineCard = ({
    buzz,
    userId,
    isStaff,
    sessionToken,
    reloadTimeline,
    match,
    currentUser,
    setOpenLinkedin,
    setCurrentShareBuzz,
}) => {
    const {
        text,
        permaId,
        author = { username: "" },
        media,
        createdAt,
        favourites = [],
        metadata,
    } = buzz;

    const { t, i18n } = useTranslation(["common", "buzz", "hiveTab"]);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const [shareAnchorEl, setShareAnchorEl] = useState(null);
    const loggedIn = !!userId;
    const favourited = favourites.some((fav) =>
        loggedIn ? fav.objectId === userId : fav.id === localStorage.getItem("buzzFavId")
    );
    const [favourite, setFavourite] = useState(favourited);
    const [favouriteSetting, setFavouriteSetting] = useState(false);
    const [numOfFavs, setNumOfFavs] = useState(favourites.length);
    const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);
    const [buzzEditMode, setBuzzEditMode] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [buzzContent, setBuzzContent] = useState({
        text,
    });

    const settingsButtonRef = useRef();
    const shareButtonRef = useRef();
    const inputWrapperRef = useRef(null);

    const isAuthor = currentUser && author.objectId === currentUser.objectId;
    const authorizedToEdit = currentUser && (isAuthor || isStaff);
    const { clientUrl } = match.params;

    const permaLink = `/${clientUrl}/buzz/${permaId}`;
    const authorString = author?.firstName
        ? author.firstName + " " + author.lastName
        : author.username;
    const weatherKeyWord = metadata?.weather?.description;

    useEffect(() => {
        //cancel buzz edit on click outside of input wrapper component
        function handleClickOutside(event) {
            if (inputWrapperRef.current && !inputWrapperRef.current.contains(event.target)) {
                handleEditCancel();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputWrapperRef]);

    //set settings menu open/close
    const settingsOpen = Boolean(settingsAnchorEl);
    const settingsId = settingsOpen ? "settings-popover" : undefined;

    //set share menu open/close
    const shareMenuOpen = Boolean(shareAnchorEl);
    const shareId = shareMenuOpen ? "share-popover" : undefined;

    //when share icon is clicked, open share menu & handle GA
    const handleShareClick = () => {
        if (!shareMenuOpen)
            ReactGA.event({
                category: "buzz",
                action: "share",
                label: "opensharemenu",
            });

        setShareAnchorEl(shareButtonRef.current);
        setCurrentShareBuzz({
            text,
            mediaUrl: useSafeAwsUrl(media?.[0]?.file.url) || null,
            mediaType: media?.[0]?.mimeType || null,
        });
    };

    //when settings icon is clicked, open settings menu & handle GA
    const handleSettingsClick = () => {
        if (!settingsOpen)
            ReactGA.event({
                category: "buzz",
                action: "share",
                label: "opensettings",
            });

        setSettingsAnchorEl(settingsButtonRef.current);
    };

    const handleLinkifyClick = (event) => {
        ReactGA.event({
            category: "buzz-hyperlink",
            action: "click",
            label: event.target.href || "",
        });
    };

    //
    const handlePermaLinkClick = () =>
        ReactGA.event({
            category: "buzz",
            action: "share",
            label: "permalink",
        });

    //on menu close, reset element's anchor in state to null
    const handleMenuClose = (setAnchorElCallback) => {
        setAnchorElCallback(null);
    };

    //social media share handlers:
    const handleShareTwitter = (event) => {
        event.preventDefault();
        ReactGA.event({
            category: "buzz",
            action: "share",
            label: "twitter",
        });

        const isFrench = i18n.language.includes("fr");

        const myHiveUrl = isFrench ? "https://maruche.alveole.buzz" : "https://myhive.alveole.buzz";

        twitter({
            text,
            url: myHiveUrl + permaLink,
            via: "alveolebuzz",
        });

        handleMenuClose(setShareAnchorEl);
    };

    const handleShareFacebook = (event) => {
        event.preventDefault();
        ReactGA.event({
            category: "buzz",
            action: "share",
            label: "facebook",
        });

        const isFrench = i18n.language.includes("fr");

        const myHiveUrl = isFrench ? "https://maruche.alveole.buzz" : "https://myhive.alveole.buzz";

        facebook({
            u: myHiveUrl + permaLink,
        });

        handleMenuClose(setShareAnchorEl);
    };

    //favourite/unfavourite
    const handleFavourite = async () => {
        setFavouriteSetting(true);

        try {
            //if no logged-in user and no buzzFavId in local storage, set id
            if (!userId && !localStorage.getItem("buzzFavId")) {
                localStorage.setItem("buzzFavId", makeId(10));
            }

            await toggleFavouriteBuzz({
                buzzId: permaId,
                sessionToken,
                buzzFavId: localStorage.getItem("buzzFavId"),
                favourite,
            });

            const newDisplayNum = numOfFavs + (favourite ? -1 : 1);
            setNumOfFavs(newDisplayNum);
            setFavourite(!favourite);
            setFavouriteSetting(false);
        } catch (err) {
            setFavouriteSetting(false);
        }
    };

    useEffect(() => {
        if (favourite)
            ReactGA.event({
                category: "buzz",
                action: "react",
                label: "heart",
            });
    }, [favourite]);

    //remove buzz click opens confirmation dialog
    const handleRemoveClick = () => {
        handleMenuClose(setSettingsAnchorEl);
        setConfirmRemoveDialogOpen(true);
    };
    //on confirm, destroy buzz in db
    const handleConfirmRemoveBuzz = async () => {
        await removeBuzz({
            buzzId: permaId,
            sessionToken,
        });
        reloadTimeline();
        setConfirmRemoveDialogOpen(false);
    };
    //on cancel, close popup
    const handleCancelRemoveBuzz = () => {
        setConfirmRemoveDialogOpen(false);
    };

    const handleEditClick = () => {
        setBuzzEditMode(true);
        handleMenuClose(setSettingsAnchorEl);
    };

    const closeEditMode = () => {
        setBuzzEditMode(false);
        setEditLoading(false);
    };

    const handleEditSave = async () => {
        setEditLoading(true);

        await buzzPatch({
            properties: buzzContent,
            permaId,
            sessionToken,
        });

        closeEditMode();
        reloadTimeline();

        enqueueSnackbar(t("buzz:editBuzz.saved-buzz-edit-notification"), {
            variant: "success",
        });
    };

    const handleEditCancel = () => {
        setBuzzContent({ text });
        closeEditMode();

        enqueueSnackbar(t("buzz:editBuzz.discarded-buzz-edit-notification"), {
            variant: "default",
        });
    };

    const onTextChange = (e) => {
        setBuzzContent({ ...buzzContent, text: e.target.value });
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                avatar={
                    <Avatar aria-label="author" className={classes.avatar}>
                        {author && author.avatar ? (
                            <img
                                alt=""
                                className={classes.avatarImg}
                                src={useSafeAwsUrl(author.avatar.url)}
                            />
                        ) : (
                            <EmojiNatureIcon />
                        )}
                    </Avatar>
                }
                action={
                    authorizedToEdit && (
                        <IconButton
                            ref={settingsButtonRef}
                            aria-describedby={settingsId}
                            aria-label="settings"
                            onClick={handleSettingsClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )
                }
                title={authorString}
                subheader={author.tagline || "MyHive user"}
            />
            {confirmRemoveDialogOpen && (
                <ConfirmRemoveDialog
                    open={confirmRemoveDialogOpen}
                    handleConfirmRemoveBuzz={handleConfirmRemoveBuzz}
                    handleCancelRemoveBuzz={handleCancelRemoveBuzz}
                />
            )}
            <Menu
                id="long-menu"
                anchorEl={settingsAnchorEl}
                keepMounted
                open={settingsOpen}
                onClose={() => handleMenuClose(setSettingsAnchorEl)}
                PaperProps={{
                    style: {
                        maxHeight: 200,
                        // width: '20ch',
                    },
                }}
            >
                <MenuItem key="edit" onClick={handleEditClick}>
                    {t("common:edit")}
                </MenuItem>
                <MenuItem key="remove" onClick={handleRemoveClick}>
                    {t("common:remove")}
                </MenuItem>
            </Menu>

            <Menu
                id="share-menu"
                anchorEl={shareAnchorEl}
                keepMounted
                open={shareMenuOpen}
                onClose={() => handleMenuClose(setShareAnchorEl)}
                PaperProps={{
                    style: {
                        maxHeight: 200,
                        // width: '20ch',
                    },
                }}
            >
                <MenuItem key="linkedin" onClick={() => setOpenLinkedin(true)}>
                    <LinkedInIcon />
                    &nbsp;{t("share.linkedIn")}
                </MenuItem>
                <MenuItem key="x" onClick={handleShareTwitter}>
                    <XIcon />
                    &nbsp;{t("share.x")}
                </MenuItem>
                <MenuItem key="facebook" onClick={handleShareFacebook}>
                    <FacebookIcon />
                    &nbsp;{t("share.facebook")}
                </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent}>
                <Linkify
                    options={{
                        target: "_blank",
                        attributes: { onClick: handleLinkifyClick },
                    }}
                >
                    {buzzEditMode ? (
                        <div ref={inputWrapperRef} tabIndex="1">
                            <TextField
                                id="edit-buzz"
                                multiline
                                fullWidth
                                defaultValue={text || ""}
                                onChange={onTextChange}
                                variant="filled"
                                autoFocus
                                className={classes.editInput}
                            />
                            <div className={classes.editActionButtons}>
                                <Button
                                    type="cancel"
                                    color="primary"
                                    onClick={handleEditCancel}
                                    disabled={editLoading}
                                    className={classes.editButton}
                                >
                                    {t("cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    onClick={handleEditSave}
                                    disabled={text === buzzContent.text || editLoading}
                                    className={classes.editButton}
                                >
                                    {t("save")}
                                    {editLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Typography
                            variant="body2"
                            component="p"
                            className={classes.text}
                            key={`${text}${Math.random()}`}
                        >
                            {text || <br />}
                        </Typography>
                    )}
                </Linkify>
            </CardContent>
            {media && media.length ? <TimelineMedia images={media} /> : null}
            <CardContent className={classes.cardContent}>
                {weatherKeyWord && (
                    <Typography variant="body2" className={classes.weather} color="textSecondary">
                        {`${metadata.weather.city}, ${formatCelsiusTemperature(
                            // `temperature` got renamed to `temperatureCelsius`. We need to support both for backwards compatibility.
                            metadata.weather.temperatureCelsius ?? metadata.weather.temperature,
                            TemperatureUnit.Celsius
                        )}  `}
                        <Tooltip
                            title={t(`hiveTab:${weatherKeyWord}`)}
                            className={classes.weatherIcon}
                        >
                            {weatherIcons[weatherKeyWord]}
                        </Tooltip>
                    </Typography>
                )}
                <Typography variant="body2" className={classes.datestamp} color="textSecondary">
                    <Link
                        href={permaLink}
                        onClick={handlePermaLinkClick}
                        target="_blank"
                        rel="noopener"
                        className={classes.datestampLink}
                        color="textSecondary"
                    >
                        {moment(createdAt).format("h:mm A, MMMM Do, Y")}
                    </Link>
                </Typography>
            </CardContent>
            <CardActions className={classes.cardActions} disableSpacing>
                <IconButton
                    className={`${classes.favourite} ${favourite ? classes.favourited : null}`}
                    aria-label="add to favourites"
                    onClick={handleFavourite}
                    disabled={favouriteSetting}
                >
                    <Badge
                        badgeContent={numOfFavs}
                        overlap="rectangular"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <FavoriteIcon />
                    </Badge>
                    {favouriteSetting && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                </IconButton>
                <IconButton
                    className={classes.share}
                    aria-label="share"
                    ref={shareButtonRef}
                    aria-describedby={shareId}
                    onClick={handleShareClick}
                >
                    <ShareIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;

    const sessionToken = currentUser && currentUser.sessionToken;
    const userId = currentUser && currentUser.objectId;
    const isStaff = (currentUser && currentUser.isStaff) || false;

    return { currentUser, userId, isStaff, sessionToken };
};

TimelineCard.propTypes = {
    buzz: PropTypes.shape({
        author: PropTypes.shape({
            username: PropTypes.string,
        }),
        createdAt: PropTypes.string,
        favourites: PropTypes.array,
        media: PropTypes.array,
        objectId: PropTypes.string,
        permaId: PropTypes.string,
        status: PropTypes.string,
        text: PropTypes.string,
        updatesAt: PropTypes.string,
        metadata: PropTypes.shape({
            weather: PropTypes.object,
        }),
    }),
    reloadTimeline: PropTypes.func,

    // mapStateToProps
    currentUser: PropTypes.object,
    userId: PropTypes.string,
    isStaff: PropTypes.bool,
    sessionToken: PropTypes.string,
    setOpenLinkedin: PropTypes.function,
    setCurrentShareBuzz: PropTypes.function,
    // HOC - withRouter
    match: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(withRouter(TimelineCard));

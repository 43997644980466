import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PreferencesPrefs from "./PreferencesPrefs";
import PreferencesManagement from "./PreferencesManagement";
import PreferencesLabels from "./PreferencesLabels";
import PreferencesPremium from "./PreferencesPremium";
import PreferencesBilling from "./PreferencesBilling";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";

import SwipeableViews from "react-swipeable-views";

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        textTransform: "uppercase",
    },
    field: {
        marginBottom: 12,
    },
    colleague: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(2),
        fontSize: 18,
    },
    tabIndicator: {
        background: theme.palette.primary.dark,
    },
}));

const PreferencesTab = ({ hive, currentUser }) => {
    const classes = useStyles();
    const { t } = useTranslation(["preferences", "labels", "common"]);

    // locks the Billing tab for users who aren't "Main Contacts" on the deal, or who aren't staff.
    const isHiveAdmin = hive.Email1 === currentUser.email || currentUser.isStaff;

    const lineItems = (hive.ServicePointer.contractLineItems || []).filter(
        (lineItem) => lineItem.product
    );
    const isHivePremium = lineItems.some(
        (lineItem) =>
            lineItem.product.quickbooksSku === "S400"
            || lineItem.product.quickbooksSku === "S400-FR"
    );

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (_, newValue) => {
        setTabValue(newValue);
    };
    const handleTabIndexChange = (index) => {
        setTabValue(index);
    };

    return (
        <div className={classes.root}>
            <div className={classes.tabs}>
                <Tabs
                    TabIndicatorProps={{
                        className: classes.tabIndicator,
                    }}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={t("preferences:preferences")} />
                    <Tab label={t("preferences:management.title")} />
                    {/* <Tab
                        style={{ pointerEvents: "auto" }}
                        disabled={!isHiveAdmin}
                        label={
                            <Tooltip
                                title={
                                    !isHiveAdmin
                                        ? `${t("preferences:billing.mainContactOnly")} (${
                                              hive.Email1
                                          })`
                                        : ""
                                }
                            >
                                <span>
                                    {t("preferences:billing.title")}
                                    {!isHiveAdmin ? " 🔒" : ""}
                                </span>
                            </Tooltip>
                        }
                    /> */}
                    <Tab label={t("labels:title")} />
                    {isHivePremium && <Tab label={t("labels:premium.title")} />}
                </Tabs>
            </div>

            <SwipeableViews
                index={tabValue}
                onChangeIndex={handleTabIndexChange}
                containerStyle={{
                    transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
                }}
            >
                <PreferencesPrefs
                    hive={hive}
                    sessionToken={currentUser.sessionToken}
                    isStaff={currentUser.isStaff}
                />
                <PreferencesManagement hive={hive} />
                {/* <PreferencesBilling
                    hive={hive}
                    sessionToken={currentUser.sessionToken}
                    isHiveAdmin={isHiveAdmin}
                    isStaff={currentUser.isStaff}
                /> */}
                <PreferencesLabels hive={hive} />
                {isHivePremium && <PreferencesPremium hive={hive} />}
            </SwipeableViews>
        </div>
    );
};
// language of communication
// myhive name
// myhive description
// (then the users)

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;
    return { currentUser };
};

PreferencesTab.propTypes = {
    currentUser: PropTypes.object,
    hive: PropTypes.object,
};

export default connect(mapStateToProps, {})(PreferencesTab);

import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import { CardSection } from "../Card";

const useStyles = makeStyles((theme) => ({
    ctaItem: {
        borderBottom: "1px solid lightgray",
        display: "flex",
        marginBottom: 12,
        padding: theme.spacing(2, 0),
        "&:last-child": {
            borderBottom: "none",
            paddingBottom: "0px",
        },
    },
    ctaItemContent: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0",
    },
    ctaDescription: {
        marginRight: theme.spacing(2),
    },
    ctaButton: {
        minHeight: "50px",
        textTransform: "none",
        textAlign: "center",
        width: (props) => (props.extendWidth ? "auto" : "108px"),
    },
    actionHover: {
        transition: "all 0.4s ease-in 0s",
        "&:hover": {
            backgroundColor: "forestgreen",
        },
    },
}));

const ActionableCardItem = ({
    variant,
    title,
    cta,
    link,
    GALabel,
    extendWidth,
    children,
    actionHover,
}) => {
    const classes = useStyles({ extendWidth });

    const clickCta = () => {
        ReactGA.event({
            category: "ctacard",
            action: "click",
            label: GALabel,
        });
    };

    return (
        <CardSection title={title} className={classes.ctaItem}>
            <div className={classes.ctaItemContent}>
                <div className={classes.ctaDescription}>{children}</div>
                <div>
                    <Button
                        className={[classes.ctaButton, actionHover && classes.actionHover]}
                        onClick={clickCta}
                        href={link}
                        target="_blank"
                        variant="contained"
                        color={variant}
                        disableElevation
                    >
                        {cta}
                    </Button>
                </div>
            </div>
        </CardSection>
    );
};

ActionableCardItem.propTypes = {
    variant: PropTypes.string,
    title: PropTypes.any,
    description: PropTypes.any,
    cta: PropTypes.any,
    link: PropTypes.any,
    GALabel: PropTypes.string,
    extendWidth: PropTypes.bool,
    children: PropTypes.array,
    actionHover: PropTypes.bool,
};

export default ActionableCardItem;

import { get, listOwned } from "../networking/Client";
import { Client } from "../typings/entities";
import { PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch } from "./types";

const FETCHING = "alveole-maruche/clients/FETCHING";
const FETCHED = "alveole-maruche/clients/FETCHED";
const FETCH = "alveole-maruche/clients/FETCH";
const CURRENT = "alveole-maruche/clients/CURRENT";

export default function clientReducer(
  state = {
    clients: [],
    clientUrl: "",
  },
  action: PayloadAction<string | Client[] | undefined> = { type: "", payload: undefined }
) {
  switch (action.type) {
    case CURRENT: {
      return {
        ...state,
        clientUrl: action.payload as string,
      };
    }
    case FETCHING: {
      return {
        ...state,
        clientsFetching: true,
      };
    }
    case FETCHED: {
      return {
        ...state,
        clientsFetching: false,
      };
    }
    case FETCH: {
      const newClientIds: Set<string> = new Set(
        (action.payload as Client[]).map((newClient: Client) => newClient.objectId)
      );
      const clients = [
        ...state.clients.filter((oldClient: Client) => !newClientIds.has(oldClient.objectId)),
        ...(action.payload as Client[]),
      ];

      return {
        ...state,
        clients,
      };
    }

    default:
      return state;
  }
}

export const clientsFetching = () => {
  return {
    type: FETCHING,
  };
};
export const clientsFetched = () => {
  return {
    type: FETCHED,
  };
};
export const fetchClientsAction = (clients: Client[]) => {
  return {
    type: FETCH,
    payload: clients,
  };
};

export const fetchClient = ({ hiveId, sessionToken }: { hiveId?: string; sessionToken?: string }) => {
  return async (dispatch: AppDispatch) => {
    dispatch(clientsFetching());
    return get({ hiveId, sessionToken }).then((clients) => {
      dispatch(fetchClientsAction(clients || []));
      dispatch(clientsFetched());
    });
  };
};

export const fetchOwnedClients = ({ sessionToken }: { sessionToken?: string }) => {
  return async (dispatch: AppDispatch) => {
    dispatch(clientsFetching());
    return listOwned({ sessionToken }).then((clients) => {
      dispatch(fetchClientsAction(clients || []));
      dispatch(clientsFetched());
    });
  };
};

export const setClientUrl = (clientUrl: string) => {
  return {
    type: CURRENT,
    payload: clientUrl,
  };
};

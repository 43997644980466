import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { resetPassword, verifyPasswordResetToken } from "../networking/UserAccounts";
import { useLocation } from "react-router-dom";
import Spinner from "./Spinner";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import { Alert } from "@material-ui/lab";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    forgotPassword: {
        color: theme.palette.tertiary,
    },
    dialog: {
        width: 400,
    },
    passwordAlert: {
        borderRadius: 0,
    },
    passwordAlertLink: {
        color: "inherit",
        cursor: "pointer",
        textDecoration: "underline",
    },
    wrapper: {
        margin: 8,
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const getSafe = (fn, defaultValue) => {
    try {
        return fn();
    } catch (error) {
        return defaultValue;
    }
};

const currentLanguageKey = window.isEnglish ? "en" : "fr";

const PasswordReset = ({ open, handleClose, history }) => {
    const getPasswordValidity = () => {
        const matches = passwordInputValue === passwordRepeatInputValue;
        const atLeast5chars = getSafe(() => passwordInputValue.length, 0) >= 5;
        const isValid = matches && atLeast5chars;
        if (isValid) return true;
        return false;
    };

    const getURLParameter = (name, queryParams) => {
        return (
            decodeURIComponent(
                (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(queryParams) || [
                    null,
                    "",
                ])[1].replace(/\+/g, "%20")
            ) || null
        );
    };

    const handlePasswordReset = () => {
        setLoading(true);
        resetPassword({
            userId,
            resetToken,
            password: passwordInputValue,
        })
            .then((data) => {
                setEmail(data.username);
                setLoading(false);
                setSuccess(true);
            })
            .catch((error) => {
                const errorMessage =
                    getSafe(() => error[currentLanguageKey]) || t("appBar.passwordServerError");

                setErrorMessage(errorMessage);
                setLoading(false);
            });
    };

    const verifyResetToken = () => {
        verifyPasswordResetToken({ userId, resetToken }).catch((error) => {
            const errorMessage =
                getSafe(() => error[currentLanguageKey]) || t("appBar.passwordServerError");

            setErrorMessage(errorMessage);
        });
    };

    const location = useLocation();
    const userId = getURLParameter("u", location.search);
    const resetToken = getURLParameter("t", location.search);
    const hiveUrl = getURLParameter("h", location.search);
    const isNewUser = Boolean(getURLParameter("create", location.search));

    const [email, setEmail] = useState("");
    const [passwordInputValue, setPasswordInputValue] = useState("");
    const [passwordRepeatInputValue, setPasswordRepeatInputValue] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [success, setSuccess] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();

    if (!userId || !resetToken) return null;

    useEffect(() => {
        verifyResetToken();
    }, []);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                {isNewUser ? t("appBar.createPassword") : t("appBar.resetTitle")}
                <EmojiNatureIcon />
            </DialogTitle>

            {loading && <Spinner />}
            {success && (
                <>
                    <Alert severity={"success"} className={classes.passwordAlert}>
                        {t("appBar.successfullyChangedPassword")}
                    </Alert>

                    <DialogActions>
                        <div className={classes.wrapper}>
                            <Button
                                onClick={() => {
                                    history.push({ 
                                        pathname: `/${hiveUrl ? hiveUrl : ""}`,
                                        search: `?login=true${isNewUser ? "&e=" + email : ""}`
                                    });
                                    history.go();
                                }}
                                color="primary"
                            >
                                {t("appBar.signIn")}
                            </Button>
                            {loading && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                            )}
                        </div>
                    </DialogActions>
                </>
            )}

            {!loading && !success && userId && resetToken && (
                <>
                    <Collapse in={Boolean(errorMessage)}>
                        <Alert severity="error" className={classes.passwordAlert}>
                            {errorMessage}
                        </Alert>
                    </Collapse>

                    <DialogContent>
                        <TextField
                            margin="dense"
                            disabled={loading || errorMessage}
                            id="new-password"
                            label={t("appBar.newPassword")}
                            type={showPassword ? "text" : "password"}
                            value={passwordInputValue}
                            onChange={(event) => setPasswordInputValue(event.target.value)}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            disabled={loading || errorMessage}
                            margin="dense"
                            id="new-password-again"
                            label={t("appBar.newPasswordAgain")}
                            type={showPasswordRepeat ? "text" : "password"}
                            value={passwordRepeatInputValue}
                            onChange={(event) => setPasswordRepeatInputValue(event.target.value)}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                setShowPasswordRepeat(!showPasswordRepeat)
                                            }
                                        >
                                            {showPasswordRepeat ? (
                                                <Visibility />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </DialogContent>

                    <DialogActions>
                        <div className={classes.wrapper}>
                            <Button onClick={handleClose} variant="text" color="default">
                                {t("common:cancel")}
                            </Button>
                            <Button
                                onClick={handlePasswordReset}
                                color="primary"
                                disabled={errorMessage || !getPasswordValidity() || loading}
                            >
                                {t("appBar.savePassword")}
                            </Button>
                            {loading && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                            )}
                        </div>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

PasswordReset.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    history: PropTypes.object,
};

export default PasswordReset;

import { SERVER_URL } from "./server";
import fetch from "node-fetch";

import FormData from "form-data";

export const BUZZ_FETCH_LIMIT = 5;

export const toggleFavouriteBuzz = async ({ buzzId, buzzFavId, sessionToken, favourite }) => {
    const url = new URL(
        `${SERVER_URL}myhive/buzz/${buzzId}/${favourite ? "unfavourite" : "favourite"}`
    );
    //include sessionToken & buzzFavId in params only if they have a truthy value
    const params = {
        ...(sessionToken ? { sessionToken } : {}),
        ...(buzzFavId ? { buzzFavId } : {}),
    };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "put",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

export const removeBuzz = async ({ buzzId, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/buzz/${buzzId}`);
    const params = { sessionToken };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "delete",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

export const fetchBuzz = async ({ buzzId, hive }) => {
    const url = new URL(`${SERVER_URL}myhive/buzz/${hive}/${buzzId}`);
    // const params = {
    //   hive,
    // };

    // url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });
    const responseObject = await response.json();

    return responseObject.data;
};

export const fetchBuzzes = async ({ hives, sessionToken, offset = 0 }) => {
    const url = new URL(`${SERVER_URL}myhive/buzz/`);

    const params = {
        ...(sessionToken ? { sessionToken } : {}),
        hives: JSON.stringify(hives),
        offset,
        limit: BUZZ_FETCH_LIMIT,
    };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

export const createBuzz = async ({ text, hives, author, media, metadata, sessionToken }) => {
    const form = new FormData();

    for (const [i, image] of media.entries()) {
        form.append(`media[${i}]`, image);
    }
    form.append("sessionToken", sessionToken);
    form.append("text", text);
    form.append("author", author);
    form.append("metadata", JSON.stringify(metadata));
    form.append("hives", JSON.stringify(hives));

    const url = new URL(`${SERVER_URL}myhive/buzz/create`);

    const params = { sessionToken };
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "post",
        body: form,
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

export const buzzPatch = async ({ properties, permaId, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/buzz/${permaId}`);

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
            properties,
            permaId,
            sessionToken,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject.data;
    } else {
        throw new Error(responseObject.message);
    }
};

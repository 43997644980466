import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { createBuzz } from "../networking/Buzz";
import { send } from "../networking/slack";

import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import TimelineMedia from "./TimelineMedia";

import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { LinearProgress, Portal } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import PublishIcon from "@material-ui/icons/Publish";

import Typography from "@material-ui/core/Typography";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import CardHeader from "@material-ui/core/CardHeader";
import { useSafeAwsUrl, getFriendlyHiveName } from "../util";
import Paper from "@material-ui/core/Paper";

import FormControl from "@material-ui/core/FormControl";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

const MAX_FILES_LENGTH = 5;
const MAX_FILES_SIZE = 512000; //500mb

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2, 2, 1),
    },
    fileInput: {
        display: "none",
    },
    addToPostContainer: {
        margin: theme.spacing(2, 0),
        position: "sticky",
        bottom: 0,
        backgroundColor: theme.palette.background.white,
        padding: theme.spacing(1, 0),
    },
    uploadIcon: {
        paddingLeft: 0,
    },
    avatar: {
        backgroundColor: theme.palette.primary,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    linearProgress: {
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0.4,
        borderRadius: 8,
    },
    avatarImg: {
        height: "100%",
        width: "100%",
    },
    header: {
        padding: 0,
    },
    add: {
        position: "fixed",
        bottom: theme.spacing(5),
        right: theme.spacing(2),
        zIndex: "999",
        [theme.breakpoints.down("sm")]: {
            left: "50%",
            transform: "translateX(-50%)",
        },
        addIcon: {
            marginRight: "4px",
        },
    },
    buzzIcon: {
        marginLeft: "4px",
        marginBottom: "5px",
    },
    accordion: {
        boxShadow: "none",
    },
    accordionSummary: {
        padding: 0,
    },
    accordionDescription: {
        padding: 0,
    },
    accordionDetails: {
        flexWrap: "wrap",
        flexDirection: "column",
    },
    hidden: {
        visibility: "hidden",
    },
    buttonSpacing: {
        [theme.breakpoints.down("sm")]: {
            paddingBottom: 8,
        },
    },
}));

const CreateABuzz = ({
    currentUser,
    currentHive,
    reloadTimeline,
    weather,
    ownedClients,
    inView,
}) => {
    const classes = useStyles();
    const { t } = useTranslation(["buzz"]);
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadSize, setUploadSize] = useState(0);

    const [changes, setChanges] = useState(false);
    const [images, setImages] = useState([]);

    const [text, setText] = useState("");
    //TODO handle loading
    const [loading, setLoading] = useState(false);
    const [postToOtherHives, setPostToOtherHives] = React.useState(false);
    const getDefaultBuzzState = (currentHive, ownedClients) => ({
        ...ownedClients.reduce(
            (state, hive) => ({
                ...state,
                [hive.id]: { checked: false, Nom: getFriendlyHiveName(hive) },
            }),
            {}
        ),
        [currentHive.id]: { checked: true, Nom: [getFriendlyHiveName(currentHive)] },
    });
    //TODO validate ( can currently have no hives selected )
    const [buzzToClients, setBuzzToClients] = useState(
        getDefaultBuzzState(currentHive, ownedClients)
    );

    useEffect(() => {
        setBuzzToClients(getDefaultBuzzState(currentHive, ownedClients));
    }, [currentHive, ownedClients]);

    const toggleAllHivesChecked = () => {
        setBuzzToClients(getDefaultBuzzState(currentHive, ownedClients));
        setPostToOtherHives((prev) => !prev);
    };
    const toggleUserHivesChecked = (id) => {
        setBuzzToClients((prev) => ({
            ...prev,
            [id]: { ...prev[id], checked: !prev[id].checked },
        }));
    };

    const checkValidFilesSize = (files) => {
        const sizeKb = [...files].reduce((acc, curr) => acc + curr.size / 1024, 0);
        return sizeKb <= MAX_FILES_SIZE;
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setImages([]);
        setChanges();
        setText("");
        setPostToOtherHives(false);
        // setBuzzToClients(getInitialBuzzState(currentHive, ownedClients))
    };

    const handleImageChange = (event) => {
        event.preventDefault();
        const newFiles = [...event.target.files];
        // Reset the event so that the same file may be uploaded twice ( in case it was removed by accident or something ) .
        event.target.value = null;

        if (newFiles.length > MAX_FILES_LENGTH) newFiles.splice(MAX_FILES_LENGTH);
        if (images.length + newFiles.length > MAX_FILES_LENGTH)
            newFiles.splice(MAX_FILES_LENGTH - images.length);

        const validSize = checkValidFilesSize([...images, ...newFiles]);
        if (!validSize) {
            return enqueueSnackbar(t("maxUploadSizeError"), { variant: "error" });
        }
        setImages((images) => [...images, ...newFiles]);
    };

    const handleRemoveImage = (imageIndex) =>
        setImages((images) => images.filter((image, index) => index !== imageIndex));

    const handleBuzzTextChange = (event) => {
        const newChanges = event.target.value.length > 0;
        setChanges(newChanges);
        setText(event.target.value);
    };

    useEffect(() => {
        if (loading) {
            const rate = 50000000 / uploadSize;
            const diff = rate < 1 ? 1 : rate > 10 ? 10 : rate / 3;

            const timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress > 80) {
                        return oldProgress + 1;
                    }

                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };
        }
    }, [loading]);

    const handleBuzzSubmit = async (event) => {
        event.preventDefault();

        const hives = Object.keys(buzzToClients).filter(
            (client) => !!buzzToClients[client].checked
        );
        if (hives.length < 1) {
            enqueueSnackbar(t("chooseHive"), {
                variant: "error",
            });
            return;
        }

        try {
            const totalSize = images.reduce((total, image) => total + image.size, 0);
            setUploadSize(totalSize);

            setLoading(true);
            setProgress(0);

            const newBuzz = await createBuzz({
                text,
                hives,
                author: currentUser,
                media: images,
                metadata: {
                    weather: weather || {},
                },
                sessionToken: currentUser.sessionToken,
            });

            const buzzer = currentUser
                ? `User: ${currentUser.firstName} ${currentUser.lastName} ${currentUser.email}`
                : "No user";

            const buzzPermaId = newBuzz && newBuzz.data && newBuzz.data.permaId;
            const buzzUrl = `https://myhive.alveole.buzz/${currentHive.MaRucheUrl}/buzz/${buzzPermaId}`;

            const tags = newBuzz.data.media
                .filter((media) => media.labels)
                .map((media) => media.labels.map((label) => `${label.description}`).join(", "))
                .map((tagSet, index) => `Image ${index + 1}: ${tagSet}`)
                .join("\n");

            const weatherMessage =
                weather
                && `Weather in ${weather.city}: ${weather.temperature} ºC, ${weather.description}`;

            const buzzData = [
                buzzer,
                "Buzz: " + buzzUrl,
                weatherMessage && weatherMessage,
                tags && tags,
            ].join("\n\n");

            send({
                text,
                messageType: "BUZZ",
                data: buzzData,
                sessionToken: currentUser.sessionToken,
            });

            setLoading(false);
            reloadTimeline();
            handleClose();
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(`Error: ${JSON.stringify(error.message)}`, {
                variant: "error",
            });
        }
    };

    const uploadImagesToTimelineImage = (images) =>
        images.map((image) => ({
            mimeType: image.type,
            file: {
                url: URL.createObjectURL(image),
            },
        }));

    const showHivePicker = ownedClients.length > 1;

    return (
        <div>
            <CssBaseline />
            <Portal>
                <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    className={[classes.add, ...[inView ? "" : classes.hidden]]}
                    onClick={handleOpen}
                >
                    {t("create")}
                    <EmojiNatureIcon className={classes.buzzIcon} />
                </Fab>
            </Portal>
            <Dialog
                aria-labelledby="simple-dialog-title"
                aria-describedby="simple-dialog-description"
                open={open}
                onClose={handleClose}
                className={classes.dialog}
                fullWidth
                maxWidth={"sm"}
            >
                <Paper rounded="true" elevation={3} className={classes.paper}>
                    <CardHeader
                        className={classes.header}
                        avatar={
                            <Avatar aria-label="author" className={classes.avatar}>
                                {currentUser && currentUser.avatar ? (
                                    <img
                                        alt=""
                                        className={classes.avatarImg}
                                        src={useSafeAwsUrl(currentUser.avatar.url)}
                                    />
                                ) : (
                                    <EmojiNatureIcon />
                                )}
                            </Avatar>
                        }
                        title={`${currentUser.firstName || currentUser.username} ${
                            currentUser.lastName || ""
                        }`}
                        subheader={currentUser.tagline || "MyHive user"}
                    />

                    <form className={classes.form} noValidate>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="buzz-text"
                                name="buzzText"
                                value={text}
                                onChange={handleBuzzTextChange}
                                multiline={true}
                                label={`${t("whatsTheBuzz")}${
                                    currentUser.firstName || currentUser.username
                                }?`}
                                inputProps={{ maxLength: 1000 }}
                            />
                            <Typography align="right" variant="subtitle2">
                                {`${text.length}/1000`}
                            </Typography>
                            {images.length ? (
                                <TimelineMedia
                                    handleRemoveImage={handleRemoveImage}
                                    setImages={setImages}
                                    images={uploadImagesToTimelineImage(images)}
                                    rawFiles={images}
                                />
                            ) : null}
                        </FormControl>

                        {showHivePicker && (
                            <FormGroup>
                                <Accordion
                                    className={classes.accordion}
                                    expanded={postToOtherHives}
                                    onChange={toggleAllHivesChecked}
                                >
                                    <AccordionSummary
                                        className={classes.accordionSummary}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography className={classes.secondaryHeading}>
                                            <Switch color="primary" checked={postToOtherHives} />{" "}
                                            Post to other hives you manage.
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        {Object.keys(buzzToClients).map((id) => (
                                            <FormControlLabel
                                                key={id}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        checked={!!buzzToClients[id].checked}
                                                        onChange={() => toggleUserHivesChecked(id)}
                                                        name={id}
                                                    />
                                                }
                                                label={buzzToClients[id].Nom}
                                            />
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </FormGroup>
                        )}

                        <Grid container className={classes.addToPostContainer}>
                            <Grid item xs={12} md={6} className={classes.buttonSpacing}>
                                <input
                                    multiple
                                    onChange={handleImageChange}
                                    accept="image/*|video/*"
                                    className={classes.fileInput}
                                    id="icon-button-file"
                                    type="file"
                                    disabled={images.length >= MAX_FILES_LENGTH}
                                />
                                <label htmlFor="icon-button-file">
                                    <Button
                                        aria-label="upload picture"
                                        component="span"
                                        startIcon={
                                            <PublishIcon
                                                color={
                                                    images.length >= MAX_FILES_LENGTH
                                                        ? "disabled"
                                                        : "primary"
                                                }
                                            />
                                        }
                                        disabled={images.length >= MAX_FILES_LENGTH}
                                        fullWidth
                                    >
                                        {t("uploadMedia")}
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleBuzzSubmit}
                                    disabled={loading || !changes}
                                    fullWidth
                                >
                                    {t("post")}
                                    {loading && (
                                        <LinearProgress
                                            variant="determinate"
                                            value={progress}
                                            color="primary"
                                            className={classes.linearProgress}
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Dialog>
        </div>
    );
};

const mapStateToProps = ({ client, user }) => {
    const { clients } = client;
    const { currentUser } = user;
    return { currentUser, ownedClients: clients.filter(({ owned }) => !!owned) };
};

CreateABuzz.propTypes = {
    currentUser: PropTypes.object,
    currentHive: PropTypes.object,
    reloadTimeline: PropTypes.func,
    weather: PropTypes.object,
    ownedClients: PropTypes.array,
    inView: PropTypes.bool,
};

export default connect(mapStateToProps, {})(CreateABuzz);

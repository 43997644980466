import { getIpLocation } from "../networking/ipLocation";

const FETCHING = "alveole-maruche/location/FETCHING";
const FETCHED = "alveole-maruche/location/FETCHED";
const ERROR = "alveole-maruche/location/ERROR";

export default function ipLocationReducer(
    state = {
        currentIpLocation: {},
        ipLocationError: null,
    },
    action = {}
) {
    switch (action.type) {
        case FETCHING: {
            return {
                ...state,
                ipLocationFetching: true,
            };
        }
        case FETCHED: {
            return {
                ...state,
                ipLocationFetching: false,
                ipLocationError: false,
                currentIpLocation: action.payload,
            };
        }
        case ERROR: {
            return {
                ...state,
                ipLocationFetching: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export const ipLocationFetching = () => {
    return {
        type: FETCHING,
    };
};
export const ipLocationFetched = (ipLocation) => {
    return {
        type: FETCHED,
        payload: ipLocation,
    };
};

export const ipLocationError = (errorMessage) => {
    return {
        type: ERROR,
        payload: errorMessage,
    };
};

export const fetchIpLocation = () => {
    return async (dispatch) => {
        dispatch(ipLocationFetching());
        return getIpLocation()
            .then((ipLocation) => {
                dispatch(ipLocationFetched(ipLocation));
            })
            .catch((error) => {
                dispatch(ipLocationError(error.message));
            });
    };
};

import { SERVER_URL } from "./server";

import FormData from "form-data";

const safeName = (filename) => filename.replace(/[/\\?%*:| ()"<>]/g, "-");

// Sets an image on the client, depending on the imageType ( either "cover": "MaRucheCoverPicture" or "profile": "MaRucheProfilePicture" )
export const uploadImage = async ({
    croppedImage,
    originalImage,
    hiveId,
    imageType,
    sessionToken,
}) => {
    // console.log(hiveId)
    if (!croppedImage) throw new Error("UploadImage needs a cropped image ");
    if (!hiveId) throw new Error("UploadImage needs a hiveId ");

    const url = new URL(`${SERVER_URL}myhive/hive/${hiveId}/coverImage`);

    const form = new FormData();
    form.append("coverImage", croppedImage);
    originalImage && form.append("coverImageOriginal", originalImage);
    form.append("hiveId", hiveId);
    form.append("imageType", imageType);
    form.append("sessionToken", sessionToken);

    const response = await fetch(url, {
        method: "PATCH",
        body: form,
    });

    const responseObject = await response.json();

    if (response.ok) {
        return;
    } else {
        throw new Error(responseObject.message);
    }
};

export const patchAvatar = async ({ file, userId, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/user/${userId}/avatar`);

    const form = new FormData();
    form.append("avatar", file, safeName(file.name));
    form.append("sessionToken", sessionToken);

    const response = await fetch(url, {
        method: "PATCH",
        body: form,
    });

    const responseObject = await response.json();

    if (response.ok) {
        return;
    } else {
        throw new Error(responseObject.message);
    }
};

import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        backgroundColor: "transparent",
    },
    dot: {
        width: "30px",
        height: "4px",
        borderRadius: "4px",
    },
    dotActive: {
        width: "45px",
    },
    icons: {},
});

const StepperDots = ({ maxSteps, onArrowClick, index }) => {
    const classes = useStyles();

    return (
        <MobileStepper
            variant="dots"
            steps={maxSteps}
            activeStep={index}
            position="static"
            classes={{
                root: classes.root,
                dot: classes.dot,
                dotActive: classes.dotActive,
            }}
            backButton={
                <Button size="small" onClick={() => onArrowClick("left")} disabled={index === 0}>
                    <ArrowBackIcon color="primary" />
                </Button>
            }
            nextButton={
                <Button
                    size="small"
                    onClick={() => onArrowClick("right")}
                    disabled={index === maxSteps - 1}
                >
                    <ArrowForwardIcon color="primary" />
                </Button>
            }
        />
    );
};

StepperDots.propTypes = {
    index: PropTypes.number,
    maxSteps: PropTypes.number,
    onArrowClick: PropTypes.func,
};

export default StepperDots;

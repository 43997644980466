import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { BeeHomeWidgetData, BiodiversityReportData } from "@alveolemtl/alveole-components";

import TimelineTab from "./TimelineTab";
import Timeline from "../components/Timeline";
import { HiveStats } from "../components/MyhiveTab/HiveStats";
import ProfileCover from "../components/MyhiveTab/ProfileCover";

import { fetchInspectionData } from "../networking/Inspection";
import { fetchUpcomingVisits } from "../networking/Appointment";
import { fetchWeather, Weather } from "../networking/Weather";
import { fetchBeeHomeData, BeeHomeData } from "../networking/beeHomes";
import { buildBeeHomeWidgetData } from "../transforms/beeHomes";
import { Beekeeper, Client, Inspection, User, UserState, Visit } from "../typings/entities";
import { ApilabReport, fetchApilabReports } from "../networking/biodiversityReports";
import { buildFloralBiodiversityReportData } from "../transforms/biodiversityReports";

const useStyles = makeStyles(() => ({
  sidebar: {
    marginTop: "-350px",
  },
}));

interface Props {
  client: Client;
  currentUser?: User;
  clientBeekeeper: Beekeeper;
}

const MyhiveTab: React.FC<Props> = ({ client, currentUser, clientBeekeeper }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const [beeHomeWidgetData, setBeeHomeWidgetData] = useState<BeeHomeWidgetData[]>();
  const [floralBiodiversityReports, setFloralBiodiversityReports] = useState<BiodiversityReportData[]>();
  const [weather, setWeather] = useState<Weather>();
  const [inspections, setInspections] = useState<Inspection[]>();
  const [visits, setVisits] = useState<Visit[]>();

  const isUserViewingTheirOwnPage: boolean = !!client.owned || !!currentUser?.isStaff;
  const language: Language = window.isEnglish ? "en" : "fr";

  useEffect(() => {
    const clientId = client.objectId as string;
    const sessionToken = currentUser && currentUser.sessionToken;
    try {
      fetchInspectionData(clientId, sessionToken).then((inspections) => setInspections(inspections));
      fetchUpcomingVisits(clientId, sessionToken).then((visits) => setVisits(visits));
    } catch (error) {
      // force a logout on any/all session errors
      history.push("/logout");
    }
    fetchBeeHomeData(clientId).then((beeHomes: BeeHomeData[]) => {
      const widgetData: BeeHomeWidgetData[] = beeHomes.map(buildBeeHomeWidgetData);
      setBeeHomeWidgetData(widgetData);
    });
    fetchApilabReports(clientId).then((apilabReports: ApilabReport[]) => {
      const biodiversityReports: BiodiversityReportData[] = apilabReports.map((report) =>
        buildFloralBiodiversityReportData(report, language)
      );
      setFloralBiodiversityReports(biodiversityReports);
    });
    fetchWeather(clientId).then((fetchedWeather) => setWeather(fetchedWeather));
  }, [client]);

  return (
    <>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item md={8} xs={12}>
          <ProfileCover client={client} editEnabled={isUserViewingTheirOwnPage} inspections={inspections} />
        </Grid>

        {/* @ts-expect-error False positive typing error due to combining React 18 + MUI 4 */}
        <Hidden smDown>
          <Grid item md={8} sm={12}>
            <Timeline currentHive={client} weather={weather} />
          </Grid>
          <Grid className={classes.sidebar} item md={4} sm={12}>
            <HiveStats
              client={client}
              clientBeekeeper={clientBeekeeper}
              currentUser={currentUser}
              visits={visits}
              inspections={inspections}
              beeHomeWidgetData={beeHomeWidgetData}
              floralBiodiversityReports={floralBiodiversityReports}
              weather={weather}
            />
          </Grid>
        </Hidden>
      </Grid>

      {/* @ts-expect-error False positive typing error due to combining React 18 + MUI 4 */}
      <Hidden mdUp>
        <TimelineTab
          HiveStats={HiveStats}
          currentHive={client}
          clientBeekeeper={clientBeekeeper}
          visits={visits}
          weather={weather}
          inspections={inspections}
          beeHomeWidgetData={beeHomeWidgetData}
          floralBiodiversityReports={floralBiodiversityReports}
        />
      </Hidden>
    </>
  );
};

const mapStateToProps = ({ user }: { user: UserState }) => {
  const { currentUser } = user;
  return { currentUser };
};

export default connect(mapStateToProps, {})(MyhiveTab);

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { getFriendlyHiveName, useSafeAwsUrl } from "../../util";
import CropDialog from "../CropDialog";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation, Trans } from "react-i18next";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import beesIcon from "../../img/stats-icon-bees.svg";
import distanceIcon from "../../img/stats-icon-distance.svg";
import hotelIcon from "@material-ui/icons/House";
import emailIcon from "@material-ui/icons/MailOutline";
import SvgIcon from "@material-ui/core/SvgIcon";
import editImg from "../../img/edit-white.png";
import profilePlaceholder from "../../img/profile-placeholder.jpeg";
import coverPlaceholder from "../../img/cover-placeholder.jpg";
import ExpandedImageModal from "../ExpandedImageModal";

import { uploadImage } from "../../networking/ImageUpload";
import { patch } from "../../networking/Client";
import { fetchClient } from "../../redux/clients";

// TODO Enable when beekeeping season is active
const BEE_DISTANCE_ENABLED = true;

const countBees = (inspections) => {
    try {
        const mostRecentSheets = inspections[0];
        const NUM_BEES_PER_FRAME = 1500;
        let beeFramesCount = 0;
        for (const [, answers] of Object.entries(mostRecentSheets.answers)) {
            beeFramesCount += answers.numBeeFrames || 0;
        }
        return beeFramesCount * NUM_BEES_PER_FRAME;
    } catch (error) {
        return 0;
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: theme.spacing(2),
        boxShadow: "none",
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
        },
    },
    mediaContainer: {
        height: 140,
        position: "relative",
        cursor: "pointer",
    },
    coverButtonGroup: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        "& button": {
            color: "#2a2a2a88",
            backgroundColor: "#f7f7f388",
            marginLeft: theme.spacing(1),
            textTransform: "none",
            justifyContent: "left",
        },
        "& button:hover": {
            color: "#2a2a2aee",
            backgroundColor: "#f7f7f3ee",
            textTransform: "none",
            justifyContent: "left",
        },
    },
    editCoverImage: {
        bottom: "15px",
        left: "35px",
    },
    profileGridContainer: {
        position: "relative",
    },
    profilePic: {
        backgroundColor: "white",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        border: "5px white solid",
        borderRadius: "50%",
        height: "120px",
        left: theme.spacing(3),
        position: "absolute",
        top: "80px",
        width: "120px",
        [theme.breakpoints.down("xs")]: {
            borderWidth: "4px",
            height: "60px",
            left: theme.spacing(2),
            top: "110px",
            width: "60px",
        },
    },
    updateProfilePicButton: {
        opacity: "0.5",
        position: "absolute",
        bottom: "-5px",
        left: "-5px",
        backgroundColor: theme.palette.primary.main,
        width: "120px",
        height: "60px",
        textAlign: "center",
        cursor: "pointer",
        borderBottomLeftRadius: "100% 200%",
        borderBottomRightRadius: "100% 200%",
        "&:hover": {
            opacity: "0.7",
        },
        "&:active": {
            opacity: "0.6",
        },
        [theme.breakpoints.down("xs")]: {
            height: "30px",
            width: "60px",
            bottom: "-4px",
            left: "-4px",
        },
    },
    bubbleIcon: {
        borderRadius: "50%",
        height: "48px",
        width: "48px",
        backgroundColor: theme.palette.neutral.beige,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        color: theme.palette.primary.dark,
    },
    bubbleIconBorder: {
        border: "white 3px solid",
    },
    bubblesContainer: {
        height: 96,
        overflow: "hidden",
        position: "absolute",
        right: theme.spacing(3),
        top: "120px",
        cursor: "default",
        [theme.breakpoints.down("xs")]: {
            right: theme.spacing(1),
            paddingRight: "0px",
        },
    },
    bubbleWrapper: {
        width: "90px",
        [theme.breakpoints.down("xs")]: {
            width: "80px",
        },
    },
    bubbleValue: {
        color: theme.palette.primary.dark,
    },
    bubbleDescription: {
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.65rem",
        },
    },
    profileTextContent: {
        marginTop: theme.spacing(7),
        padding: theme.spacing(3),
    },
    profileTextHeader: {
        marginBottom: theme.spacing(0.5),
    },
    modalContent: {
        outline: "none",
        maxHeight: "95vh",
        maxWidth: "80vw",
    },
}));

const ProfileEditButton = ({ onClick, text }) => {
    const classes = useStyles();

    return (
        <div className={classes.updateProfilePicButton} onClick={onClick}>
            <img className={classes.editCoverImage} src={String(editImg)} alt={text} />
        </div>
    );
};
ProfileEditButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
};

const CoverEditButton = ({ onClick, text }) => (
    <Button onClick={onClick} variant="contained">
        <EditIcon /> {text}
    </Button>
);

const CoverRemoveButton = ({ onClick, text }) => (
    <Button onClick={onClick} variant="contained">
        <DeleteIcon /> {text}
    </Button>
);

CoverEditButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
};

CoverRemoveButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
};

const StatsBubble = ({ icon, value, description }) => {
    const classes = useStyles();

    if (!value || value[0] === "-") {
        return null;
    }

    let image = <img alt="" src={icon} className={classes.bubbleIcon} />;
    if (typeof icon === "object") {
        image = (
            <SvgIcon
                component={icon}
                viewBox="-8 -7 40 40"
                className={`${classes.bubbleIcon} ${classes.bubbleIconBorder}`}
            ></SvgIcon>
        );
    }

    return (
        <Grid item container direction="column" align="center" className={classes.bubbleWrapper}>
            <Grid item>{image}</Grid>
            <Grid item>
                <Typography variant="subtitle2" className={classes.bubbleValue}>
                    {value}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="caption" className={classes.bubbleDescription}>
                    <Trans i18nKey={description} count={parseInt(value, 10)} />
                </Typography>
            </Grid>
        </Grid>
    );
};

StatsBubble.propTypes = {
    icon: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
};

const ProfileCover = ({ inspections, client, editEnabled, showStats = true }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { t } = useTranslation(["common", "hiveTab"]);
    const { currentUser } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const [coverIsExpanded, setCoverIsExpanded] = useState(false);
    const [originalImage, setOriginalImage] = useState();
    const [fileTypeToSave, setFileTypeToSave] = useState();
    const [aspect, setAspect] = useState();
    const [circularCrop, setCircularCrop] = useState();
    const [time, setTime] = useState(Date.now());
    const uploadCoverRef = useRef(null);
    const uploadProfileRef = useRef(null);
    const name = getFriendlyHiveName(client);
    const coverImageUrl = client.MaRucheCoverPicture && client.MaRucheCoverPicture.url;
    const originalCoverImageUrl =
        client.MaRucheCoverPictureOriginal && client.MaRucheCoverPictureOriginal.url;
    const profileImageUrl = client.MaRucheProfilePicture && client.MaRucheProfilePicture.url;
    const hiveId = client.MaRucheUrl;
    const hiveCount = client.ServicePointer.nbRuches || 0;
    const hotelCount = client.ServicePointer.nbHotels || 0;
    const emailCount = (client.subscriptions && client.subscriptions.length) || 0;
    const country = client.Pays;
    const description = client.MaRucheDescription;

    const sessionToken = currentUser && currentUser.sessionToken;

    const coverImageIsPresent = !!coverImageUrl;
    const originalCoverImageIsPresent = !!originalCoverImageUrl;
    const profileImg = profileImageUrl
        ? useSafeAwsUrl(profileImageUrl)
        : useSafeAwsUrl(String(profilePlaceholder));
    const coverImg = coverImageIsPresent
        ? useSafeAwsUrl(coverImageUrl)
        : useSafeAwsUrl(String(coverPlaceholder));
    const expandedCoverImage = originalCoverImageIsPresent
        ? useSafeAwsUrl(originalCoverImageUrl)
        : coverImg;

    const beesCount = countBees(inspections, hiveCount);
    const kmTravelled = kilometersTravelled(beesCount, time);
    const milesTravelled = Math.floor(kmTravelled * 0.621371);

    // //for updating the km flown today every second
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleCropSet = async (croppedImage) => {
        await uploadImage({
            croppedImage,
            originalImage,
            imageType: fileTypeToSave,
            hiveId,
            sessionToken,
        });
        history.go(0);
    };

    function kilometersTravelled(beesCount, now) {
        const timeStampToSeconds = (timestamp) => Math.floor(timestamp / 1000);
        const currentTimeSeconds = timeStampToSeconds(now);
        const eightAmTimeSeconds = timeStampToSeconds(new Date().setHours(8, 0, 0, 0));
        const sixPmTimeSeconds = timeStampToSeconds(new Date().setHours(18, 0, 0, 0));

        // if less than 8 am then current, otherwise eight am
        const start =
            currentTimeSeconds < eightAmTimeSeconds ? currentTimeSeconds : eightAmTimeSeconds;
        // if less than 6 pm then current, otherwise six pm
        const finish =
            currentTimeSeconds < sixPmTimeSeconds ? currentTimeSeconds : sixPmTimeSeconds;

        const percentageOfDayComplete = (finish - start) / (sixPmTimeSeconds - eightAmTimeSeconds);
        const totalKilometresPerBeePerDay = 10;
        const foragerPercentage = 20;
        const foragerBees = beesCount * (foragerPercentage / 100);

        return Math.floor(percentageOfDayComplete * foragerBees * totalKilometresPerBeePerDay);
    }

    const handleRemoveCoverButtonTapped = async () => {
        await patch({
            hiveId,
            sessionToken,
            properties: { MaRucheCoverPicture: null, MaRucheCoverPictureOriginal: null },
        });
        dispatch(fetchClient({ hiveId }));
    };

    const handleCoverInputChange = (event) => {
        const file = event.target.files[0];
        setFileTypeToSave("cover");
        setAspect(700 / 140); // 5:1
        setCircularCrop(false);
        setOriginalImage(file);
        // Since the file input can't be controlled for security reasons, we need to use a dom ref and manually clear the value.
        // https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
        uploadCoverRef.current.value = "";
    };

    const handleCoverImageClick = (e) => {
        // Only accept direct clicks - ignore bubbling up from children.
        if (e.currentTarget === e.target) {
            setCoverIsExpanded(true);
        }
    };

    const handleProfileClick = () => {
        if (!editEnabled) {
            history.push("/" + hiveId);
        }
    };

    const handleProfileInputChange = (event) => {
        const file = event.target.files[0];
        setFileTypeToSave("profile");
        setAspect(1); // 1:1 ... rendered as a circle
        setCircularCrop(true);
        setOriginalImage(file);
        // "Since the file input is always uncontrolled you'll need to use a dom ref and manually clear the value."  https://www.google.com/search?q=React%3A+How+to+clear+file+input+and+data+input+fields+after+submitting+form
        uploadProfileRef.current.value = "";
    };

    const coverEditButton = editEnabled ? (
        <CoverEditButton text={t("hiveTab:edit")} onClick={() => uploadCoverRef.current.click()} />
    ) : null;
    const profileEditButton = editEnabled ? (
        <ProfileEditButton
            text={t("hiveTab:edit")}
            onClick={() => uploadProfileRef.current.click()}
        />
    ) : null;
    const coverRemoveButton =
        editEnabled && coverImageIsPresent ? (
            <CoverRemoveButton text={t("hiveTab:remove")} onClick={handleRemoveCoverButtonTapped} />
        ) : null;

    return (
        <>
            <Card className={classes.root}>
                <CardMedia
                    className={classes.mediaContainer}
                    style={{
                        backgroundImage: `linear-gradient(to bottom, transparent 50%, black 120%), url(${coverImg})`,
                    }}
                    onClick={handleCoverImageClick}
                >
                    <ButtonGroup
                        orientation="horizontal"
                        color="primary"
                        className={classes.coverButtonGroup}
                    >
                        {coverEditButton}
                        {coverRemoveButton}
                    </ButtonGroup>

                    <Grid className={classes.profileGridContainer} container alignItems="flex-end">
                        <Grid item xs={3}>
                            <div
                                className={classes.profilePic}
                                style={{
                                    cursor: editEnabled ? "inherit" : "pointer",
                                    backgroundImage: `url(${profileImg})`,
                                    borderColor: !profileImageUrl && theme.palette.primary.main,
                                }}
                                onClick={handleProfileClick}
                            >
                                {profileEditButton}
                            </div>
                        </Grid>

                        <Grid
                            className={classes.bubblesContainer}
                            xs={9}
                            item
                            container
                            justify="flex-end"
                            alignItems="flex-end"
                        >
                            {showStats ? (
                                <>
                                    <StatsBubble
                                        icon={beesIcon}
                                        value={beesCount}
                                        description="bees"
                                    />
                                    {BEE_DISTANCE_ENABLED && (
                                        <StatsBubble
                                            icon={distanceIcon}
                                            value={
                                                country === "USA"
                                                    ? `${milesTravelled} miles`
                                                    : `${kmTravelled} km`
                                            }
                                            description="travelled"
                                        />
                                    )}
                                    <StatsBubble
                                        icon={hotelIcon}
                                        value={hotelCount}
                                        description={
                                            hotelCount > 1 ? "wildBeeHotels" : "wildBeeHotel"
                                        }
                                    />
                                    <StatsBubble
                                        icon={emailIcon}
                                        value={emailCount}
                                        description={emailCount > 1 ? "subscribers" : "subscriber"}
                                    />
                                </>
                            ) : null}
                        </Grid>
                    </Grid>

                    <input
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleCoverInputChange}
                        type="file"
                        ref={uploadCoverRef}
                    />
                    <input
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleProfileInputChange}
                        type="file"
                        ref={uploadProfileRef}
                    />
                    <CropDialog
                        handleClose={() => setOriginalImage(undefined)}
                        handleSet={handleCropSet}
                        file={originalImage}
                        aspect={aspect}
                        circularCrop={circularCrop}
                    />
                </CardMedia>
                <CardContent className={classes.profileTextContent}>
                    <Typography variant="h1" className={classes.profileTextHeader}>
                        {name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {description || t("hiveTab:placeholderDescription")}
                    </Typography>
                </CardContent>
            </Card>
            <ExpandedImageModal isOpen={coverIsExpanded} close={() => setCoverIsExpanded(false)}>
                <img
                    alt=""
                    loading="lazy"
                    className={classes.modalContent}
                    src={expandedCoverImage}
                />
            </ExpandedImageModal>
        </>
    );
};

ProfileCover.propTypes = {
    profileImageUrl: PropTypes.string,
    coverImageUrl: PropTypes.string,
    country: PropTypes.string,
    hiveCount: PropTypes.number,
    hiveId: PropTypes.string,
    hotelCount: PropTypes.number,
    emailCount: PropTypes.number,
    name: PropTypes.string,
    instagram: PropTypes.string,
    editEnabled: PropTypes.bool,
    client: PropTypes.object,
    inspections: PropTypes.array,
    showStats: PropTypes.bool,
};

export default ProfileCover;

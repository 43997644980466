import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export type Weather = {
  city: string;
  temperatureCelsius: number;
  description: WeatherDescription;
};

export type WeatherDescription = "hot" | "cold" | "rainy" | "sunny" | "cloudy";

export async function fetchWeather(clientId: string): Promise<Weather> {
  const response = await fetch(`${SERVER_URL}clients/${clientId}/weather/current`);
  if (!response.ok) {
    throw new Error(`Could not fetch weather data. ${response.status}: ${response.statusText}`);
  }
  return response.json();
}

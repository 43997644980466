import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useSafeAwsUrl } from "../util";
import { useHistory } from "react-router";
import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Image from "@material-ui/icons/Image";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { uploadLogo, setPremiumLabelPreference } from "../networking/Jarring";
import { getIsIgnoredStatus } from "./PreferencesLabels";

const premiumLabels = ["premiumProofCandle", "premiumProofLipBalm", "premiumProofSoap"];

const useStyles = makeStyles((theme) => ({
    preferencesCard: {
        padding: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        textTransform: "uppercase",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    heading: {
        fontSize: 24,
        marginTop: theme.spacing(2),
    },
    logoUpload: {
        display: "none",
    },
    formRow: {
        marginTop: theme.spacing(1),
        // paddingTop: theme.spacing(2),
        // borderTop: "1px solid #dedede",
        fontSize: 18,
    },
    formType: {
        paddingTop: theme.spacing(1.5),
    },
    label: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        width: "100%",
    },
    changes: {
        marginLeft: "30px",
        width: "90%",
    },
    confirm: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    labelCaption: {
        textAlign: "center",
    },
    labelsRow: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    pdf: {
        minHeight: "200px",
        cursor: "pointer",
    },
}));

const PreferencesPremium = ({ hive }) => {
    const classes = useStyles();
    const cardRef = useRef(null);
    const history = useHistory();
    const { t } = useTranslation(["labels"]);
    const { currentUser } = useSelector((state) => state.user);
    const sessionToken = currentUser && currentUser.sessionToken;

    const [logoBusy, setLogoBusy] = useState(false);
    const [labelBusy, setLabelBusy] = useState(false);

    const [proofChoice, setProofChoice] = useState("APPROVED");
    const [proofChanges, setProofChanges] = useState();

    // reset the custom changes string when switching between selections
    useEffect(() => setProofChanges(""), [proofChoice]);

    const existingLogo = hive.ServicePointer.jarringLogo;

    const automaticApproval =
        hive.ServicePointer.productionStatus
        && hive.ServicePointer.productionStatus.premium === "TO_PRINT_AUTOMATICALLY";

    const labels = premiumLabels.reduce((acc, labelRow) => {
        acc[labelRow] = hive.ServicePointer[labelRow];

        return acc;
    }, {});

    const labelsReady = Object.values(labels).filter((label) => label).length === 3;

    const handleNewLogo = (event) => {
        setLogoBusy(true);
        event.preventDefault();
        uploadLogo({ file: event.target.files[0], hiveId: hive.id, sessionToken }).then(() => {
            setLogoBusy(false);
            history.go(0);
        });
    };

    const handlePdfClick = (event, url) => {
        event.preventDefault();
        window.open(url);
    };

    const handleConfirm = () => {
        const preferences = {
            premiumApproved: proofChoice || "",
            premiumDetails: proofChanges || "",
        };

        setLabelBusy(true);
        setPremiumLabelPreference({
            preferences,
            hiveId: hive.id,
            premiumApprovalTime: new Date().toString(),
            sessionToken,
        })
            .then(() => {
                setLabelBusy(false);
                history.go(0);
            })
            .catch((error) => {
                alert("There was an error! Please try again.", error);
                setLabelBusy(false);
            });
    };

    // Production crew uses "waiting for approval" for clients who have had labels printed before (last year),
    // and "labels ready for approval" for clients who haven't.
    const hasHadPremiumProductsPrintedPreviously =
        hive?.ServicePointer?.productionStatus?.premium === "WAITING_FOR_APPROVAL";

    return (
        <>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {t("premium.title")}
                <EmojiNatureIcon />
            </Typography>
            <Card className={classes.preferencesCard}>
                <CardContent>
                    <Typography gutterBottom ref={cardRef}>
                        {t("premium.formIntro")}
                    </Typography>
                    {!automaticApproval && (
                        <>
                            <Typography className={classes.heading} component="h2" gutterBottom>
                                Logo
                            </Typography>
                            <Grid container direction="row" spacing={2} className={classes.formRow}>
                                {!existingLogo ? (
                                    <>
                                        <Grid item xs={1}>
                                            <input
                                                onChange={(event) => handleNewLogo(event)}
                                                accept="*"
                                                type="file"
                                                id="logo-upload"
                                                className={classes.logoUpload}
                                            />
                                            <label htmlFor="logo-upload">
                                                <Tooltip title={t("clickHereToUploadYourLogo")}>
                                                    <IconButton
                                                        className={classes.uploadIcon}
                                                        component="span"
                                                        disabled={logoBusy}
                                                    >
                                                        <Image />
                                                        {logoBusy && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={classes.buttonProgress}
                                                            />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            </label>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography className={classes.formType}>
                                                {t("logoQuestion")}
                                            </Typography>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography component="p" gutterBottom>
                                            <Trans i18nKey="needToUpdate">
                                                {"We've received "}
                                                <Link href={useSafeAwsUrl(existingLogo.url)}>
                                                    your logo
                                                </Link>
                                                {"! Need to update it? Please send us an email at "}
                                                <Link href="mailto:design@alveole.buzz">
                                                    design@alveole.buzz
                                                </Link>
                                                .
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                    <Divider className={classes.divider} />
                    <Typography className={classes.heading} component="h2" gutterBottom>
                        {t("premium.labels")}
                    </Typography>
                    {labelsReady ? (
                        <>
                            <Typography component="p" gutterBottom>
                                {t("premium.confirmIntro")}
                            </Typography>
                            <Typography component="p" gutterBottom>
                                {t("premium.proofLabels")}
                            </Typography>
                            {hasHadPremiumProductsPrintedPreviously ? (
                                <Typography component="p">
                                    <strong>{t("willPrintAutomaticallyWarning")}</strong>
                                </Typography>
                            ) : null}
                            <Grid
                                container
                                direction="row"
                                spacing={0}
                                className={classes.labelsRow}
                            >
                                {Object.entries(labels).map((label, key) => (
                                    <Grid key={key} item md={4}>
                                        <Typography
                                            component="p"
                                            color="textSecondary"
                                            className={classes.labelCaption}
                                        >
                                            {t(`premium.${label[0]}`)}
                                        </Typography>
                                        {label[1].url
                                        && label[1].url.toLowerCase().includes("pdf") ? (
                                            <Document
                                                file={useSafeAwsUrl(label[1].url)}
                                                loading={t("pdfLoading")}
                                                className={classes.pdf}
                                                onClick={(event) =>
                                                    handlePdfClick(
                                                        event,
                                                        useSafeAwsUrl(label[1].url)
                                                    )
                                                }
                                            >
                                                <Page
                                                    pageNumber={1}
                                                    width={
                                                        (cardRef.current
                                                            && cardRef.current.clientWidth / 3)
                                                        || 230
                                                    }
                                                />
                                            </Document>
                                        ) : (
                                            <img
                                                alt=""
                                                src={useSafeAwsUrl(label[1].url)}
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                            {hive.ServicePointer.premiumApproved ? (
                                <Typography component="p" gutterBottom>
                                    {t("premium.confirmed")}
                                </Typography>
                            ) : getIsIgnoredStatus(
                                  hive.ServicePointer.productionStatus
                                      && hive.ServicePointer.productionStatus.premium
                              ) ? (
                                <Typography component="p" gutterBottom>
                                    {t("alreadyPrinted")}
                                </Typography>
                            ) : (
                                <>
                                    <Typography component="p" gutterBottom>
                                        <strong>{t("verify")}</strong>
                                        <ul>
                                            <li>{t("textAndContent")}</li>
                                            <li>{t("yourLogo")}</li>
                                            <li>{t("colours")}</li>
                                        </ul>
                                    </Typography>
                                    <Typography component="p" gutterBottom>
                                        {t("noteTrim")}
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            value={proofChoice}
                                            onChange={(event) => setProofChoice(event.target.value)}
                                        >
                                            <FormControlLabel
                                                value="APPROVED"
                                                control={<Radio />}
                                                label={t("proofApproved")}
                                            />
                                            <FormControlLabel
                                                value="SELF_DESIGN"
                                                control={<Radio />}
                                                label={t("premium.proofSelfDesign")}
                                            />
                                            <FormControlLabel
                                                value="HAVE_CHANGES"
                                                control={<Radio />}
                                                label={t("proofChanges")}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                    <Fade in={proofChoice === "HAVE_CHANGES"} unmountOnExit={true}>
                                        <TextField
                                            variant="outlined"
                                            className={classes.changes}
                                            onChange={(event) =>
                                                setProofChanges(event.target.value)
                                            }
                                            label={t("proofDetailsPlaceholder")}
                                            helperText={t("proofSpecific")}
                                            disabled={proofChoice !== "HAVE_CHANGES"}
                                        />
                                    </Fade>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.confirm}
                                        onClick={handleConfirm}
                                        disabled={
                                            proofChoice === "HAVE_CHANGES"
                                                ? !proofChanges
                                                : !proofChoice
                                        }
                                    >
                                        {t("confirmOrder")}
                                        {labelBusy && (
                                            <CircularProgress
                                                size={24}
                                                className={classes.buttonProgress}
                                            />
                                        )}
                                    </Button>
                                </>
                            )}
                        </>
                    ) : (
                        <Typography component="p" gutterBottom>
                            {t("premium.proofNotReady")}
                        </Typography>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

PreferencesPremium.propTypes = {
    hive: PropTypes.object,
};

export default PreferencesPremium;

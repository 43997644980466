// twitter allows tweet creation from a simple HTTP GET op
// docs: https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent
export const twitter = ({ text, url, via }) => {
    const endpoint = new URL("https://twitter.com/intent/tweet");
    const params = { text, url, via };

    endpoint.search = new URLSearchParams(params).toString();

    window.open(endpoint.href);
};

// same w/ facebook.
// docs: https://developers.facebook.com/docs/workplace/sharing/share-dialog/
export const facebook = ({ u }) => {
    const endpoint = new URL("https://www.facebook.com/sharer/sharer.php");
    const params = { u };

    endpoint.search = new URLSearchParams(params).toString();

    window.open(endpoint.href);
};

// linkedIn:
export const linkedIn = ({ url }) => {
    const endpoint = new URL("https://www.linkedin.com/sharing/share-offsite/");
    const params = { url };

    endpoint.search = new URLSearchParams(params).toString();

    window.open(endpoint.href);
};

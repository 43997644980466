import { getRegions } from "../networking/regions";

const FETCHING = "region/FETCHING";
const FETCHED = "region/FETCHED";
const ERROR = "region/ERROR";

export default function regionsReducer(
    state = {
        regions: {},
        regionsError: null,
    },
    action = {}
) {
    switch (action.type) {
        case FETCHING: {
            return {
                ...state,
                regionsFetching: true,
            };
        }
        case FETCHED: {
            return {
                ...state,
                regionsFetching: false,
                regionsError: false,
                regions: action.payload,
            };
        }
        case ERROR: {
            return {
                ...state,
                regionsFetching: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export const regionsFetching = () => {
    return {
        type: FETCHING,
    };
};
export const regionsFetched = (regions) => {
    return {
        type: FETCHED,
        payload: regions,
    };
};

export const regionsError = (errorMessage) => {
    return {
        type: ERROR,
        payload: errorMessage,
    };
};

export const fetchRegions = () => {
    return async (dispatch) => {
        dispatch(regionsFetching());
        return getRegions()
            .then((regions) => {
                dispatch(regionsFetched(regions));
            })
            .catch((error) => {
                dispatch(regionsError(error.message));
            });
    };
};

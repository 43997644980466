import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { patch } from "../networking/Client";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

import QRCodeStyling from "qr-code-styling";
import { getFriendlyHiveName, getQueryStringParams } from "../util";

const qrCode = new QRCodeStyling({
    width: 900,
    height: 900,
    // image: require("../img/bee-icon-black.png"),
    dotsOptions: {
        color: "#000000",
        // type: "dots",
    },
    cornersSquareOptions: {
        // color: "#b89e6e",
        type: "extra-rounded",
    },
    cornersDotOptions: {
        // color: "#b89e6e",
        // type: "dot",
    },
    imageOptions: {
        // crossOrigin: "anonymous",
        // imageSize: 0.5,
        // margin: 0,
    },
    backgroundOptions: {
        color: "#ffffff00",
    },
    qrOptions: {
        errorCorrectionLevel: "L",
    },
});

const useStyles = makeStyles((theme) => ({
    preferencesCard: {
        padding: theme.spacing(2),
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    title: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        textTransform: "uppercase",
    },
    field: {
        marginTop: theme.spacing(3),
        // marginBottom: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(4),
    },
    qrCode: {
        "& canvas": {
            width: "200px",
        },
        cursor: "pointer",
        marginTop: theme.spacing(4),
    },
}));

const PreferencesPrefs = ({ hive, sessionToken, isStaff = false }) => {
    const { t } = useTranslation(["preferences", "common"]);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const location = useLocation();
    const queryParamsObj = getQueryStringParams(location.search);

    const qrRef = useRef(null);
    const [qrSource, setQrSource] = useState("");

    const UTM_MEDIUM = "qr";
    const UTM_CAMPAIGN = queryParamsObj.qrcampaign;

    const UTM_STRING =
        `?utm_medium=${UTM_MEDIUM}`
        + `${qrSource !== "" ? `&utm_source=${qrSource}` : ""}`
        + `${UTM_CAMPAIGN ? `&utm_campaign=${UTM_CAMPAIGN}` : ""}`;

    const defaultHiveName = getFriendlyHiveName(hive);
    const defaultHiveDescription = hive.MaRucheDescription || "";
    const defaultHiveUrl = hive.MaRucheUrl || "";
    const defaultHiveLanguage = hive.Langue; // 0-based... watch for false nulls
    const defaultVisibilty = hive.visibility || "PUBLIC";

    const classes = useStyles();

    const [changes, setChanges] = useState(false);

    const [values, setValues] = useState({
        MaRucheNom: defaultHiveName,
        MaRucheDescription: defaultHiveDescription,
        MaRucheUrl: defaultHiveUrl,
        Langue: defaultHiveLanguage, // 0-based... watch for false nulls
        visibility: defaultVisibilty,
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        setChanges(true);
        const { id, value } = event.target;

        setValues({
            ...values,
            [id]: value,
        });
    };

    const handleLanguage = (event) => {
        setChanges(true);
        const { value } = event.target;

        setValues({
            ...values,
            Langue: value,
        });
    };

    const handleVisibility = (event) => {
        setChanges(true);
        const { value } = event.target;

        setValues({
            ...values,
            visibility: value,
        });
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            await patch({
                hiveId: hive.id,
                sessionToken,
                properties: values,
            });

            enqueueSnackbar(t("preferences:hive.saved"), {
                variant: "success",
            });

            setTimeout(() => history.push(`/${values.MaRucheUrl}/preferences`), 1500);
        } catch (error) {
            setValues({
                MaRucheNom: defaultHiveName,
                MaRucheDescription: defaultHiveDescription,
                MaRucheUrl: defaultHiveUrl,
                Langue: defaultHiveLanguage, // 0-based... watch for false nulls
            });
            enqueueSnackbar(
                t(
                    error.message === "409"
                        ? "preferences:hive.duplicateUrlError"
                        : "preferences:hive.error"
                ),
                {
                    variant: "error",
                }
            );
        }
        setChanges(false);
        setLoading(false);
    };

    useEffect(() => {
        const isMaRuche = window.location.href.includes("maruche");

        qrCode.append(qrRef.current);
        qrCode.update({
            data: isMaRuche
                ? `https://maruche.alveole.buzz/${hive.MaRucheUrl}${UTM_STRING}`
                : `https://myhive.alveole.buzz/${hive.MaRucheUrl}${UTM_STRING}`,
        });
    }, []);

    useEffect(
        () =>
            qrCode.update({
                data: `https://myhive.alveole.buzz/${hive.MaRucheUrl}${UTM_STRING}`,
            }),
        [qrSource]
    );

    const handleDownloadQR = () => {
        qrCode.download({
            name: `${hive.MaRucheUrl}${qrSource}`,
            extension: "png",
        });
    };

    return (
        <>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {t("preferences:preferences")}
                <EmojiNatureIcon />
            </Typography>
            <Card className={classes.preferencesCard}>
                <CardContent>
                    <TextField
                        label={t("preferences:hive.nameTitle")}
                        helperText={t("preferences:hive.nameDescription")}
                        id="MaRucheNom"
                        fullWidth
                        value={values.MaRucheNom}
                        className={classes.field}
                        onChange={handleChange}
                    />
                    <TextField
                        label={t("preferences:hive.descriptionTitle")}
                        helperText={t("preferences:hive.descriptionDescription")}
                        id="MaRucheDescription"
                        fullWidth
                        value={values.MaRucheDescription}
                        className={classes.field}
                        onChange={handleChange}
                    />
                    <TextField
                        label={t("preferences:hive.urlTitle")}
                        helperText={t("preferences:hive.urlDescription")}
                        id="MaRucheUrl"
                        fullWidth
                        value={values.MaRucheUrl}
                        className={classes.field}
                        onChange={handleChange}
                    />
                    <TextField
                        label={t("preferences:hive.languageTitle")}
                        id="Langue"
                        select
                        fullWidth
                        value={values.Langue}
                        className={classes.field}
                        onChange={handleLanguage}
                    >
                        <MenuItem value={0}>{t("common:french")}</MenuItem>
                        <MenuItem value={1}>{t("common:english")}</MenuItem>
                    </TextField>
                    <TextField
                        label={t("preferences:hive.unlistedTitle")}
                        id="Visibility"
                        select
                        fullWidth
                        value={values.visibility}
                        className={classes.field}
                        onChange={handleVisibility}
                    >
                        <MenuItem value="PUBLIC">{t("preferences:hive.listed")}</MenuItem>
                        <MenuItem value="UNLISTED">{t("preferences:hive.unlisted")}</MenuItem>
                    </TextField>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSave}
                        disabled={loading || !changes}
                    >
                        {t("common:save")}
                        {loading && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                    </Button>
                </CardContent>
            </Card>

            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {t("preferences:qr.title")}
                <EmojiNatureIcon />
            </Typography>
            <Card className={classes.preferencesCard}>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                        {t("preferences:qr.description")}
                    </Typography>
                    <div ref={qrRef} className={classes.qrCode} onClick={handleDownloadQR} />
                    {isStaff && (
                        <TextField
                            label="Set a custom utm_source value"
                            helperText="If you're tracking multiple QR codes across a property, differentiate between them here: 'frontlobby', 'elevator', 'bathroomstall', etc. This field is visible to Alvéole staff only."
                            id="qrSource"
                            fullWidth
                            value={qrSource}
                            className={classes.field}
                            onChange={(event) => setQrSource(event.target.value)}
                        />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

PreferencesPrefs.propTypes = {
    hive: PropTypes.object,
    isStaff: PropTypes.bool,
    sessionToken: PropTypes.string,
};

export default PreferencesPrefs;

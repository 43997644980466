import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    attribute: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        margin: "7px 0px",
    },
    label: {
        fontSize: "0.7em",
        padding: "2em 0 0em 0",
        verticalAlign: "bottom",
    },
    stat: {
        textAlign: "right",
        padding: "0.2em 0 0em 0",
        fontSize: "1.8em",
        fontWeight: 300,
        color: theme.palette.primary.dark,
    },
}));

const StatLabel = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.attribute}>
            <div className={classes.label}>{props.label}</div>
            <div className={classes.stat}>{props.stat || "-"}</div>
        </div>
    );
};

StatLabel.propTypes = {
    label: PropTypes.string,
    stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StatLabel;

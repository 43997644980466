import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const fetchWorkshopAllotments = async (clientId, sessionToken) => {
    const url = new URL(`${SERVER_URL}myhive/allotments/${clientId}`);
    const response = await fetch(url, {
        method: "post",
        body: JSON.stringify({
            sessionToken,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

export const placeWorkshop = async ({
    clientId,
    userId,
    selectedTime,
    formData,
    isVirtual,
    organisersEmail,
    materiel,
    sessionToken,
}) => {
    const url = new URL(`${SERVER_URL}agenda/workshop`);

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            clientId,
            userId,
            selectedTime,
            formData,
            isVirtual,
            organisersEmail,
            materiel,
            sessionToken,
        }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw responseObject;
    }
};

export const workshopTimeSlotsThisYear = async ({ clientId, workshopType }) => {
    const url = new URL(`${SERVER_URL}agenda/timeslots/${workshopType}/${clientId}/thisYear`);

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();
    if (response.ok) {
        return responseObject;
    } else {
        throw responseObject;
    }
};

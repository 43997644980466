import { useState, useEffect } from "react";
import { visitTypes } from "./seed";
import profilePlaceholder from "./img/profile-placeholder.jpeg";

export const urlRegEx = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/
);

export const isAppleMobile =
    navigator.platform.includes("iP")
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

export function preloadImage(src) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = src;
    });
}

/**
 * This function is used to parse unsafe urls
 * to avoid them being flagged as insecure.
 *
 * The function expects a url string ,and it returns a parsed url
 * if the url has .s3.amazonaws.com in it.The function retuns nothing
 * if the parameter given type is not a string and logs the error in the
 * console.
 *
 * @param {string} url
 * @returns url
 *
 */
export function useSafeAwsUrl(url) {
    if (typeof url !== "string") {
        return;
    }
    if (url.includes(".s3.amazonaws.com")) {
        return url.replace(".s3.amazonaws.com", "");
    } else {
        return url;
    }
}

/**
 * This function takes a string text containing all sorts of characters
 * and cleans it by replacing accented characters and turning all characters
 * to lowercase which helps in making comparisons.
 *
 * it returns a sanitized String when the parameter given is a string,
 * in other cases it return nothing and logs an error when there is type mismatch
 *
 * @param {string} text
 * @returns {string}
 */
export function sanitizeText(text) {
    if (typeof text !== "string") {
        return;
    }
    return text
        .toLowerCase()
        .replace(/[áàãâä]/gi, "a")
        .replace(/[éè¨ê]/gi, "e")
        .replace(/[íìïî]/gi, "i")
        .replace(/[óòöôõ]/gi, "o")
        .replace(/[úùüû]/gi, "u");
}

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export function getCroppedImage(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName;
                resolve(blob);
            },
            "image/jpeg",
            1
        );
    });
}

export function fuzzyRound(labelValue) {
    return Math.abs(Number(labelValue)) >= 1.0e9
        ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
        : Math.abs(Number(labelValue)) >= 1.0e6
          ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(0) + "M"
          : Math.abs(Number(labelValue)) >= 1.0e4
            ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(0) + "K"
            : Math.abs(Number(labelValue));
}

// hook to determine if an element (by ref) is displayed onscreen or not
// leverages IntersectionObserver, compatible w basically all browsers since 2018
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
export function useOnScreen(ref, rootMargin = "0px") {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
        rootMargin,
    });

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}

export const chunks = (arr, size) =>
    Array.from(new Array(Math.ceil(arr.length / size)), (_, i) =>
        arr.slice(i * size, i * size + size)
    );

export const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split("&").reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value;
              return params;
          }, {})
        : {};
};

export const getFriendlyHiveName = ({ MaRucheNom, Nom = "Client Name" }) => {
    const name = MaRucheNom ? MaRucheNom : Nom;
    return name;
};

export const getAvatarUrl = (client) => {
    if (client && client.MaRucheProfilePicture && client.MaRucheProfilePicture.url) {
        return useSafeAwsUrl(client.MaRucheProfilePicture.url);
    }
    return String(profilePlaceholder);
};

export const checkIsNorthAmerica = (client) => {
    return client.Pays === "USA" || client.Pays === "Canada" ? true : false;
};

export const selectSheetsForSheetCard = (sheets) =>
    sheets
        .filter((sheet) => sheet.RendezVousPointer && sheet.RendezVousPointer.VisitTypeId)
        .sort((a, b) => a.RendezVousPointer.Debut.iso < b.RendezVousPointer.Debut.iso)
        .filter((sheet) =>
            visitTypes
                .map((t) => t.rdvVisitTypeIds || [])
                .flat()
                .includes(sheet.RendezVousPointer.VisitTypeId)
        )
        .reduce((acc, sheet) => {
            const hasMoreRecentSheet = Object.entries(acc).find(([accRdvId, accSheets]) => {
                return accSheets.find((accSheet) => {
                    const differentRdv = accRdvId !== sheet.RendezVousPointer.objectId;
                    const sameYear =
                        new Date(accSheet.RendezVousPointer.Debut.iso).getFullYear()
                        === new Date(sheet.RendezVousPointer.Debut.iso).getFullYear();
                    const sameVisitType =
                        accSheet.RendezVousPointer.VisitTypeId
                        === sheet.RendezVousPointer.VisitTypeId;
                    return sameYear && sameVisitType && differentRdv;
                });
            });
            // only include the most recent sheet, this works cause these are already sorted.
            if (hasMoreRecentSheet) return acc;

            return {
                ...acc,
                [sheet.RendezVousPointer.objectId]: [
                    sheet,
                    ...(acc[sheet.RendezVousPointer.objectId] || []),
                ].sort((a, b) => a.noRuche - b.noRuche),
            };
        }, {});

export const makeId = (length) => {
    if (typeof length !== "number" || length < 1) return "";
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < Math.floor(length); i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const getIsSchoolOrBusinessType = (typeClient) => [3, 4].includes(typeClient);

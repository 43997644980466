import fetch from "node-fetch";
import { SERVER_URL } from "./server";

export const getIpLocation = async () => {
    const url = new URL(`${SERVER_URL}ipGeoLocation`);

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(responseObject.message);
    }
};

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import SendIcon from "@material-ui/icons/Send";
import Hidden from "@material-ui/core/Hidden";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LinkIcon from "@material-ui/icons/Link";
import MoreVertIcon from "@material-ui/icons//MoreVert";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";

import { useSnackbar } from "notistack";
import { invite, uninvite, resetPasswordLink, optIn, optOut } from "../networking/UserAccounts";
import { subscribe, unsubscribe } from "../networking/Client";
import ConfirmResetDialog from "../components/ConfirmResetDialog";

const useStyles = makeStyles((theme) => ({
    colleague: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(2),
        // borderTop: "1px solid #dedede",
        fontSize: 18,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    checkbox: {
        padding: 0,
    },
}));

const ColleagueRow = ({
    mainContact,
    relationship = {},
    editable = false,
    hiveId = "",
    fetchRelationships,
    sessionToken,
    visitRemindersSub,
    buzzNotificationsSub,
}) => {
    const { t, i18n } = useTranslation(["preferences", "common"]);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { currentUser } = useSelector((state) => state.user);
    const isStaff = (currentUser && currentUser.isStaff) || false;

    const [valid, setValid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [resetLoading, setResetLoading] = useState(false);
    const [emailOptLoading, setEmailOptLoading] = useState(false);
    const [buzzOptLoading, setBuzzOptLoading] = useState(false);
    const [resetDialogOpen, setResetDialogOpen] = useState(false);
    const [linkOpen, setLinkOpen] = useState(false);
    const [resetLink, setResetLink] = useState("");
    const [values, setValues] = useState({
        username: relationship.user ? relationship.user.username : "",
        firstName: relationship.user ? relationship.user.firstName : "",
        lastName: relationship.user ? relationship.user.lastName : "",
    });
    const [visitReminderChecked, setVisitReminderChecked] = useState(visitRemindersSub);
    const [buzzNotificationChecked, setBuzzNotificationChecked] = useState(buzzNotificationsSub);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const resetValues = () =>
        setValues({
            username: "",
            firstName: "",
            lastName: "",
        });

    const handleChange = (event) => {
        // TODO validate email
        const { id, value } = event.target;
        const { valid } = event.target.validity;

        setValues({
            ...values,
            [id]: value,
        });
        setValid(true);
        setEmailInvalid(!valid);
    };

    const handleInvite = async () => {
        if (!emailInvalid) {
            setAddLoading(true);

            const response = await invite({
                ...values,
                username: values.username.toLowerCase(),
                hiveId,
                sessionToken,
            });

            const { status, message } = response;

            if (status !== "OK") {
                enqueueSnackbar(`Error inviting ${values.username}: ${message}`, {
                    // TODO localize
                    variant: "error",
                });
            } else {
                if (editable) resetValues();

                enqueueSnackbar(
                    t("preferences:colleagueRow.inviteSuccess", { name: values.username }),
                    { variant: "success" }
                );
                fetchRelationships();
            }

            setAddLoading(false);
            handleMenuClose();
        }
    };

    const handleUninvite = async () => {
        setRemoveLoading(true);

        const { status } = await uninvite({
            username: values.username,
            hiveId,
            sessionToken,
        });

        if (status !== "OK") {
            enqueueSnackbar(t("preferences:colleagueRow.removeError", { name: values.username }), {
                variant: "error",
            });
        } else {
            if (editable) resetValues();

            enqueueSnackbar(
                t("preferences:colleagueRow.removeSuccess", { name: values.username }),
                {
                    variant: "success",
                }
            );
            fetchRelationships();
        }

        setRemoveLoading(false);
        handleMenuClose();
    };

    const handleResetClick = () => {
        setResetDialogOpen(true);
        handleMenuClose();
    };

    const handleConfirmReset = async () => {
        setResetLoading(true);
        const response = await resetPasswordLink({
            username: values.username.toLowerCase(),
            sessionToken,
        });
        const { status, message } = response;
        if (status !== "OK") {
            enqueueSnackbar(`Error generating reset link for ${values.username}: ${message}`, {
                // TODO localize
                variant: "error",
            });
        } else {
            setResetLink(response.data);
            setLinkOpen(true);
        }
        setResetLoading(false);
    };

    const handleCancelReset = () => {
        setResetDialogOpen(false);
        setResetLoading(false);
        setLinkOpen(false);
        setResetLink("");
    };

    const visitSubscribe = async (status) => {
        const opt = status ? optOut : optIn;
        const response = await opt({
            userId: relationship.user && relationship.user.objectId,
            hiveId,
            sessionToken,
        });
        return response;
    };

    const buzzSubscribe = async (status) => {
        const opt = status ? unsubscribe : subscribe;
        const response = await opt({
            email: values && values.username,
            hiveId,
            language: i18n.language,
        });
        return response;
    };

    const handleVisitReminderClick = async () => {
        setEmailOptLoading(true);

        const response = await visitSubscribe(visitReminderChecked);

        const { status } = response;

        if (status !== "OK") {
            enqueueSnackbar(
                visitReminderChecked
                    ? t("preferences:colleagueRow.visitOptOutError", { name: values.username })
                    : t("preferences:colleagueRow.visitOptInError", { name: values.username }),
                { variant: "error" }
            );
        } else {
            enqueueSnackbar(
                visitReminderChecked
                    ? t("preferences:colleagueRow.visitOptOutSuccess", { name: values.username })
                    : t("preferences:colleagueRow.visitOptInSuccess", { name: values.username }),
                { variant: "success" }
            );
            const opt = !visitReminderChecked;
            setVisitReminderChecked(opt);
        }

        setEmailOptLoading(false);
        handleMenuClose();
    };

    const handleBuzzNotificationClick = async () => {
        try {
            setBuzzOptLoading(true);
            await buzzSubscribe(buzzNotificationChecked);

            enqueueSnackbar(
                buzzNotificationChecked
                    ? t("preferences:colleagueRow.buzzOptOutSuccess", { name: values.username })
                    : t("preferences:colleagueRow.buzzOptInSuccess", { name: values.username }),
                { variant: "success" }
            );
            const opt = !buzzNotificationChecked;
            setBuzzNotificationChecked(opt);
            setBuzzOptLoading(false);
            handleMenuClose();
        } catch (error) {
            setBuzzOptLoading(false);
            enqueueSnackbar(
                buzzNotificationChecked
                    ? t("preferences:colleagueRow.buzzOptOutError", { name: values.username })
                    : t("preferences:colleagueRow.buzzOptInError", { name: values.username }),
                { variant: "error" }
            );
        }
    };

    return (
        <>
            {resetDialogOpen && (
                <ConfirmResetDialog
                    open={resetDialogOpen}
                    handleConfirmReset={handleConfirmReset}
                    handleCancelReset={handleCancelReset}
                    linkOpen={linkOpen}
                    resetLink={resetLink}
                    resetLoading={resetLoading}
                    username={values.username}
                />
            )}
            <Grid container direction="row" spacing={2} className={classes.colleague}>
                <Grid item xs={7} md={4}>
                    <TextField
                        label={t("common:email")}
                        value={values.username || ""}
                        onChange={handleChange}
                        id="username"
                        fullWidth
                        required
                        type="email"
                        disabled={!editable}
                        variant={editable ? "outlined" : "standard"}
                        error={emailInvalid}
                        helperText={emailInvalid && "This must be a valid email address."}
                    />
                </Grid>
                <Hidden smDown>
                    <Grid item xs={2}>
                        <TextField
                            label={t("common:firstName")}
                            value={values.firstName || ""}
                            onChange={handleChange}
                            id="firstName"
                            fullWidth
                            disabled={!editable}
                            variant={editable ? "outlined" : "standard"}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={t("common:lastName")}
                            value={values.lastName || ""}
                            onChange={handleChange}
                            id="lastName"
                            fullWidth
                            disabled={!editable}
                            variant={editable ? "outlined" : "standard"}
                        />
                    </Grid>
                </Hidden>
                <Grid item xs={5} md={4} style={{ textAlign: "end" }}>
                    {editable ? (
                        <ButtonGroup variant="text">
                            <Tooltip title="Invite to manage your hive">
                                <IconButton
                                    className={classes.sendButton}
                                    disabled={emailInvalid || !valid || addLoading}
                                    onClick={handleInvite}
                                >
                                    <PersonAddIcon />
                                    {addLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    ) : (
                        <div>
                            <IconButton
                                id="hive-management-menu-button"
                                aria-controls="hive-management-menu"
                                aria-haspopup="true"
                                aria-expanded={menuOpen ? "true" : undefined}
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="hive-management-menu"
                                aria-labelledby="hive-management-menu-button"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuItem
                                    onClick={handleUninvite}
                                    disabled={mainContact || removeLoading}
                                >
                                    <DeleteForeverIcon /> &nbsp;
                                    {t("preferences:colleagueRow.removeAuthorization")}
                                    {removeLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </MenuItem>
                                <MenuItem onClick={handleInvite} disabled={addLoading}>
                                    <SendIcon /> &nbsp;
                                    {t("preferences:colleagueRow.resendInviteEmail")}
                                    {addLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </MenuItem>
                                {isStaff && (
                                    <MenuItem onClick={handleResetClick}>
                                        <LinkIcon /> &nbsp;
                                        {t("preferences:colleagueRow.generatePasswordResetLink")}
                                    </MenuItem>
                                )}
                                <MenuItem>
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={visitReminderChecked}
                                        onChange={handleVisitReminderClick}
                                        inputProps={{ "aria-label": "controlled" }}
                                        disabled={emailOptLoading}
                                    />{" "}
                                    &nbsp;{t("preferences:colleagueRow.getVisitReminders")}
                                    {emailOptLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </MenuItem>
                                <MenuItem>
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={buzzNotificationChecked}
                                        onChange={handleBuzzNotificationClick}
                                        inputProps={{ "aria-label": "controlled" }}
                                        disabled={buzzOptLoading}
                                    />{" "}
                                    &nbsp;{t("preferences:colleagueRow.getBuzzNotifications")}
                                    {buzzOptLoading && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

ColleagueRow.propTypes = {
    mainContact: PropTypes.bool,
    relationship: PropTypes.object,
    editable: PropTypes.string,
    hiveId: PropTypes.string,
    fetchRelationships: PropTypes.func,
    sessionToken: PropTypes.string,
    visitRemindersSub: PropTypes.bool,
    buzzNotificationsSub: PropTypes.bool,
};

export default ColleagueRow;

import React, { useEffect } from "react";
import Card from "../Card";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import { checkIsNorthAmerica } from "../../util";

import Typography from "@material-ui/core/Typography";
import ActionableCardItem from "./ActionableCardItem";

const CtaCard = ({ clients, match }) => {
    const { t } = useTranslation(["cards"]);

    const clientUrl = match && match.params && match.params.clientUrl;
    const client = clients.find((client) => client.MaRucheUrl === clientUrl);
    const isNorthAmerica = checkIsNorthAmerica(client);

    //labels map to keys/objects in translation locale file
    const ctaLabels = ["requestproducts_shop", "keepLearning", "askAQuestion"];
    const ctaItems = ctaLabels.map((ctaLabel) => ({
        title: t(`ctaCard.${ctaLabel}.title`),
        description: t(`ctaCard.${ctaLabel}.description`),
        cta: t(`ctaCard.${ctaLabel}.cta`),
        label: ctaLabel,
        link: t(`ctaCard.${ctaLabel}.link`),
    }));

    // report that the cta card has been seen
    useEffect(
        () =>
            ReactGA.event({
                category: "ctacard",
                action: "impression",
            }),
        []
    );

    return (
        <Card title={t("ctaCard.title")}>
            {/* filter: do not show 'Shop' cta for clients outside of North America */}
            {ctaItems
                .filter((ctaItem) => isNorthAmerica || ctaItem.label !== "requestproducts_shop")
                .map((ctaItem, index) => {
                    return (
                        <ActionableCardItem
                            variant="secondary"
                            key={index}
                            GALabel={ctaItem.label}
                            {...ctaItem}
                        >
                            <Typography variant="subtitle3" color="textSecondary">
                                {ctaItem.description}
                            </Typography>
                        </ActionableCardItem>
                    );
                })}
        </Card>
    );
};

const mapStateToProps = ({ client }) => {
    const { clients } = client;
    return { clients };
};

CtaCard.propTypes = {
    clients: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.any,
};

export default connect(mapStateToProps, {})(withRouter(CtaCard));

import { NEXTGEN_API_URL } from "./server";

export type ApilabReport = {
  _id: string;
  sampledAt: string; // ISO 8601 date string
  result: {
    biodiversityScore: number; // Float between 0 and 1
    floralDiversity: ApilabFloralDiversityData[];
  };
};

export type ApilabFloralDiversityData = {
  commonName?: string;
  order: string;
  family: string;
  taxon: string;
  abundance: number;
  proportionalAbundance: number;
  plantType?: string;
  category?: string;
  iucn?: string;
  keystoneSpecies?: boolean;
  caterpillarSpeciesAssociated?: number;
  specialistBeeSpeciesAssociated?: number;
};

type ApilabResponseData = {
  success: boolean;
  apilabs: ApilabReport[];
};

export async function fetchApilabReports(clientId: string): Promise<ApilabReport[]> {
  const response = await fetch(`${NEXTGEN_API_URL}module-company/legacyClient/${clientId}/apilab`);
  const data: ApilabResponseData = await response.json();
  return data.success ? data.apilabs : [];
}

export const languages = [
  {
    id: "en",
    name: "English",
  },
  {
    id: "fr",
    name: "Français",
  },
];

export const convertI18nLanguages = (language: unknown): "en" | "fr" => {
  if (typeof language === "string" && language.includes("fr")) {
    return "fr";
  }
  return "en";
};

export const namespaces = ["common", "cards"];

export const tabs = [
  {
    id: "myhive",
    titleFr: "Ma ruche",
    titleEn: "My hive",
    urlFr: "/",
    urlEn: "/",
    icon: "tab_maruche.png",
    icon2x: "tab_maruche_2x.png",
  },
  {
    id: "visits",
    titleFr: "Visites",
    titleEn: "Visits",
    urlFr: "/visites",
    urlEn: "/visits",
    icon: "tab_visites.png",
    icon2x: "tab_visites_2x.png",
  },
  {
    id: "workshops",
    titleFr: "Ateliers",
    titleEn: "Workshops",
    urlFr: "/ateliers",
    urlEn: "/workshops",
    icon: "tab_ateliers.png",
    icon2x: "tab_ateliers_2x.png",
  },
  {
    id: "preferences",
    titleFr: "Préférences",
    titleEn: "Preferences",
    urlFr: "/preferences",
    urlEn: "/preferences",
    icon: "tab_compte.png",
    icon2x: "tab_compte_2x.png",
  },
  {
    id: "support",
    titleFr: "Soutien",
    titleEn: "Support",
    urlFr: "/soutien",
    urlEn: "/support",
    icon: "tab_soutien.png",
    icon2x: "tab_soutien_2x.png",
  },
];

export const visitTypesParis = [
  {
    id: "ouverture",
    category: "regular",
    rdvVisitTypeIds: ["reg_ouverture"],
    titleFr: "Préparation à la saison",
    titleEn: "Spring Opening",
    descriptionFr:
      "Afin d’anticiper la saison, l'apiculteur-trice prévoit de poser deux hausses au-dessus de chaque ruche. Il faudra attendre les premières floraisons et un développement de la colonie suffisant pour permettre aux abeilles d’utiliser ces espaces.",
    descriptionEn:
      "It’s time to unpack the hive, check that the colony made it through the winter and that it has enough space to grow. It’s also important to make sure the hive itself is in good shape and that the bees have enough resources to last until the first flowers come. The treatment and feeding are included with this visit.",
    durationFr: "30 minutes",
    durationEn: "30 minutes",
    imageUrl: "thumb_ouverture.jpg",
    image2xUrl: "thumb_ouverture_2x.jpg",
  },
  {
    id: "hausses",
    category: "regular",
    rdvVisitTypeIds: ["reg_hausses"],
    titleFr: "Visite de printemps",
    titleEn: "Spring Opening",
    descriptionFr:
      "C’est l’une des visites les plus excitantes de l’année. Après un long hiver, les abeilles s’activent à butiner le nectar et le pollen des premières fleurs. Un nettoyage de la ruche peut s’imposer afin de préparer au mieux les abeilles à la saison qui démarre. C’est le moment idéal pour chercher la reine, la marquer et observer la reprise de sa ponte.",
    descriptionEn: "",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_inspection-complete.jpg",
    image2xUrl: "thumb_inspection-complete_2x.jpg",
  },
  {
    id: "prevention-essaimage",
    category: "regular",
    rdvVisitTypeIds: ["reg_prevention"],
    titleFr: "Abeilles bourdonnantes!",
    titleEn: "Bzzy bees!",
    price: 130,
    descriptionFr:
      "Lorsque les fleurs éclosent, les colonies sont en pleine activité – et les abeilles bourdonnent! Grâce à l’abondance du pollen, la reine va commencer à pondre plus d’œufs. C’est à nous de nous assurer qu’elle a toute la place dont elle a besoin, et que tout va bien dans la ruche!",
    descriptionEn:
      "When flowers bloom, colonies boom - and the bees are bzzy! With the abundance of pollen, the queen will start to lay more eggs, and it's up to us to make sure she's got all the room she needs and that things are happy in the hive!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_prevention-essaimage.jpg",
    image2xUrl: "thumb_prevention-essaimage_2x.jpg",
  },
  {
    id: "trouver-la-reine",
    category: "regular",
    rdvVisitTypeIds: ["reg_nectar", "reg_preparation"],
    titleFr: "Une douce anticipation",
    titleEn: "Sweet Anticipation",
    descriptionFr:
      "C’est presque la période la plus douce de l’année! Et nous devons préparer la colonie pour la prochaine récolte de miel. Nous aiderons les abeilles à organiser la ruche pour stocker leurs cadres de miel complets dans les boîtes supérieures et nous nous assurerons que sa majesté reste dans ses quartiers royaux en bas!",
    descriptionEn:
      "It's nearly the sweetest time of year! And we've got to get the colony ready for the upcoming honey harvest. We'll be helping the bees organize the hive to store their finished honey frames in the upper boxes and make sure her majesty is sticking to her royal quarters down below!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_prevention-essaimage.jpg",
    image2xUrl: "thumb_prevention-essaimage_2x.jpg",
  },
  {
    id: "recolte",
    category: "regular",
    rdvVisitTypeIds: ["reg_recolte", "reg_recolte2"],
    titleFr: "C’est le temps de la récolte!",
    titleEn: "Harvest Time!",
    descriptionFr:
      "La plus belle visite de l’année! C’est enfin le temps de récolter le miel de la ruche de manière responsable. Les abeilles produisent plus de miel que ce que la colonie peut consommer en une saison, et c’est ce surplus que nous récolterons. Tout d’abord, nous ferons l’inventaire de tout le miel de la colonie et laisserons aux abeilles une grande quantité de leur or liquide durement mérité. Ensuite, nous retirerons délicatement les abeilles des cadres de miel excédentaires et transporterons ces cadres à la miellerie pour en extraire le miel!",
    descriptionEn:
      "The sweetest visit of the year! It's finally time to responsibly harvest honey from the hive. Honey bees will produce honey in excess of what the colony can consume in one season, and it's only from this surplus that we will harvest. First, we'll take stock of all the honey in the colony and leave the bees with plenty of their hard-earned liquid gold. Next, we'll gently remove the bees from the surplus honey frames and bring them back to the honeyhouse to be extracted!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_recolte.jpg",
    image2xUrl: "thumb_recolte_2x.jpg",
  },
  {
    id: "hivernisage",
    category: "regular",
    rdvVisitTypeIds: ["reg_hivernage", "reg_hivernage2"],
    titleFr: "Se mettre à l’aise",
    titleEn: "Getting Cozy",
    descriptionFr:
      "Même si les abeilles n’entrent pas en dormance comme d’autres créatures pendant les temps froids ou lorsque la nourriture est moins abondante, la colonie ralentit ses activités pendant la saison froide, en maintenant une plus petite population et en se concentrant surtout sur la régulation de la température à l’intérieur de la ruche. Lors de cette visite, nous aiderons la colonie à s’installer confortablement pour cette période moins active!",
    descriptionEn:
      "While honey bees don't go dormant like other creatures do during low-food periods or colder temperatures, the colony will slow down their operations during the chilly months, maintaining a smaller population and focusing mainly on regulating the temperature inside the hive. At this visit, we'll help the colony get cozy for this less-active period!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_hivernisage.jpg",
    image2xUrl: "thumb_hivernisage_2x.jpg",
  },
  {
    id: "nourrissage",
    category: "regular",
    rdvVisitTypeIds: ["reg_nourissage", "reg_nourissage2"],
    titleFr: "Une friandise sucrée",
    titleEn: "A Sweet Treat",
    descriptionFr:
      "Au cours de la saison apicole, les ressources en nectar et en pollen dans l’environnement de la colonie vont fluctuer naturellement. Parfois, les abeilles ont besoin d’un supplément sucré! Lors de cette visite, nous offrons aux abeilles un complément de nourriture sous la forme de cadres de miel supplémentaires ou de sirop de sucre enrichi.",
    descriptionEn:
      "During the beekeeping season, nectar and pollen resources in the colony's surroundings will naturally fluctuate. Sometimes, the bees need a sweet food boost! At this visit we'll offer the bees some additional food in the form extra honey frames or an enriched sugary-syrup.",
    durationFr: "30 minutes",
    durationEn: "30 minutes",
    imageUrl: "thumb_nourrissage.jpg",
    image2xUrl: "thumb_nourrissage_2x.jpg",
  },
  {
    id: "installation",
    category: "special",
    rdvVisitTypeIds: ["reg_installation"],
    titleFr: "Installation de la ruche",
    titleEn: "Hive Installation",
    descriptionFr:
      "Nous avons trouvé ensemble le meilleur endroit pour accueillir votre ruche, il est donc temps de l’installer. Après un vol d’orientation qui permet aux abeilles de découvrir leur nouvel environnement, elles sont prêtes à butiner!",
    descriptionEn:
      "In the first year of beekeeping, it’s essential to find an appropriate location for the colony, taking into consideration geography, traffic, sunshine, wind and water access. Then, the hive should be installed and given time to discover its new environment.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_installation.jpg",
    image2xUrl: "thumb_installation_2x.jpg",
  },
  {
    id: "essaimage",
    category: "special",
    rdvVisitTypeIds: ["spe_essaimage"],
    titleFr: "Essaimage",
    titleEn: "Swarming",
    descriptionFr:
      "Vous observez une nuée d’abeilles inhabituelle sortir de la ruche ou une grappe bourdonnante sur un arbre ? Pas de panique : votre colonie est en train d’essaimer ! Une partie des abeilles de la ruche sont en quête d’un nouvel habitat. Ce phénomène, bien qu’impressionnant, ne représente pas un danger. Pour en savoir plus, contactez votre apicuteur-trice.",
    descriptionEn:
      "Swarming is a phenomenon to avoid, since a natural division affects the growth of your colony as well as its honey production. In the case of a swarm in an urban area, one must act quickly to collect it safely.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_essaimage.jpg",
    image2xUrl: "thumb_essaimage_2x.jpg",
  },
];

export const visitTypes = [
  {
    id: "ouverture",
    category: "regular",
    rdvVisitTypeIds: ["reg_ouverture", "reg_check", "reg_check2"],
    titleFr: "Bonjour, les abeilles!",
    titleEn: "Hello, Bees!",
    price: 130,
    descriptionFr:
      "Hé, les abeilles! Ça fait un bail! C’est le moment que nos apiculteurs attendent depuis quelques mois! C’est aujourd’hui que nous allons voir comment la colonie s’est comportée pendant la basse saison, lorsqu’elle n’était pas dérangée. ",
    descriptionEn:
      "Hey, bees! It's been a while! This is the moment our beekeepers have spent the last few months waiting for! Today's the day we get to see how the colony has fared during the slow-season, when they couldn't be disturbed.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_ouverture.jpg",
    image2xUrl: "thumb_ouverture_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/effectuer-la-premiere-inspection-de-printemps/",
    imageLinkEnUrl: "https://www.alveole.buzz/perform-your-first-spring-inspection",
    // doesn't exist.
    // articleFrTitle: 'Aide-mémoire : calendrier de la saison apicole',
    // articleEnTitle: 'Memory Aid: The Beekeeper’s Seasonal Calendar',
    // articleFrUrl: 'https://www.alveole.buzz/apprendre/articles/calendrier-saison-apicole.html',
    // articleEnUrl: 'https://www.alveole.buzz/en/learn/articles/the-beekeepers-seasonal-calendar.html',
    materiel: [
      {
        titreFr: "10 cadres",
        titreEn: "10 frames",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/90MDe3p1ZF/90MDe3p1ZF_1_s.jpg",
        urlFr: "cadre-assemble-et-cire",
        urlEn: "frame-assembled-and-waxed",
      },
      {
        titreFr: "1 hausse",
        titreEn: "1 beebox",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/Y1wTWCBvvB/Y1wTWCBvvB_1_s.jpg",
        urlFr: "hausse",
        urlEn: "beebox",
      },
      {
        titreFr: "Réducteur d’entrée",
        titreEn: "Entrance reducer",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/5ob6u3IXmH/5ob6u3IXmH_1_s.jpg",
        urlFr: "reducteur-entree",
        urlEn: "entrance-reducer",
      },
      {
        titreFr: "Nourrisseur en bois",
        titreEn: "Wooden feeder",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/92HgpkDObM/92HgpkDObM_1_s.jpg",
        urlFr: "nourrisseur-bois-miller",
        urlEn: "miller-wooden-feeder",
      },
      {
        titreFr: "Sucre de nourrissage",
        titreEn: "Feeding sugar",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/MIIXMuypLE/MIIXMuypLE_1_s.jpg",
        urlFr: "sucre-pour-nourrissage",
        urlEn: "feeding-sugar",
      },
      {
        titreFr: "Honey-B-Healthy",
        titreEn: "Honey-B-Healthy",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/6UXMmG4rsq/6UXMmG4rsq_1_s.jpg",
        urlFr: "honeybhealthy",
        urlEn: "honeybhealthy",
      },
    ],
  },
  {
    id: "inspection-complete1",
    category: "regular",
    rdvVisitTypeIds: ["reg_inspection", "reg_inspection1", "reg_inspection2"],
    titleEn: "Back in Bzzness",
    titleFr: "Reprise des activités",
    price: 130,
    descriptionFr:
      "Alors que la nature s’éveille, c’est le moment de faire nos premières vérifications complètes des ruches! Nous allons examiner chaque cadre et évaluer ce dont la colonie a besoin pour bien démarrer la saison.",
    descriptionEn:
      "As the season starts to pick up, now is the moment for our first complete hive check-ups! We'll go through each frame and assess what the colony needs in order to get the season off to a great start.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_inspection-complete.jpg",
    image2xUrl: "thumb_inspection-complete_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/effectuer-une-inspection-ete/",
    imageLinkEnUrl: "https://www.alveole.buzz/perform-your-summer-inspection",
    materiel: [
      {
        titreFr: "Kit d’outils essentiels",
        titreEn: "Essential tool kit",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/QTGBacLRkO/QTGBacLRkO_1_s.jpg",
        urlFr: "kit-outils-essentiels",
        urlEn: "essential-tool-kit",
      },
      {
        titreFr: "Kit de protection",
        titreEn: "Protective kit",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/eNL42gBGav/eNL42gBGav_1_s.jpg",
        urlFr: "kit-de-protection",
        urlEn: "protective-kit",
      },
      {
        titreFr: "1 hausse",
        titreEn: "1 beebox",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/Y1wTWCBvvB/Y1wTWCBvvB_1_s.jpg",
        urlFr: "hausse",
        urlEn: "beebox",
      },
      {
        titreFr: "10 cadres",
        titreEn: "10 frames",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/90MDe3p1ZF/90MDe3p1ZF_1_s.jpg",
        urlFr: "cadre-assemble-et-cire",
        urlEn: "frame-assembled-and-waxed",
      },
    ],
  },
  {
    id: "division",
    category: "regular",
    rdvVisitTypeIds: ["reg_division", "reg_division_particulier", "reg_division_business"],
    titleFr: "Répandre l’amour",
    titleEn: "Spread the Love",
    price: 130,
    descriptionFr:
      "Il est temps de diviser la colonie et de répandre l’amour! À l’état sauvage, les abeilles domestiques ont l’instinct de diviser leur ruche en plusieurs colonies plus petites. Aujourd’hui, nous allons « diviser » la colonie pour elles et réfréner cet instinct.  Nous allons enlever quelques cadres de couvain qui deviendront de toutes nouvelles ruches installées ailleurs en ville!",
    descriptionEn:
      "It’s time to split the colony and spread the love! In the wild, honey bees have the natural instinct to divide their hive into multiple, smaller colonies. Today, we’re going to “split” the colony for them and curb that instinct.  We’ll be taking away a few frames of brood - which will become brand new hives to be installed elsewhere in the city!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_division.jpg",
    image2xUrl: "thumb_division_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/diviser-la-ruche/",
    imageLinkEnUrl: "https://www.alveole.buzz/divide-the-hive/",
    materiel: [
      {
        titreFr: "Kit de printemps",
        titreEn: "Spring kit",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/993PZ8scya/993PZ8scya_1_s.jpg",
        urlFr: "kit-printemps",
        urlEn: "spring-kit",
      },
      {
        titreEn: "Queen",
        titreFr: "Reine",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/Np62QjAf9U/Np62QjAf9U_1_xl.jpg",
        urlEn: "queen",
        urlFr: "reine",
      },
    ],
  },
  {
    id: "installation",
    category: "regular",
    rdvVisitTypeIds: ["reg_installation"],
    titleFr: "La douceur de la ruche",
    titleEn: "Home Sweet Home",
    price: 130,
    descriptionFr:
      "Bienvenue à la maison, abeilles à miel! Aujourd’hui, c’est un nouveau départ! Nous installerons les abeilles dans leur toute nouvelle maison et nous les regarderons faire des vols de reconnaissance pour se familiariser avec leur nouvel environnement.  Pour l’instant, la colonie ne compte que quelques milliers d’abeilles, mais leur nombre va augmenter rapidement alors qu’elles butinent le nectar et le pollen du voisinage!",
    descriptionEn:
      "Welcome home, honey bees! Today's the day it all begins! We'll get the bees settled in their brand new home and watch as they perform orientation flights, slowly learning their new surroundings.  Right now the colony holds a small population of a mere few thousand bees, but they will begin to grow bigger in no time as they forage the nectar and pollen in the neighbourhood!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_installation.jpg",
    image2xUrl: "thumb_installation_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/choisir-endroit-parfait-pour-une-ruche/",
    imageLinkEnUrl: "https://www.alveole.buzz/choose-a-perfect-spot-for-your-hive/",
    materiel: [
      {
        titreFr: "Nucléi d’abeilles",
        titreEn: "Nucleus colonies",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/s4OUbRr2Ry/s4OUbRr2Ry_1_s.jpg",
        urlFr: "nuclei-dabeilles",
        urlEn: "nucleus-colonies",
      },
      {
        titreFr: "Kit de printemps",
        titreEn: "Spring kit",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/993PZ8scya/993PZ8scya_1_s.jpg",
        urlFr: "kit-printemps",
        urlEn: "spring-kit",
      },
      {
        titreFr: "Kit d’outils essentiels",
        titreEn: "Essential tool kit",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/QTGBacLRkO/QTGBacLRkO_1_s.jpg",
        urlFr: "kit-outils-essentiels",
        urlEn: "essential-tool-kit",
      },
      {
        titreFr: "Kit de protection",
        titreEn: "Protective kit",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/eNL42gBGav/eNL42gBGav_1_s.jpg",
        urlFr: "kit-de-protection",
        urlEn: "protective-kit",
      },
    ],
  },
  {
    id: "summer",
    category: "regular",
    rdvVisitTypeIds: ["reg_summer"],
    titleFr: `Aider la ruche a prospérer`,
    titleEn: "Helping the Hive Thrive",
    price: 130,
    descriptionFr:
      "Comment ça va, les abeilles? La saison bat son plein, et il est temps d’aider la ruche à prospérer! Cette visite a pour but de faciliter la croissance de la colonie en lui donnant plus d’espace pour construire des rayons de miel, plus d’abeilles ou de la nourriture provenant d’une autre colonie.",
    descriptionEn:
      "How've things, bees? The season is in full swing, and it's time to help the hive thrive! This visit is all about facilitating the colony's growth - be it giving them more space to build honeycomb, or a boost of bees or food from another colony.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_inspection-complete.jpg",
    image2xUrl: "thumb_inspection-complete_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/effectuer-une-inspection-ete/",
    imageLinkEnUrl: "https://www.alveole.buzz/perform-your-summer-inspection",
    materiel: [],
  },
  {
    id: "prevention-essaimage",
    category: "regular",
    rdvVisitTypeIds: ["reg_prevention", "reg_equalizing1", "reg_equalizing2"],
    titleFr: "Abeilles bourdonnantes!",
    titleEn: "Bzzy Bees!",
    price: 130,
    descriptionFr:
      "Lorsque les fleurs éclosent, les colonies sont en pleine activité – et les abeilles bourdonnent! Grâce à l’abondance du pollen, la reine va commencer à pondre plus d’œufs. C’est à nous de nous assurer qu’elle a toute la place dont elle a besoin, et que tout va bien dans la ruche! ",
    descriptionEn:
      "When flowers bloom, colonies boom - and the bees are bzzy! With the abundance of pollen, the queen will start to lay more eggs, and it's up to us to make sure she's got all the room she needs, and that things are happy in the hive! ",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_prevention-essaimage.jpg",
    image2xUrl: "thumb_prevention-essaimage_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/controler-essaimage/",
    imageLinkEnUrl: "https://www.alveole.buzz/check-for-swarming/",
    // doesn't exist.
    // articleFrTitle: '6 règles pour éviter l’essaimage d’une colonie d’abeilles',
    // articleEnTitle: '6 rules to prevent your beehive from swarming',
    // articleFrUrl: 'https://www.alveole.buzz/apprendre/articles/6-regles-eviter-essaimage.html',
    // articleEnUrl: 'https://www.alveole.buzz/en/learn/articles/6-rules-prevent-swarming.html',
    materiel: [
      {
        titreFr: "1 hausse",
        titreEn: "1 beebox",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/Y1wTWCBvvB/Y1wTWCBvvB_1_s.jpg",
        urlFr: "hausse",
        urlEn: "beebox",
      },
      {
        titreFr: "10 cadres",
        titreEn: "10 frames",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/90MDe3p1ZF/90MDe3p1ZF_1_s.jpg",
        urlFr: "cadre-assemble-et-cire",
        urlEn: "frame-assembled-and-waxed",
      },
      {
        titreFr: "Base d’aération à lattes",
        titreEn: "Slatted rack",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/o44G4HuQVB/o44G4HuQVB_1_s.jpg",
        urlFr: "base-daeration-a-lattes",
        urlEn: "slatted-rack",
      },
    ],
  },
  {
    id: "trouver-la-reine",
    category: "regular",
    rdvVisitTypeIds: ["reg_spotting"],
    titleFr: "Trouver la reine",
    titleEn: "Spot the Queen",
    price: 130,
    descriptionFr:
      "Cette visite a pour but de voir sa majesté la reine. Elle est parfois timide ou elle ne daigne pas se montrer. Cette tâche apicole se compare à chercher une aiguille dans une botte de foin! Une fois que nous l’avons trouvée, elle passera le reste de la saison dans ses quartiers royaux (la boîte du bas) où ses œufs ne seront pas mélangés aux cadres de miel de l’étage.",
    descriptionEn:
      "Whether she's shy or likes to show off, this visit is focused on laying eyes on her majesty, the queen. This beekeeping task can be a little like finding a needle in a haystack! Once we've found her, she'll spend the rest of the season in her royal quarters (the bottom box) where her eggs won't get mixed in with the honey frames upstairs.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_prevention-essaimage.jpg",
    image2xUrl: "thumb_prevention-essaimage_2x.jpg",
    // imageLinkFrUrl: 'https://www.alveole.buzz/fr/controler-essaimage/',
    // imageLinkEnUrl: 'https://www.alveole.buzz/check-for-swarming/',
    // articleFrTitle: '6 règles pour éviter l’essaimage d’une colonie d’abeilles',
    // articleEnTitle: '6 rules to prevent your beehive from swarming',
    // articleFrUrl: 'https://www.alveole.buzz/apprendre/articles/6-regles-eviter-essaimage.html',
    // articleEnUrl: 'https://www.alveole.buzz/en/learn/articles/6-rules-prevent-swarming.html',
    materiel: [],
  },
  {
    id: "preparation-recolte",
    category: "regular",
    rdvVisitTypeIds: ["reg_preparation"],
    titleFr: "Une douce anticipation",
    titleEn: "Sweet Anticipation",
    price: 130,
    descriptionFr:
      "C’est presque la période la plus douce de l’année! Et nous devons préparer la colonie pour la prochaine récolte de miel. Nous aiderons les abeilles à organiser la ruche pour stocker leurs cadres de miel complets dans les boîtes supérieures et nous nous assurerons que sa majesté reste dans ses quartiers royaux en bas!",
    descriptionEn:
      "It's nearly the sweetest time of year! And we've got to get the colony ready for the upcoming honey harvest. We'll be helping the bees organize the hive to store their finished honey frames in the upper boxes and make sure her majesty is sticking to her royal quarters down below!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_preparation-recolte.jpg",
    image2xUrl: "thumb_preparation-recolte_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/installer-une-grille-a-reine/",
    imageLinkEnUrl: "https://www.alveole.buzz/install-the-queen-excluder/",
    // articleFrTitle: 'L’ajout d’une 3e hausse : une étape cruciale',
    // articleEnTitle: 'Adding a third box: an important step',
    // articleFrUrl: 'https://www.alveole.buzz/apprendre/articles/ajout-3e-hausse.html',
    // articleEnUrl: 'https://www.alveole.buzz/en/learn/articles/adding-third-box.html',
    materiel: [
      {
        titreEn: "Gloves for varroa treatment",
        titreFr: "Gants pour traitement du varroa",
        urlEn: "gloves-for-varroa-treatment",
        urlFr: "gants-pour-traitement-du-varroa",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/uHKqHzYCZj/uHKqHzYCZj_1_xl_2x.jpg",
      },
      {
        titreFr: "10 cadres",
        titreEn: "10 frames",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/90MDe3p1ZF/90MDe3p1ZF_1_s.jpg",
        urlFr: "cadre-assemble-et-cire",
        urlEn: "frame-assembled-and-waxed",
      },
      {
        titreFr: "1 hausse",
        titreEn: "1 beebox",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/Y1wTWCBvvB/Y1wTWCBvvB_1_s.jpg",
        urlFr: "hausse",
        urlEn: "beebox",
      },
      {
        titreFr: "Grille à reine",
        titreEn: "Queen excluder",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/3yOg9ep5NV/3yOg9ep5NV_1_xl_2x.jpg",
        urlFr: "grille-a-reine-metal",
        urlEn: "metal-queen-excluder",
      },
    ],
  },
  // {
  //     id: 'inspection-complete2',
  //     category: 'regular',
  //     rdvVisitTypeIds: ['reg_inspection2'],
  //     titleFr: 'Inspection complète',
  //     titleEn: 'Complete Inspection',
  //     price: 130,
  //     descriptionFr: 'La colonie grandit rapidement à ce moment-ci de l’année. Il est donc essentiel d’inspecter la ruche en profondeur pour s’assurer que la reine a assez de place pour pondre, qu’il y a bel équilibre dans les ressources et que la colonie n’a pas l’intention d’essaimer.',
  //     descriptionEn: 'Around this time of year, the colony is growing rapidly. The hive must be fully inspected to ensure that the queen has enough space to lay eggs, that the resources are well distributed and that the colony doesn’t intend to swarm.',
  //     durationFr: '1 heure',
  //     durationEn: '1 hour',
  //     imageUrl: 'thumb_inspection-complete.jpg',
  //     image2xUrl: 'thumb_inspection-complete_2x.jpg',
  //     imageLinkFrUrl: 'https://www.alveole.buzz/apprendre/videos/effectuer-linspection-dete.html',
  //     imageLinkEnUrl: 'https://www.alveole.buzz/en/learn/videos/perform-your-summer-inspection.html',
  // articleFrTitle: 'Fiche d’inspection : une étape incontournable',
  // articleEnTitle: 'The inspection sheet: an essential tool',
  //     articleFrUrl: 'https://www.alveole.buzz/apprendre/articles/fiche-inspection-etape-incontournable.html',
  //     articleEnUrl: 'https://www.alveole.buzz/en/learn/articles/inspection-sheet-essential-tool.html',
  //     materiel: [
  //         {
  //             titreFr: 'Kit d’outils essentiels',
  //             titreEn: 'Essential tool kit',
  //             imageUrl: 'https://www.alveole.buzz/boutique/produit/img/QTGBacLRkO/QTGBacLRkO_1_s.jpg',
  //             urlFr: 'kit-outils-essentiels',
  //             urlEn: 'essential-tool-kit'
  //         },
  //         {
  //             titreFr: 'Kit de protection',
  //             titreEn: 'Protective kit',
  //             imageUrl: 'https://www.alveole.buzz/boutique/produit/img/eNL42gBGav/eNL42gBGav_1_s.jpg',
  //             urlFr: 'kit-de-protection',
  //             urlEn: 'protective-kit'
  //         },
  //         {
  //             titreFr: '1 hausse',
  //             titreEn: '1 beebox',
  //             imageUrl: 'https://www.alveole.buzz/boutique/produit/img/Y1wTWCBvvB/Y1wTWCBvvB_1_s.jpg',
  //             urlFr: 'hausse',
  //             urlEn: 'beebox'
  //         },
  //         {
  //             titreFr: '10 cadres',
  //             titreEn: '10 frames',
  //             imageUrl: 'https://www.alveole.buzz/boutique/produit/img/90MDe3p1ZF/90MDe3p1ZF_1_s.jpg',
  //             urlFr: 'cadre-assemble-et-cire',
  //             urlEn: 'frame-assembled-and-waxed'
  //         }
  //     ]
  // },
  {
    id: "recolte",
    category: "regular",
    rdvVisitTypeIds: ["reg_recolte", "reg_recolte2"],
    titleFr: "C’est le temps de la récolte!",
    titleEn: "Harvest Time!",
    price: 200,
    descriptionFr:
      "La plus belle visite de l’année! C’est enfin le temps de récolter le miel de la ruche de manière responsable. Les abeilles produisent plus de miel que ce que la colonie peut consommer en une saison, et c’est ce surplus que nous récolterons. Tout d’abord, nous ferons l’inventaire de tout le miel de la colonie et laisserons aux abeilles une grande quantité de leur or liquide durement mérité. Ensuite, nous retirerons délicatement les abeilles des cadres de miel excédentaires et transporterons ces cadres à la miellerie pour en extraire le miel!",
    descriptionEn:
      "The sweetest visit of the year! It's finally time to responsibly harvest honey from the hive. Honey bees will produce honey in excess of what the colony can consume in one season, and it's only from this surplus that we will harvest. First, we'll take stock of all the honey in the colony and leave the bees with plenty of their hard-earned liquid gold. Next, we'll gently remove the bees from the surplus honey frames and bring them back to the honeyhouse to be extracted!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_recolte.jpg",
    image2xUrl: "thumb_recolte_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/recolter-le-miel/",
    imageLinkEnUrl: "https://www.alveole.buzz/harvest-the-honey/",
    materiel: [
      {
        titreFr: "Balai à abeilles",
        titreEn: "Bee brush",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/fhzSdt4ozj/fhzSdt4ozj_1_s.jpg",
        urlFr: "brosse-abeilles",
        urlEn: "bee-brush",
      },
      {
        titreFr: "Chasse-abeille",
        titreEn: "Bee escape board",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/tmCU1DkLEn/tmCU1DkLEn_1_s.jpg",
        urlFr: "chasse-abeille",
        urlEn: "bee-escape-board",
      },
      {
        titreFr: "MAQS (inclus avec la visite)",
        titreEn: "MAQS (included with visit)",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/swNRenNkLP/swNRenNkLP_1_s.jpg",
        urlFr: "mite-away",
        urlEn: "mite-away",
      },
    ],
  },
  {
    id: "extraction",
    category: "regular",
    rdvVisitTypeIds: [],
    includedForClientTypes: [1, 2, 3, 4],
    titleFr: "Extraction professionnelle du miel",
    titleEn: "Professional Honey Extraction",
    descriptionFr:
      "Une fois la récolte des hausses et des cadres de miel terminée, l’heure est venue d’extraire le précieux nectar emmagasiné dans les alvéoles. Le processus est simple, mais requiert tout de même quelques éléments de matériel spécialisé: bac et fourchettes à désoperculer, extracteur à centrifuge, filtres, chaudières de maturation et pots. Vous pouvez le faire chez vous sans problème, mais réservez cette visite si vous préférez laisser notre équipe s’occuper du processus d’extraction dans notre miellerie certifiée.",
    descriptionEn:
      "Once the boxes and frames have been collected, the time has come for the extraction of the precious nectar stored in the honeycombs. The process is simple, but requires some specialized equipment: uncapping forks and tank, buckets with honey valves, filters, honey tank and jars. You can do it at home, but you can also book a professional honey extraction if you prefer to have our team take care of the extraction in our certified honey house.",
    durationFr: null,
    durationEn: null,
    imageUrl: "thumb_extraction.jpg",
    image2xUrl: "thumb_extraction_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/extraire-le-miel/",
    imageLinkEnUrl: "https://www.alveole.buzz/extract-your-honey/",
    materiel: [
      {
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/JakkK0WgG4/JakkK0WgG4_1_xl.jpg",
        titreEn: "Pail with honey valve",
        urlEn: "bin-gate-lid",
        titreFr: "Chaudière à miel avec valve",
        urlFr: "sceau-robinet-couvercle",
      },
      {
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/KEtLMGcYa0/KEtLMGcYa0_1_xl.jpg",
        urlEn: "honey-glass-pots",
        titreEn: "Jars",
        titreFr: "Pots en verre",
        urlFr: "pots-verre-miel",
      },
    ],
  },
  {
    id: "nourrissage",
    category: "regular",
    rdvVisitTypeIds: ["reg_nourissage", "reg_nourissage2", "reg_nourissage3"],
    titleFr: "Une friandise sucrée",
    titleEn: "A Sweet Treat",
    price: 130,
    descriptionFr:
      "Au cours de la saison apicole, les ressources en nectar et en pollen dans l’environnement de la colonie vont fluctuer naturellement. Parfois, les abeilles ont besoin d’un supplément sucré! Lors de cette visite, nous offrons aux abeilles un complément de nourriture sous la forme de cadres de miel supplémentaires ou de sirop de sucre enrichi.",
    descriptionEn:
      "During the beekeeping season, nectar and pollen resources in the colony's surroundings will naturally fluctuate. Sometimes, the bees need a sweet food boost! At this visit we'll offer the bees some additional food in the form extra honey frames or an enriched sugary-syrup.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_nourrissage.jpg",
    image2xUrl: "thumb_nourrissage_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/nourrir-la-colonie/",
    imageLinkEnUrl: "https://www.alveole.buzz/feed-the-colony/",
    // articleFrTitle: 'Nourrir les abeilles',
    // articleEnTitle: 'Feed the bees',
    // articleFrUrl: 'https://www.alveole.buzz/apprendre/articles/nourrir-les-abeilles.html',
    // articleEnUrl: 'https://www.alveole.buzz/en/learn/articles/feed-the-bees.html',
    materiel: [
      {
        titreFr: "Nourrisseur en bois",
        titreEn: "Wooden feeder",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/92HgpkDObM/92HgpkDObM_1_s.jpg",
        urlFr: "nourrisseur-bois-miller",
        urlEn: "miller-wooden-feeder",
      },
      {
        titreFr: "Sucre de nourrissage",
        titreEn: "Feeding sugar",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/MIIXMuypLE/MIIXMuypLE_1_s.jpg",
        urlFr: "sucre-pour-nourrissage",
        urlEn: "feeding-sugar",
      },
      {
        titreFr: "Honey-B-Healthy",
        titreEn: "Honey-B-Healthy",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/6UXMmG4rsq/6UXMmG4rsq_1_s.jpg",
        urlFr: "honeybhealthy",
        urlEn: "honeybhealthy",
      },
      {
        titreFr: "Réducteur d’entrée",
        titreEn: "Entrance reducer",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/5ob6u3IXmH/5ob6u3IXmH_1_s.jpg",
        urlFr: "reducteur-entree",
        urlEn: "entrance-reducer",
      },
    ],
  },
  {
    id: "hivernisage",
    category: "regular",
    rdvVisitTypeIds: ["reg_hivernage", "reg_hivernage2"],
    titleFr: "Se mettre à l’aise",
    titleEn: "Getting Cozy",
    price: 130,
    descriptionFr:
      "Même si les abeilles n’entrent pas en dormance comme d’autres créatures pendant les temps froids ou lorsque la nourriture est moins abondante, la colonie ralentit ses activités pendant la saison froide, en maintenant une plus petite population et en se concentrant surtout sur la régulation de la température à l’intérieur de la ruche. Lors de cette visite, nous aiderons la colonie à s’installer confortablement pour cette période moins active!",
    descriptionEn:
      "While honey bees don't go dormant like other creatures do during low-food periods or colder temperatures, the colony will slow down their operations during the chilly months, maintaining a smaller population and focusing mainly on regulating the temperature inside the hive. At this visit, we'll help the colony get cozy for this less-active period!",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_hivernisage.jpg",
    image2xUrl: "thumb_hivernisage_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/hiverniser-la-ruche/",
    imageLinkEnUrl: "https://www.alveole.buzz/overwinter-your-hive",
    materiel: [
      {
        titreFr: "Isolant pour l’hiver (inclus avec la visite)",
        titreEn: "Winter insulation (included with visit)",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/jcyAzn9mtF/jcyAzn9mtF_1_s.jpg",
        urlFr: "thermofoil-isolant",
        urlEn: "thermofoil-insulation",
      },
    ],
  },
  {
    id: "weight-check",
    category: "regular",
    rdvVisitTypeIds: ["reg_weight"],
    titleFr: "Portez-vous bien, les abeilles!",
    titleEn: "Keep Well, Bees!",
    price: 0,
    descriptionFr:
      "Alors que la colonie s’installe confortablement, il est temps de faire une dernière évaluation de ses réserves en nourriture. Lors de cette visite, nous pèserons la ruche, afin de déterminer si elle a suffisamment de réserves de nourriture pour la période où le nectar sera peu abondant. Et nous souhaitons aux abeilles un bon temps d’arrêt, jusqu’à la reprise de contact au printemps!",
    descriptionEn:
      "With the colony getting cozy, it's now time for one last assessment of their food stores. At this visit we'll weigh the hive to determine whether they enough food reserves for the low-nectar period and wish the bees a happy downtime until we see them again in the spring!",
    durationFr: "",
    durationEn: "",
    imageUrl: "",
    image2xUrl: "",
    imageLinkFrUrl: "",
    imageLinkEnUrl: "",
    materiel: [],
  },
  {
    id: "oxalique",
    category: "regular",
    rdvVisitTypeIds: ["reg_oxalique"],
    titleFr: "Disparaissez, les mites!",
    titleEn: "Bee gone, mites!",
    price: 0,
    descriptionFr:
      "Les abeilles à miel deviennent souvent des hôtes involontaires de mites gênantes, surtout pendant la saison froide. Au cours de cette visite rapide et préventive, nous administrerons aux abeilles un traitement biologique qui leur permettra de maîtriser tout invité indésirable. Mites, disparaissez!",
    descriptionEn:
      "Honey bees often become unwilling hosts of troublesome mites, particularly during cold months. At this quick, preventative visit, we'll give the bees an organic treatment to help keep any uninvited guests under control. Mites, bee gone!",
    durationFr: "",
    durationEn: "",
    imageUrl: "",
    image2xUrl: "",
    imageLinkFrUrl: "",
    imageLinkEnUrl: "",
    materiel: [],
  },
  {
    id: "essaimage",
    category: "special",
    rdvVisitTypeIds: ["spe_essaimage"],
    titleFr: "Essaimage",
    titleEn: "Swarming",
    price: 130,
    descriptionFr:
      "L’essaimage est un phénomène à éviter, puisque la croissance de votre colonie et sa production de miel sont affectées par la division naturelle de l’essaim. Toutefois, lorsque la gestion de l’essaimage a été insuffisante, il faut réagir rapidement pour récupérer l’essaim de manière sécuritaire, surtout en milieu urbain.",
    descriptionEn:
      "Swarming is a phenomenon to avoid, since a natural division affects the growth of your colony as well as its honey production. In the case of a swarm in an urban area, one must act quickly to collect it safely.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_essaimage.jpg",
    image2xUrl: "thumb_essaimage_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/capturer-un-esssaim/",
    imageLinkEnUrl: "https://www.alveole.buzz/catching-a-swarm/",
    // articleFrTitle: 'Quoi faire après un essaimage?',
    // articleEnTitle: 'What to do after a swarm?',
    // articleFrUrl: 'https://www.alveole.buzz/apprendre/articles/quoi-faire-apres-essaimage.html',
    // articleEnUrl: 'https://www.alveole.buzz/en/learn/articles/what-to-do-after-swarm.html',
    materiel: [
      {
        titreFr: "1 hausse",
        titreEn: "1 beebox",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/Y1wTWCBvvB/Y1wTWCBvvB_1_s.jpg",
        urlFr: "hausse",
        urlEn: "beebox",
      },
      {
        titreFr: "10 cadres",
        titreEn: "10 frames",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/90MDe3p1ZF/90MDe3p1ZF_1_s.jpg",
        urlFr: "cadre-assemble-et-cire",
        urlEn: "frame-assembled-and-waxed",
      },
      {
        titreFr: "Base d’aération à lattes",
        titreEn: "Slatted rack",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/o44G4HuQVB/o44G4HuQVB_1_s.jpg",
        urlFr: "base-daeration-a-lattes",
        urlEn: "slatted-rack",
      },
    ],
  },
  {
    id: "diagnostic-maladie",
    category: "special",
    rdvVisitTypeIds: ["spe_diagnostic"],
    titleFr: "Diagnostic de maladie",
    titleEn: "Disease Diagnosis",
    price: 130,
    descriptionFr:
      "Vous soupçonnez que votre ruche souffre du varroa, du couvain plâtré, du petit coléoptère de la ruche, de la fausse teigne, ou pire, de la loque? Agissez rapidement avec notre soutien pour éviter la perte de votre colonie, lorsque possible, ou la propagation de maladies graves.",
    descriptionEn:
      "Are you worried that your hive suffers from varroa, small hive beetles, wax moth, or worse, foulbrood? Act fast with our help to avoid, if possible, the loss of your colony or the spread of serious diseases.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_diagnostic-maladie.jpg",
    image2xUrl: "thumb_diagnostic-maladie_2x.jpg",
    imageLinkFrUrl: "https://www.alveole.buzz/fr/traiter-contre-le-varroa/",
    imageLinkEnUrl: "https://www.alveole.buzz/treat-for-varroa/",
    materiel: [
      {
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/swNRenNkLP/swNRenNkLP_1_xl.jpg",
        titreEn: "MAQS (included with visit)",
        urlEn: "mite-away",
        titreFr: "MAQS (inclus avec la visite)",
        urlFr: "mite-away",
      },
      {
        titreEn: "Gloves for varroa treatment",
        titreFr: "Gants pour traitement du varroa",
        urlEn: "gloves-for-varroa-treatment",
        urlFr: "gants-pour-traitement-du-varroa",
        imageUrl: "https://www.alveole.buzz/boutique/produit/img/uHKqHzYCZj/uHKqHzYCZj_1_xl_2x.jpg",
      },
    ],
  },
  {
    id: "deplacement-ruche",
    category: "special",
    rdvVisitTypeIds: ["spe_deplacement"],
    titleFr: "Déplacement de ruche",
    titleEn: "Moving the Hive",
    price: 130,
    descriptionFr:
      "Vous voulez déplacer vos abeilles pour une raison ou une autre? Notre équipe s’occupe de le faire de manière entièrement sécuritaire.",
    descriptionEn:
      "For one reason or another, you want to move your bees? Our team will arrange for it to be done safely.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_deplacement-ruche.jpg",
    image2xUrl: "thumb_deplacement-ruche_2x.jpg",
    materiel: [
      {
        titreFr: "Styromousse de transport",
        titreEn: "Transportation foam",
      },
      {
        titreFr: "Ruban adhésif extérieur",
        titreEn: "Outdoor tape",
      },
    ],
  },
  {
    id: "introduction-apiculture-urbaine",
    category: "workshop",
    rdvVisitTypeIds: ["ate_introduction"],
    inclusionId: "atelier-introduction-apiculture-urbaine",
    titleFr: "À la découverte du monde des abeilles",
    titleEn: "Discover the World of Bees",
    price: 400,
    descriptionFr: (
      <>
        Les abeilles sont les ouvrières derrière le tiers de la nourriture qui se retrouve dans notre assiette, comme
        les fruits, les légumes, le chocolat, les noix et le café. Dans cet atelier de style conférence, vous en
        apprendrez plus sur l’apiculture urbaine et la petite société qu’est une ruche, et vous suivrez l’histoire de la
        vie d’une abeille, du moment où elle émerge de son alvéole jusqu’à son tout dernier envol.
        <br />
        <br />
        <h3>Option virtuelle disponible </h3>
        Une période interactive de questions-réponses est prévue à la fin de l’atelier pour répondre à toutes vos
        questions sur le monde fascinant des abeilles!
      </>
    ),
    descriptionEn: (
      <>
        Bees are behind a third of all the food we eat, including fruit, veggies, chocolate, nuts, and coffee. In this
        immersive, conference-style online workshop, you’ll learn more about urban beekeeping and the microcosm that is
        the hive. We’ll follow a bee’s life’s journey from when she first emerges from her cell to her final flight.
      </>
    ),
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_introduction-apiculture-urbaine.jpg",
    image2xUrl: "thumb_introduction-apiculture-urbaine_2x.jpg",
    virtualInvitationLinkEn: {
      linkText: "Discover the World be Bees Virtual Workshop",
      url: "https://drive.google.com/file/d/1AT784EreajeRu9UEk8DB4ViBcPAR_NZe/view?usp=sharing",
    },
    virtualInvitationLinkFr: {
      linkText: "Atelier virtuel À la découverte du monde des abeilles",
      url: "https://drive.google.com/file/d/1-LGX6CV-XRzjJ9EtNVSSJe0hQNmBoZWJ/view?usp=sharing",
    },
    inPersonInvitationLinkEn: {
      linkText: "Discover the World be Bees In-Person Workshop",
      url: "https://drive.google.com/file/d/1i_LJbeIMn3ht0M09XyPlVJyka0NVETXt/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Atelier en personne À la découverte du monde des abeilles",
      url: "https://drive.google.com/file/d/1jTiZQrD6KFHVHzQro9XFOTs2b6pRxcGf/view?usp=sharing",
    },
    materiel: [
      {
        titreFr: "Écran ou Projecteur",
        titreEn: "Screen or Projector",
      },
      {
        titreFr: "Ordinateur",
        titreEn: "Computer",
      },
      {
        titreEn: "Internet connection",
        titreFr: "Connection internet",
      },
      {
        titreFr: "Sièges",
        titreEn: " Seating",
      },
      {
        titreFr: "Deux tables",
        titreEn: " Two tables",
      },
    ],
  },
  {
    id: "ouverture-ruche",
    category: "workshop",
    rdvVisitTypeIds: ["ate_ouverture"],
    inclusionId: "atelier-ouverture-ruche",
    titleFr: "Rencontrez vos abeilles",
    titleEn: "Meet Your Bees",
    price: 400,
    descriptionFr: (
      <>
        C’est le moment de devenir apiculteur ou apicultrice d’un jour à l’occasion de l’inspection d’une ruche! Venez
        rencontrer vos abeilles et de prendre une tonne de photos pour vous remémorer ce moment unique. Vous pourrez
        toucher, sentir et goûter les différents produits de la ruche, voir les ouvrières à l’oeuvre et rencontrer la
        seule et l’unique, Sa Majesté, la reine.
        <br />
        <br />
        <h3>Option virtuelle disponible </h3>
        {`Décrochez un peu de votre quotidien à la maison ou au bureau pour vous immerger dans l’univers des abeilles à miel (à travers votre écran d’ordinateur!) le temps d’une ouverture de ruche.`}
      </>
    ),
    descriptionEn: (
      <>
        How about experiencing a day in the life of a beekeeper by opening and inspecting a very real, very buzzing
        hive? It’s time to finally meet your bees and create unforgettable memories with a ton of photo opportunities.
      </>
    ),
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_ouverture-ruche.jpg",
    image2xUrl: "thumb_ouverture-ruche_2x.jpg",
    virtualInvitationLinkEn: {
      linkText: "Meet The Bees Virtual Workshop",
      url: "https://drive.google.com/file/d/1ctdMNbAxDr5qjotAYyvC1qpljhXr1S2A/view?usp=sharing",
    },
    virtualInvitationLinkFr: {
      linkText: "Atelier virtuel Rencontrez vos abeilles",
      url: "https://drive.google.com/file/d/1a7vk3RirHSJAaRygM2OHT-GDiq-N6VEY/view?usp=sharing",
    },
    inPersonInvitationLinkEn: {
      linkText: "Meet The Bees In-Person Workshop",
      url: "https://drive.google.com/file/d/1TADWoaQcy6nz19PUTTtv-LRFcT0WaHZw/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Atelier en personne Rencontrez vos abeilles",
      url: "https://drive.google.com/file/d/11427_eV8GE3CLZ4P6-atJiiwByyeOVYI/view?usp=sharing",
    },
    materiel: [
      {
        titreFr: "Minimum une ruche",
        titreEn: "At least one hive",
      },
    ],
  },
  {
    id: "extraction-miel",
    category: "workshop",
    rdvVisitTypeIds: ["ate_extraction"],
    inclusionId: "atelier-extraction-miel",
    titleFr: "De la ruche à la récolte",
    titleEn: "From Hive to Honey Jar",
    price: 400,
    descriptionFr: (
      <>
        C’est l’heure de récolter le précieux miel de vos abeilles à l’occasion d’une séance d’extraction artisanale.
        Pendant cet atelier ludique, tout le monde aura la chance de participer au processus d’extraction, que ce soit
        en enlevant la cire qui recouvre les alvéoles, en faisant tourner les rayons dans une centrifugeuse ou en
        filtrant et en empotant le miel brut.
        <br />
        <br />
        <h3>Option virtuelle disponible </h3>
        {`Retrouvez votre apiculteur ou votre apicultrice pour «baigner» (façon de parler!) dans le miel pendant une bonne heure. Vous aurez droit à une visite exclusive de la miellerie Alvéole pour mieux comprendre le processus d’extraction artisanal et en apprendre plus sur les particularités fascinantes du miel urbain.`}
      </>
    ),
    descriptionEn: (
      <>
        Time to reap the fruit of our labour with this very hands-on honey extraction workshop. A beekeeper will assist
        you over the course of this fun-filled hour as you uncap honeycomb cells, hand-spin honeycombs in the
        centrifugal extractor, and filter the raw honey before jarring and labelling it all!
      </>
    ),
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_extraction-miel.jpg",
    image2xUrl: "thumb_extraction-miel_2x.jpg",
    virtualInvitationLinkEn: {
      linkText: "From Hive To Honey Jar Virtual Workshop",
      url: "https://drive.google.com/file/d/1a9Q3OoVDNRul-3ifpd-o1S3GtkMdRUib/view?usp=sharing",
    },
    virtualInvitationLinkFr: {
      linkText: "Atelier virtuel De la ruche à la récolte",
      url: "https://drive.google.com/file/d/1fo7Lf5mBzILNlm52cPBdupczPzsQ_obL/view?usp=sharing",
    },
    inPersonInvitationLinkEn: {
      linkText: "From Hive To Honey Jar In-Person Workshop",
      url: "https://drive.google.com/file/d/1ClmAao8Aek7xe5FU-vCMhX-odqiSDKI5/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Atelier en personne De la ruche à la récolte",
      url: "https://drive.google.com/file/d/1OIolfG9N3qZFMN2VsESj1bGYXdnPe6qq/view?usp=sharing",
    },
    materiel: [
      {
        titreFr: "Table",
        titreEn: "Table",
      },
      {
        titreFr: "Accès à l'eau pour nettoyer",
        titreEn: "Access to water for cleaning",
      },
    ],
  },
  {
    id: "meet-your-beekeeper",
    category: "workshop",
    rdvVisitTypeIds: ["ate_beekeeper"],
    inclusionId: "atelier-meet-your-beekeeper",
    titleFr: "Rencontrez votre apiculteur ou apicultrice",
    titleEn: "Meet Your Beekeeper",
    price: 400,
    descriptionFr:
      "L’heure est venue de rencontrer la personne qui vous accompagnera tout au long de votre aventure en apiculture. Joignez-vous à l’apiculteur ou l’apicultrice qui s’occupera de vos abeilles à l’occasion d’un p’tite conversation ouverte et informelle sur une foule de sujets et d’enjeux reliés à votre projet de ruche. Apiculture urbaine, abeilles à miel, pollinisateurs, miel, produits de la ruche, matériel apicole, alouette: vous trouverez assurément lors de cette discussion la réponse à toutes vos questions.",
    descriptionEn:
      "Time to meet your guide on your beekeeping journey! Get acquainted with your dedicated, knowledgeable urban beekeeper for the season in an “Ask Me Anything” open conversation.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_beekeeper_2x.jpg",
    image2xUrl: "thumb_beekeeper_2x.jpg",
    virtualInvitationLinkEn: {
      linkText: "Meet Your Beekeeper Virtual Workshop",
      url: "https://drive.google.com/file/d/1A71f9obB7OQj37vxIrOENdSEa2sHvOM3/view?usp=sharing",
    },
    virtualInvitationLinkFr: {
      linkText: "Atelier virtuel Rencontrez votre apiculteur ou apicultrice",
      url: "https://drive.google.com/file/d/1kxjopljA4fTlzCgImk3YjOLkSzj6cycx/view?usp=sharing",
    },
    inPersonInvitationLinkEn: {
      linkText: "Meet Your Beekeeper In-Person Workshop",
      url: "https://drive.google.com/file/d/1XyP4G4t-f1inf2Y77lKtjrXnmt2Sgqh8/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Atelier en person Rencontrez votre apiculteur ou apicultrice",
      url: "https://drive.google.com/file/d/1U8ua2ZO6wysj7gZVvFMqlLxRYjgTRUOY/view?usp=sharing",
    },
    materiel: [
      {
        titreFr: "Ordinateur",
        titreEn: "Computer",
      },
      {
        titreEn: "Internet connection",
        titreFr: "Connection internet",
      },
    ],
  },
  {
    id: "bee-the-movement",
    category: "workshop",
    rdvVisitTypeIds: ["ate_beethemovement"],
    inclusionId: "atelier-bee-the-movement",
    titleFr: "L'envol de l'agriculture urbaine",
    titleEn: "Bee the Movement",
    price: 400,
    descriptionFr:
      "Un atelier qui fera voyager les participants dans le vaste et inspirant mouvement de l'agriculture urbaine d'hier à aujourd'hui, allant de l'élevage de poules aux jardins sur les toits, en passant par l'agriculture verticale et leur rôle dans la création de liens en milieu urbain. Qu’on parle du succès sans précédent des jardins établis sur le gazon face à la rue lors de la 2e Guerre Mondiale, de la réappropriation de terrains vacants pour en faire des jardins communautaires, ou de la conversion de toits urbains en serres produisant des milliers de kilos de nourriture chaque année, le mouvement de l’agriculture urbaine véhicule une passion contagieuse pour la créativité lorsqu’il s’agit de réinventer nos villes. Suivez votre apiculteur dans l’exploration de ces moments clés dans le mouvement nord américain pour verdir nos villes et nous rapprocher de notre nourriture. Vous découvrirez toutes ces innovations contemporaines étonnantes, et plusieurs initiatives locales inspirantes. À la fin de cet atelier, les participants verront les espaces urbains d’un nouvel œil… Et ils auront probablement envie de faire pousser quelques tomates!",
    descriptionEn:
      "Chicken keeping, rooftop gardens, vertical farming and creating community - this workshop takes participants a few steps out of the beehive and into the broader and inspiring movement of urban agriculture. Whether it be the massive success of WW2 homefront victory gardens on front lawns, the reappropriation of downtown vacant lots to host community gardens, or the conversion of urban rooftops into greenhouses that produce tens of thousands of pounds of food a year, the urban agriculture movement holds a contagious passion for creativity when it comes to (re)imagining our cities. Follow your beekeeper as they walk you through pivotal moments of the North American movement for greener cities and a closer relationship to food, touching on exciting contemporary innovations and inspiring local initiatives. Participants are sure to leave with a fresh lens when it comes to urban space (and maybe even the desire to grow a tomato!).",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_introduction-apiculture-urbaine.jpg",
    image2xUrl: "thumb_introduction-apiculture-urbaine_2x.jpg",
    virtualInvitationLinkEn: {
      linkText: "Bee the Movement (virtual)",
      url: "https://drive.google.com/file/d/11rMU9VlUkFuUP8koV5Oe0wQF6Wb5ffst/view",
    },
    virtualInvitationLinkFr: {
      linkText: "Atelier virtuel L'envol de l'agriculture urbaine",
      url: "https://drive.google.com/file/d/1vYyn8CKSewFe8P68oWujquKrmXXqscGk/view?usp=sharing",
    },
    inPersonInvitationLinkEn: {
      linkText: "Bee the Movement (in-person)",
      url: "https://drive.google.com/file/d/1QNDKFieotSPkQlLdSwI5BDMPVRtNzvZ6/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Atelier en person L'envol de l'agriculture urbaine",
      url: "https://drive.google.com/file/d/1lcXRJFKXc5QG2TvirFzyEYzI0IzVMYtW/view?usp=sharing",
    },
    materiel: [
      {
        titreFr: "Écran ou Projecteur",
        titreEn: "Screen or Projector",
      },
      {
        titreFr: "Ordinateur",
        titreEn: "Computer",
      },
      {
        titreEn: "Internet connection",
        titreFr: "Connection internet",
      },
      {
        titreFr: "Sièges",
        titreEn: " Seating",
      },
      {
        titreFr: "Deux tables",
        titreEn: " Two tables",
      },
    ],
  },
  {
    id: "bee-there",
    category: "workshop",
    rdvVisitTypeIds: ["ate_beethere"],
    inclusionId: "atelier-bee-there",
    titleFr: "Ça pique la curiosité: quiz en équipe!",
    titleEn: "Bee There or Bee Square: Team Trivia!",
    price: 400,
    descriptionFr:
      "Vous êtes à la recherche d’une interaction virtuelle et dynamique pour votre communauté? Ça pique la curiosité est un jeu-questionnaire virtuel en équipe parfait pour tisser des liens entre les participants, les laissant avec un sourire aux lèvres et de nouvelles (et surprenantes!) connaissances à propos des abeilles à miel! Convenant tant aux néophytes qu’aux férus du monde des abeilles, il n’y a pas d’expérience de base requise pour participer à ce jeu-questionnaire - tous pourront y contribuer et avoir du plaisir! Tout ce que cet atelier requiert, c’est la soif d’apprendre (et un peu d’esprit compétitif - hum hum! pardon - d’équipe)! En utilisant la fonction “salles de discussion privée” de Zoom, Ça pique la curiosité recrée le petit côté dynamique des jeux-questionnaires en personne - les équipes sont envoyées dans leurs “salles” pour discuter à la fin de chaque tour. Amusant à la fois pour créer de nouvelles connexions et pour les équipes tissées serrées, les participants en ressortiront la tête pleine de nouveaux faits à partager autour d’un bon souper, mais aussi avec une connexion approfondie à leurs abeilles et collègues. Le contenu peut être modulé à votre groupe et touchera à plusieurs sujets et styles au fil des tours de questions.",
    descriptionEn:
      "Looking for a way for your community to interact in a dynamic way online? Bee There or Bee Square is a team-based trivia game that is sure to leave participants walking away with a smile, a sense of connection to the rest of the group, and some fun (and surprising) bee knowledge! Adaptable for beginners within the world of bees to those who wish to dive a little deeper, there is no baseline of ‘expertise’ required to participate, contribute and have fun in this workshop. All this workshop requires is an enthusiasm for learning (and a little competitive - ahem!, we mean - team spirit)! Utilizing the ‘breakout room’ feature on Zoom, Bee There or Bee Square recreates the dynamic of an in-person trivia game, with teams being sent to their own ‘rooms’ to discuss after each round. Fun for new acquaintances and close teams alike, this workshop is sure to leave folks with new facts to share around the dinner table as well as a sense of connection to their bees and each other. Content can be tailored to fit your group and will touch on a range of topics and styles of question rounds.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "thumb_ouverture-ruche.jpg",
    image2xUrl: "thumb_ouverture-ruche_2x.jpg",
    virtualInvitationLinkEn: {
      linkText: "Bee There or Bee Square: Team Trivia! (Virtual)",
      url: "https://drive.google.com/file/d/1N_W8aE6Vrh3vGJI7v4rV7MGChFvG3t31/view?usp=sharing",
    },
    virtualInvitationLinkFr: {
      linkText: "Atelier virtuel Ça pique la curiosité: quiz en équipe!",
      url: "https://drive.google.com/file/d/1TfulW_6JDJy9R8oSPq1tFOtEabFWJA6k/view?usp=sharing",
    },
    inPersonInvitationLinkEn: {
      linkText: "Bee There or Bee Square: Team Trivia! (In-person)",
      url: "https://drive.google.com/file/d/1lv1UnJoKhEh0kYZ9qQWcL8nI42_CaiGh/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Atelier en person Ça pique la curiosité: quiz en équipe!",
      url: "https://drive.google.com/file/d/17TtqcESbpumRotoEIYbMTcWlqZo5d1xq/view?usp=sharing",
    },
    materiel: [
      {
        titreFr: "Aucun",
        titreEn: "None",
      },
    ],
  },
  {
    id: "beeswax",
    category: "workshop",
    rdvVisitTypeIds: ["ate_beeswax"],
    inclusionId: "atelier-beeswax",
    titleFr: "Découvrir la cire d’abeille",
    titleEn: "The Wonders of Beeswax",
    price: 400,
    descriptionFr:
      "Les abeilles sont réputées pour leur miel sucré, mais ce n’est pas le seul produit de la ruche à découvrir! Dans cet atelier artisanal d’une heure, découvrez les propriétés de la cire d’abeille de manière pratique. En compagnie de votre apiculteur ou apicultrice, réalisez vous-même des bougies artisanales selon un savoir-faire centenaire, tout en explorant son histoire. Une expérience artisanale hors du commun!",
    descriptionEn:
      "Honey bees are famous for their sweet honey, but there are more products from the hive to discover! In this hour-long artisanal workshop we invite you to learn and experience the wonders of beeswax in a hands-on way.",
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "Wax-workshop-1.jpg",
    image2xUrl: "Wax-workshop-1.jpg",
    inPersonInvitationLinkEn: {
      linkText: "The Wonders of Beeswax In-Person Workshop",
      url: "https://drive.google.com/file/d/1l5v2GOm6cj7wy3HPqo3TBxUCD-JYzg-K/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Atelier en personne Découvrir la cire d’abeille",
      url: "https://drive.google.com/file/d/1OMfFwuINvAJgOgmjpXrJ0UIqsa1JwLqN/view?usp=sharing",
    },
    materiel: [
      {
        titreFr: "Deux tables",
        titreEn: " Two tables",
      },
      {
        titreFr: "Accès à l’électricité (avec des prises de 2 à 3 broches)",
        titreEn: "Access to power (minimum of two 3-prong outlets)",
      },
    ],
  },
  {
    id: "kiosque",
    category: "workshop",
    rdvVisitTypeIds: ["ate_kiosque"],
    inclusionId: "atelier-kiosque",
    titleFr: "Kiosque d'information",
    titleEn: "Information Kiosk",
    price: 400,
    descriptionFr: `Avez-vous entendu le buzz? C’est le début d’une belle collaboration avec Alvéole! À l’occasion de cet atelier informatif, votre apiculteur ou apicultrice Alvéole vient s’installer sur place pour présenter tous les aspects de votre projet avec les abeilles. Les gens sont invités à interagir avec le matériel essentiel en apiculture et à poser toutes leurs questions sur la saison à venir, en plus de goûter à une sélection raffinée de miel urbain.`,
    descriptionEn: `Have you heard the buzz? Alvéole’s teaming up with you! For this special activity, your dedicated beekeeper will set up a kiosk at your organization, equipped with an eye-catching visual set-up and some beekeeping tools to showcase the urban beekeeping experience.`,
    durationFr: "1 heure",
    durationEn: "1 hour",
    inPersonInvitationLinkEn: {
      linkText: "Information Kiosk: What’s The Buzz? In-Person Workshop",
      url: "https://drive.google.com/file/d/1qVXQlM1R04aQoCqbctvnnY7ke6JdggGx/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Kiosque d’information en personne C’est quoi le buzz?",
      url: "https://drive.google.com/file/d/1knV7JVMw4ul_wP7en-Hv2RhlpNvZHu_5/view?usp=sharing",
    },
    imageUrl: "thumb_kiosque.jpg",
    image2xUrl: "thumb_kiosque_2x.jpg",
    // articleFrTitle: '',
    // articleEnTitle: '',
    //  articleFrUrl: '',
    // articleEnUrl: '',
    materiel: [
      {
        titreFr: "Ordinateur",
        titreEn: "Table",
      },
      {
        titreFr: "Ordinateur",
        titreEn: "Laptop",
      },
      {
        titreFr: "Prise de courant",
        titreEn: "Electrical outlet",
      },
      {
        titreFr: "Écran et câble USB (facultatif)",
        titreEn: "Screen and USB cable (optional)",
      },
    ],
  },
  {
    id: "explore-wild-bees",
    category: "workshop",
    rdvVisitTypeIds: ["ate_explorewildbees"],
    inclusionId: "atelier-explore-wild-bees",
    titleFr: "Explorer le monde des abeilles sauvages",
    titleEn: "Explore the World of Wild Bees",
    price: 400,
    descriptionFr: `Saviez-vous que notre monde abrite plus de 20 000 espèces d'abeilles? Qu'elles soient minuscules, dodues, colorées ou duveteuses, les abeilles ont toutes une allure remarquablement diversifiée. Dans cet atelier, les gens seront invités à découvrir ces minuscules et adorables créatures avec qui nous partageons nos villes. À travers des récits magnifiques et imagés, votre apiculteur ou apicultrice vous fera découvrir l’univers de la pollinisation et vous présentera quelques-unes des surprises qui se cachent au coeur de votre maisonnette pour pollinisateurs BeeHome. Enfin, tout le monde sera invité à créer son propre mélange de semences mellifères, qui permettra de semer des ressources pour tous les pollinisateurs urbains. Ne manquez pas cette incursion inoubliable dans le monde des abeilles!`,
    descriptionEn: `Did you know that our world is home to over 20,000 species of bee? From tiny and colorful to big and fluffy, the world of bees is remarkably diverse. In this workshop, participants are invited to embark on a journey to discover these tiny, lovable creatures who share our cities. Through beautiful imagery and storytelling, your beekeeper will uncover the enchanting world of pollination and introduce participants to the wonders of your Wild Bee Home nesting habitat. To tie it all together, the group will learn how to create meaningful habitat for urban pollinators through a DIY seed mix activity, where participants will leave with their very own “bee mix”. You will not want to miss this unforgettable presentation!`,
    durationFr: "1 heure",
    durationEn: "1 hour",
    imageUrl: "ate_explorewildbees1.jpg",
    image2xUrl: "ate_explorewildbees1.jpg",
    virtualInvitationLinkEn: {
      linkText: "Explore the World of Wild Bees Virtual Workshop",
      url: "https://drive.google.com/file/d/131Rpfs4RMpc0TadveKRjHQ015G_398HR/view?usp=sharing",
    },
    virtualInvitationLinkFr: {
      linkText: "Atelier virtuel Explorer le monde des abeilles sauvages",
      url: "https://drive.google.com/file/d/1goaaNE3izySgw_u6rmEdrkqSSXaCE509/view?usp=sharing",
    },
    inPersonInvitationLinkEn: {
      linkText: "Explore the World of Wild Bees In-Person Workshop",
      url: "https://drive.google.com/file/d/1lbPotsc0mpYTlpNaBVTmF3hDc8v7hwKh/view?usp=sharing",
    },
    inPersonInvitationLinkFr: {
      linkText: "Atelier en personne Explorer le monde des abeilles sauvages",
      url: "https://drive.google.com/file/d/1eAPRftSp5Hst0flyPtZBngfrg_AvIPR1/view?usp=sharing",
    },
    materiel: [
      {
        titreFr: "Écran ou Projecteur",
        titreEn: "Screen or Projector",
      },
      {
        titreFr: "Ordinateur",
        titreEn: "Computer",
      },
      {
        titreEn: "Internet connection",
        titreFr: "Connection internet",
      },
      {
        titreFr: "Sièges",
        titreEn: " Seating",
      },
      {
        titreFr: "Deux tables",
        titreEn: " Two tables",
      },
    ],
  },
];

export const supportCategories = [
  {
    id: "urban-beekeeping",
    labelFr: "L’apiculture en milieu urbain",
    labelEn: "Urban Beekeeping",
  },
  {
    id: "bees",
    labelFr: "Les abeilles",
    labelEn: "Bees",
  },
  {
    id: "urban-honey",
    labelFr: "Le miel urbain",
    labelEn: "Urban Honey",
  },
  {
    id: "beekeeping-service",
    labelFr: "Forfaits d’accompagnement apicole",
    labelEn: "How Our Beekeeping Service Works",
  },
  {
    id: "home-packages",
    labelFr: "Forfaits à domicile",
    labelEn: "Home Packages",
  },
  {
    id: "school-packages",
    labelFr: "Forfaits pour les écoles",
    labelEn: "School Packages",
  },
  {
    id: "business-packages",
    labelFr: "Forfaits pour les entreprises et organisations",
    labelEn: "Business and Organization Packages",
  },
  {
    id: "municipal-packages",
    labelFr: "Forfaits pour les municipalités",
    labelEn: "Municipal Packages",
  },
  {
    id: "other-services",
    labelFr: "Autres services",
    labelEn: "Other services",
  },
  {
    id: "billing",
    labelFr: "Facturation",
    labelEn: "Billing",
  },
  {
    id: "online-store",
    labelFr: "Boutique en ligne",
    labelEn: "Online Store",
  },
  {
    id: "beekeeping",
    labelFr: "L’apiculture",
    labelEn: "Beekeeping",
  },
];

export const supportVideos = [
  {
    id: "video1",
    titleFr: "Diviser la ruche",
    titleEn: "Divide the hive",
    urlFr: "https://www.alveole.buzz/fr/diviser-la-ruche/",
    urlEn: "https://www.alveole.buzz/divide-the-hive/",
    imageUrl: "https://www.alveole.buzz/img/thumb20.jpg",
  },
  {
    id: "video2",
    titleFr: "Repérer les cellules royales",
    titleEn: "Identify queen cells",
    urlFr: "https://www.alveole.buzz/fr/reperer-les-cellules-royales/",
    urlEn: "https://www.alveole.buzz/identify-queen-cells/",
    imageUrl: "https://www.alveole.buzz/img/thumb8.jpg",
  },
  {
    id: "video3",
    titleFr: "Contrôler l’essaimage",
    titleEn: "Check for swarming",
    urlFr: "https://www.alveole.buzz/fr/controler-essaimage/",
    urlEn: "https://www.alveole.buzz/check-for-swarming/",
    imageUrl: "https://www.alveole.buzz/img/thumb12.jpg",
  },
];

export const accountFields = [
  {
    id: "name",
    labelFr: "Nom",
    labelEn: "Name",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "Nom",
    isEditable: false,
    formId: "account",
  },
  {
    id: "hiveName",
    labelFr: "MaRuche nom",
    labelEn: "MyHive name",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "MaRucheNom",
    isEditable: true,
    formId: "account",
  },
  {
    id: "email",
    labelFr: "Nom d'utilisateur",
    labelEn: "Username",
    isLabelFeminine: false,
    type: "string",
    parseClass: "User",
    parseColumn: "username",
    isEditable: false,
    validation: "email",
    formId: "account",
  },
  {
    id: "language",
    labelFr: "Langue de communication",
    labelEn: "Language of communication",
    isLabelFeminine: true,
    type: "dropdown",
    dropdownOptions: [
      {
        labelFr: "Français",
        labelEn: "French",
        value: 0,
      },
      {
        labelFr: "Anglais",
        labelEn: "English",
        value: 1,
      },
    ],
    parseClass: "Client",
    parseColumn: "Langue",
    isEditable: true,
    formId: "account",
  },
  {
    id: "marucheurl",
    labelFr: "MaRuche lien",
    labelEn: "MyHive link",
    isLabelFeminine: true,
    type: "string",
    parseClass: "Client",
    parseColumn: "MaRucheUrl",
    isEditable: true,
    formId: "account",
    validation: "marucheurl",
    title: {
      fr: "Doit comporter au moins quatre caractères et ne peut contenir que des lettres non accentués, des chiffres et des tirets (-).",
      en: "Must be at least four characters long, and may only contain non-accented letters, numbers and dashes (-).",
    },
  },
  {
    id: "password",
    labelFr: "Mot de passe",
    labelEn: "Password",
    isLabelFeminine: false,
    type: "password",
    parseClass: "User",
    parseColumn: "password",
    isEditable: true,
    validation: "password",
    formId: "account",
    title: {
      fr: "Doit avoir au moins cinq caractères.",
      en: "Must be atleast five characters long.",
    },
  },
  {
    id: "pc1-name",
    labelFr: "Nom",
    labelEn: "Name",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "PersonneContact1",
    isEditable: true,
    formId: "contact",
    sectionId: "pc1",
  },
  {
    id: "pc1-email",
    labelFr: "Courriel",
    labelEn: "Email",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "Email1",
    isEditable: true,
    validation: "email",
    formId: "contact",
    sectionId: "pc1",
  },
  {
    id: "pc1-phone1",
    labelFr: "Téléphone (résidence/bureau)",
    labelEn: "Phone (home/office)",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "Telephone1",
    isEditable: true,
    formId: "contact",
    sectionId: "pc1",
  },
  {
    id: "pc1-phone2",
    labelFr: "Téléphone (cellulaire)",
    labelEn: "Phone (cell)",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "Telephone2",
    isEditable: true,
    formId: "contact",
    sectionId: "pc1",
  },
  {
    id: "pc2-name",
    labelFr: "Nom",
    labelEn: "Name",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "PersonneContact2",
    isEditable: true,
    formId: "contact",
    sectionId: "pc2",
  },
  {
    id: "pc2-email",
    labelFr: "Courriel",
    labelEn: "Email",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "Email2",
    isEditable: true,
    validation: "email",
    formId: "contact",
    sectionId: "pc2",
  },
  {
    id: "pc2-phone",
    labelFr: "Téléphone",
    labelEn: "Phone",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "TelephonePersonneContact2",
    isEditable: true,
    formId: "contact",
    sectionId: "pc2",
  },
  {
    id: "pc3-name",
    labelFr: "Nom",
    labelEn: "Name",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "PersonneContact3",
    isEditable: true,
    formId: "contact",
    sectionId: "pc3",
  },
  {
    id: "pc3-email",
    labelFr: "Courriel",
    labelEn: "Email",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "Email3",
    isEditable: true,
    validation: "email",
    formId: "contact",
    sectionId: "pc3",
  },
  {
    id: "pc3-phone",
    labelFr: "Téléphone",
    labelEn: "Phone",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Client",
    parseColumn: "TelephonePersonneContact3",
    isEditable: true,
    formId: "contact",
    sectionId: "pc3",
  },
  {
    id: "typeClient",
    labelFr: "Type de service",
    labelEn: "Service type",
    isLabelFeminine: false,
    type: "dropdown",
    dropdownOptions: [
      {
        labelFr: "Aucun",
        labelEn: "None",
        value: 0,
      },
      {
        labelFr: "L’apprenti",
        labelEn: "New-Bee",
        value: 1,
      },
      {
        labelFr: "L’autonome",
        labelEn: "Worker-Bee",
        value: 2,
      },
      {
        labelFr: "Entreprise",
        labelEn: "Business",
        value: 3,
      },
      {
        labelFr: "École",
        labelEn: "School",
        value: 4,
      },
      {
        labelFr: "Organismes",
        labelEn: "Organization",
        value: 5,
      },
      {
        labelFr: "Production",
        labelEn: "Yard",
        value: 6,
      },
      {
        labelFr: "Le pro",
        labelEn: "Pro",
        value: 7,
      },
    ],
    parseClass: "Service",
    parseColumn: "typeClient",
    isEditable: false,
    formId: "service",
    sectionId: "subscription",
  },
  {
    id: "nbHives",
    labelFr: "Nombre de ruches",
    labelEn: "Number of hives",
    isLabelFeminine: false,
    // type: 'number', todo when editable
    type: "string",
    parseClass: "Service",
    parseColumn: "nbRuches",
    isEditable: false,
    formId: "service",
    sectionId: "subscription",
  },
  {
    id: "address",
    labelFr: "Adresse",
    labelEn: "Address",
    isLabelFeminine: true,
    type: "string",
    parseClass: "Service",
    parseColumn: "adresse",
    isEditable: false,
    formId: "service",
    sectionId: "address",
  },
  {
    id: "city",
    labelFr: "Ville",
    labelEn: "City",
    isLabelFeminine: true,
    type: "string",
    parseClass: "Service",
    parseColumn: "ville",
    isEditable: false,
    formId: "service",
    sectionId: "address",
  },
  {
    id: "province",
    labelFr: "Province",
    labelEn: "Province",
    isLabelFeminine: true,
    type: "string",
    parseClass: "Service",
    parseColumn: "province",
    isEditable: false,
    formId: "service",
    sectionId: "address",
  },
  {
    id: "postalCode",
    labelFr: "Code postal",
    labelEn: "Postal code",
    isLabelFeminine: false,
    type: "string",
    parseClass: "Service",
    parseColumn: "codepostal",
    isEditable: false,
    formId: "service",
    sectionId: "address",
  },
];

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { makeStyles } from "@material-ui/core/styles";
import {
    AppBar as MuiAppBar,
    Grid,
    Toolbar,
    Typography,
    IconButton,
    Collapse,
    Hidden,
    Fade,
    Link,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { fuzzyRound } from "../util";
import logoPng from "../img/Alveole_logo_400x400.png";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.neutral.lightgrey,
        position: "relative",
        zIndex: 100,
    },
    ctaBar: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: "0 auto",
    },
    ctaBarTransparent: {
        bottom: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        background: "linear-gradient(180deg, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0) 100%);",
    },
    ctaLogo: {
        border: 0,
        height: theme.spacing(6),
    },
    ctaLink: {
        color: theme.palette.primary.dark,
    },
    ctaMission: {
        fontSize: "14px",
        color: "#000",
    },
    ctaClose: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    ctaCloseIcon: {
        fontSize: "16px",
    },
    ctaAnalyticGrid: {
        borderRight: "1px solid #c0c0c0",
        color: "#000",
        textAlign: "center",
        "&:last-child": {
            borderRight: "none",
        },
    },
    ctaAnalyticValue: {
        color: theme.palette.primary.dark,
    },
    clientSearch: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
}));

const ctaAnalytics = ["cities", "hives", "bees", "people"];

const CtaAnalytic = ({ ctaKey, value = 0 }) => {
    const { t } = useTranslation();
    const renderValue = fuzzyRound(value);
    const classes = useStyles();

    // TODO localize
    return (
        <Grid item xs={3} className={classes.ctaAnalyticGrid}>
            <Typography variant="h5" className={classes.ctaAnalyticValue}>
                {renderValue}
            </Typography>
            <Typography variant="subtitle2">{t(`ctaBar.${ctaKey}`)}</Typography>
        </Grid>
    );
};

CtaAnalytic.propTypes = {
    ctaKey: PropTypes.string,
    value: PropTypes.number,
};

const CtaBar = ({ showClientSearch }) => {
    const { t } = useTranslation();
    const analytics = useSelector((state) => state.analytics);
    const history = useHistory();
    const isDeep = history.location.pathname.length > 1;

    const classes = useStyles();
    const [showCta, setShowCta] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowCta(true);

            ReactGA.event({
                category: "ctabar",
                action: "impression",
            });
        }, 2000);
    }, []);

    const engageCta = ({ label = "" }) => {
        ReactGA.event({
            category: "ctabar",
            action: "click",
            label,
        });
    };

    const closeCta = () => {
        ReactGA.event({
            category: "ctabar",
            action: "close",
        });

        setShowCta(false);
    };

    const handleLogoClick = () => {
        ReactGA.event({
            category: "ctabar",
            action: "click",
            label: "alveolelogo",
        });
    };

    const learnMore = "https://hubs.ly/H0W29yn0";

    return (
        <MuiAppBar className={classes.root} elevation={1}>
            <Collapse
                in={showCta}
                timeout={{
                    enter: 1000,
                    exit: 500,
                }}
            >
                <Toolbar className={classes.ctaBar}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3} sm={1}>
                            <Link
                                target="_blank"
                                href="https://hubs.ly/H0RH0V-0"
                                onClick={handleLogoClick}
                            >
                                <img alt="" src={logoPng} className={classes.ctaLogo} />
                            </Link>
                        </Grid>
                        <Grid item xs={9} sm={6}>
                            <Typography className={classes.ctaMission}>
                                {t("ctaBar.ctaString")}{" "}
                                <Link
                                    target="_blank"
                                    href={learnMore}
                                    onClick={() => engageCta({ label: "learnmore" })}
                                    className={classes.ctaLink}
                                >
                                    {t("ctaBar.ctaButton")}
                                </Link>
                                .
                            </Typography>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={5}>
                                <Fade
                                    in={(isDeep || showClientSearch) && analytics.cities > 0}
                                    timeout={500}
                                >
                                    <Grid container spacing={2} alignItems="center">
                                        {ctaAnalytics.map((ctaAnalytic, index) => (
                                            <CtaAnalytic
                                                key={index}
                                                ctaKey={ctaAnalytic}
                                                value={analytics[ctaAnalytic]}
                                            />
                                        ))}
                                    </Grid>
                                </Fade>
                            </Grid>
                        </Hidden>
                        <IconButton edge="start" className={classes.ctaClose} onClick={closeCta}>
                            <CloseIcon className={classes.ctaCloseIcon} />
                        </IconButton>
                    </Grid>
                </Toolbar>
            </Collapse>
        </MuiAppBar>
    );
};

CtaBar.propTypes = {
    showClientSearch: PropTypes.bool,
};

export default CtaBar;

import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const getVisitTypes = async () => {
    const url = new URL(`${SERVER_URL}agenda/visitTypes`);

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();
    if (response.status !== 200) throw new Error("error fetching visit types");

    return responseObject;
};

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fuzzyRound } from "../util";
import { useTranslation } from "react-i18next";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import HexSolidBeepants from "../img/hex-solid-beepants.png";
import HexSolidBeek from "../img/hex-solid-beek.png";
import HexSolidFrame from "../img/hex-solid-frame.png";

import { renderToStaticMarkup } from "react-dom/server";

const useStyles = makeStyles({
    hex: {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "clip",
        textAlign: "center",
    },
    hexValue: {
        fontSize: "28px",
        fontWeight: 700,
        display: "block",
    },
    hexText: {
        fontSize: "16px",
        display: "block",
    },
});

const HexSolid = ({ fillColor }) => {
    return (
        <svg
            width="183"
            height="208"
            viewBox="0 0 183 208"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M86.5178 1.86284C89.6028 0.0901839 93.3972 0.0901848 96.4822 1.86284L177.848 48.6167C180.952 50.4003 182.866 53.7072 182.866 57.2872V150.713C182.866 154.293 180.952 157.6 177.848 159.383L96.4822 206.137C93.3972 207.91 89.6028 207.91 86.5178 206.137L5.15213 159.383C2.04809 157.6 0.134323 154.293 0.134323 150.713V57.2872C0.134323 53.7072 2.04809 50.4003 5.15213 48.6167L86.5178 1.86284Z"
                fill={fillColor}
            />
        </svg>
    );
};

HexSolid.propTypes = {
    fillColor: PropTypes.string,
};

const HexHollow = ({ strokeColor }) => {
    return (
        <svg
            width="144"
            height="161"
            viewBox="0 0 144 161"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M67.5206 2.72334C70.2947 1.13148 73.7053 1.13148 76.4794 2.72334L137.493 37.735C140.29 39.3396 142.014 42.3173 142.014 45.5411V115.459C142.014 118.683 140.29 121.66 137.493 123.265L76.4794 158.277C73.7053 159.869 70.2947 159.869 67.5206 158.277L6.50654 123.265C3.71032 121.66 1.98592 118.683 1.98592 115.459V45.5411C1.98592 42.3173 3.71031 39.3396 6.50654 37.735L67.5206 2.72334Z"
                stroke={strokeColor}
                strokeWidth="2"
            />
        </svg>
    );
};

HexHollow.propTypes = {
    strokeColor: PropTypes.string,
};

const Hex = ({
    color = "#ffffff",
    analytic = "",
    Image = "",
    value = 0,
    zIndex = 0,
    height = 10,
    top = 0,
    left = 0,
}) => {
    const classes = useStyles();
    const renderValue = fuzzyRound(value);
    const { t } = useTranslation();

    let background = `url(${Image})`;
    if (typeof Image === "object") {
        background = `url(data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(Image))})`;
    }

    return (
        <div
            className={classes.hex}
            style={{
                backgroundImage: background,
                color,
                height: height + "vw",
                width: height * 0.88 + "vw",
                top: top + "vw",
                left: left + "vw",
                zIndex,
            }}
        >
            <Fade in={value} timeout={Math.random() * 3000}>
                <div>
                    <Typography
                        className={classes.hexValue}
                        style={{ color }}
                        variant="h5"
                        color="primary"
                    >
                        {renderValue}
                    </Typography>
                    <Typography className={classes.hexText} style={{ color }} variant="subtitle2">
                        {t(`ctaBar.${analytic}`)}
                    </Typography>
                </div>
            </Fade>
        </div>
    );
};

Hex.propTypes = {
    color: PropTypes.string,
    analytic: PropTypes.string,
    Image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.number,
    zIndex: PropTypes.number,
    height: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
};

const HexGrid = ({ analytics }) => {
    const theme = useTheme();

    return (
        <Grid
            item
            container
            md={6}
            xs={12}
            spacing={2}
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(0%,-50%) scale(0.7)",
                marginTop: 100,
            }}
        >
            <Grid item xs={3}>
                <Hex Image={HexSolidBeepants} height={15} top={3} left={3} zIndex={4} />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexSolid fillColor={theme.palette.primary.main} />}
                    color={theme.palette.common.white}
                    analytic="hives"
                    value={analytics.hives}
                    height={15}
                    top={2}
                    left={3}
                    zIndex={9}
                />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexHollow strokeColor={theme.palette.brand.darkred} />}
                    height={8}
                    top={1}
                    left={4}
                    zIndex={15}
                />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexSolid fillColor={theme.palette.primary.dark} />}
                    color={theme.palette.brand.darkred}
                    height={12}
                    top={3}
                    zIndex={12}
                    analytic="cities"
                    value={analytics.cities}
                />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexHollow strokeColor={theme.palette.brand.darkred} />}
                    height={12}
                    top={-2}
                    left={1}
                    zIndex={10}
                />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexSolid fillColor={theme.palette.neutral.grey} />}
                    color={theme.palette.neutral.charcoal}
                    analytic="bees"
                    value={analytics.bees}
                    height={15}
                    left={-1}
                    zIndex={10}
                />
            </Grid>
            <Grid item xs={3}>
                <Hex Image={HexSolidFrame} height={17} top={-5} left={1} zIndex={5} />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexHollow strokeColor={theme.palette.common.white} />}
                    height={12}
                    top={-5}
                    left={3}
                    zIndex={7}
                />
            </Grid>
            <Grid item xs={3}>
                <Hex Image={HexSolidBeek} height={12} top={-11} left={4} zIndex={5} />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexHollow strokeColor={theme.palette.primary.main} />}
                    height={11}
                    top={-11}
                    left={8}
                    zIndex={3}
                />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexSolid fillColor={theme.palette.secondary.main} />}
                    color={theme.palette.secondary.dark}
                    analytic="people"
                    value={analytics.people}
                    height={12}
                    top={-11}
                    left={9}
                    zIndex={8}
                />
            </Grid>
            <Grid item xs={3}>
                <Hex
                    Image={<HexHollow strokeColor={theme.palette.brand.darkred} />}
                    height={15}
                    top={-18}
                    left={7}
                    zIndex={8}
                />
            </Grid>
        </Grid>
    );
};

HexGrid.propTypes = {
    analytics: PropTypes.object,
};

const mapStateToProps = ({ analytics }) => {
    return { analytics };
};

export default connect(mapStateToProps)(HexGrid);

import {
  BeeHomeWidgetData,
  BeeHomeWidgetInspectionData,
  BeeHomeWidgetCappedTubeData,
} from "@alveolemtl/alveole-components";
import { BeeHomeData, BeeHomeDetails, BeeHomeInspectionData } from "../networking/beeHomes";

export function buildBeeHomeWidgetData(beeHome: BeeHomeData): BeeHomeWidgetData {
  const { id, location, inspectionData } = beeHome;
  return {
    id,
    location,
    inspectionData: inspectionData.map(buildBeeHomeWidgetInspectionData),
  };
}

function buildBeeHomeWidgetInspectionData(inspectionData: BeeHomeInspectionData): BeeHomeWidgetInspectionData {
  const { id, timestamp, details, beesFlightRangesMeters } = inspectionData;
  return {
    id,
    timestamp: new Date(timestamp),
    cappedTubes: details.map(buildBeeHomeWidgetCappedTubeData),
    beesFlightRangesMeters,
  };
}

function buildBeeHomeWidgetCappedTubeData(details: BeeHomeDetails): BeeHomeWidgetCappedTubeData {
  const { cappedTubes, bees } = details;
  const languageKey = window.isEnglish ? "en" : "fr";
  return {
    tubeLabel: cappedTubes.labels[languageKey],
    tubeAmount: cappedTubes.number,
    beesLabel: bees.labels[languageKey],
    beesAmount: bees.number,
  };
}

// TODO localize
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    media: {},
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    card: {
        display: "block",
        borderRadius: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
            marginRight: 0,
            borderRadius: "0px",
        },
    },
    text: {
        textAlign: "left",
        fontSize: "18px",
    },
    skeleton: {
        marginTop: -32,
        borderRadius: theme.spacing(2),
        height: 200,
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
}));

const TimelineEmptyCard = () => {
    const { t } = useTranslation(["buzz", "common"]);
    const classes = useStyles();

    return (
        <>
            <Card className={classes.card} elevation={0}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="author" className={classes.avatar}>
                            <EmojiNatureIcon />
                        </Avatar>
                    }
                    title={t("create")}
                    subheader={t("common:main.myHive")}
                />
                <CardContent>
                    <Typography variant="body2" component="p" className={classes.text}>
                        {t("empty")}
                    </Typography>
                </CardContent>
            </Card>
        </>
    );
};

export default TimelineEmptyCard;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ButtonBase from "@material-ui/core/ButtonBase";
import Slide from "@material-ui/core/Slide";

import { setSearches } from "../redux/searchClients";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getFriendlyHiveName } from "../util";

const useStyles = makeStyles((theme) => ({
    buttonbase: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 24,
    },
    card: {
        width: "100%",
        backgroundColor: "transparent",
        border: "1px dotted",
        borderColor: theme.palette.primary.dark,
        borderRadius: "24px",
        lineHeight: "1.5",
    },
    cardContent: {
        padding: theme.spacing(1),
        "&:last-child": {
            paddingBottom: theme.spacing(1),
        },
    },
    nom: {
        fontWeight: 500,
        fontSize: "12px",
        color: "#5a5a5a",
    },
    ville: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
        fontSize: "10px",
    },
}));

const RecentSearchCard = ({ history, setSearches, searchObj, slideIn, direction }) => {
    const classes = useStyles();

    const handleClick = (event, searchObj) => {
        const ruche = searchObj.MaRucheUrl;

        event.preventDefault();
        ReactGA.event({
            category: "landing_recent",
            action: "click",
            label: ruche,
        });

        history.push(`/${ruche}`);
        setSearches(searchObj);
    };

    return (
        <Slide direction={direction} in={slideIn}>
            <div>
                <ButtonBase
                    onClick={(event) => handleClick(event, searchObj)}
                    className={classes.buttonbase}
                >
                    <Card elevation={0} className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <Grid container spacing={0}>
                                <Grid item md={12} xs={12}>
                                    <Typography component="p" className={classes.nom}>
                                        {getFriendlyHiveName(searchObj)}
                                    </Typography>
                                    <Typography component="p" className={classes.ville}>
                                        {searchObj.ServicePointer.ville.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </ButtonBase>
            </div>
        </Slide>
    );
};

const mapStateToProps = ({ searchClient }) => {
    const { recentSearches } = searchClient;
    return { recentSearches };
};

RecentSearchCard.propTypes = {
    setSearches: PropTypes.func,
    searchObj: PropTypes.object,
    slideIn: PropTypes.bool,
    direction: PropTypes.string,
    history: PropTypes.object,
};

export default connect(mapStateToProps, { setSearches })(withRouter(RecentSearchCard));

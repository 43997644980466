import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import SwipeableViews from "react-swipeable-views";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import WhereWeBuzzTabView from "./WhereWeBuzzTabView";

import { listMostActive } from "../networking/Client";

const useStyles = makeStyles((theme) => ({
    content: {
        maxWidth: "1150px",
        margin: "0 auto",
        paddingLeft: 12,
        paddingRight: 12,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        fontWeight: 500,
        fontSize: "30px",
        marginTop: theme.spacing(6),
    },
    subtitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        color: "#5a5a5a",
        lineHeight: "1.5",
        fontSize: "14px",
    },
    tabIndicator: {
        background: theme.palette.primary.dark,
    },
}));

const WhereWeBuzz = ({ whereWeBuzz }) => {
    const classes = useStyles();
    const { t } = useTranslation(["common", "landingPage"]);

    const [tabValue, setTabValue] = useState(0);
    const [stepperIndex, setStepperIndex] = useState(0);
    const [cityIdHighlighted, setCityIdHighlighted] = useState(0); // Montreal
    const [mostActiveHives, setMostActiveHives] = useState([]);

    const handleTabChange = (_, newValue) => {
        setTabValue(newValue);
        setStepperIndex(0);

        switch (newValue) {
            case 0: //Canada
                setCityIdHighlighted(0); //Montreal
                break;
            case 1: //United States
                setCityIdHighlighted(7); //New York City
                break;
            case 2: //Europe
                setCityIdHighlighted(14); //Paris
                break;
            default:
                return;
        }
    };
    const handleTabIndexChange = (tabIndex) => {
        setTabValue(tabIndex);
    };

    useEffect(() => {
        listMostActive({ regionId: cityIdHighlighted }).then((hives) => setMostActiveHives(hives));
    }, [cityIdHighlighted]);

    return (
        <>
            <Grid container spacing={2} className={classes.content}>
                <Grid item>
                    <Typography variant="h2" color="primary" className={classes.title}>
                        {t("landingPage:whereWeBuzz.title")}
                    </Typography>
                    <Typography component="p" className={classes.subtitle}>
                        {t("landingPage:whereWeBuzz.description")}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.content}>
                <Grid item md={6} xs={12}>
                    <Tabs
                        TabIndicatorProps={{
                            className: classes.tabIndicator,
                        }}
                        variant="fullWidth"
                        value={tabValue}
                        onChange={handleTabChange}
                    >
                        {Object.entries(whereWeBuzz).map((greaterRegion, key) => (
                            <Tab key={key} label={t(`common:greaterRegion.${greaterRegion[0]}`)} />
                        ))}
                    </Tabs>
                </Grid>
                <Grid item xs={12} className={classes.content}>
                    <SwipeableViews
                        index={tabValue}
                        onChangeIndex={handleTabIndexChange}
                        containerStyle={{
                            transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
                        }}
                    >
                        {Object.entries(whereWeBuzz).map((greaterRegion, key) => (
                            <WhereWeBuzzTabView
                                key={key}
                                setStepperIndex={setStepperIndex}
                                stepperIndex={stepperIndex}
                                cities={greaterRegion[1]}
                                mostActiveHives={mostActiveHives}
                                cityIdHighlighted={cityIdHighlighted}
                                setCityIdHighlighted={setCityIdHighlighted}
                            />
                        ))}
                    </SwipeableViews>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = ({ analytics }) => {
    const { whereWeBuzz } = analytics;
    return { whereWeBuzz };
};

WhereWeBuzz.propTypes = {
    whereWeBuzz: PropTypes.object,
};

export default connect(mapStateToProps)(WhereWeBuzz);

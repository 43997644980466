import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Timeline from "../components/Timeline";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: { maxWidth: "100vw" },
    title: {
        marginTop: theme.spacing(4),
        fontSize: 14,
        textTransform: "uppercase",
    },
    field: {
        marginBottom: 12,
    },
    colleague: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(2),
        fontSize: 18,
    },
}));

const TimelineTab = ({
    currentHive,
    weather,
    HiveStats,
    clientBeekeeper,
    visits,
    inspections,
    beeHomeWidgetData,
    floralBiodiversityReports,
}) => {
    const classes = useStyles();
    const { t } = useTranslation(["common", "hiveTab"]);

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleTabIndexChange = (index) => {
        setTabValue(index);
    };

    return (
        <div className={classes.root}>
            <div className={classes.tabs}>
                <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    value={tabValue}
                    onChange={handleTabChange}
                >
                    <Tab label={t("hiveTab:timelineTab.timeline")} />
                    <Tab label={t("hiveTab:timelineTab.stats")} />
                </Tabs>
            </div>

            <SwipeableViews
                index={tabValue}
                onChangeIndex={handleTabIndexChange}
                containerStyle={{
                    transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
                }}
            >
                <Timeline inView={tabValue === 0} currentHive={currentHive} weather={weather} />
                <HiveStats
                    client={currentHive}
                    clientBeekeeper={clientBeekeeper}
                    visits={visits}
                    inspections={inspections}
                    beeHomeWidgetData={beeHomeWidgetData}
                    floralBiodiversityReports={floralBiodiversityReports}
                    weather={weather}
                />
            </SwipeableViews>
        </div>
    );
};

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;
    return { currentUser };
};

TimelineTab.propTypes = {
    currentHive: PropTypes.object,
    weather: PropTypes.object,
    HiveStats: PropTypes.func,
    clientBeekeeper: PropTypes.object,
    visits: PropTypes.arrayOf(PropTypes.object),
    inspections: PropTypes.arrayOf(PropTypes.object),
    beeHomeWidgetData: PropTypes.arrayOf(PropTypes.object),
    floralBiodiversityReports: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps, {})(TimelineTab);

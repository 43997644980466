import React from "react";
import ReactGA from "react-ga";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";

const EN_TERMS_URL = "https://www.alveole.buzz/terms-and-conditions/";
const EN_PRIVACY_URL = "https://www.alveole.buzz/privacy-policy/";
const FR_TERMS_URL = "https://www.alveole.buzz/fr/conditions-utilisation/";
const FR_PRIVACY_URL = "https://www.alveole.buzz/fr/politique-de-confidentialite/";

const useStyles = makeStyles((theme) => ({
    preFooter: {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "12vw",
    },
    footer: {
        backgroundColor: theme.palette.neutral.charcoal,
    },
    footerGrid: {
        maxWidth: "1150px",
        margin: "0 auto",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    copyright: {
        color: "#ffffff",
    },
    link: {
        color: "#EBCFB0",
    },
}));

const Footer = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const isEnglish = i18n.language.includes("en");
    // TODO consider formalizing some Analytics interactions and getting them
    // somewhere central
    const engageCta = ({ label = "" }) => {
        ReactGA.event({
            category: "footer",
            action: "click",
            label,
        });
    };

    const TermsLink = (
        <Link
            className={classes.link}
            target="_blank"
            href={isEnglish ? EN_TERMS_URL : FR_TERMS_URL}
            onClick={() => engageCta({ label: "terms" })}
        >
            {t("footer.terms")}
        </Link>
    );

    const PrivacyLink = (
        <Link
            className={classes.link}
            target="_blank"
            href={isEnglish ? EN_PRIVACY_URL : FR_PRIVACY_URL}
            onClick={() => engageCta({ label: "privacy" })}
        >
            {t("footer.privacy")}
        </Link>
    );

    return (
        <>
            <div className={classes.preFooter} />
            <div className={classes.footer}>
                <Grid container spacing={2} className={classes.footerGrid}>
                    <Grid item md={9}>
                        <Typography
                            component="span"
                            variant="subtitle2"
                            className={classes.copyright}
                        >
                            {`© 2021 Alvéole, Inc. ${t("footer.copyright")}`}
                        </Typography>
                        <Typography component="span" variant="subtitle2" className={classes.link}>
                            &nbsp;· {TermsLink} · {PrivacyLink}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Footer;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import commonEN from "../src/locales/en/common.json";
import commonFR from "../src/locales/fr/common.json";
import commonFRFR from "../src/locales/fr-FR/common.json";
import headerEN from "../src/locales/en/header.json";
import headerFR from "../src/locales/fr/header.json";
import cardsEN from "../src/locales/en/cards.json";
import cardsFR from "../src/locales/fr/cards.json";
import hiveTabEN from "../src/locales/en/hiveTab.json";
import hiveTabFR from "../src/locales/fr/hiveTab.json";
import workshopsEN from "../src/locales/en/workshops.json";
import workshopsFR from "../src/locales/fr/workshops.json";
import workshopsFRFR from "../src/locales/fr-FR/workshops.json";
import buzzEN from "../src/locales/en/buzz.json";
import buzzFR from "../src/locales/fr/buzz.json";
import labelsEN from "../src/locales/en/labels.json";
import labelsFR from "../src/locales/fr/labels.json";
import profileEN from "../src/locales/en/profile.json";
import profileFR from "../src/locales/fr/profile.json";
import preferencesEN from "../src/locales/en/preferences.json";
import preferencesFR from "../src/locales/fr/preferences.json";
import notificationsEN from "../src/locales/en/notifications.json";
import notificationsFR from "../src/locales/fr/notifications.json";
import citiesEN from "../src/locales/en/cities.json";
import citiesFR from "../src/locales/fr/cities.json";
import landingPageEN from "../src/locales/en/landingPage.json";
import landingPageFR from "../src/locales/fr/landingPage.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        common: commonEN,
        header: headerEN,
        cards: cardsEN,
        hiveTab: hiveTabEN,
        workshops: workshopsEN,
        buzz: buzzEN,
        labels: labelsEN,
        profile: profileEN,
        preferences: preferencesEN,
        cities: citiesEN,
        landingPage: landingPageEN,
        notifications: notificationsEN,
    },
    "en-CA": {
        common: commonEN,
        header: headerEN,
        cards: cardsEN,
        hiveTab: hiveTabEN,
        workshops: workshopsEN,
        buzz: buzzEN,
        labels: labelsEN,
        profile: profileEN,
        preferences: preferencesEN,
        cities: citiesEN,
        landingPage: landingPageEN,
        notifications: notificationsEN,
    },
    "en-US": {
        common: commonEN,
        header: headerEN,
        cards: cardsEN,
        hiveTab: hiveTabEN,
        workshops: workshopsEN,
        buzz: buzzEN,
        labels: labelsEN,
        profile: profileEN,
        preferences: preferencesEN,
        cities: citiesEN,
        landingPage: landingPageEN,
        notifications: notificationsEN,
    },
    fr: {
        common: commonFR,
        header: headerFR,
        cards: cardsFR,
        hiveTab: hiveTabFR,
        workshops: workshopsFR,
        buzz: buzzFR,
        labels: labelsFR,
        profile: profileFR,
        preferences: preferencesFR,
        cities: citiesFR,
        landingPage: landingPageFR,
        notifications: notificationsFR,
    },
    "fr-CA": {
        common: commonFR,
        header: headerFR,
        cards: cardsFR,
        hiveTab: hiveTabFR,
        workshops: workshopsFR,
        buzz: buzzFR,
        labels: labelsFR,
        profile: profileFR,
        preferences: preferencesFR,
        cities: citiesFR,
        landingPage: landingPageFR,
        notifications: notificationsFR,
    },
    "fr-FR": {
        common: commonFRFR,
        header: headerFR,
        cards: cardsFR,
        hiveTab: hiveTabFR,
        workshops: workshopsFRFR,
        buzz: buzzFR,
        labels: labelsFR,
        profile: profileFR,
        preferences: preferencesFR,
        cities: citiesFR,
        landingPage: landingPageFR,
        notifications: notificationsFR,
    },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        defaultNS: "common",
        fallbackLng: ["en"],
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

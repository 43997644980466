import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSafeAwsUrl } from "../util";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import DeleteIcon from "@material-ui/icons/Delete";
import CropIcon from "@material-ui/icons/Crop";
import IconButton from "@material-ui/core/IconButton";
import ExpandedImageModal from "./ExpandedImageModal";
import CropDialog from "./CropDialog";

const useStyles = makeStyles(() => ({
    closeButton: {
        position: "absolute",
        right: "-48px",
        top: "-12px",
        color: "white",
    },
    formattedImage: {
        cursor: "pointer",
        display: "block",
        height: "inherit",
        margin: 0,
        objectFit: "cover",
        objectPosition: "50% 50%",
        width: "100%",
    },
    formattedVideo: {
        backgroundColor: "black",
        display: "block",
        height: "319px",
        margin: 0,
        objectFit: "contain",
        width: "100%",
    },
    mediaContainer: {
        borderRadius: "1em",
        height: "319px",
        margin: "1em 0",
        overflow: "hidden",
        position: "relative",
    },
    cropIcon: {
        position: "absolute",
        top: 16,
        left: 16,
        backgroundColor: "#fff",
        padding: 6,
    },
    deleteIcon: {
        position: "absolute",
        top: 16,
        right: 16,
        backgroundColor: "#fff",
        padding: 6,
        minWidth: 24,
        zIndex: 1,
    },
    modalArrowLeft: {
        position: "absolute",
        left: "-48px",
        top: "50%",
        color: "white",
    },
    modalArrowRight: {
        position: "absolute",
        right: "-48px",
        top: "50%",
        color: "white",
    },
    modalContent: {
        outline: "none",
        maxHeight: "95vh",
        maxWidth: "80vw",
    },
    stepper: {
        backgroundColor: "inherit",
    },
}));

const TimelineMedia = ({ setImages, images = [], rawFiles = [] }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [expandedImage, setExpandedImage] = useState();
    const [gaAction, setGaAction] = useState("");
    const [cropOpen, setCropOpen] = useState(false);

    const maxSteps = images.length;
    const lastImage = currentIndex === maxSteps - 1;
    const firstImage = currentIndex === 0;
    const currentRawFile = rawFiles[currentIndex];
    const prevFilesRef = useRef();

    useEffect(() => {
        //changes stepper index to most recent photo on new file selection,
        //but does not update index to last if photo is removed
        if (prevFilesRef.current && rawFiles.length > prevFilesRef.current.length) {
            setCurrentIndex(rawFiles.length - 1);
        }
        prevFilesRef.current = rawFiles;
    }, [rawFiles]);

    useEffect(() => {
        ReactGA.event({
            category: "buzz_carousel",
            action: gaAction,
            label: maxSteps,
            value: currentIndex + 1,
        });
    }, [currentIndex, gaAction]);

    const handleCloseExpandedImage = () => {
        setExpandedImage();
    };

    const handleNext = () => {
        if (!lastImage) {
            setCurrentIndex((prevActiveStep) => prevActiveStep + 1);
            setGaAction("next");
        }
    };

    const handleBack = () => {
        if (!firstImage) {
            setCurrentIndex((prevActiveStep) => prevActiveStep - 1 || 0);
            setGaAction("previous");
        }
    };

    const handleArrowKeys = (e) => {
        if (e.key === "ArrowLeft") {
            handleBack();
        } else if (e.key === "ArrowRight") {
            handleNext();
        }
    };

    const handleCropSave = (croppedImage) => {
        setCropOpen(false);
        setImages((images) =>
            images.map((image, i) => (currentIndex === i ? croppedImage : image))
        );
    };

    const mediaType = ((images[currentIndex] && images[currentIndex].mimeType) || "").split("/")[0];
    const contentLink = images[currentIndex] && useSafeAwsUrl(images[currentIndex].file.url);

    const MediaContainer = (
        <div className={classes.mediaContainer}>
            {currentRawFile && (
                <>
                    {mediaType === "image" && (
                        <Button
                            variant="contained"
                            onClick={() => setCropOpen(true)}
                            className={classes.cropIcon}
                            aria-label="crop"
                        >
                            <CropIcon /> &nbsp; Crop
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            e.stopPropagation();
                            setImages((images) =>
                                images.filter((image, index) => index !== currentIndex)
                            );
                            handleBack();
                        }}
                        className={classes.deleteIcon}
                        aria-label="delete"
                    >
                        <DeleteIcon />
                    </Button>
                </>
            )}
            {mediaType === "video" ? (
                <video className={classes.formattedVideo} controls loop>
                    <source src={contentLink} />
                </video>
            ) : (
                <img
                    alt=""
                    loading="lazy"
                    className={classes.formattedImage}
                    src={contentLink}
                    onClick={() => setExpandedImage(images[currentIndex])}
                />
            )}
        </div>
    );

    const ModalContent =
        mediaType === "video" ? (
            <video className={classes.modalContent} controls loop>
                <source src={contentLink} />
            </video>
        ) : (
            <img alt="" loading="lazy" className={classes.modalContent} src={contentLink} />
        );

    return (
        <>
            {currentRawFile && mediaType === "image" && (
                <CropDialog
                    open={cropOpen}
                    handleClose={() => setCropOpen(false)}
                    handleSet={handleCropSave}
                    file={currentRawFile}
                />
            )}
            {images.length > 1 ? (
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={currentIndex}
                    className={classes.stepper}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={lastImage}>
                            {t("next")}
                            {theme.direction === "rtl" ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={firstImage}>
                            {theme.direction === "rtl" ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            {t("previous")}
                        </Button>
                    }
                />
            ) : null}

            {MediaContainer}
            <ExpandedImageModal
                isOpen={!!expandedImage}
                close={handleCloseExpandedImage}
                onKeyDown={handleArrowKeys}
            >
                <IconButton
                    aria-label="previous"
                    className={classes.modalArrowLeft}
                    onClick={handleBack}
                    disabled={firstImage}
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                    aria-label="next"
                    className={classes.modalArrowRight}
                    onClick={handleNext}
                    disabled={lastImage}
                >
                    <KeyboardArrowRight />
                </IconButton>
                {ModalContent}
            </ExpandedImageModal>
        </>
    );
};

TimelineMedia.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
    setImages: PropTypes.func,
    rawFiles: PropTypes.arrayOf(PropTypes.object),
};

export default TimelineMedia;

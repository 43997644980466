/* eslint-disable */
// TODO uploadLogoImage
// TODO setLabelPreference

import fetch from "node-fetch";
import { SERVER_URL } from "./server";

export const setJarringLabelPreference = async ({
    preferences,
    hiveId,
    jarringLabelApprovalTime,
    sessionToken,
}) => {
    const url = new URL(`${SERVER_URL}myhive/hive/${hiveId}/jarringLabelPreferences`);

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
            preferences,
            jarringLabelApprovalTime,
            sessionToken,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw responseObject;
    }
};

export const setPremiumLabelPreference = async ({
    preferences,
    hiveId,
    premiumApprovalTime,
    sessionToken,
}) => {
    const url = new URL(`${SERVER_URL}myhive/hive/${hiveId}/premiumLabelPreferences`);

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
            preferences,
            premiumApprovalTime,
            sessionToken,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw responseObject;
    }
};

export const uploadLogo = async ({ file, hiveId, sessionToken }) => {
    if (!file) throw new Error("UploadLogo needs a file ");
    if (!hiveId) throw new Error("UploadLogo needs a hiveId ");

    const url = new URL(`${SERVER_URL}myhive/hive/${hiveId}/logo`);

    const form = new FormData();
    form.append("logo", file);
    form.append("hiveId", hiveId);
    form.append("sessionToken", sessionToken);

    const response = await fetch(url, {
        method: "PATCH",
        body: form,
    });

    const responseObject = await response.json();

    if (response.ok) {
        return;
    } else {
        throw new Error(responseObject.message);
    }
};

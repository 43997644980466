import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const isAuthenticated = async ({ sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/user/authenticated`);

    const params = {
        sessionToken,
    };

    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject.data;
};

export const invite = async ({ username, firstName, lastName, hiveId, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/user/invite`);

    const params = {
        username,
        firstName,
        lastName,
        hiveId,
        sessionToken,
    };

    // url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject;
};

export const uninvite = async ({ username, hiveId, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/user/uninvite`);

    const params = {
        username,
        hiveId,
        sessionToken,
    };

    const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject;
};

export const resetPasswordLink = async ({ username, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/user/reset`);

    const params = {
        username,
        sessionToken,
    };

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject;
};

export const patch = async ({ userId, userValues, beekeeperId, beekeeperValues, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/user/${userId}`);

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
            sessionToken,
            userValues,
            beekeeperId,
            beekeeperValues,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject.data;
    } else {
        throw responseObject;
    }
};

export const optIn = async ({ sessionToken, hiveId, userId }) => {
    const url = new URL(`${SERVER_URL}myhive/user/${hiveId}/optIn`);

    const params = { userId, sessionToken };

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject;
};

export const optOut = async ({ sessionToken, hiveId, userId }) => {
    const url = new URL(`${SERVER_URL}myhive/user/${hiveId}/optOut`);

    const params = { userId, sessionToken };

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(params),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject;
};

export const changePassword = async ({
    sessionToken,
    oldPassword,
    newPassword,
    username,
    userId,
}) => {
    const url = new URL(`${SERVER_URL}myhive/user/${userId}/password`);

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
            sessionToken,
            userId,
            username,
            oldPassword,
            newPassword,
        }),
        headers: { "Content-Type": "application/json" },
    });

    if (response.ok) {
        const responseObject = await response.json();
        return responseObject.data;
    } else {
        throw response;
    }
};

export const resetPassword = async ({ userId, resetToken, password }) => {
    const url = new URL(`${SERVER_URL}myhive/user/${userId}/resetPassword`);

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
            resetToken,
            password,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw responseObject;
    }
};

export const sendPasswordResetEmail = async ({ username, isEnglish }) => {
    const url = new URL(`${SERVER_URL}myhive/user/resetEmail`);
    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
            username,
            isEnglish,
        }),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject;
    } else {
        throw new Error(response.status);
    }
};

export const verifyPasswordResetToken = async ({ resetToken, userId }) => {
    const url = new URL(`${SERVER_URL}myhive/user/${userId}/verifyPasswordResetToken`);

    const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify({
            resetToken,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject.data;
    } else {
        throw responseObject;
    }
};

import { getVisitTypes } from "../networking/visitTypes";

const FETCHING = "visitType/FETCHING";
const FETCHED = "visitType/FETCHED";
const ERROR = "visitType/ERROR";

export default function visitTypesReducer(
    state = {
        visitTypes: {},
        visitTypesFetching: false,
        visitTypesError: null,
    },
    action = {}
) {
    switch (action.type) {
        case FETCHING: {
            return {
                ...state,
                visitTypesFetching: true,
            };
        }
        case FETCHED: {
            return {
                ...state,
                visitTypesFetching: false,
                visitTypesError: false,
                visitTypes: action.payload,
            };
        }
        case ERROR: {
            return {
                ...state,
                visitTypesFetching: false,
                error: action.payload,
            };
        }
        default:
            return state;
    }
}

export const visitTypesFetching = () => {
    return {
        type: FETCHING,
    };
};
export const visitTypesFetched = (visitTypes) => {
    return {
        type: FETCHED,
        payload: visitTypes,
    };
};

export const visitTypesError = (errorMessage) => {
    return {
        type: ERROR,
        payload: errorMessage,
    };
};

export const fetchVisitTypes = () => {
    return async (dispatch) => {
        dispatch(visitTypesFetching());
        return getVisitTypes()
            .then((visitTypes) => {
                dispatch(visitTypesFetched(visitTypes));
            })
            .catch((error) => {
                dispatch(visitTypesError(error.message));
            });
    };
};

import { login as auth } from "../Authentication";
import { patch } from "../networking/UserAccounts";

const UPDATE_USER_STARTED = "UPDATE_USER_STARTED";
const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

const LOGIN_STARTED = "LOGIN_STARTED";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";

const LOGOUT = "LOGOUT";

export default function userReducer(state = {}, action = {}) {
    switch (action.type) {
        case UPDATE_USER_FAILURE: {
            return {
                ...state,
                userLoading: false,
                userErrorMessage: action.payload,
            };
        }
        case UPDATE_USER_SUCCESS: {
            localStorage.setItem("user", JSON.stringify(action.payload));
            return {
                ...state,
                currentUser: action.payload,
                userLoading: false,
            };
        }
        case UPDATE_USER_STARTED: {
            return {
                ...state,
                userLoading: true,
            };
        }

        case LOGIN_FAILURE: {
            return {
                ...state,
                userLoading: false,
                loginLoading: false,
                loginError: action.payload,
            };
        }
        case LOGIN_SUCCESS: {
            localStorage.setItem("user", JSON.stringify(action.payload));
            return {
                ...state,
                currentUser: action.payload,
                loginLoading: false,
            };
        }
        case LOGIN_STARTED: {
            localStorage.removeItem("user");
            return {
                ...state,
                loginError: false,
                loginLoading: true,
            };
        }
        case LOGOUT: {
            Object.keys(localStorage).forEach((key) => {
                if (key.includes("Parse")) localStorage.removeItem(key);
                if (key.includes("user")) localStorage.removeItem(key);
            });
            return {
                ...state,
                currentUser: null,
            };
        }
        default:
            return state;
    }
}

export const updateUserStarted = () => {
    return {
        type: UPDATE_USER_STARTED,
    };
};

export const updateUserSuccess = (user) => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload: user,
    };
};

export const updateUserFailure = (error) => {
    return {
        type: UPDATE_USER_FAILURE,
        payload: error,
    };
};

export const updateUser = (args) => async (dispatch) => {
    try {
        dispatch(updateUserStarted());
        const user = await patch(args);
        dispatch(updateUserSuccess(user));
        return;
    } catch ({ message }) {
        dispatch(updateUserFailure(message));
        throw new Error();
    }
};

export const loginStarted = () => {
    return {
        type: LOGIN_STARTED,
    };
};

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    };
};

export const loginFailure = (error) => {
    return {
        type: LOGIN_FAILURE,
        payload: error,
    };
};

export const login =
    ({ username, password }) =>
    async (dispatch) => {
        dispatch(loginStarted());
        try {
            const userResponse = await auth({ username, password });
            const redirectUrl = userResponse.redirectUrl;
            delete userResponse.redirectUrl;
            dispatch(loginSuccess(userResponse));
            return redirectUrl;
        } catch (error) {
            dispatch(loginFailure(error));
        }
    };

export const logout = () => {
    return {
        type: LOGOUT,
    };
};

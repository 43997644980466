import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useSafeAwsUrl, getFriendlyHiveName } from "../util";
import moment from "moment-timezone";
import ReactGA from "react-ga";

import Slide from "@material-ui/core/Slide";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
    content: {
        fontFamily: theme.typography.fontFamily,
        color: "#5a5a5a",
        lineHeight: "1.4",
        fontSize: "14px",
        overflow: "clip",
        height: "225px",
        position: "relative",
    },
    buzzCard: {
        width: "49%",
        height: "250px",
        float: "left",
        backgroundColor: theme.palette.neutral.beige,
        marginRight: "10px",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "inherit",
        },
    },
    buzzImage: {
        width: "100%",
        height: "100%",
        borderRadius: theme.spacing(2),
        backgroundSize: "cover",
        [theme.breakpoints.down("md")]: {
            height: "150px",
        },
    },
    updatedAt: {
        marginBottom: theme.spacing(1),
        fontSize: "9px",
        color: theme.palette.primary.dark,
    },
    buzzText: {
        marginBottom: theme.spacing(2),
        fontSize: "14px",
    },
    buzzAuthor: {
        fontSize: "12px",
        fontWeight: "700",
        marginBottom: "2px",
    },
    buzzHive: {
        fontSize: "12px",
        marginBottom: theme.spacing(1),
    },
    hiveLink: {
        fontSize: "15px",
        fontWeight: "500",
        verticalAlign: "middle",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {},
    },
    byline: {
        position: "absolute",
        bottom: "0px",
    },
    linkIcon: {
        position: "relative",
        top: "3px",
    },
}));

const BuzzCardSmall = ({ history, buzz, direction, slideIn }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const fuzzyDate = (date) => moment(date).fromNow();
    const truncatedBuzz = `\`\`${buzz.buzz.text.substring(0, 180)}...\`\``;

    const hiveClick = (event, url) => {
        event.preventDefault();

        ReactGA.event({
            category: "landing_buzzes",
            action: "click",
            label: url,
        });

        history.push(`/${url}`);
    };

    const getAuthorName = (buzz) => {
        const hasFirstName = !!buzz.author.firstName;
        const hasLastName = !!buzz.author.lastName;
        if (!hasFirstName && !hasLastName) return "MyHive user";

        const name = `${hasFirstName ? `${buzz.author.firstName} ` : ""}${
            hasLastName ? buzz.author.lastName : ""
        }`;
        return name;
    };

    const getAuthorTagline = (buzz) => (buzz.author.tagline ? ` -${buzz.author.tagline}` : "");

    return (
        <Slide direction={direction} in={slideIn}>
            <div>
                <Card className={classes.buzzCard} elevation={0}>
                    <CardContent>
                        <Grid container spacing={2} className={classes.buzzGrid}>
                            <Grid item md={5} xs={12}>
                                <div
                                    className={classes.buzzImage}
                                    style={{
                                        backgroundImage: `url(${useSafeAwsUrl(buzz.file.url)})`,
                                    }}
                                />
                            </Grid>
                            <Grid item md={7} xs={12} className={classes.content}>
                                <Typography variant="body2" className={classes.updatedAt}>
                                    {fuzzyDate(buzz.updatedAt).toUpperCase()}
                                </Typography>
                                <Typography component="p" className={classes.buzzText}>
                                    {truncatedBuzz}
                                </Typography>
                                <div className={classes.byline}>
                                    <Typography component="p" className={classes.buzzAuthor}>
                                        {getAuthorName(buzz)} {getAuthorTagline(buzz)}
                                    </Typography>
                                    <Typography component="p" className={classes.buzzHive}>
                                        {`${getFriendlyHiveName(buzz.buzz.hives[0])}, ${
                                            buzz.buzz.hives[0].Ville
                                        }`}
                                    </Typography>
                                    <Typography component="p" className={classes.hiveLink}>
                                        <Link
                                            onClick={(event) =>
                                                hiveClick(event, buzz.buzz.hives[0].MaRucheUrl)
                                            }
                                            target="_blank"
                                            rel="noopener"
                                            color="secondary"
                                        >
                                            {t("visitHive")}
                                        </Link>
                                        <Link
                                            onClick={(event) =>
                                                hiveClick(event, buzz.buzz.hives[0].MaRucheUrl)
                                            }
                                            className={classes.linkIcon}
                                            target="_blank"
                                            rel="noopener"
                                            color="secondary"
                                        >
                                            <ArrowForwardIcon />
                                        </Link>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Slide>
    );
};

BuzzCardSmall.propTypes = {
    history: PropTypes.object,
    buzz: PropTypes.object,
    direction: PropTypes.string,
    slideIn: PropTypes.bool,
};

export default withRouter(BuzzCardSmall);

import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import "react-datepicker/dist/react-datepicker.css";
import "./VisitDatePicker.css";

moment.defineLocale("fr-short-weekdays", {
    parentLocale: "fr",
    weekdaysMin: ["D", "L", "M", "M", "J", "V", "S"],
    week: { dow: 1 },
});

moment.defineLocale("en-short-weekdays", {
    parentLocale: "en",
    weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
    week: { dow: 1 },
});

class WorkshopDatePicker extends Component {
    state = {
        clickedDate: null,
        selectedTimes: [],
        selectedTime: null,
    };

    render() {
        const { selectedTimes, clickedDate, selectedTime } = this.state;

        const dateList = selectedTimes.map((dateRun) => {
            const runMoment = moment(dateRun.showTime);
            const label = runMoment
                .locale(window.isEnglish ? "en" : "fr")
                .format(window.isEnglish ? "dddd MMMM Do [at] h:mm A" : "dddd D MMMM [à] H:mm");
            const key = runMoment.format("DD-MM-YYYY-HH-mm") + "-" + dateRun.runId;

            return (
                <div
                    className={
                        "timeslot-row"
                        + (runMoment.isSame(selectedTime && selectedTime.showTime)
                            ? " selected"
                            : "")
                    }
                    key={key}
                    onClick={() => this.handleListTimeClick(dateRun)}
                >
                    {label}
                </div>
            );
        });

        const listKey = clickedDate ? clickedDate.format("DD-MM-YYYY") : null;

        const continueButtonLabel = window.isEnglish ? "Continue" : "Continuer";

        // selectedTime sometimes gets filled by a proxy object, so we have to check the inner object
        const continueButtonDisabledClass =
            selectedTime && selectedTime.showTime ? "" : " disabled";

        return (
            <div id="visit-date-picker">
                <DatePicker
                    inline
                    disabledKeyboardNavigation
                    locale={window.isEnglish ? "en-short-weekdays" : "fr-short-weekdays"}
                    dayClassName={(date) => this.getDayClassName(date)}
                    selected={clickedDate}
                    onChange={this.handleChange}
                    onMonthChange={this.handleMonthChange}
                />
                <div id="timeslot-list" key={listKey}>
                    {dateList}
                </div>
                <div
                    className={"date-picker-continue-button" + continueButtonDisabledClass}
                    onClick={this.handleContinueClick}
                >
                    {continueButtonLabel}
                </div>
            </div>
        );
    }

    componentDidMount() {
        // Get first date with timeslots, we only have dates 14 days from now.
        const { timeSlots } = this.props;
        const firstSlotWithTimes = timeSlots.find((timeSlot) => timeSlot.times.length > 0);
        if (firstSlotWithTimes) this.handleChange(moment(firstSlotWithTimes.date), null);
    }

    handleChange = (clickedDate, selectedTime) => {
        const { timeSlots } = this.props;
        const selectedTimes = timeSlots
            .filter((timeSlot) => moment(timeSlot.date).isSame(clickedDate, "day"))
            .reduce((acc, curr) => acc.concat(curr.times), []);

        this.setState({
            clickedDate,
            selectedTime,
            selectedTimes,
        });
    };

    handleMonthChange = (date) => {
        const { timeSlots } = this.props;
        const firstSlotWithTimes = timeSlots.find(
            (timeSlot) => moment(timeSlot.date).isSame(date, "month") && timeSlot.times.length > 0
        );
        if (firstSlotWithTimes) this.handleChange(moment(firstSlotWithTimes.date), null);
    };

    handleListTimeClick = (selectedTime) => {
        this.setState({ selectedTime });
    };

    handleContinueClick = () => {
        const { selectedTime } = this.state;
        const { onReserve } = this.props;
        if (selectedTime && selectedTime.showTime) onReserve(selectedTime);
    };

    getDayClassName = (date) => {
        const isTheSameAsDate = (dateToCheck) => moment(dateToCheck).isSame(moment(date), "day");
        const { clickedDate } = this.state;
        const { timeSlots } = this.props;
        if (isTheSameAsDate(clickedDate)) return "selected";
        if (
            timeSlots.some(
                (timeSlot) => isTheSameAsDate(timeSlot.date) && timeSlot.times.length > 0
            )
        )
            return "available";
        return undefined;
    };
}

WorkshopDatePicker.propTypes = {
    timeSlots: PropTypes.arrayOf(PropTypes.object),
    onReserve: PropTypes.func,
};

export default WorkshopDatePicker;

import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";

import { useSnackbar } from "notistack";

export default function CopyToClipboard({ label, value, open, handleConfirm, loading }) {
    const { t } = useTranslation(["preferences", "common"]);
    const { enqueueSnackbar } = useSnackbar();

    const handleCopy = (value) => {
        if (!value) {
            enqueueSnackbar(`Generate a link to copy`, {
                variant: "default",
            });
            return;
        }
        navigator.clipboard
            .writeText(value)
            .then(() => {
                enqueueSnackbar(`Link copied to clipboard!`, {
                    variant: "default",
                });
            })
            .catch(() => {
                enqueueSnackbar(`Failed to copy`, {
                    variant: "error",
                });
            });
    };

    return (
        <TextField
            fullWidth
            id="copy-to-clipboard"
            label={open ? label : ""}
            value={value}
            variant="outlined"
            InputProps={{
                readOnly: true,
                endAdornment: open ? (
                    <Tooltip title={t("preferences:resetWarning.tooltips.copy")}>
                        <IconButton onClick={() => handleCopy(value)}>
                            <FileCopyIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title={t("preferences:resetWarning.tooltips.generate")}>
                        <IconButton onClick={() => handleConfirm(value)} disabled={loading}>
                            {loading ? <CircularProgress size={24} /> : <GetAppIcon size={24} />}
                        </IconButton>
                    </Tooltip>
                ),
            }}
        />
    );
}

CopyToClipboard.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    open: PropTypes.bool,
    handleConfirm: PropTypes.func,
    loading: PropTypes.bool,
};

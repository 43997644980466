import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import BuzzCardSmall from "./BuzzCardSmall";
import StepperDots from "./StepperDots";

import { fetchMediaForMain } from "../networking/Media";
import { chunks } from "../util";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    content: {
        maxWidth: "1150px",
        margin: "0 auto",
        paddingLeft: 12,
        paddingRight: 12,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        fontWeight: 500,
        fontSize: "30px",
        marginTop: theme.spacing(6),
    },
    subtitle: {
        // fontWeight: 700,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        color: "#5a5a5a",
        lineHeight: "1.5",
        fontSize: "14px",
    },
}));

const NearbyBuzzes = () => {
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm")); //bool
    const { currentIpLocation, ipLocationFetching } = useSelector((state) => state.ipLocation);

    const [media, setMedia] = useState([]);
    const [steps, setSteps] = useState([]);
    const [index, setIndex] = useState(0);
    const [slideIn, setSlideIn] = useState(true);
    const [slideDirection, setSlideDirection] = useState("left");

    const isEnglish = i18n.language.includes("en");
    const yourCity = isEnglish ? "your city" : "votre ville";

    const _fetchMedia = async () => {
        try {
            const newMedia = await fetchMediaForMain({
                // sessionToken,
                count: 6,
                isMaRuche: false,
                geoLocation: currentIpLocation,
            });

            setMedia(newMedia);
        } catch (error) {
            enqueueSnackbar(t("error.session"), {
                variant: "warning",
                preventDuplicate: true,
            });
        }
    };

    const onArrowClick = (direction) => {
        const increment = direction === "left" ? -1 : 1;
        const newIndex = index + increment;

        const oppDirection = direction === "left" ? "right" : "left";
        setSlideDirection(direction);
        setSlideIn(false);

        setTimeout(() => {
            setIndex(newIndex);
            setSlideDirection(oppDirection);
            setSlideIn(true);
        }, 250);
    };

    useEffect(() => {
        !ipLocationFetching && _fetchMedia();
    }, [ipLocationFetching]);

    useEffect(() => {
        const chunkSize = mobileScreen ? 1 : 2;
        setSteps(
            chunks(
                (media || []).sort(() => 0.5 - Math.random()),
                chunkSize
            )
        );
        setIndex(0);
    }, [mobileScreen, media]);

    return (
        <>
            <Grid container spacing={2} className={classes.content}>
                <Grid item md={6} xs={12}>
                    <Typography variant="h2" color="primary" className={classes.title}>
                        {t("landingPage:nearbyBuzzes.title")}
                    </Typography>
                    <Typography component="p" className={classes.subtitle}>
                        {t("landingPage:nearbyBuzzes.description", { city: yourCity })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.content}>
                <Grid item md={12} xs={12}>
                    {steps[index]
                        && steps[index].map((buzz, key) => (
                            <BuzzCardSmall
                                key={key}
                                buzz={buzz}
                                slideIn={slideIn}
                                direction={slideDirection}
                            />
                        ))}
                </Grid>
                <Grid item md={12} xs={12}>
                    <StepperDots
                        maxSteps={steps.length}
                        onArrowClick={onArrowClick}
                        index={index}
                    ></StepperDots>
                </Grid>
            </Grid>
        </>
    );
};

export default NearbyBuzzes;

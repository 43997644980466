import { Environment, environment } from "./environments";

export const NEXTGEN_API_URL = "https://alveole-newprod.buzz/api/";

const SERVER_URLS: { [env in Environment]: string } = {
  [Environment.Local]: "http://localhost:1337/",
  [Environment.Development]: "https://heroku-dev.alveole.buzz/",
  [Environment.Production]: "https://heroku.alveole.buzz/",
};

export const SERVER_URL = SERVER_URLS[environment];

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
    AppBar as MuiAppBar,
    Grid,
    Toolbar,
    Typography,
    IconButton,
    Collapse,
    Button,
    CircularProgress,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import CloseIcon from "@material-ui/icons/Close";

import { updateUser } from "../redux/user";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        position: "relative",
        zIndex: 100,
        color: theme.palette.background.white,
    },
    npsBar: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        margin: "0 auto",
    },
    question: {
        textAlign: "right",
        [theme.breakpoints.down("sm")]: {
            textAlign: "center",
        },
    },
    close: {
        position: "absolute",
        top: 0,
        right: 0,
    },
    closeIcon: {
        fontSize: "16px",
        color: theme.palette.background.white,
    },
    submit: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.background.white,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    rating: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    smallText: {
        fontSize: "12px",
    },
}));

const modes = {
    PROMPT: "PROMPT",
    SUCCESS: "SUCCESS",
    HIDE: "HIDE",
};

const NpsBar = () => {
    const { t } = useTranslation();
    const { currentUser } = useSelector((state) => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [npsMode, setNpsMode] = useState(modes.HIDE); //PROMPT, SUCCESS, HIDE
    const [loading, setLoading] = useState(false);
    const [score, setScore] = useState(0);

    useEffect(() => {
        if (!currentUser.NPS) {
            setTimeout(() => {
                setNpsMode(modes.PROMPT);
            }, 2000);
        }
    }, []);

    const handleIgnore = () => {
        const userValues = {
            NPS: {
                createdAt: new Date(),
            },
        };
        dispatch(
            updateUser({
                sessionToken: currentUser.sessionToken,
                userId: currentUser.objectId,
                userValues,
            })
        );
        setNpsMode(modes.HIDE);
    };

    const handleSubmit = async () => {
        const userValues = {
            NPS: {
                createdAt: new Date(),
                score,
            },
        };
        try {
            setLoading(true);

            await dispatch(
                updateUser({
                    sessionToken: currentUser.sessionToken,
                    userId: currentUser.objectId,
                    userValues,
                })
            );

            setLoading(false);
            setNpsMode(modes.SUCCESS);

            setTimeout(() => {
                setNpsMode(modes.HIDE);
            }, 3000);
        } catch (error) {
            setLoading(false);

            enqueueSnackbar(`Error: ${JSON.stringify(error.message)}`, {
                variant: "error",
            });
        }
    };

    return (
        <MuiAppBar className={classes.root} elevation={1}>
            <Collapse
                in={npsMode !== modes.HIDE}
                timeout={{
                    enter: 1000,
                    exit: 500,
                }}
            >
                <Toolbar className={classes.npsBar}>
                    {npsMode === modes.PROMPT && (
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.question}>
                                    {t("npsBar.question")}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                sm={6}
                                alignItems="center"
                                spacing={2}
                                className={classes.rating}
                            >
                                <Grid item>
                                    <Rating
                                        max={10}
                                        value={score}
                                        onChange={(_, newValue) => setScore(newValue)}
                                    />
                                    <Grid container justifyContent="space-between">
                                        <Typography className={classes.smallText}>
                                            {t("npsBar.notLikely")}
                                        </Typography>
                                        <Typography className={classes.smallText}>
                                            {t("npsBar.veryLikely")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        onClick={handleSubmit}
                                        disabled={loading || !score}
                                        className={classes.submit}
                                    >
                                        {t("npsBar.submit")}
                                        {loading && <CircularProgress size={24} />}
                                    </Button>
                                </Grid>
                            </Grid>
                            <IconButton
                                edge="start"
                                className={classes.close}
                                onClick={handleIgnore}
                            >
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        </Grid>
                    )}
                    {npsMode === modes.SUCCESS && (
                        <Grid container justifyContent="center">
                            <Typography>{t("npsBar.thanks")}</Typography>
                            <IconButton
                                edge="start"
                                className={classes.close}
                                onClick={() => setNpsMode(modes.HIDE)}
                            >
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        </Grid>
                    )}
                </Toolbar>
            </Collapse>
        </MuiAppBar>
    );
};

export default NpsBar;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import TimelineCard from "./TimelineCard";
import TimelineEmptyCard from "./TimelineEmptyCard";

import { BUZZ_FETCH_LIMIT, fetchBuzzes } from "../networking/Buzz";

import { connect } from "react-redux";
import CreateABuzz from "./CreateABuzz";
import { useOnScreen } from "../util";
import TimelineSkeleton from "./TimelineSkeleton";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { shareBuzzToLinkedin } from "../networking/linkedin";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import CardHeader from "@material-ui/core/CardHeader";
import { useTranslation } from "react-i18next";
import { useSafeAwsUrl } from "../util";
import { Typography, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2, 2, 1),
    },
    avatar: {
        backgroundColor: theme.palette.primary,
    },

    avatarImg: {
        height: "100%",
        width: "100%",
    },
    header: {
        padding: 0,
        marginBottom: 12,
    },
    formattedVideo: {
        backgroundColor: "black",
        display: "block",
        height: "319px",
        margin: 0,
        objectFit: "contain",
        width: "100%",
        borderRadius: 8,
    },
    formattedImage: {
        display: "block",
        height: "319px",
        objectFit: "cover",
        objectPosition: "50% 50%",
        width: "100%",
        borderRadius: 8,
        backgroundColor: "black",
    },
}));

const Timeline = ({ currentHive, currentUser, weather, inView = true }) => {
    const classes = useStyles();
    const { t } = useTranslation(["buzz"]);
    const [openLinkedin, setOpenLinkedin] = useState(false);
    const [submittingLinkedin, setSubmittingLinkedin] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const [currentShareBuzz, setCurrentShareBuzz] = useState({
        text: "",
        mediaUrl: "",
        mediaType: null,
    });
    //do not put this in a .map or on success will be called multiple times and invalidate your accessToken
    const { linkedInLogin } = useLinkedIn({
        clientId: "77aho6zajgj3db",
        redirectUri: `${window.location.origin}/linkedin`,
        onSuccess: async (code) => {
            setSubmittingLinkedin(true);
            await shareBuzzToLinkedin({
                code,
                mediaUrl: currentShareBuzz.mediaUrl,
                mediaType: currentShareBuzz.mediaType,
                text: `${currentShareBuzz.text}  

${window.location.href}`,
                redirectUri: `${window.location.origin}/linkedin`,
            });
            enqueueSnackbar(`Successfully Shared Post!`, {
                variant: "default",
            });
            setSubmittingLinkedin(false);
            setOpenLinkedin(false);
        },
        onError: () => {
            enqueueSnackbar(`Failed to share post`, {
                variant: "error",
            });
            setSubmittingLinkedin(false);
        },
        scope: "email openid w_member_social",
    });

    const [buzzes, setBuzzes] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const bottomRef = useRef();
    const topRef = useRef();
    const bottomReached = useOnScreen(bottomRef);
    const scrollTimelineTop = () =>
        topRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    const offsetRef = useRef(offset);
    const updateOffset = (nextOffset) => {
        offsetRef.current = nextOffset;
        setOffset(nextOffset);
    };

    const handleBuzzFetch = async () => {
        setLoading(true);
        const response = await fetchBuzzes({
            hives: [currentHive.objectId],
            sessionToken: currentUser && currentUser.sessionToken,
            offset: offsetRef.current,
        });
        setHasMore(response.hasMore);
        setBuzzes((prevBuzzes) => [...prevBuzzes, ...response.buzzes]);
        setLoading(false);
        updateOffset(offsetRef.current + BUZZ_FETCH_LIMIT);
    };

    const load = () => {
        setHasMore(true);
        setBuzzes([]);
        updateOffset(0);
        return !loading && handleBuzzFetch();
    };

    const reload = async () => {
        await load();
        scrollTimelineTop();
    };

    useEffect(() => {
        window.scroll(0, 0);
        load();
    }, [currentHive]);

    useEffect(() => {
        !loading && hasMore && bottomReached && handleBuzzFetch();
    }, [bottomReached]);

    // TODO localize the Alert
    return (
        <>
            <span ref={topRef} />
            <Dialog
                aria-labelledby="simple-dialog-title"
                aria-describedby="simple-dialog-description"
                open={openLinkedin}
                onClose={() => setOpenLinkedin(false)}
                fullWidth
                maxWidth={"sm"}
            >
                <Paper rounded="true" elevation={3} className={classes.paper}>
                    {currentUser ? (
                        <CardHeader
                            className={classes.header}
                            avatar={
                                <Avatar aria-label="author" className={classes.avatar}>
                                    {currentUser.avatar ? (
                                        <img
                                            alt=""
                                            className={classes.avatarImg}
                                            src={useSafeAwsUrl(currentUser.avatar.url)}
                                        />
                                    ) : null}
                                </Avatar>
                            }
                            title={`${currentUser.firstName || currentUser.username} ${
                                currentUser.lastName || ""
                            }`}
                            subheader={currentUser.tagline || "MyHive user"}
                        />
                    ) : null}

                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="buzz-text"
                        name="buzzText"
                        value={currentShareBuzz.text}
                        onChange={(e) =>
                            setCurrentShareBuzz({
                                ...currentShareBuzz,
                                text: e.target.value,
                            })
                        }
                        multiline={true}
                        label={
                            currentUser
                                ? `${t("whatsTheBuzz")}${
                                      currentUser.firstName || currentUser.username
                                  }?`
                                : t("whatsTheBuzz").slice(0, -2) + "?"
                        }
                        inputProps={{ maxLength: 3000 }}
                    />
                    <Typography
                        style={{ marginBottom: 12 }}
                        color="textSecondary"
                        align="right"
                        variant="subtitle2"
                    >
                        {`${currentShareBuzz.text.length}/3000`}
                    </Typography>
                    {currentShareBuzz.mediaUrl ? (
                        currentShareBuzz.mediaType?.split("/")[0] === "video" ? (
                            <video className={classes.formattedVideo} controls loop>
                                <source src={currentShareBuzz.mediaUrl} />
                            </video>
                        ) : (
                            <img
                                className={classes.formattedImage}
                                alt=""
                                src={currentShareBuzz.mediaUrl}
                            />
                        )
                    ) : null}
                    <Typography style={{ marginTop: 12 }} variant="subtitle2" color="textSecondary">
                        {window.location.href}
                    </Typography>
                    <Button
                        loading={submittingLinkedin}
                        type="button"
                        variant="contained"
                        color="primary"
                        style={{ marginBottom: 12, marginTop: 24 }}
                        onClick={linkedInLogin}
                        disabled={submittingLinkedin || !currentShareBuzz.text}
                        fullWidth
                    >
                        {t("postToLinkedin")}
                        {submittingLinkedin && (
                            <CircularProgress style={{ marginLeft: 4 }} size={16} />
                        )}
                    </Button>
                </Paper>
            </Dialog>
            {buzzes.map((buzz) => (
                <TimelineCard
                    buzz={buzz}
                    key={buzz.permaId}
                    reloadTimeline={reload}
                    setOpenLinkedin={setOpenLinkedin}
                    setCurrentShareBuzz={setCurrentShareBuzz}
                />
            ))}
            {!loading && !buzzes.length && <TimelineEmptyCard />}
            {loading && <TimelineSkeleton />}
            {currentUser && (currentHive.owned || currentUser.isStaff) && (
                <CreateABuzz
                    currentHive={currentHive}
                    reloadTimeline={reload}
                    weather={weather}
                    inView={inView}
                />
            )}
            <span ref={bottomRef} />
        </>
    );
};

const mapStateToProps = ({ user }) => {
    const { currentUser } = user;
    return { currentUser };
};

Timeline.propTypes = {
    currentHive: PropTypes.object,
    currentUser: PropTypes.object,
    weather: PropTypes.object,
    inView: PropTypes.bool,
};

export default connect(mapStateToProps, {})(Timeline);

import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ReactGA from "react-ga";
import debounce from "lodash/debounce";

import { setSearches } from "./redux/searchClients";
import { getFriendlyHiveName, getAvatarUrl } from "./util";

const gaSearchInput = debounce((category, label) => {
    ReactGA.event({
        category,
        action: "pauseinput",
        label,
    });
}, 800);

const useStyles = makeStyles((theme) => ({
    rootFocused: {
        [theme.breakpoints.down("xs")]: {
            position: "absolute !important",
            width: "calc(100% - 20px)",
            left: "0px",
            margin: "0 10px",
            // backgroundColor: theme.palette.background.paper,
        },
    },
    root: {
        minWidth: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        zIndex: "10",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "0px",
        },
    },
    popupIndicator: {
        display: "none",
    },
    search: {
        position: "relative",
        borderRadius: "24px",
        marginLeft: 0,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        // [theme.breakpoints.up("sm")]: {
        //   // marginLeft: theme.spacing(1),
        // },
    },
    inputRoot: {
        // color: "inherit",
        borderRadius: "24px",
        height: "35px",
        width: "100%",
        padding: theme.spacing(3),
    },
    inputInput: {
        transition: theme.transitions.create("width"),
        position: "absolute",
        "&::placeholder": {
            color: "transparent",
        },
        "&:focus": {
            position: "relative",
            "&::placeholder": {
                color: "unset",
            },
        },
        [theme.breakpoints.up("sm")]: {
            position: "relative",
            display: "unset",
            // width: "12ch",
            "&::placeholder": {
                color: "unset",
            },
            "&:focus": {
                // width: "20ch",
            },
        },
    },
    inputOutline: {
        [theme.breakpoints.down("xs")]: {
            border: "none",
        },
    },
    adornment: {
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    optionContainer: {
        display: "flex",
        alignItems: "flex-start",
        // width: "500px",
    },
    listItemAvatar: {
        marginTop: "8px",
    },
    popperRoot: {
        width: "60%",
        zIndex: 999,
        marginTop: "-120px",
        "& div": {
            borderRadius: "24px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const OPTIONS_LIMIT = 5;
const MIN_CHARACTERS_LENGTH = 2;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
    if (state.inputValue.length > MIN_CHARACTERS_LENGTH) {
        return state.inputValue ? defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT) : [];
    }
    return [];
};

function ClientSearch({
    searchClients,
    history,
    searchClientsFetching,
    setSearches,
    analyticsCategory = "clientsearch",
    ...props
}) {
    const { t } = useTranslation(); // pull in the "common" namespace by default

    const [inputValue, setInputValue] = useState("");

    const classes = useStyles();

    // Avoid forwarding staticContext prop
    const { staticContext: _, ...restProps } = props;

    const handleHiveSelect = (event, newValue) => {
        setSearches(newValue);
        newValue && history.push(`/${newValue.MaRucheUrl}`);
        ReactGA.event({
            category: analyticsCategory,
            action: "select",
            label: newValue.MaRucheUrl,
        });
        setInputValue("");
    };

    useEffect(() => {
        inputValue && gaSearchInput(analyticsCategory, inputValue);
    }, [inputValue]);

    return (
        <Autocomplete
            value={null}
            loading={searchClientsFetching}
            inputValue={inputValue}
            onInputChange={async (event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            clearOnBlur={true}
            blurOnSelect={true}
            onChange={handleHiveSelect}
            classes={{
                root: classes.root,
                focused: classes.rootFocused,
                popupIndicator: classes.popupIndicator,
            }}
            freeSolo={inputValue.length > MIN_CHARACTERS_LENGTH ? false : true}
            filterOptions={filterOptions}
            id="combo-box-demo"
            options={searchClients}
            getOptionLabel={(option) =>
                `${option.Nom || ""} ${option.MaRucheNom || ""} ${
                    option.ServicePointer.adresse || ""
                }`
            }
            noOptionsText={t("searchBar.noneFound")}
            PopperComponent={(params) => (
                <Popper
                    {...params}
                    open={true}
                    className={classes.popperRoot}
                    placement="bottom-end"
                />
            )}
            renderOption={(option) => (
                <div className={classes.optionContainer}>
                    <ListItemAvatar className={classes.listItemAvatar}>
                        <Avatar alt="client-avatar" src={getAvatarUrl(option)} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={getFriendlyHiveName(option)}
                        secondary={
                            <>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                ></Typography>
                                {`${option.ServicePointer.adresse}, ${option.ServicePointer.ville}`}
                            </>
                        }
                    />
                    <Divider />
                </div>
            )}
            renderInput={(params) => (
                <div ref={params.InputProps.ref} className={classes.search}>
                    <OutlinedInput
                        {...params.inputProps}
                        // Hack to Prevents dropdown from opening automatically.
                        onMouseDownCapture={(e) => e.stopPropagation()}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                            notchedOutline: classes.inputOutline,
                            adornedEnd: classes.endAdornment,
                            adornedStart: classes.adornment,
                        }}
                        placeholder={t("searchBar.placeholder")}
                        size="medium"
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        {...restProps}
                    />
                </div>
            )}
        />
    );
}

const mapStateToProps = ({ searchClient }) => {
    const { searchClients, searchClientsFetching } = searchClient;
    return { searchClients, searchClientsFetching };
};

ClientSearch.propTypes = {
    searchClients: PropTypes.array,
    history: PropTypes.object,
    searchClientsFetching: PropTypes.bool,
    setSearches: PropTypes.func,
    analyticsCategory: PropTypes.string,
    staticContext: PropTypes.object,
};

export default connect(mapStateToProps, { setSearches })(withRouter(ClientSearch));

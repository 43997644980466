import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const EN_DISCOVER_CTA = "https://hubs.ly/H0WYgjd0";
const FR_DISCOVER_CTA = "https://www.alveole.buzz/fr/debuter-un-projet/";

const useStyles = makeStyles((theme) => ({
    parent: {
        marginTop: theme.spacing(4),
    },
    content: {
        maxWidth: "1150px",
        margin: "0 auto",
        paddingLeft: 12,
        paddingRight: 12,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    title: {
        fontWeight: 500,
        fontSize: "50px",
        marginTop: theme.spacing(6),
    },
    subtitle: {
        // fontWeight: 700,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        color: "#5a5a5a",
        lineHeight: "1.5",
        fontSize: "14px",
    },
    ctaButton: {
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    ctaImage: {
        width: "100%",
        height: "250px",
        background:
            "url(https://www.alveole.buzz/wp-content/uploads/2021/03/2021-blogpost-bcorps-that-inspire-our-team-blog-hero-1.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: theme.spacing(2),
    },
}));

const JoinTheMovement = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation(["common", "landingPage"]);
    const isEnglish = i18n.language.includes("en");

    const engageCta = ({ label = "" }) => {
        ReactGA.event({
            category: "landing_cta",
            action: "click",
            label,
        });
    };

    return (
        <>
            <div className={classes.parent}>
                <Grid container spacing={2} className={classes.content}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2" color="primary" className={classes.title}>
                            {t("landingPage:discover.title")}
                        </Typography>
                        <Typography component="p" className={classes.subtitle}>
                            {t("landingPage:discover.description")}
                        </Typography>
                        <Button
                            className={classes.ctaButton}
                            href={isEnglish ? EN_DISCOVER_CTA : FR_DISCOVER_CTA}
                            onClick={() => engageCta({ label: "discover" })}
                            target="_blank"
                            variant="contained"
                            color="secondary"
                            disableElevation
                        >
                            {t("landingPage:discover.button")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={classes.ctaImage} />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default JoinTheMovement;

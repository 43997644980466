import React, { useState } from "react";
import { Row } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import InspectionCardStats from "./InspectionCardStats";
import DynamicHive from "./DynamicHive";
import { MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        marginTop: "15px",
    },
});

const localizeDateString = (date, isEnglish) =>
    moment(date)
        .locale(isEnglish ? "en" : "fr")
        .format(isEnglish ? "MMM D, YYYY" : "MMM D, YYYY");

const getIsQueenHealthy = (queenStatus) => {
    if (queenStatus === "queenright") return true;
    if (!queenStatus) return true;
    return false;
};

const InspectionSheetCard = ({ inspection }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation(["hiveTab"]);
    const isEnglish = i18n.language.includes("en");
    const inspectionHives = Object.entries(inspection.answers).map(([id], i) => ({
        id,
        label: `Hive ${i + 1}`,
    }));

    const [selectedHive, setSelectedHive] = useState(inspectionHives[0].id);
    const selectedHiveAnswers = inspection.answers[selectedHive];
    const { numBoxes, numHoneyFrames, numBroodFrames, numBeeFrames, queenStatus } =
        selectedHiveAnswers;
    const healthyQueen = t(getIsQueenHealthy(queenStatus) ? "yes" : "no");
    const inspectionDate = inspection.createdAt;

    const handleSelectHive = (event) => setSelectedHive(event.target.value);

    return (
        <>
            {inspectionHives.length > 1 ? (
                <Select
                    labelId="hive-selector"
                    fullWidth
                    value={selectedHive}
                    onChange={handleSelectHive}
                    classes={classes}
                >
                    {inspectionHives.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            ) : null}
            <DynamicHive
                eggFramesCount={numBroodFrames}
                honeyFramesCount={numHoneyFrames}
                beeFramesCount={numBeeFrames}
                numBoxes={numBoxes}
            />
            <InspectionCardStats
                eggFramesCount={numBroodFrames}
                honeyFramesCount={numHoneyFrames}
                beeFramesCount={numBeeFrames}
                numBoxes={numBoxes}
                healthyQueen={healthyQueen}
            />
            <Row>
                <div className="inspection-date-label">
                    {`Date: ${localizeDateString(inspectionDate, isEnglish)}`}
                </div>
            </Row>
        </>
    );
};

InspectionSheetCard.propTypes = {
    title: PropTypes.string,
    inspection: PropTypes.shape({
        createdAt: PropTypes.string,
        answers: PropTypes.objectOf(
            PropTypes.shape({
                numBoxes: PropTypes.number.isRequired,
                numHoneyFrames: PropTypes.number,
                numBroodFrames: PropTypes.number,
                numBeeFrames: PropTypes.number,
                queenStatus: PropTypes.string,
            })
        ),
    }),
};

export default InspectionSheetCard;

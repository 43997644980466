import React from "react";
import PropTypes from "prop-types";
import StatLabel from "./StatLabel";
import { useTranslation } from "react-i18next";

const InspectionCardStats = ({
    eggFramesCount,
    honeyFramesCount,
    beeFramesCount,
    healthyQueen,
    numBoxes,
}) => {
    const { t } = useTranslation(["hiveTab"]);
    if (eggFramesCount || numBoxes || honeyFramesCount || beeFramesCount || healthyQueen)
        return (
            <div>
                <StatLabel label={t("eggFramesCount")} stat={eggFramesCount} />
                <StatLabel label={t("honeyFramesCount")} stat={honeyFramesCount} />
                <StatLabel label={t("beeFramesCount")} stat={beeFramesCount} />
                <StatLabel label={t("numBoxes")} stat={numBoxes} />
                <StatLabel label={t("queenState")} stat={healthyQueen} />
            </div>
        );
    return <div></div>;
};

InspectionCardStats.propTypes = {
    eggFramesCount: PropTypes.number,
    honeyFramesCount: PropTypes.number,
    beeFramesCount: PropTypes.number,
    healthyQueen: PropTypes.string,
    inspection: PropTypes.shape({
        v2CadresMiel: PropTypes.number,
        RendezVousPointer: PropTypes.shape({
            Debut: PropTypes.shape({
                __type: PropTypes.string,
                iso: PropTypes.string,
            }),
            VisitTypeId: PropTypes.string.isRequired,
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
            objectId: PropTypes.string.isRequired,
            __type: PropTypes.string,
            className: PropTypes.string,
        }).isRequired,
        v2CadresCouvain: PropTypes.number,
        v2CadresAbeilles: PropTypes.number,
        v2EtatReine: PropTypes.number,
        noRuche: PropTypes.number,
        v2RucheMorte: PropTypes.number,
        v2Nourrissage: PropTypes.number,
        v2TraitementVarroa: PropTypes.number,
        v2Hivernage: PropTypes.number,
        v2Boites: PropTypes.number,
    }),
    isSpringOpening: PropTypes.bool,
    numBoxes: PropTypes.number,
};

export default InspectionCardStats;

import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Client } from "../typings/entities";
import { RootState } from "../redux/types";

interface Props {
  isPageIndexable: boolean; // Note: Blocking with either `robots.txt` or the server response's HTTP headers will take precedence over this value
}

const Head: React.FC<Props> = ({ isPageIndexable }) => {
  const { t } = useTranslation(["header"]);
  return (
    <Helmet>
      <title>{t("title")}</title>
      <meta name="description" content={t("description")} />
      <meta name="robots" content={isPageIndexable ? "index, follow" : "noindex, nofollow"} />
    </Helmet>
  );
};

const mapStateToProps = (state: RootState): Props => {
  const { clientUrl, clients } = state.client;
  const isClientPage: boolean = !!clientUrl;
  const client: Client | undefined = isClientPage ? clients.find((c: Client) => c.MaRucheUrl === clientUrl) : undefined;
  const isClientListed: boolean = client ? client.visibility !== "UNLISTED" : false;
  const isPageIndexable: boolean = isClientPage ? isClientListed : true;
  return { isPageIndexable };
};

export default connect(mapStateToProps, {})(Head);

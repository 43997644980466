import { createTheme } from "@mui/material/styles";
function toRem(pixel: number, rootFontSize = 16) {
  return `${pixel / rootFontSize}rem`;
}

import "@mui/material/styles/createPalette";
declare module "@mui/material/styles/createPalette" {
  export interface PaletteOptions {
    pill: SimplePaletteColorOptions;
    myhiveGreen: SimplePaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    myhiveGreen: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    pill: true;
  }
  interface ChipPropsVariantOverrides {
    iucnTag: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    square: true;
  }
}

declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions {
    h7: CSSProperties;
    xs: CSSProperties;
    sm: CSSProperties;
    md: CSSProperties;
    lg: CSSProperties;
    xl: CSSProperties;
    subtext: CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    subtext: true;
  }
}

const colors = {
  palette: {
    yellow: "#FFD43A",
    lightYellow: "#FFEDAD",
    orange: "#FE9D35",
    lightOrange: "#FED7AD",
    green: "#2EDB4B",
    lightGreen: "#D1FFD9",
    blue: "#34AFF7",
    lightBlue: "#B0DCFF",
    red: "#FF5252",
    lightRed: "#FFB8B8",
    teal: "#26A69A",
    purple: "#6200EE",
    myhiveGreen: "#58827F",
  },
  lightWarning: "#FFD0D0",
  warning: "#E53E3E",
  gray: {
    900: "#1A202C",
    800: "#2D3648",
    700: "#4A5468",
    600: "#717D96",
    500: "#A0ABC0",
    400: "#CBD2E0",
    300: "#E2E7F0",
    200: "#EDF0F7",
    100: "#F7F9FC",
  },
};

const fontSizes = {
  xs: toRem(12),
  sm: toRem(14),
  md: toRem(16),
  lg: toRem(18),
  xl: toRem(20),
};

const { palette } = createTheme();
export const MUItheme = createTheme({
  palette: {
    common: {
      black: colors.gray[800],
    },
    grey: colors.gray,
    pill: palette.augmentColor({ color: { main: colors.gray[600] } }),
    myhiveGreen: palette.augmentColor({ color: { main: colors.palette.myhiveGreen, contrastText: "#fff" } }),
    primary: {
      main: colors.palette.blue,
    },
    secondary: {
      main: colors.palette.orange,
    },
    error: {
      main: colors.palette.red,
    },
    warning: {
      main: colors.warning,
      light: colors.lightWarning,
    },
    info: {
      main: colors.palette.lightBlue,
    },
    success: {
      main: colors.palette.green,
    },
  },
  typography: {
    fontFamily: "Apercu Pro",
    h1: {
      // xxl
      fontSize: "56px",
      lineHeight: "120%",
      letterSpacing: "0.56px",
      color: "gray.800",
      fontWeight: 700,
    },
    h2: {
      // xl
      fontSize: "48px",
      lineHeight: "120%",
      letterSpacing: "0.48px",
      color: "gray.800",
      fontWeight: 700,
    },
    h3: {
      // lg
      fontSize: "40px",
      lineHeight: "120%",
      letterSpacing: "0.4px",
      color: "gray.800",
      fontWeight: 700,
    },
    h4: {
      // md
      fontSize: "32px",
      lineHeight: "130%",
      color: "gray.800",
      fontWeight: 700,
    },
    h5: {
      // sm
      fontSize: "24px",
      lineHeight: "140%",
      color: "gray.800",
      fontWeight: 700,
    },
    h6: {
      // xs
      fontSize: "20px",
      lineHeight: "140%",
      color: "gray.800",
      fontWeight: 700,
    },
    h7: {
      // xxs
      fontSize: "16px",
      lineHeight: "140%",
      color: "gray.800",
      fontWeight: 700,
    },
    xs: {
      color: colors.gray[800],
      fontSize: fontSizes.xs,
      lineHeight: "150%",
    },
    sm: {
      color: colors.gray[800],
      fontSize: fontSizes.sm,
      lineHeight: "150%",
      letterSpacing: "-0.14px",
    },
    md: {
      color: colors.gray[800],
      fontSize: fontSizes.md,
      lineHeight: "150%",
      letterSpacing: "-0.16px",
    },
    lg: {
      color: colors.gray[800],
      fontSize: fontSizes.lg,
      lineHeight: "150%",
      letterSpacing: "-0.18px",
    },
    xl: {
      color: colors.gray[800],
      fontSize: fontSizes.xl,
      lineHeight: "160%",
      letterSpacing: "-0.22px",
    },
    subtext: {
      fontSize: fontSizes.sm,
      fontWeight: 400,
      lineHeight: "128.571%",
    },
  },
  components: {
    MuiIconButton: {
      variants: [
        {
          props: { color: "square" },
          style: {
            color: colors.gray[800],
            borderRadius: "6px",
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "iucnTag" },
          style: {
            borderRadius: "3px",
            height: "22px",
            padding: "0 8px",
          },
        },
        {
          props: { color: "pill" },
          style: {
            height: "26px",
            fontWeight: 600,
          },
        },
      ],
    },
  },
});

import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const subscribe = async ({ hiveId, email, language = "en" }) => {
    const url = new URL(`${SERVER_URL}myhive/hive/${hiveId}/subscribe`);

    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
            hiveId,
            email,
            language,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject.data;
    } else {
        if (responseObject.status === 409) throw new Error(409);

        throw new Error(responseObject.message);
    }
};

export const unsubscribe = async ({ hiveId, email, decrypt }) => {
    const url = new URL(`${SERVER_URL}myhive/hive/unsubscribe`);

    const response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify({
            hiveId,
            email,
            decrypt,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject.data;
    } else {
        if (responseObject.status === 409) throw new Error(409);

        throw new Error(responseObject.message);
    }
};

export const get = async ({ hiveId, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/hive/${hiveId}`);
    const params = { sessionToken };

    if (sessionToken) url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    // the server says we should 301 redirect that URL to another spot. let the app know.
    if (responseObject.status === 301) throw responseObject.data;

    if (responseObject.status !== "OK") throw new Error("Bad things");

    return responseObject.data;
};

export const getRelationships = async ({ hiveId, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/hive/${hiveId}/relationships`);
    const params = { sessionToken };

    if (sessionToken) url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject.data;
};

export const getRelationship = async ({ userId, hiveId, sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/user/${hiveId}/relationship`);
    const params = { sessionToken, userId };

    if (sessionToken) url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject.data;
};

export const listMostActive = async ({ regionId }) => {
    const url = new URL(`${SERVER_URL}myhive/hive/buzzing/${regionId}`);

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (responseObject.status === 301) throw responseObject.data;
    if (responseObject.status !== "OK") throw new Error("More bad things");
    // console.log(responseObject.data)
    return responseObject.data;
};

export const listOwned = async ({ sessionToken }) => {
    const url = new URL(`${SERVER_URL}myhive/hive`);
    const params = { sessionToken };
    url.search = new URLSearchParams(params).toString();

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    return responseObject.data;
};

export const listSearchClients = async () => {
    const call = new URL(SERVER_URL + "clients/");
    const params = {
        sparse: true,
        json: true,
    };

    call.search = new URLSearchParams(params).toString();

    const response = await fetch(call, {
        method: "post",
        // body: JSON.stringify({ sessionToken }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();
    if (response.ok) {
        return responseObject;
    } else {
        throw responseObject;
    }
};

export const patch = async ({ sessionToken, properties, hiveId }) => {
    const url = new URL(`${SERVER_URL}myhive/hive/${hiveId}`);
    // console.log("IM PATCHING PATCHING  : ", sessionToken, properties, hiveId  )
    const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify({
            sessionToken,
            hiveId,
            properties,
        }),
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();

    if (response.ok) {
        return responseObject.data;
    } else {
        if (responseObject.status === 409) throw new Error(409);

        throw new Error(responseObject.message);
    }
};

import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Slide from "@material-ui/core/Slide";
import EmojiNatureIcon from "@material-ui/icons/EmojiNatureTwoTone";
import Avatar from "@material-ui/core/Avatar";

import { getFriendlyHiveName, getAvatarUrl } from "../util";

const OwnedHivesDialog = ({ open, handleClose, ownedHives, onClick }) => {
    const { t } = useTranslation("common");

    const handleCloseDialog = () => {
        handleClose();
    };

    const ownedHiveListItems = ownedHives.map((client) => {
        const { MaRucheUrl, id } = client;

        return (
            <ListItem button onClick={() => onClick(MaRucheUrl)} key={id}>
                <ListItemAvatar>
                    <Avatar alt="client-avatar" src={getAvatarUrl(client)} />
                </ListItemAvatar>
                <ListItemText primary={getFriendlyHiveName(client)} />
            </ListItem>
        );
    });

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xs"}
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="ownedHives-dialog-title"
            TransitionComponent={Transition}
            keepMounted
        >
            <DialogTitle id="ownedHives-dialog-title">
                {t("common:ownedHivesDialog.title")}
                <EmojiNatureIcon />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{t("common:ownedHivesDialog.description")}</DialogContentText>
                <List sx={{ pt: 0 }}>{ownedHiveListItems}</List>
            </DialogContent>
        </Dialog>
    );
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

OwnedHivesDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    ownedHives: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func,
};

export default OwnedHivesDialog;

import { SERVER_URL } from "./server";
import fetch from "node-fetch";

export const getRegions = async () => {
    const url = new URL(`${SERVER_URL}regions`);

    const response = await fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json" },
    });

    const responseObject = await response.json();
    if (response.status !== 200) throw new Error("error fetching regions");

    return responseObject;
};
